import React, { Component } from "react";
import AdminSidebar from "../sidebar/admin_sidebar";
import AdminHeader from "../header/admin_header";
import Table from "react-bootstrap/Table";
import config from "../../config/config.json";
import Moment from "react-moment";
import "./control.css";
import { NavLink } from "react-router-dom";
import { authenticationService } from "../../_services/authentication";
const currentUser = authenticationService.currentUserValue;

export default class IntroductionVideos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      login: false,
      loading: false,
      introductionVideo: []
    };
  }

  componentDidMount() {
    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${currentUser.data.token}`,
        "Content-Type": "application/json",
      },
    };
      fetch(
        config.API_URL +
        "getIntroductionVideos",
        requestOptions
      )
        .then((res) => res.json())
        .then(
          (result) => {
            this.setState({
              isLoaded2: true,
              introductionVideo: result.introductionVideo
            });
            console.log("getIntroductionVideos", this.state.introductionVideo);
          },
          (error2) => {
            this.setState({
              isLoaded2: true,
              error2,
            });
          }
        );
  }

  render() {
    const { introductionVideo } = this.state;
    return (
      <div>
        <AdminSidebar dataFromParent={this.props.location.pathname} />
        <AdminHeader />
        <div className="main_wrapper">
          <div className="inner_wraapper">
            <div className="container-fluid">
              <section className="d_text">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="col-sm-12">
                        <div className="color_div_on framwork_2">
                          <div className="business_detail">
                            <div className="">
                              <div className="heading">
                                <h4>Introduction Video</h4>
                              </div>
                              <hr className="line"></hr>
                              <div className="saved_cards">
                                
                                <div className="table_f">
                                  <Table striped bordered hover size="sm">
                                    <thead>
                                      <tr className="heading_color">
                                        <th style={{ width: "5%" }}>Sr.</th>
                                        <th>Video Title</th>
                                        <th>Video Link</th>
                                        <th>Date</th>
                                        <th style={{ width: "5%" }}>Edit </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {introductionVideo.map((item, key) => (
                                        <tr key={key}>
                                          <td>{key + 1}</td>
                                          <td>{item.title}</td>
                                          <td>{item.video_link}</td>
                                          <td><Moment
                                        format="DD-MMM-YYYY"
                                        withTitle
                                      >{item.created_at}</Moment></td>
                                          <td>
                                            <NavLink
                                              className="non_underline_link bold view_c"
                                              to={"/video/" + item.id + "/update_introduction_video"}
                                            >
                                              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M6.414 16L16.556 5.858l-1.414-1.414L5 14.586V16h1.414zm.829 2H3v-4.243L14.435 2.322a1 1 0 0 1 1.414 0l2.829 2.829a1 1 0 0 1 0 1.414L7.243 18zM3 20h18v2H3v-2z"/></svg>
                                            </NavLink>
                                          </td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  </Table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
