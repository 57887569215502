import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import Table from "react-bootstrap/Table";
import "../../Company Admin/Setting/setting.css";
import AdminSidebar from "../../sidebar/admin_sidebar";
import AdminHeader from "../../header/admin_header";

export default class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      uuid: "",
      uri: "",
    };
  }
  componentDidMount() {
    let urlArr = window.location.pathname.split("/");
    this.setState({
      uuid: urlArr[urlArr.length - 2],
      uri: urlArr[urlArr.length - 1],
    });
  }

  render() {
    const { uuid } = this.state;
    return (
      <div>
        <AdminSidebar dataFromParent={this.props.location.pathname} />
        <AdminHeader />
        <div className="main_wrapper">
          <div className="inner_wraapper">
            <div className="container-fluid">
              <section className="d_text">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="strp_progess">
                        <div className="hol_rell">
                          <div className="steps-form">
                            <div className="steps-row setup-panel"></div>
                          </div>
                        </div>
                      </div>
                      <div className="SVG Stepper">
                        <div className="stepperr_design">
                          <div className="color_div_step my-3">
                            <div className="include">
                              <section className="forms">
                                <div className="row">
                                  <div className="col-md-12 col-xs-12">
                                    <div className="table_f">
                                      <Table striped bordered hover size="sm">
                                        <thead>
                                          <tr className="heading_color">
                                            <th>MODULE</th>
                                            <th style={{ width: "20%" }}>
                                              RESPONSES
                                            </th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr>
                                            <td>
                                              <span>
                                                <i className="fal fa-calendar mx-3" />
                                              </span>
                                              INNOVATIVE PROGRAMMES
                                            </td>
                                            <td>
                                              <NavLink
                                                to={`/companies/${uuid}/innovative_programmes`}
                                                className="table-tag"
                                              >
                                                <span>
                                                  <i className="fa fa-eye"></i>
                                                </span>{" "}
                                                View{" "}
                                              </NavLink>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>
                                              <span>
                                                <i className="fal fa-calendar mx-3" />
                                              </span>
                                              POLICIES
                                            </td>
                                            <td>
                                              <NavLink
                                                to={`/companies/${uuid}/company_policies`}
                                                className="table-tag"
                                              >
                                                <span>
                                                  <i className="fa fa-eye"></i>
                                                </span>{" "}
                                                View{" "}
                                              </NavLink>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>
                                              <span>
                                                <i className="fal fa-calendar mx-3" />
                                              </span>
                                              REFER BUSINESS
                                            </td>
                                            <td>
                                              <NavLink
                                                to={`/companies/${uuid}/refer_business`}
                                                className="table-tag"
                                              >
                                                <span>
                                                  <i className="fa fa-eye"></i>
                                                </span>{" "}
                                                View{" "}
                                              </NavLink>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>
                                              <span>
                                                <i className="fal fa-calendar mx-3" />
                                              </span>
                                              COACHING
                                            </td>
                                            <td>
                                              <NavLink
                                                to={`/companies/${uuid}/coaching`}
                                                className="table-tag"
                                              >
                                                <span>
                                                  <i className="fa fa-eye"></i>
                                                </span>{" "}
                                                View{" "}
                                              </NavLink>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>
                                              <span>
                                                <i className="fal fa-calendar mx-3" />
                                              </span>
                                              ESG Annual Report
                                            </td>
                                            <td>
                                              <NavLink
                                                to={`/companies/${uuid}/annual_report`}
                                                className="table-tag"
                                              >
                                                <span>
                                                  <i className="fa fa-eye"></i>
                                                </span>{" "}
                                                View{" "}
                                              </NavLink>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </Table>
                                    </div>
                                  </div>
                                </div>
                              </section>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
