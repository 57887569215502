/* eslint-disable jsx-a11y/anchor-is-valid */
import Swal from "sweetalert2";
import React, { Component } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { Button, Header, Modal } from "semantic-ui-react";
import { sweetAlert, isValidEmail } from "../../utils/UniversalFunction";
import logo from "../../img/logosine.png";
import axios from "axios";
import "./login.css";
import "../sidebar/common.css";
import config from "../../config/config.json";
import { authenticationService } from "../../_services/authentication";
import env from "../../env";
import { NavLink } from "react-router-dom";
const baseURL = config.baseURL;
export default class signup extends Component {
  constructor(props) {
    super(props);
    // redirect to home if already logged in
    if (authenticationService.currentUserValue) {
      this.props.history.push("/admin");
    }

    this.state = {
      user: {
        email: "",
        password: "",
        firstname: "",
        lastname: "",
        isLoggedIn: false,
        userInfo: {
          name: "",
          emailId: "",
        },
      },
      type: "password",
      submitted: false,
      emailForResetPassword: "",
      captchaIsVerified: false,
      emailvalidation: false,
      emailvalidationMessage: "",
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleChange2 = this.handleChange2.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleVerification = this.handleVerification.bind(this);
    this.showHide = this.showHide.bind(this);
    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleSubmit2 = this.handleSubmit2.bind(this);
  }

  handleOpenModal(val) {
    this.setState({ activeModal: val });
    this.setState({ showModal: true });
  }

  handleCloseModal() {
    this.setState({ showModal: false });
    this.setState({ showModal: "" });
  }
  showHide(e) {
    e.preventDefault();
    e.stopPropagation();
    this.setState({
      type: this.state.type === "password" ? "input" : "password",
    });
  }

  onClose() {
    this.setState({
      setOpen: false,
    });
  }
  setOpen(event) {
    this.setState({
      setOpen: true,
    });
  }

  handleVerification(e) {
    this.setState({
      captchaIsVerified: true,
    });
  }

  // Logout Session and Update State
  logout = (response) => {
    console.log(response);
    let userInfo = {
      name: "",
      emailId: "",
    };
    this.setState({ userInfo, isLoggedIn: false });
  };

  handleChange(event) {
    const { name, value } = event.target;
    const { user } = this.state;

    if (name === "email") {
      let condition = isValidEmail(value);
      if (condition === true) {
        this.setState({
          emailValidation: true,
          emailValidationMessage: "",
        });
      } else {
        this.setState({
          emailValidation: false,
          emailValidationMessage: "Please check email format",
        });
      }
    }

    this.setState({
      user: {
        ...user,
        [name]: value,
      },
    });
  }

  handleChange2(e) {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  }

  handleSubmit(event) {
    event.preventDefault();
    this.setState({ submitted: true });
    const { user, captchaIsVerified, emailValidation } = this.state;
    let pushToRoute = "";
    // document.getElementById('user_captcha_input').value = "";
    if (user.email && user.password && captchaIsVerified) {
      if (emailValidation) {
        axios
          .post(config.API_URL + "admin/login", {
            email: user.email,
            password: user.password,
            token: captchaIsVerified,
          })
          .then((response) => {
            console.log("Response is here", response);
            Swal.fire({
              icon: "success",
              title: response.data.customMessage,
              showConfirmButton: true,
              timer: 1000,
            });
            let setResponse = {};
            setResponse.data = response.data.data;
            setResponse.data.role = response.data.data.role;
            let setSecondResponse = response.data.data.twoFaStatus;
            let setThirdResponse = response.data.data.userID;
            let setFourthResponse = response.data.data.role;
            localStorage.setItem("currentUser", JSON.stringify(setResponse));
            localStorage.setItem("userID", JSON.stringify(setThirdResponse));
            localStorage.setItem(
              "2faStatus",
              JSON.stringify(setSecondResponse)
            );
            localStorage.setItem("role", setFourthResponse);
            let check2faStatus = response.data.data.twoFaStatus === 0;
            if (check2faStatus) {
              pushToRoute = "/OTPVerify";
            } else {
              pushToRoute = "/admin";
            }
            window.location.href = baseURL + pushToRoute;
          })
          .catch(function (error) {
            if (error.response) {
              Swal.fire({
                icon: "error",
                title: error.response.data.customMessage,
                showConfirmButton: true,
                timer: 3000,
              });
            }
          });
      } else {
        Swal.fire({
          icon: "error",
          title: "Please check email format",
          showConfirmButton: true,
          timer: 1000,
        });
      }
    } else {
      Swal.fire({
        icon: "error",
        title: "Please fill all input",
        showConfirmButton: true,
        timer: 1500,
      });
    }
  }

  handleSubmit2(event) {
    event.preventDefault();
    const { emailForResetPassword } = this.state;
    if (emailForResetPassword) {
      axios
        .post(config.ADMIN_API_URL + "password/email", {
          email: emailForResetPassword,
        })
        .then((response) => {
          sweetAlert("success", response.data.message);
          window.location.reload(false);
        })
        .catch(function (error) {
          if (error.response) {
            sweetAlert("error", error.response.data.message);
          }
        });
    } else {
      sweetAlert("error", "Please fill Email Address");
    }
  }
  render() {
    const { user, submitted, type } = this.state;
    return (
      <div>
        <section className="login">
          <div className="login_part">
            <div className="sing_log">
              <div className="sing_one">
                <img src={logo} alt="logo" />
              </div>
              <div className="text_sing mb-4">
                <h4 className="Account">Login</h4>
                <p className="faster_oval">
                  Please fill out your information below to log into your
                  dashboard
                </p>
              </div>

              <form name="form" onSubmit={this.handleSubmit}>
                <div className="ster_form">
                  <div className="make_form">
                    <div className="row">
                      <div className="col-lg-12 col-xs-12">
                        <div className="login_bt form_sign">
                          <div
                            className={
                              "form-group fg" +
                              (submitted && !user.register_company_name
                                ? " has-error"
                                : "")
                            }
                          >
                            <label className="st_name" htmlFor="name">
                              Email
                            </label>
                            <input
                              className="form-control name_nf"
                              type="text"
                              name="email"
                              placeholder="Enter Register Email Address"
                              value={user.email}
                              onChange={this.handleChange}
                            />

                            {this.state.emailValidation === false && (
                              <div className="help-block">
                                {this.state.emailValidationMessage}
                              </div>
                            )}

                            {submitted && !user.email && (
                              <div className="help-block">
                                Email is required
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-12 col-xs-12">
                        <div className="form_sign password-eye">
                          <div className="img-eye">
                            <span onClick={this.showHide}>
                              {this.state.type === "input" ? (
                                <i className="fas fa-eye-slash"></i>
                              ) : (
                                <i className="fas fa-eye"></i>
                              )}
                            </span>
                          </div>
                          <div
                            className={
                              "form-group fg" +
                              (submitted && !user.email ? " has-error" : "")
                            }
                          >
                            <label className="st_name" htmlFor="name">
                              Password
                            </label>
                            <input
                              className="form-control name_nf"
                              type={type}
                              name="password"
                              placeholder="Enter your password"
                              value={user.password}
                              onChange={this.handleChange}
                            />
                            {submitted && !user.password && (
                              <div className="help-block">
                                Password is required
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form-check reset">
                    <span>
                      Forgot Password?{" "}
                      <NavLink className="aClass" to="/ResetPass">
                        Reset
                      </NavLink>
                    </span>
                  </div>
                  <div className="make_form">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="glee">
                          <div className="bacei">
                            <ReCAPTCHA
                              sitekey={env.GOOGLE_RECAPTCHA_SITE_KEY}
                              onChange={(e) => this.handleVerification(e)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <div className="view_bottoma">
                    <button type="submit" value="Submit" className="btn">
                      Login
                    </button>
                  </div> */}

                  <div className="global_link mx-auto d-flex justify-content-center">
                    {user.email && user.password ? (
                      <button
                        type="submit"
                        value="Submit"
                        className="page_width page_save w-100"
                      >
                        Login
                      </button>
                    ) : (
                      <button
                        type="submit"
                        value="Submit"
                        className="page_width page_save disabledd w-100"
                        disabled
                      >
                        Login
                      </button>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </section>

        <Modal open={this.state.setOpen} className="feedback2 feedback3">
          <Modal.Header>Reset Password</Modal.Header>
          <form>
            <Modal.Content>
              <Modal.Description>
                <Header>
                  Enter your registered email address below to receive a
                  password reset link.
                </Header>
                <div className="form-group">
                  <input
                    value={this.state.emailForResetPassword}
                    onChange={(e) => this.handleChange2(e)}
                    className="form-control padd"
                    name="emailForResetPassword"
                    placeholder="Enter your Register Email Address"
                    required
                  ></input>
                </div>
              </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
              <Button
                className="link_bal_next"
                content="Send"
                onClick={(e) => this.handleSubmit2(e)}
              />
              <Button className="mx-3" onClick={() => this.onClose(false)}>
                Cancel
              </Button>
            </Modal.Actions>
          </form>
        </Modal>
      </div>
    );
  }
}
