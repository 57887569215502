import React, { useState, useEffect } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import editorConfig from "./editorConfig";
import AdminSidebar from "../../sidebar/admin_sidebar";
import AdminHeader from "../../header/admin_header";
import "./document.css";
import { useLocation, useParams } from "react-router-dom";
import { authenticationService } from "../../../_services/authentication";
import config from "../../../config/config.json";
import { sweetAlert } from "../../../utils/UniversalFunction";

const currentUser = authenticationService.currentUserValue;

const TextEditor = () => {
  const [content, setContent] = useState(null);

  const [loading, setLoading] = useState(false);

  const [html, setHtml] = useState(null);

  const location = useLocation();
  const { uuid } = useParams();
  const handleChangeContent = (event, editor) => {
    const text = editor.getData();
    setHtml(text);
    // const test = ck-content
  };

  const handleSave = async () => {

    // set request parms
    const requestOptions = {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${currentUser.data.token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ template: html }),
    };

    // hit save request
    await fetch(
      config.API_URL + `repotings/updateReport/${uuid}`,
      requestOptions
    ).catch((err) => {
      alert("Content not saved");
    });

    sweetAlert("success", "Saved Successfully");
  };

  const fetchEditorContent = async () => {
    setLoading(true);
    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${currentUser.data.token}`,
        "Content-Type": "application/json",
      },
    };
    await fetch(
      config.API_URL + `repotings/getReportDetail/${uuid}`,
      requestOptions
    )
      .then((res) => res.json())
      .then((result) => {
        setLoading(false);
        // console.log("result", result.data.template);
        setContent(result.data.template);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchEditorContent();
    // eslint-disable-next-line
  }, []);
  return (
    <>
      <div>
        <AdminSidebar dataFromParent={location.pathname} />
        <AdminHeader />
        <div className="main_wrapper">
          <div className="inner_wraapper">
            <div className="container-fluid">
              <section className="d_text">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="col-sm-12">
                        <div className="color_div_on framwork_2">
                          <div className="business_detail">
                            <div className="">
                              <div className="heading">
                                <div className="heading__container">
                                  <h4>Reportings</h4>
                                  <button
                                    className="link_bal_next page_width white"
                                    variant="none"
                                    onClick={() => handleSave()}
                                  >
                                    Save
                                  </button>
                                </div>
                              </div>
                              <hr className="line"></hr>
                              <div className="saved_cards">
                                <div className="business_detail">
                                  <div className="document-editor">
                                    <div className="document-editor__toolbar"></div>
                                    <div className="document-editor__editable-container">
                                      {!loading && (
                                        <CKEditor
                                          config={editorConfig}
                                          onInit={(editor) => {
                                            window.editor = editor;
                                            const toolbarContainer =
                                              document.querySelector(
                                                ".document-editor__toolbar"
                                              );
                                            toolbarContainer.appendChild(
                                              editor.ui.view.toolbar.element
                                            );
                                          }}
                                          editor={Editor}
                                          data={content}
                                          onReady={(editor) => {
                                            editor.ui
                                              .getEditableElement()
                                              .parentElement.insertBefore(
                                                editor.ui.view.toolbar.element,
                                                editor.ui.getEditableElement()
                                              );
                                          }}
                                          onChange={(event, editor) =>
                                            handleChangeContent(event, editor)
                                          }
                                        />
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="App">
        <h2>CKEditor 5 using a custom build - DecoupledEditor</h2>


      </div> */}
    </>
  );
};

export default TextEditor;
