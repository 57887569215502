import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import AdminSidebar from "../../sidebar/admin_sidebar";
import AdminHeader from "../../header/admin_header";

export default class GovernancePage extends Component {
  render() {
    return (
      <div>
        <AdminSidebar dataFromParent={this.props.location.pathname} />
        <AdminHeader />
        <div className="main_wrapper">
          <div className="inner_wraapper">
            <div className="container-fluid">
              <section className="d_text">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="governance">
                        <div className="text_Parts">
                          <div className="text_governance">
                            <h5 className="governance_head">Introduction</h5>
                            <p className="governance_p my-3">
                              Good governance starts with understanding how a
                              business is run, which policies and procedures are
                              followed and who is accountable. The purpose of
                              this governance module is to provide a
                              comprehensive review of existing administrative
                              and governance policies. This process will give
                              you an outline of a range of policies which can
                              impact your ESG performance as a business. Not all
                              policies are appropriate for all businesses, and
                              in some instances may have a different name or
                              title.{" "}
                            </p>
                          </div>
                          <div className="reviewed">
                            <div className="reviewed_ones">
                              <h4 className="reviewed_h">
                                The below is a good process to follow to
                                understand if a policy should be uploaded and
                                reviewed.
                              </h4>
                              <p className="policy">
                                <span className="policy_icon">
                                  <i className="fal fa-chevron-right"></i>
                                </span>
                                Determine whether a policy is still needed or if
                                it should be combined with another
                                administrative policy;
                              </p>
                              <p className="policy">
                                <span className="policy_icon">
                                  <i className="fal fa-chevron-right"></i>
                                </span>
                                Determine whether the purpose and goal of the
                                policy is still being met;
                              </p>
                              <p className="policy">
                                <span className="policy_icon">
                                  <i className="fal fa-chevron-right"></i>
                                </span>
                                Determine whether changes are required to
                                improve the effectiveness or clarity of the
                                policy and procedures; and
                              </p>
                              <p className="policy">
                                <span className="policy_icon">
                                  <i className="fal fa-chevron-right"></i>
                                </span>
                                To ensure that appropriate education, monitoring
                                and ongoing review of the policy is occurring
                              </p>
                            </div>
                          </div>
                          <div className="succeed">
                            <div className="succeed_text">
                              <p className="succeed_one">
                                <span className="without_icon">
                                  <i className="fas fa-quote-left"></i>
                                </span>
                                Today, no business can succeed without
                                conforming to financial reporting standards. The
                                same should be true for non-financial reporting,
                                with equivalent levels of governance, assurance,
                                incentives and sanctions for non-compliance.
                                <span className="with_icon">
                                  <i className="fas fa-quote-right"></i>
                                </span>
                              </p>
                              <p className="Mortiz">
                                - Bob Mortiz, Global Chairman, PwC
                              </p>
                            </div>
                          </div>

                          <div className="global_link m-0">
                            <NavLink
                              to="/admin/GovernanceFast"
                              className="page_width page_save"
                            >
                              next
                            </NavLink>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
