import React, { Component } from "react";

export default class enviornmental_consideration extends Component {
  render() {
    return (
      <div>
        <div id="panel1" className="panel">
          <div className="table_well">
            <p className="actual">
              <strong>Greenhouse gas emissions (GHG):</strong> Actual or
              potential regulations such as carbon taxes, emissions trading
              schemes, and other direct or indirect costs. The GHG emissions
              under the Kyoto climate change agreement are carbon dioxide (CO2),
              methane (CH4), nitrous oxide (N2O), hydrofluorocarbons (HFCs),
              perfluorocarbons (PFCs), and sulfur hexafluoride (SF6).
            </p>
            <p className="actual">
              <strong>Sensitivity to extreme weather events: </strong>{" "}
              Incremental costs or the potential physical impact on assets
              associated with recurring (for example, hurricanes) or infrequent
              (droughts) severe weather events.
            </p>

            <p className="actual">
              <strong>Sensitivity to water scarcity:</strong> Potential costs
              related to the need for extracting or sourcing large quantities of
              water, or requiring on-site water treatment, in comparison to
              other water users of the same water basins or utilities.
            </p>
            <p className="actual">
              <strong>Waste, pollution, and toxicity:</strong> Potential fines
              or rising costs associated with prevention and treatment of waste
              and pollution, including hazardous waste and air pollution.
            </p>
            <p className="actual">
              <strong>Land use and biodiversity:</strong> Asset retirement
              obligations, developing natural land or potential operating
              constraints, or increased costs associated with protecting plant
              and animal life.
            </p>
          </div>
        </div>
      </div>
    );
  }
}
