/* eslint-disable jsx-a11y/iframe-has-title */
import React, { Component } from "react";
import "./form.css";

export default class ClientReview extends Component {
  render() {
    return (
      <div>
        <h2 className="bold mt-5 center">Zoho Form Testing</h2>
       <iframe className="FormSize" src='https://forms.zohopublic.com.au/esgreportingintelligence/form/ClientReview/formperma/sGxZoe3wUbbQ1cz-gXb8vQt3flu6Y93NnxH6bJil4xg'></iframe>
      </div>
    );
  }
}
