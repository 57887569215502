import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./App.css";
import "semantic-ui-css/semantic.min.css";
import Home from "./component/Company Sub Admin/Pages/home/home";
import Login from "./component/Login/login";
import OTPVerify from "./component/Login/otpverify";
import ResetPass from "./component/Login/resetpass";
import AddSubAdmin from "./component/Login/addSubAdmin";
// import Signup from "./component/signup/signup";
import Privacy from "./component/signup/privacy";
import Terms from "./component/signup/terms";
import Sidebar from "./component/sidebar/sidebar";
import AdminSidebar from "./component/sidebar/admin_sidebar";
import Header from "./component/header/header";
import AdminHeader from "./component/header/admin_header";
import Invite from "./component/signup/invite";
import SuperSubAdminInvite from "./component/signup/SuperSubAdminInvite";
// import ZohoUserDetails from "./component/Zoho Form/ZohoUserDetails";
// import FormDetails from "./component/Zoho Form/formdetails";
import ClientReview from "./component/Zoho Form/ClientReview";
import EsgReportingPie from "./component/Company Admin/esg_repoting/esg_reporting_pie";
import setting from "./component/Company Admin/Setting/setting";
// import Checkout from "./component/Company Admin/component/setting/payment_success";
import invoice from "./component/Company Admin/Setting/invoice";
import user_detail from "./component/Company Admin/Setting/user_detail";
import Google2FA from "./component/Company Admin/component/setting/google2FA";
import dashboard from "./component/Company_Super_Admin/dashboard/dashboard";
import companies from "./component/Company_Super_Admin/companies/companies";
import Activities from "./component/Company_Super_Admin/companies/activities";
import IndustriesType from "./component/Industry_Manage/industriesType";
import Industries from "./component/Industry_Manage/industries";
import AddIndustries from "./component/Industry_Manage/addIndustries";
import ADDIndustryType from "./component/Industry_Manage/addIndustryType";
import EditIndustryType from "./component/Industry_Manage/editIndustryType";
import EditIndustries from "./component/Industry_Manage/editIndustries";
import SmartUPLearningRecord from "./component/SmartUp/smartup_learning_record";
import SmartUPMembership from "./component/SmartUp/smartup_membership";
import SmartUPUsers from "./component/SmartUp/smartup_users";
import SmartUPChannels from "./component/SmartUp/smartup_channels";
import SmartUPUserDetails from "./component/SmartUp/smartup_user_details";
import SmartUPChannelDetails from "./component/SmartUp/smartup_channel_details";
import SmartupTabbing from "./component/SmartUp/smartup_tabbing";
import CompaniesTabbingPage from "./component/Company_Super_Admin/companies/company_tabbing_page";
import ModuleDetail from "./component/Company_Super_Admin/companies/modul_detail";
import subAdmin from "./component/Company_Super_Admin/companies/subAdmin";
import AdminInvoice from "./component/Company_Super_Admin/companies/invoice";
// import SupplierManagement from "./component/Company_Super_Admin/supplier-management/supplier_management";
import SupplierDetail from "./component/Company_Super_Admin/supplier-management/supplier_detail";
import invoiceSupplierManagement from "./component/Company_Super_Admin/supplier-management/invoice";
import supplierManagementField from "./component/Company_Super_Admin/supplier-management/supplier_management_field";
import AddQuestion from "./component/Company_Super_Admin/supplier-management/AddQuestion";
import QuestionForm from "./component/Company_Super_Admin/supplier-management/QuestionForm";
import EsgSubAdmin from "./component/Company_Super_Admin/esg_sub_admin/EsgSubAdmin";
import Revenue from "./component/Company_Super_Admin/Revenue/Revenue";
import sectorQuestionPage from "./component/Company_Super_Admin/companies/sectorQuestionPage";
import governancePage from "./component/Company_Super_Admin/companies/governance";
import SupplierPage from "./component/Company_Super_Admin/companies/supplierPage";
import supplierManagementDetail from "./component/Company_Super_Admin/companies/supplierManagementDetail";
import sustainablePage from "./component/Company_Super_Admin/companies/sustanaiblePage";
import carbonFootprintDetail from "./component/Company_Super_Admin/companies/carbonFootprintDetail";
import boardSkillPage from "./component/Company_Super_Admin/companies/boardSkillPage";
import boardSkillDetail from "./component/Company_Super_Admin/companies/boardSkillDetail";
import managementDetail from "./component/Company_Super_Admin/companies/managementDetails";
import EsgProduct from "./component/Company_Super_Admin/companies/EsgProduct";
import UserDetail from "./component/Company_Super_Admin/esg_sub_admin/UserDetail";
import StatusDetail from "./component/Company_Super_Admin/esg_sub_admin/StatusDetail";
import SupplierDetailPage from "./component/Company_Super_Admin/supplier-management/supplier_detail";
import SettingDetail from "./component/Company_Super_Admin/Setting/setting";
import SettingDetailPage from "./component/Company_Super_Admin/Setting/SettingDetailPage";
import GoogleTwoFA from "./component/Company_Super_Admin/Setting/twoFA";
import Introduction from "./component/Supplier/introduction";
import supplierForm from "./component/Supplier/supplierForm";
import SupplierQuestionDetail from "./component/Supplier/SupplierQuestionDetail";
import NotFoundRoute from "./component/Company Sub Admin/Pages/404";
import ScrollToTop from "./scrollToTop";
import CompaniesDetail from "./component/Company_Super_Admin/companies/company_detail";
import CompanyModule from "./component/Company_Super_Admin/companies/modules";
import Plans from "./component/Company_Super_Admin/companies/plans";
import ComapanySubAdmin from "./component/Company_Super_Admin/companies/subAdmin";
import ComapanybillingDetail from "./component/Company_Super_Admin/companies/billingDetail";
import GovernancePage from "./component/Company_Super_Admin/component/companies/governance";
import SocialPoliciesTabbing from "./component/Company_Super_Admin/component/companies/social_policies";
import CyberTechnologies from "./component/Company_Super_Admin/component/companies/cyber_technology";
import HealthSafetyPolicy from "./component/Company_Super_Admin/component/companies/health_safety_policy";
import EnviornmentalPolicyTabbing from "./component/Company_Super_Admin/component/companies/enviornmental_policy";
import OtpVerification from "./OtpVerification";

import NewPassword from "./component/Login/NewPassword";
import BillingNew from "./component/Company_Super_Admin/Setting/billingNew";
import TwoFaNew from "./component/Company_Super_Admin/Setting/TwoFaNew";
import Controls from "./component/Company_Super_Admin/companies/Controls";
import SectorQuestionCategories from "./component/Sector_Question_Manage/sectorQuestioncategories";
import SectorQuestionIndustryWise from "./component/Sector_Question_Manage/sectorQuestionindustrywise";
import SectorQuestions from "./component/Sector_Question_Manage/SectorQuestions";
import AddSectorQuestions from "./component/Sector_Question_Manage/addSectorQuestions";
import EditSectorQuestions from "./component/Sector_Question_Manage/editSectorQuestions";
import AddNewCompany from "./component/Company_Super_Admin/companies/addNewCompany";
import Congratulations from "./component/Company_Super_Admin/companies/congratulations";
import CompanyInvoice from "./component/Company_Super_Admin/companies/companyInvoice";
import Massage from "./component/signup/massage";
import ResetMassage from "./component/Login/resetMassage";
import AddInnovativeProgram from "./component/programmes/addInnovativeProgram";
import EditInnovativeProgram from "./component/programmes/editInnovativeProgram";
import InnovativeLists from "./component/programmes/innovativeLists";
import AddGlobalInnovativeProgram from "./component/globalProgrammes/addGlobalInnovativeProgram";
import EditGlobalInnovativeProgram from "./component/globalProgrammes/editGlobalInnovativeProgram";
import GlobalInnovativeLists from "./component/globalProgrammes/globalInnovativeLists";
import Dashboard from "./component/Company_Super_Admin/companies/dashboard";
import EmailVerification from "./component/Verfications/EmailVerfications";
import AddIntroductionVideos from "./component/introductionVideos/addIntroductionVideos";
import EditIntroductionVideos from "./component/introductionVideos/editIntroductionVideos";
import IntroductionVideos from "./component/introductionVideos/introductionVideos";
import Policies from "./component/policiesManage/policies";
import GovernancePolicies from "./component/policiesManage/governancePolicies";
import AddNewPolicy from "./component/policiesManage/addNewPolicy";
import EditPolicy from "./component/policiesManage/editPolicy";
import CyberAndTechnologyPolicies from "./component/policiesManage/cyberTechnologyPolicies";
import SocialPolicies from "./component/policiesManage/socialPolicies";
import HelthAndSeftyPolicies from "./component/policiesManage/helthSeftyPolicies";
import EnvironmentalPolicies from "./component/policiesManage/environmentalPolicies";
import OtherPolicies from "./component/policiesManage/otherPolicies";
import CompanyPolicies from "./component/companyPoliciesManage/policies";
import AddNewCompanyPolicy from "./component/companyPoliciesManage/addNewPolicy";
import EditCompanyPolicy from "./component/companyPoliciesManage/editPolicy";
import GovernanceCompanyPolicies from "./component/companyPoliciesManage/governancePolicies";
import CyberAndTechnologyCompanyPolicies from "./component/companyPoliciesManage/cyberTechnologyPolicies";
import SocialCompanyPolicies from "./component/companyPoliciesManage/socialPolicies";
import HelthAndSeftyCompanyPolicies from "./component/companyPoliciesManage/helthSeftyPolicies";
import EnvironmentalCompanyPolicies from "./component/companyPoliciesManage/environmentalPolicies";
import OtherCompanyPolicies from "./component/companyPoliciesManage/otherPolicies";
import OthersPolicies from "./component/Company_Super_Admin/component/companies/other_policy";
import Requests from "./component/Company_Super_Admin/Requests/Requests";
import ReferBusinessLists from "./component/referbusiness/referBusinessLists";
import RequestCoachingDataLists from "./component/coaching/requestCoachingDataLists";
import AnnualReport from './component/Company_Super_Admin/companies/annualReport';
// Reportings
import Reports from "./component/reportings/Reports";
import SectorReports from './component/reportings/SectorReports';
// import ReportDetail from "./component/reportings/ReportDetail";
import TextEditor from './component/reportings/ck_editor/TextEditor';
// import js_for_react from './component/signup/js_for_react';

function App() {
  return (
    <div className="App">
      <Router>
        <OtpVerification />
        <ScrollToTop />
        <Switch>
          <Route exact path="/" component={Login}></Route>
          <Route exact path="/home" component={Home}></Route>
          <Route exact path="/Login" component={Login}></Route>
          <Route exact path="/otpverify" component={OTPVerify}></Route>
          <Route exact path="/ResetPass" component={ResetPass}></Route>
          <Route exact path="/verify/:token" component={NewPassword}></Route>
          <Route exact path="/AddSubAdmin" component={AddSubAdmin}></Route>
          {/* <Route exact path="/signup" component={Signup}></Route> */}
          <Route exact path="/terms_and_conditions" component={Terms}></Route>
          <Route exact path="/privacy_policy" component={Privacy}></Route>
          <Route exact path="/sidebar" component={Sidebar}></Route>
          <Route exact path="admin/sidebar" component={AdminSidebar}></Route>
          <Route exact path="/header" component={Header}></Route>
          <Route exact path="admin/header" component={AdminHeader}></Route>
          <Route exact path="/invite/:uuid" component={Invite}></Route>
          <Route exact path="/verify_message" component={Massage}></Route>
          <Route exact path="/reset_massage" component={ResetMassage}></Route>
          <Route
            exact
            path="/SubAdmin/invite/:uuid"
            component={SuperSubAdminInvite}
          ></Route>
          <Route
            exact
            path="/email/verify/:token"
            component={EmailVerification}
          ></Route>

          {/* <Route
            exact
            path="/checkout/:success/:sessionId"
            component={Checkout}
          ></Route> */}

          {/* Company Admin */}
          <Route exact path="/setting" component={setting}></Route>
          <Route
            exact
            path="/esg_reporting_pie"
            component={EsgReportingPie}
          ></Route>

          <Route exact path="/invoice" component={invoice}></Route>
          <Route
            exact
            path="/user_detail/:uuid"
            component={user_detail}
          ></Route>

          {/* Company Super Admin */}
          <Route exact path="/admin" component={dashboard}></Route>
          <Route exact path="/companies" component={companies}></Route>
          <Route
            exact
            path="/add_new_company"
            component={AddNewCompany}
          ></Route>
          <Route
            exact
            path="/congratulations"
            component={Congratulations}
          ></Route>
          <Route
            exact
            path="/admin/CompaniesTabbingPage"
            component={CompaniesTabbingPage}
          ></Route>
          <Route
            exact
            path="/companies/:uuid/details"
            component={CompaniesDetail}
          ></Route>
          <Route
            exact
            path="/companies/:uuid/modules"
            component={CompanyModule}
          ></Route>
          <Route
            exact
            path="/companies/:uuid/dashboard"
            component={Dashboard}
          ></Route>
          <Route
            exact
            path="/companies/:uuid/sub-accounts"
            component={ComapanySubAdmin}
          ></Route>
          <Route
            exact
            path="/companies/:uuid/billing-details"
            component={ComapanybillingDetail}
          ></Route>
          <Route exact path="/companies/:uuid/plans" component={Plans}></Route>
          <Route
            exact
            path="/companies/:uuid/esg-reporting"
            component={ModuleDetail}
          ></Route>
          <Route exact path="/admin/subAdmin" component={subAdmin}></Route>
          <Route
            exact
            path="/companies/:uuid/invoice"
            component={CompanyInvoice}
          ></Route>
          <Route
            exact
            path="/admin/supplier_detail"
            component={SupplierDetail}
          ></Route>
          <Route
            exact
            path="/admin/invoiceSupplierManagement"
            component={invoiceSupplierManagement}
          ></Route>
          <Route
            exact
            path="/admin/supplierManagementField"
            component={supplierManagementField}
          ></Route>
          <Route
            exact
            path="/admin/AddQuestion"
            component={AddQuestion}
          ></Route>
          <Route
            exact
            path="/admin/QuestionForm"
            component={QuestionForm}
          ></Route>
          <Route exact path="/sub-users" component={EsgSubAdmin}></Route>
          <Route exact path="/revenue" component={Revenue}></Route>
          <Route exact path="/partners_request" component={Requests}></Route>
          <Route
            exact
            path="/revenue/invoice/:uuid"
            component={AdminInvoice}
          ></Route>
          <Route
            exact
            path="/companies/:uuid/sector-question"
            component={sectorQuestionPage}
          ></Route>
          <Route
            exact
            path="/admin/governance"
            component={governancePage}
          ></Route>
          <Route
            exact
            path="/companies/:uuid/governance/governance-policy"
            component={GovernancePage}
          ></Route>
          <Route
            exact
            path="/companies/:uuid/governance/social-policy"
            component={SocialPoliciesTabbing}
          ></Route>
          <Route
            exact
            path="/companies/:uuid/governance/cyber-and-policy"
            component={CyberTechnologies}
          ></Route>
          <Route
            exact
            path="/companies/:uuid/governance/other-policy"
            component={OthersPolicies}
          ></Route>
          <Route
            exact
            path="/companies/:uuid/governance/health-and-safety-policy"
            component={HealthSafetyPolicy}
          ></Route>
          <Route
            exact
            path="/companies/:uuid/governance/environmental-policy"
            component={EnviornmentalPolicyTabbing}
          ></Route>
          {/* <Route
            exact
            path="/admin/supplierPage"
            component={SupplierPage}
          ></Route> */}
          <Route
            exact
            path="/companies/:uuid/modules/suppliers"
            component={SupplierPage}
          ></Route>
          <Route
            exact
            path="/companies/:uuid/modules/supplier-management"
            component={supplierManagementDetail}
          ></Route>

          {/* <Route
            exact
            path="/admin/sustainablePage"
            component={sustainablePage}
          ></Route> */}
          <Route
            exact
            path="/companies/:uuid/modules/sustainable"
            component={sustainablePage}
          ></Route>
          <Route
            exact
            path="/companies/:uuid/modules/carbon-footprint"
            component={carbonFootprintDetail}
          ></Route>
          <Route
            exact
            path="/admin/boardSkillPage"
            component={boardSkillPage}
          ></Route>
          <Route
            exact
            path="/companies/:uuid/modules/board-skills"
            component={boardSkillDetail}
          ></Route>
          <Route
            exact
            path="/companies/:uuid/modules/management"
            component={managementDetail}
          ></Route>
          <Route
            exact
            path="/companies/:uuid/modules/esg-products"
            component={EsgProduct}
          ></Route>
          <Route exact path="/sub-users/:uuid" component={UserDetail}></Route>
          <Route
            exact
            path="/sub-users/:uuid/companies"
            component={StatusDetail}
          ></Route>
          {/* <Route
            exact
            path="/admin/StatusDetail"
            component={StatusDetail}
          ></Route> */}
          <Route
            exact
            path="/admin/SupplierDetailPage"
            component={SupplierDetailPage}
          ></Route>
          <Route exact path="/profile" component={SettingDetail}></Route>
          <Route exact path="/billing" component={BillingNew}></Route>
          <Route exact path="/2fa" component={TwoFaNew}></Route>

          <Route exact path="/admin/Google2FA" component={Google2FA}></Route>
          <Route exact path="/admin/twoFA" component={GoogleTwoFA}></Route>
          <Route
            exact
            path="/admin/SettingDetailPage"
            component={SettingDetailPage}
          ></Route>

          <Route
            exact
            path="/supplier/Introduction"
            component={Introduction}
          ></Route>
          <Route
            exact
            path="/supplier/supplierForm"
            component={supplierForm}
          ></Route>
          <Route
            exact
            path="/supplier/SupplierQuestionDetail"
            component={SupplierQuestionDetail}
          ></Route>
          <Route exact path="/activities" component={Activities}></Route>
          <Route exact path="/global_controls" component={Controls}></Route>
          <Route
            exact
            path="/industry_categories"
            component={IndustriesType}
          ></Route>
          <Route
            exact
            path="/industry/:id/industries_name"
            component={Industries}
          ></Route>
          <Route exact path="/add_industry" component={AddIndustries}></Route>
          <Route
            exact
            path="/industry/:id/update_industry"
            component={EditIndustries}
          ></Route>
          <Route
            exact
            path="/add_industry_type"
            component={ADDIndustryType}
          ></Route>
          <Route
            exact
            path="/industry/:id/update_industry_type"
            component={EditIndustryType}
          ></Route>

          <Route
            exact
            path="/sector_questions/:id/questions_tabs"
            component={SectorQuestionCategories}
          ></Route>
          <Route
            exact
            path="/questions_industry_wise"
            component={SectorQuestionIndustryWise}
          ></Route>
          <Route
            exact
            path="/sector_questions/:tab_name/:id/question_lists"
            component={SectorQuestions}
          ></Route>
          <Route
            exact
            path="/add_sector_questions"
            component={AddSectorQuestions}
          ></Route>
          <Route
            exact
            path="/sector_questions/:id/update_sector_question"
            component={EditSectorQuestions}
          ></Route>
          <Route
            exact
            path="/admin/Smartup_Tabbing"
            component={SmartupTabbing}
          ></Route>
          <Route
            exact
            path="/admin/SmartUP/LearningRecords"
            component={SmartUPLearningRecord}
          ></Route>
          <Route exact path="/admin/SmartUP" component={SmartUPUsers}></Route>
          <Route
            exact
            path="/admin/SmartUP/Users"
            component={SmartUPUsers}
          ></Route>
          <Route
            exact
            path="/admin/SmartUP/Membership"
            component={SmartUPMembership}
          ></Route>
          <Route
            exact
            path="/admin/SmartUP/Channels"
            component={SmartUPChannels}
          ></Route>
          <Route
            exact
            path="/admin/SmartUP/Users/Details"
            component={SmartUPUserDetails}
          ></Route>
          <Route
            exact
            path="/admin/SmartUP/Channels/Details"
            component={SmartUPChannelDetails}
          ></Route>
          {/* <Route exact path="/Zoho-Form" component={FormDetails}></Route> */}
          <Route exact path="/ClientReview" component={ClientReview}></Route>
          {/* <Route
            path="/Zoho-Form/Details/:id/details"
            component={ZohoUserDetails}
          ></Route> */}
          <Route exact path="/companies/:uuid/add_innovative_programmes" component={AddInnovativeProgram}></Route>
          <Route exact path="/companies/:uuid/refer_business" component={ReferBusinessLists}></Route>
          <Route exact path="/companies/:uuid/coaching" component={RequestCoachingDataLists}></Route>
          <Route exact path="/companies/:uuid/annual_report" component={AnnualReport}></Route>
          <Route exact path="/companies/:uuid/:id/update_innovative_programmes" component={EditInnovativeProgram}></Route>
          <Route exact path="/companies/:uuid/innovative_programmes" component={InnovativeLists}></Route>
          
          <Route exact path="/add_innovative_programmes" component={AddGlobalInnovativeProgram}></Route>
          <Route exact path="/program/:id/update_innovative_programmes" component={EditGlobalInnovativeProgram}></Route>
          <Route exact path="/innovative_programmes" component={GlobalInnovativeLists}></Route>

          <Route exact path="/add_introduction_videos" component={AddIntroductionVideos}></Route>
          <Route exact path="/video/:id/update_introduction_video" component={EditIntroductionVideos}></Route>
          <Route exact path="/introduction_videos" component={IntroductionVideos}></Route>

          <Route exact path="/policies" component={Policies}></Route>
          <Route exact path="/add_new_policy" component={AddNewPolicy}></Route>
          <Route exact path="/policy/:id/update_policy" component={EditPolicy}></Route>
          <Route exact path="/governance_policies" component={GovernancePolicies}></Route>
          <Route exact path="/cyber_&_technology_policies" component={CyberAndTechnologyPolicies}></Route>
          <Route exact path="/social_policies" component={SocialPolicies}></Route>
          <Route exact path="/health_&_sefty_policies" component={HelthAndSeftyPolicies}></Route>
          <Route exact path="/environmental_policies" component={EnvironmentalPolicies}></Route>
          <Route exact path="/other_policies" component={OtherPolicies}></Route>

          <Route exact path="/companies/:uuid/company_policies" component={CompanyPolicies}></Route>
          <Route exact path="/companies/:uuid/add_new_company_policy" component={AddNewCompanyPolicy}></Route>
          <Route exact path="/companies`  `:id/update_company_policy" component={EditCompanyPolicy}></Route>
          <Route exact path="/companies/:uuid/governance_company_policies" component={GovernanceCompanyPolicies}></Route>
          <Route exact path="/companies/:uuid/cyber_&_technology_company_policies" component={CyberAndTechnologyCompanyPolicies}></Route>
          <Route exact path="/companies/:uuid/social_company_policies" component={SocialCompanyPolicies}></Route>
          <Route exact path="/companies/:uuid/health_&_sefty_company_policies" component={HelthAndSeftyCompanyPolicies}></Route>
          <Route exact path="/companies/:uuid/environmental_company_policies" component={EnvironmentalCompanyPolicies}></Route>
          <Route exact path="/companies/:uuid/other_company_policies" component={OtherCompanyPolicies}></Route>
          <Route exact path="/reportings" component={Reports}></Route>
          <Route exact path="/reportings/sector" component={SectorReports}></Route>
          {/* <Route exact path="/report/:uuid" component={ReportDetail}></Route> */}
          <Route exact path="/report/:uuid" component={TextEditor}></Route>

          <Route path="*" component={NotFoundRoute} />
        </Switch>
      </Router>
    </div>
  );
}

export default App;
