/* eslint-disable react/jsx-pascal-case */
import React, { Component } from "react";
import "../Company Admin/Setting/setting.css";
import Table from "react-bootstrap/Table";
import AdminSidebar from "../sidebar/admin_sidebar";
import AdminHeader from "../header/admin_header";
import Smartup_Tabbing from "./smartup_tabbing";

export default class SmartUPUserDetails extends Component {
  render() {
    return (
      <div>
        <AdminSidebar dataFromParent={this.props.location.pathname} />
        <AdminHeader />
        <div className="main_wrapper">
          <div className="inner_wraapper">
            <div className="container-fluid">
              <section className="d_text">
                <div className="container-fluid">
                  <div className="row">
                  <Smartup_Tabbing />
                    <div className="col-sm-12">
                      <div className="SVG Stepper">
                        <div className="stepperr_design">
                          <div className="color_div_step my-5">
                            <div className="include">
                              <section className="forms">
                                <div className="row">
                                  <div className="col-md-12 col-xs-12">
                                    <form>
                                      <div className="business_detail">
                                        <div className="main_one">
                                          <div className="main_two">
                                            <h4>SmartUP User Details</h4>
                                          </div>
                                        </div>
                                        <hr className="line"></hr>
                                        <div className="form-group pb-3">
                                          <label htmlFor="exampleInputEmail1">
                                            Username
                                          </label>
                                          <input
                                            type="text"
                                            value="vairagi786"
                                            disabled
                                            name="register_company_name"
                                            className="form-control"
                                            id="exampleInputEmail1"
                                            aria-describedby="emailHelp"
                                            placeholder="Johnson &amp; Johnson"
                                          />
                                        </div>
                                        <div className="row">
                                          <div className="col-lg-6 col-xs-6">
                                            <div className="form-group pb-3">
                                              <label htmlFor="exampleInputPassword1">
                                                First Name
                                              </label>
                                              <input
                                                type="text"
                                                disabled
                                                value="Siddharth"
                                                name="firstName"
                                                className="form-control"
                                                id="exampleInputPassword1"
                                                placeholder="John"
                                              />
                                            </div>
                                          </div>
                                          <div className="col-lg-6 col-xs-6">
                                            <div className="form-group pb-3">
                                              <label htmlFor="exampleInputPassword1">
                                                Last Name
                                              </label>
                                              <input
                                                type="text"
                                                disabled
                                                value="Vairagi"
                                                name="lastName"
                                                className="form-control"
                                                id="exampleInputPassword1"
                                                placeholder="Cooper"
                                              />
                                            </div>
                                          </div>
                                        </div>
                                        <div className="row">
                                          <div className="col-lg-6 col-xs-6">
                                            <div className="form-group pb-3">
                                              <label htmlFor="exampleInputPassword1">
                                                Email Address
                                              </label>
                                              <input
                                                type="text"
                                                value="abc@gmail.com"
                                                name="company_industry"
                                                disabled
                                                className="form-control"
                                                id="exampleInputPassword1"
                                                placeholder="Business"
                                              />
                                            </div>
                                          </div>
                                          <div className="col-lg-6 col-xs-6">
                                            <div className="form-group pb-3">
                                              <label htmlFor="exampleInputPassword1">
                                                Role
                                              </label>
                                              <input
                                                type="text"
                                                value="User"
                                                disabled
                                                name="userCategory"
                                                className="form-control"
                                                id="exampleInputPassword1"
                                                placeholder="Partner"
                                              />
                                            </div>
                                          </div>
                                        </div>
                                        <div className="row">
                                          <div className="col-lg-6 col-xs-6">
                                            <div className="form-group pb-3">
                                              <label htmlFor="exampleInputPassword1">
                                                Created Date/Time
                                              </label>
                                              <input
                                                type="text"
                                                value="28/03/2022 :: 09:30AM"
                                                name="company_industry"
                                                className="form-control"
                                                disabled
                                                id="exampleInputPassword1"
                                                placeholder="Business"
                                              />
                                            </div>
                                          </div>
                                          <div className="col-lg-6 col-xs-6">
                                            <div className="form-group pb-3">
                                              <label htmlFor="exampleInputPassword1">
                                                Status
                                              </label>
                                              <input
                                                type="text"
                                                value="Enable"
                                                disabled
                                                name="userCategory"
                                                className="form-control"
                                                id="exampleInputPassword1"
                                                placeholder="Partner"
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="business_detail mt-3">
                                        <div className="heading">
                                          <h4>Channel Details</h4>
                                        </div>
                                        <hr className="line"></hr>
                                        <Table striped bordered hover size="sm">
                                          <thead>
                                            <tr className="heading_color">
                                              <th style={{ width: "5%" }}>ID</th>
                                              <th>Name</th>
                                              <th>Description </th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            <tr>
                                              <td>1</td>
                                              <td>Research</td>
                                              <td>The place where the innovative things happen. The place where the innovative things happen.</td>
                                            </tr>
                                            <tr>
                                              <td>2</td>
                                              <td>Compliance</td>
                                              <td>This channel is all about compliance. This channel is all about compliance.</td>
                                            </tr>
                                          </tbody>
                                        </Table>
                                      </div>
                                    </form>
                                  </div>
                                </div>
                              </section>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
