/* eslint-disable react/jsx-no-target-blank */
import React, { Component } from "react";
import AdminSidebar from "../../sidebar/admin_sidebar";
import AdminHeader from "../../header/admin_header";
import Table from "react-bootstrap/Table";
import { NavLink } from "react-router-dom";
import { Button, Modal } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import moment from "moment";
import { Pagination, Icon, Dropdown } from "semantic-ui-react";
import "../../Company Sub Admin/Pages/supplier_management/supplier_management.css";
import config from "../../../config/config.json";
import { sweetAlert } from "../../../utils/UniversalFunction";
import { authenticationService } from "../../../_services/authentication";
import Select from 'react-select';

const BackendBaseUrl = config.BASE_URL;
const currentUser = authenticationService.currentUserValue;


const criteriaList = [
  {
    label: 'Supplier Code of Conduct',
    value: 'Supplier Code of Conduct',
  },
  {
    label: 'ESG Policy',
    value: 'ESG Policy',
  },
  
  {
    label: 'Anti-bribery & Corruption Policy',
    value: 'Anti-bribery & Corruption Policy',
  },
  {
    label: 'Conflict of Interest',
    value: 'Conflict of Interest',
  },
  {
    label: 'Work, Health and Safety Policy',
    value: 'Work, Health and Safety Policy',
  },
  {
    label: 'Human Rights and Labour Policy',
    value: 'Human Rights and Labour Policy',
  },
  {
    label: 'Whistleblower Policy',
    value: 'Whistleblower Policy',
  },
  {
    label: 'Modern Slavery Act Statement',
    value: 'Modern Slavery Act Statement',
  },
  {
    label: 'Diversity, Equity and Inclusion Policy',
    value: 'Diversity, Equity and Inclusion Policy',
  },
  {
    label: 'Sustainability Policy',
    value: 'Sustainability Policy',
  },
  {
    label: 'Climate Change Policy',
    value: 'Climate Change Policy',
  },
  {
    label: 'Information and Cyber Security Policy',
    value: 'Information and Cyber Security Policy',
  },
  {
    label: 'Disaster Recovery and Business Continuity Plan',
    value: 'Disaster Recovery and Business Continuity Plan',
  },
  
  {
    label: 'Breach Detection and Reporting',
    value: 'Breach Detection and Reporting',
  },
]

export default class supplierManagementDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      uuid: "",
      login: false,
      activeModal: "",
      activeModal1: "",
      items: [],
      feeedbackLogs: [],
      loading: false,
      totalRows: 0,
      currentPage: 1,
      skip: 0,
      limit: 10,
      orderByName: "id",
      orderByValue: "DESC",
      setStartDate: null,
      setEndDate: null,
      search: "",
      pageCount: 0,
      totalData: 0,
      industryList: [],
      id: "",
      idd: "",
      userid: "",
      feedback: "",
      response: "",
      criteria: [],
      report: [],
      csvLink: "",
      openAddModal: false,
      formData: {
        id: null,
        companyName: null,
        firstName: null,
        lastName: null,
        email: null,
        industry: null,
        criteria: null,
      },
      industries: []
    };
    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleOpenModal1 = this.handleOpenModal1.bind(this);
    this.handleCloseModal1 = this.handleCloseModal1.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.serverRequest = this.serverRequest.bind(this);
    this.pageChange = this.pageChange.bind(this);
    this.onChangeRisk = this.onChangeRisk.bind(this);
    this.onFileChangeRes = this.onFileChangeRes.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
    this.getUploadedReportData = this.getUploadedReportData.bind(this);
    this.exportCSV = this.exportCSV.bind(this);
    this.onDateChange = this.onDateChange.bind(this);
  }

  handleChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  }

  onDateChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
    let setStartDate = null;
    let setEndDate = null;
    if (this.state.setStartDate === null) {
      if (name === "setStartDate") {
        setStartDate = value;
      }
    } else {
      if (value !== this.state.setStartDate && name === "setStartDate") {
        setStartDate = value;
      } else {
        setStartDate = this.state.setStartDate;
      }
    }

    if (this.state.setEndDate === null) {
      if (name === "setEndDate") {
        setEndDate = value;
      }
    } else {
      if (value !== this.state.setEndDate && name === "setEndDate") {
        setEndDate = value;
      } else {
        setEndDate = this.state.setEndDate;
      }
    }

    if (setStartDate !== null && setEndDate !== null) {
      setTimeout(() => {
        this.serverRequest();
      }, 200);
    }
  }

  onFileChange = (event) => {
    let urlArr = window.location.pathname.split("/");
    const finalUUId = urlArr[urlArr.length - 3];

    const formData = new FormData();
    // Update the formData object
    formData.append(
      "uploadImage",
      event.target.files[0],
      event.target.files[0].name
    );
    formData.append("section_name", "supplier_management");
    formData.append("sub_section", "supplier_management");
    const headers = {
      Authorization: `Bearer ${currentUser.data.token}`,
      Accept: "application/json",
    };
    axios
      .post(
        `${config.ADMIN_API_URL}company/${finalUUId}/module/supplier/upload/report`,
        formData,
        {
          headers,
        }
      )
      .then((response) => {
        sweetAlert("success", response.data.message);
      })
      .catch(function (response) {
        sweetAlert("error", response.data.message);
      });
  };

  exportCSV(event) {
    event.preventDefault();

    let urlArr = window.location.pathname.split("/");
    const finalUUId = urlArr[urlArr.length - 3];

    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${currentUser.data.token}`,
        "Content-Type": "application/json",
      },
    };

    fetch(
      `${config.ADMIN_API_URL}company/modules/${finalUUId}/supplierManagement/exportSupplyChain`,
      requestOptions
    )
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            csvLink: result.data,
          });
          let url = BackendBaseUrl + result.data;
          window.open(url, "_blank");
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error,
          });
        }
      );
  }

  getUploadedReportData = () => {
    let urlArr = window.location.pathname.split("/");
    const finalUUId = urlArr[urlArr.length - 2];
    const headers = {
      Authorization: `Bearer ${currentUser.data.token}`,
      Accept: "application/json",
    };
    fetch(
      `${config.ADMIN_API_URL}company/${finalUUId}/module/supplier/download/report/supplier_management/supplier_management`,
      {
        headers,
      }
    )
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            report: result.data,
          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error,
          });
        }
      );
    // company/:uuid/sector-question/download/report
  };

  handleOpenModal(val) {
    let userid = val.target.getAttribute("user-id");
    let id = val.target.getAttribute("data-id");
    this.setState({ activeModal: "login" });
    this.setState({ showModal: true, idd: id, userid: userid });
  }

  handleCloseModal() {
    this.setState({ showModal: false });
    this.setState({ showModal: "" });
  }

  handleOpenModal1(val) {
    let userid = val.target.getAttribute("user-id");
    let id = val.target.getAttribute("data-id");
    this.setState({ activeModal1: "login" });
    this.setState({ showModal1: true, idd: id, userid: userid });
    console.log("details", userid, id);

    const headers = {
      Authorization: `Bearer ${currentUser.data.token}`,
      Accept: "application/json",
    };
    fetch(config.ADMIN_API_URL + `getSupplierFeedback/${id}/${userid}`, {
      headers,
    })
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded2: true,
            feeedbackLogs: result.result,
          });
        },

        (error2) => {
          this.setState({
            isLoaded2: true,
            error2,
          });
        }
      );
  }

  handleCloseModal1() {
    this.setState({ showModal1: false });
    this.setState({ showModal1: "" });
  }

  onChange(e) {
    let feedback = e.target.value;
    this.setState({
      feedback: feedback,
    });
  }

  handleSubmit(event) {
    event.preventDefault();
    let { feedback } = this.state;
    let id = this.state.idd;
    let userid = this.state.userid;

    const headers = {
      Authorization: `Bearer ${currentUser.data.token}`,
      Accept: "application/json",
    };
    let parms = `addSupplierFeedback`;
    axios
      .post(
        config.ADMIN_API_URL + parms,
        {
          supplierID: id,
          user_id: userid,
          feedback: feedback,
        },
        { headers }
      )
      .then((response) => {
        sweetAlert("success", response.data.message);
        this.serverRequest();
      })
      .catch(function (error) {
        if (error.response) {
          sweetAlert("error", error.response[0].message);
        }
      });
  }

  onFileChangeRes = (e) => {
    let idData = e.target.getAttribute("data-id");
    const formData = new FormData();
    // Update the formData object
    if (e.target.files[0].name !== undefined) {
      formData.append("uploadImage", e.target.files[0]);
      formData.append("id", idData);
      // formData.append("uuid", uuid);
      const headers = {
        Authorization: `Bearer ${currentUser.data.token}`,
        Accept: "application/json",
      };
      let parms = `company/${this.state.uuid}/modules/supplierManagement/criteria_assess/udpateSupplierResponse`;
      axios
        .post(config.ADMIN_API_URL + parms, formData, { headers })
        .then((response) => {
          console.log("Upload Image ", response);
          sweetAlert("success", response.data.message);
          this.setState({ logo: response.data.data });
          this.serverRequest(this.state.uuid);
        })
        .catch(function (response) {
          console.log(response);
          sweetAlert("error", response.data.message);
        });
    }
  };

  onChangeRisk(e) {
    let riskData = e.target.value;
    let idData = e.target.getAttribute("data-id");
    const headers = {
      Authorization: `Bearer ${currentUser.data.token}`,
      Accept: "application/json",
    };
    let parms = `company/${this.state.uuid}/modules/supplierManagement/criteria_assess/udpateSupplierRisk`;
    axios
      .post(
        config.ADMIN_API_URL + parms,
        { risk: riskData, id: idData },
        { headers }
      )
      .then((response) => {
        sweetAlert("success", response.data.message);
        this.serverRequest();
      })
      .catch(function (error) {
        if (error.response) {
          sweetAlert("error", error.response[0].message);
        }
      });
  }

  serverRequest() {
    const { skip, limit, showModal, uuid, setStartDate, setEndDate } =
      this.state;

    if (showModal === true) {
      this.setState({
        showModal: false,
      });
    }
    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${currentUser.data.token}`,
        "Content-Type": "application/json",
      },
    };
    let parms = `company/${uuid}/modules/supplierManagement/criteria_assess?skip=${skip}&limit=${limit}&startDate=${setStartDate}&endDate=${setEndDate}`;
    fetch(config.ADMIN_API_URL + parms, requestOptions)
      .then((res) => res.json())
      .then(
        (data) => {

          this.setState({
            pageCount: Math.ceil(data.data.items.length / this.state.perPage),
            totalData: data.data.totalCount,
            items: data.data.items,
            criteria: data.criteria,
          });
        },
        (error) => {}
      );
  }

  pageChange(e, data) {
    console.log("Handle Click", data);
    let page = data.activePage;
    let pageNo = page === 1 ? 0 : (page - 1) * this.state.limit;
    console.log("clicked", pageNo);
    this.setState({
      skip: pageNo,
    });
    setTimeout(() => {
      this.serverRequest();
    }, 200);
    // console.log("order", orders);
  }

  componentDidMount() {
    this.getUploadedReportData();
    this.setState({ items: [] });
    let urlArr = window.location.pathname.split("/");
    this.setState({
      uuid: urlArr[urlArr.length - 3],
    });
    setTimeout(() => {
      this.serverRequest();
    }, 200);

    this.getIndustriesList();
  }

  async getIndustriesList() {
    try {
      const headers = {
        Authorization: `Bearer ${currentUser.data.token}`,
        Accept: "application/json",
      };
      const response = await axios.get(`${config.API_URL}getCompanyIndustoryName`, { headers: headers });

      console.log("response", response.data)

        const entities = response.data.companyIndustory.map((item) => {

          return {
            key: item.title,
            value: item.title,
            text: item.title
          }
        })

      // map through every industry and select only name
      this.setState({industries: entities});

    } catch (error) {
      // show error message
      console.log(error.message)
    }
  }

  async handleSupplierChange (e) {
    const {name, value} = e.target;
    this.setState({[name]: value});
  }


  handleOpenAddModal(item){
    let temp = {
      id:  item?.id,
      companyName: item?.companyName,
      firstName: item?.firstName,
      lastName: item?.lastName,
      email: item?.email,
      industry: item?.industry,
      criteria: item?.followingCriteria.split('>').map((item) => {
        return {
          label: item,
          value: item
        }
      })
    }

    this.setState({
      openAddModal: true,
      formData: temp
    });

  }

  
  handleCloseAddModal(){
    this.setState({openAddModal: false});
  }

  async handleFormChange (e) {
    const {name, value} = e.target;
    let temp = Object.assign(this.state.formData);
    temp[name] = value;
    this.setState({formData: temp});
    
  }

  handleSelectChange(value) {
    let temp = Object.assign(this.state.formData);
    temp.criteria = value;
    this.setState({formData: temp});
  }

  async handleSaveFormData(e) {

    e.preventDefault();
    const { uuid } = this.props.match.params;

    let payload = {
      uuid: uuid,
      id: this.state.formData.id,
      companyName: this.state.formData.companyName,
      firstName: this.state.formData.firstName,
      lastName: this.state.formData.lastName,
      email: this.state.formData.email,
      industry: this.state.formData.industry,
      criteria: this.state.formData.criteria,
    }
    try {
      const headers = {
        Authorization: `Bearer ${currentUser.data.token}`,
        Accept: "application/json",
      };

      await axios.put(`${config.ADMIN_API_URL}supplier/updateSupplierManagement`, payload, { headers: headers });
      sweetAlert("success", "Updated Successfully");

      // // make server request
      this.serverRequest();
      this.handleCloseAddModal();
    } catch (error) {
      console.log(error.message)
    }

    console.log("payload", payload);
    
  }

  render() {
    const { items, report, feeedbackLogs, skip } = this.state;
    const riskType = ["High", "Medium", "Low"];
    return (
      <div>
        <AdminSidebar dataFromParent={this.props.location.pathname} />
        <AdminHeader />
        <div className="main_wrapper">
          <div className="inner_wraapper">
            <div className="container-fluid">
              <section className="d_text">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="color_div_on framwork_2">
                        <div className="text_Parts">
                          <div className="text_Description">
                            <div className="d-flex justify-content-between">
                              <h5 className="motor">Introduction</h5>
                            </div>
                            <p className="specialize">
                              The Supplier ESG Assessment helps you get started
                              on your journey towards understanding social,
                              environmental, and business ethics practices
                              throughout your supply chain.
                            </p>
                            <p>
                              Based on responsible supply chain engagement
                              strategies at leading global businesses, the
                              Supplier ESG Assessment's proven indicator set
                              helps you quickly measure supplier performance and
                              identify your biggest opportunities to drive
                              supplier improvement.
                            </p>
                            <p>
                              We help you quickly create visibility, access
                              supplier intelligence, and accelerate progress
                              toward your goals.
                            </p>
                          </div>

                          <div className="variants">
                            <div className="file file--upload">
                              <label htmlFor="input-file">
                                <i
                                  className="fa fa-upload"
                                  aria-hidden="true"
                                ></i>
                                Upload Report
                              </label>
                              <input
                                // style={{ visibility: "hidden" }}
                                type="file"
                                id="input-file"
                                accept=".pdf, .doc, .docx, .xlsx, .pptx, .ppt, .ott, .odp"
                                name="governancePolicy"
                                onChange={this.onFileChange}
                              />
                            </div>
                            {report[0]?.file !== undefined && (
                              <a
                                className="outputFile"
                                target="_blank"
                                href={BackendBaseUrl + report[0]?.file ?? ""}
                              >
                                Preview
                              </a>
                            )}
                          </div>
                          <div className="directly p-0 mt-4">
                            <button
                              className="next_page_one mx-3"
                              type="button"
                              onClick={this.exportCSV}
                            >
                              <span className="Download_r">
                                <i className="fa fa-download"></i>
                              </span>
                              Download Supplier List
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-sm-12">
                      <div className="color_div_on framwork_2 mt-3">
                        <div className="business_detail">
                          <div className="saved_cards">
                            <div className="business_detail">
                              <div className="heading justify-content-start">
                                <h5 className="frame">
                                  Which of the following criteria would you like
                                  to assess?
                                </h5>
                              </div>
                              <div className="checkbox5">
                                <div className="form-group clobal_check ">
                                  <div className="form-div2">
                                    <div className="row">
                                      {this.state.criteria.map((item, key) => (
                                        <div className="form-check my-3 col-md-6">
                                          <input
                                            className="form-check-input"
                                            type="checkbox"
                                            checked={item.status}
                                          />
                                          <label
                                            className="form-check-label"
                                            htmlFor="flexCheckDefault"
                                          >
                                            {item.title}
                                          </label>
                                        </div>
                                      ))}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-sm-12">
                      <div className="color_div_on framwork_2 mt-3">
                        <div className="business_detail">
                          <hr className="line mt-5"></hr>
                          <div className="saved_cards">
                            <div className="business_detail">
                              <div className="heading">
                                <div className="heading_wth_text saveAndTitleContainer">
                                  <h4>Supply Chain ESG Risk Management</h4>
                                  {/* <div>
                                <button
                                  id="savbtnMain5"
                                  className="link_bal_next page_width white "
                                  variant="none"
                                  onClick={() => this.handleOpenAddModal()}
                                >
                              Add new supplier
                            </button>
                                      </div> */}
                                </div>
                                <form>
                                  <div className="row">
                            
                                    <div className="col-md-6 col-xs-12">
                                      
                                      <div className="form-group mb-4">
                                        <label htmlFor="exampleInputEmail1">
                                          From
                                        </label>
                                        <input
                                          type="date"
                                          onChange={
                                            (this.handleChange,
                                            this.onDateChange)
                                          }
                                          className="form-control date-picker"
                                          name="setStartDate"
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-6 col-xs-12">
                                      <div className="form-group">
                                        <label htmlFor="exampleInputEmail1">
                                          To
                                        </label>
                                        <input
                                          type="date"
                                          onChange={
                                            (this.handleChange,
                                            this.onDateChange)
                                          }
                                          className="form-control date-picker"
                                          name="setEndDate"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </form>
                              </div>
                            </div>
                            <div className="table_f table-responsive">
                              <Table striped bordered hover size="sm">
                                <thead>
                                  <tr className="heading_color">
                                    <th>ID</th>
                                    <th>Supplier</th>
                                    <th>Date Added</th>
                                    <th>Industry</th>
                                    <th>Criteria Assessed</th>
                                    <th>Response</th>
                                    <th>Identified Risk</th>
                                    <th>Comments</th>
                                    <th>Actions</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {items.map((item, key) => (
                                    <tr key={key}>
                                      <td>{key + 1 + skip}</td>
                                      <td>{item.companyName}</td>
                                      <td>
                                        {moment(item.createdAt)
                                          .utc()
                                          .format("DD-MM-YYYY")}
                                      </td>
                                      <td>{item.industry}</td>
                                      <td>{item.followingCriteria}</td>
                                      <td>
                                        <div className="d-flex justify-content-center">
                                          <span className="upload mb-4 upload-document">
                                            <i className="fas fa-upload"></i>
                                            <input
                                              className="upload_files download_files_supplier"
                                              type="file"
                                              name="uploadImage"
                                              accept=".pdf, .doc, .docx, .xlsx, .pptx, .ppt, .ott, .odp"
                                              data-id={item.id}
                                              onChange={this.onFileChangeRes}
                                            />
                                          </span>
                                          {item.response ? (
                                            <a
                                            className="mx-3"
                                            target="_blank"
                                            href={
                                              config.BASE_URL + item.response
                                            }
                                          >
                                            <span className="upload mb-4 upload-document">
                                              <i className="fas fa-download"></i>
                                            </span>
                                          </a>
                                          ): 
                                          // eslint-disable-next-line
                                          <a
                                            className="mx-3"
                                            target="_blank"
                                            style={{ opacity: "0.4" }}
                                          >
                                            <span className="upload mb-4 upload-document">
                                              <i className="fas fa-download"></i>
                                            </span>
                                          </a>
                                          }

                                        </div>
                                      </td>
                                      <td>
                                        <select
                                          className="form-select select-s"
                                          aria-label="Default select example"
                                          onChange={this.onChangeRisk}
                                          data-id={item.id}
                                        >
                                          <option>Select</option>
                                          {riskType.map((itemss) => (
                                            <option
                                              selected={
                                                itemss === item.identified_risk
                                              }
                                              value={itemss}
                                            >
                                              {itemss}
                                            </option>
                                          ))}
                                        </select>
                                      </td>
                                      <td>
                                        <span className="global_link mx-0">
                                          <button
                                            className="btn btn-link supply-addd"
                                            variant="none"
                                            data-id={item.id}
                                            user-id={item.userId}
                                            onClick={(e) =>
                                              this.handleOpenModal(e)
                                            }
                                          >
                                            {" "}
                                            <span
                                              data-id={item.id}
                                              user-id={item.userId}
                                              className="view-eye supply-add"
                                            >
                                              <i
                                                data-id={item.id}
                                                user-id={item.userId}
                                                className="fa fa-pencil mx-2"
                                              ></i>
                                              ADD
                                            </span>{" "}
                                          </button>
                                        </span>
                                      </td>
                                      <td>
                                        <div className="actionsCont">
                                          <i
                                            onClick={() => this.handleOpenAddModal(item)}
                                            data-id={item.id}
                                            user-id={item.userId}
                                            className="fa fa-pencil"
                                          ></i>
                                          <NavLink
                                              to="#"
                                              data-id={item.id}
                                              user-id={item.userId}
                                              onClick={(e) =>
                                                this.handleOpenModal1(e)
                                              }
                                            >
                                              <i
                                                data-id={item.id}
                                                user-id={item.userId}
                                                className="fa fa-info center"
                                              ></i>
                                            </NavLink>

                                        </div>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </Table>
                              <Pagination
                                className="mx-auto pagination"
                                defaultActivePage={1}
                                onPageChange={this.pageChange}
                                ellipsisItem={{
                                  content: <Icon name="ellipsis horizontal" />,
                                  icon: true,
                                }}
                                firstItem={{
                                  content: <Icon name="angle double left" />,
                                  icon: true,
                                }}
                                lastItem={{
                                  content: <Icon name="angle double right" />,
                                  icon: true,
                                }}
                                prevItem={{
                                  content: <Icon name="angle left" />,
                                  icon: true,
                                }}
                                nextItem={{
                                  content: <Icon name="angle right" />,
                                  icon: true,
                                }}
                                totalPages={Math.ceil(
                                  this.state.totalData / this.state.limit
                                )}
                              />
                              {/* <div className="pagination_billing justify-content-between">
                                <ul>
                                  <li>
                                    <NavLink to="">
                                      <span>
                                        <i className="fal fa-arrow-left mx-3"></i>
                                      </span>
                                      Back
                                    </NavLink>
                                  </li>
                                  <li>
                                    <NavLink to="">
                                      Showing 1-10 of 1023
                                    </NavLink>
                                  </li>
                                  <li className="justify-align-right">
                                    <NavLink to="">
                                      Next
                                      <span>
                                        <i className="fal fa-arrow-right mx-3"></i>
                                      </span>
                                    </NavLink>
                                  </li>
                                </ul>
                              </div> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>


        <Modal
          animation={true}
          size="md"
          className="modal_box"
          shadow-lg="border"
          show={this.state.openAddModal}
        >
          <div className="modal-lg">
            <Modal.Header className="pb-0">
              <Button variant="outline-dark" onClick={() => this.handleCloseAddModal()}>
                <i className="fa fa-times"></i>
              </Button>
            </Modal.Header>
            <div className="modal-body vekp pt-0">
              <div className="row">
                <div className="col-md-12">
                  <div className="pb3">
                    <h5 className="feedbackTitle mainTitleSupplier">Update Supplier</h5>
                    <form onSubmit={this.handleSubmit}>
                      <div className="mb-3">
                        <div className="supplierInpCont">
                          <label  className="form-check-label supplierFormLable" htmlFor="flexCheckDefault">
                            Supplier Company Name*
                          </label>
                          <input
                            type="text"
                            className="form-control supplierInp"
                            placeholder="Enter Company Name"
                            value={this.state.formData.companyName}
                            name="companyName"
                            onChange={(e) => this.handleFormChange(e)}
                          />
                          </div>
                          <div className="supplierInpCont">
                            <label  className="form-check-label supplierFormLable" htmlFor="flexCheckDefault">
                            Contact First Name*
                            </label>
                            <input
                              type="text"
                              className="form-control supplierInp"
                              placeholder="Enter First Name"
                              name="firstName"
                              value={this.state.formData.firstName}
                              onChange={(e) => this.handleFormChange(e)}
                            />
                          </div>
                        <div className="supplierInpCont">
                          <label  className="form-check-label supplierFormLable" htmlFor="flexCheckDefault">
                          Contact Last Name*
                          </label>
                          <input
                            type="text"
                            className="form-control supplierInp"
                            placeholder="Enter Last Name"
                            name="lastName"
                            value={this.state.formData.lastName}
                            onChange={(e) => this.handleFormChange(e)}
                          />
                        </div>

                        <div className="supplierInpCont">
                          <label  className="form-check-label supplierFormLable" htmlFor="flexCheckDefault">
                          Contact Email Address*
                          </label>
                          <input
                            type="text"
                            className="form-control supplierInp"
                            placeholder="Enter Email Address"
                            name="email"
                            value={this.state.formData.email}
                            onChange={(e) => this.handleFormChange(e)}
                          />
                        </div>

                        <div className="supplierInpCont">
                          <label  className="form-check-label supplierFormLable" htmlFor="flexCheckDefault">
                          Industry*
                          </label>
                          <Dropdown 
                            placeholder='State' 
                            search 
                            selection 
                            options={this.state.industries} 
                            fluid
                            name="industry"
                            value={this.state.formData.industry}
                            className="supplierInp"
                            onChange={(e) => this.handleFormChange(e)}
 
                          />
                        </div>
                        <div className="supplierInpCont">
                          <label  className="form-check-label supplierFormLable" htmlFor="flexCheckDefault">
                          Criteria Access
                          </label>
                          <Select
                            value={this.state.formData.criteria}
                            id={5}
                            name="criteria"
                            onChange={(e) => this.handleSelectChange(e)}
                            options={criteriaList}
                            // className="supplierInp" 
                            isMulti
                        />

                        </div>
                    
                      </div>
                      <div className="group_link d-flex supplierBtnConts">
                        <button
                          type="button"
                          className="link_bal_next  page_width cnlSupplierBtn"
                          onClick={() => this.handleCloseAddModal()}
                        >
                          Cancel
                        </button>
                        <button className="page_save page_width svSupplierBtn" type="submit" onClick={(e) => this.handleSaveFormData(e)}>

                          SAVE
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>



        <Modal
          animation={true}
          size="md"
          className="modal_box"
          shadow-lg="border"
          show={this.state.showModal && this.state.activeModal === "login"}
        >
          <div className="modal-lg">
            <Modal.Header className="pb-0">
              <Button variant="outline-dark" onClick={this.handleCloseModal}>
                <i className="fa fa-times"></i>
              </Button>
            </Modal.Header>
            <div className="modal-body vekp pt-0">
              <div className="row">
                <div className="col-md-12">
                  <div className="pb3">
                    <h5 className="feedbackTitle">Feedback</h5>
                    <form onSubmit={this.handleSubmit}>
                      <div className="mb-3">
                        <textarea
                          defaultValue={this.feedback}
                          onChange={this.onChange}
                          className="form-control"
                          placeholder="Please enter your comments below"
                          rows="3"
                        ></textarea>
                      </div>
                      <div className="group_link d-flex">
                        <button className="page_save page_width" type="submit">
                          SAVE
                        </button>
                        <button
                          type="button"
                          className="link_bal_next  page_width"
                          onClick={this.handleCloseModal}
                        >
                          Cancel
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>

        <Modal
          animation={true}
          size="md"
          className="modal_box"
          shadow-lg="border"
          show={this.state.showModal1 && this.state.activeModal1 === "login"}
        >
          <div className="modal-lg">
            <Modal.Header className="pb-0">
              <Button variant="outline-dark" onClick={this.handleCloseModal1}>
                <i className="fa fa-times"></i>
              </Button>
            </Modal.Header>
            <div className="modal-body vekp pt-0">
              <div className="row">
                <div className="col-md-12">
                  <div className="pb3">
                    <h4>Feedback Logs</h4>
                    <form>
                      <div className="mb-3 p-3">
                        <Table striped bordered hover size="sm">
                          <thead>
                            <tr className="heading_color">
                              <th>ID</th>
                              <th>Feedbacks / Comments</th>
                              <th>Updated date</th>
                            </tr>
                          </thead>
                          <tbody>
                            {feeedbackLogs.map((item, key) => (
                              <tr key={key}>
                                <td>{key + 1}</td>
                                <td>{item.feedback}</td>
                                <td>
                                  {moment(item.createdAt)
                                    .utc()
                                    .format("DD-MM-YYYY")}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}
