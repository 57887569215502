/* eslint-disable react/jsx-no-target-blank */
import React, { Component } from "react";
import axios from "axios";
import { sweetAlert } from "../../../utils/UniversalFunction";
import AdminSidebar from "../../sidebar/admin_sidebar";
import AdminHeader from "../../header/admin_header";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Image } from "react-bootstrap";
import Sustainable1 from "../../../img/E_SDG_PRINT-01.jpg";
import Sustainable2 from "../../../img/E_SDG_PRINT-02.jpg";
import Sustainable3 from "../../../img/E_SDG_PRINT-03.jpg";
import Sustainable4 from "../../../img/E_SDG_PRINT-04.jpg";
import Sustainable5 from "../../../img/E_SDG_PRINT-05.jpg";
import Sustainable6 from "../../../img/E_SDG_PRINT-06.jpg";
import Sustainable7 from "../../../img/E_SDG_PRINT-07.jpg";
import Sustainable8 from "../../../img/E_SDG_PRINT-08.jpg";
import Sustainable9 from "../../../img/E_SDG_PRINT-09.jpg";
import Sustainable10 from "../../../img/E_SDG_PRINT-10.jpg";
import Sustainable11 from "../../../img/E_SDG_PRINT-11.jpg";
import Sustainable12 from "../../../img/E_SDG_PRINT-12.jpg";
import Sustainable13 from "../../../img/E_SDG_PRINT-13.jpg";
import Sustainable14 from "../../../img/E_SDG_PRINT-14.jpg";
import Sustainable15 from "../../../img/E_SDG_PRINT-15.jpg";
import Sustainable16 from "../../../img/E_SDG_PRINT-16.jpg";
import Sustainable17 from "../../../img/E_SDG_PRINT-17.jpg";
import { faLightbulb } from "@fortawesome/free-solid-svg-icons";
import "../../Company Sub Admin/Pages/sustainable/sustainable.css";
import { authenticationService } from "../../../_services/authentication";
import './companies.css';

import config from "../../../config/config.json";
const BackendBaseUrl = config.BASE_URL;
const currentUser = authenticationService.currentUserValue;
export default class sustainablePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      items: [],
      submitted: false,
      noPoverty: false,
      noPovertyDesc: null,
      zeroHunger: false,
      zeroHungerDesc: null,
      goodHealth: false,
      goodHealthDesc: null,
      qualityEducation: false,
      qualityEducationDesc: null,
      genderEquality: false,
      genderEqualityDesc: null,
      cleanWater: false,
      cleanWaterDesc: null,
      affordable: false,
      affordableDesc: null,
      decentWork: false,
      decentWorkDesc: null,
      industryInnovation: false,
      industryInnovationDesc: null,
      reducedInqualites: false,
      reducedInqualitesDesc: null,
      sustainableCities: false,
      sustainableCitiesDesc: null,
      responsibleConsumption: false,
      responsibleConsumptionDesc: null,
      climateAction: false,
      climateActionDesc: null,

      lifeBelowWater: false,
      lifeBelowWaterDesc: null,
      lifeOnLand: false,
      lifeOnLandDesc: null,
      peace: false,
      peaceDesc: null,
      partnership: false,
      partnershipDesc: null,
      selectedUser: [],
      isCompanySubAdminSubmit: false,
      isCompanyAdminSubmit: false,
      redirect: false,
      report: [],
    };
    this.onFileChange = this.onFileChange.bind(this);
    this.getUploadedReportData = this.getUploadedReportData.bind(this);
  }

  onFileChange = (event) => {
    let urlArr = window.location.pathname.split("/");
    const finalUUId = urlArr[urlArr.length - 3];

    const formData = new FormData();
    // Update the formData object
    formData.append(
      "uploadImage",
      event.target.files[0],
      event.target.files[0].name
    );
    formData.append("section_name", "sustainable");
    formData.append("sub_section", "sustainable");
    const headers = {
      Authorization: `Bearer ${currentUser.data.token}`,
      Accept: "application/json",
    };
    axios
      .post(
        `${config.ADMIN_API_URL}company/${finalUUId}/sector-question/upload/report`,
        formData,
        {
          headers,
        }
      )
      .then((response) => {
        sweetAlert("success", response.data.message);
      })
      .catch(function (response) {
        sweetAlert("error", response.data.message);
      });
  };

  getUploadedReportData = () => {
    let urlArr = window.location.pathname.split("/");
    const finalUUId = urlArr[urlArr.length - 3];
    const headers = {
      Authorization: `Bearer ${currentUser.data.token}`,
      Accept: "application/json",
    };
    fetch(
      `${config.ADMIN_API_URL}company/${finalUUId}/sector-question/download/report/sustainable/sustainable`,
      {
        headers,
      }
    )
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            report: result.data,
          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error,
          });
        }
      );
  };

  componentDidMount() {
    this.getUploadedReportData();
    let urlArr = window.location.pathname.split("/");
    const finalUUId = urlArr[urlArr.length - 3];

    const headers = {
      Authorization: `Bearer ${currentUser.data.token}`,
      Accept: "application/json",
    };
    fetch(config.ADMIN_API_URL + `company/${finalUUId}/modules/sustainable`, {
      headers,
    })
      .then((res) => res.json())
      .then(
        (result) => {
          // this.removeArray(result);
          this.setState({
            isLoaded: true,
            noPoverty:
              result.result.noPoverty === undefined
                ? false
                : result.result.noPoverty === 1
                  ? true
                  : false,
            noPovertyDesc:
              result.result.noPovertyDesc === undefined
                ? null
                : result.result.noPovertyDesc == null
                  ? null
                  : result.result.noPovertyDesc,

            zeroHunger:
              result.result.zeroHunger === undefined
                ? false
                : result.result.zeroHunger === 1
                  ? true
                  : false,
            zeroHungerDesc:
              result.result.zeroHungerDesc === undefined
                ? null
                : result.result.zeroHungerDesc == null
                  ? null
                  : result.result.zeroHungerDesc,

            goodHealth:
              result.result.goodHealth === undefined
                ? false
                : result.result.goodHealth === 1
                  ? true
                  : false,
            goodHealthDesc:
              result.result.goodHealthDesc === undefined
                ? null
                : result.result.goodHealthDesc == null
                  ? null
                  : result.result.goodHealthDesc,

            qualityEducation:
              result.result.qualityEducation === undefined
                ? false
                : result.result.qualityEducation === 1
                  ? true
                  : false,
            qualityEducationDesc:
              result.result.qualityEducationDesc === undefined
                ? null
                : result.result.qualityEducationDesc == null
                  ? null
                  : result.result.qualityEducationDesc,

            genderEquality:
              result.result.genderEquality === undefined
                ? false
                : result.result.genderEquality === 1
                  ? true
                  : false,
            genderEqualityDesc:
              result.result.genderEqualityDesc === undefined
                ? null
                : result.result.genderEqualityDesc == null
                  ? null
                  : result.result.genderEqualityDesc,

            cleanWater:
              result.result.cleanWater === undefined
                ? false
                : result.result.cleanWater === 1
                  ? true
                  : false,
            cleanWaterDesc:
              result.result.cleanWaterDesc === undefined
                ? null
                : result.result.cleanWaterDesc == null
                  ? null
                  : result.result.cleanWaterDesc,

            affordable:
              result.result.affordable === undefined
                ? false
                : result.result.affordable === 1
                  ? true
                  : false,
            affordableDesc:
              result.result.affordableDesc === undefined
                ? null
                : result.result.affordableDesc == null
                  ? null
                  : result.result.affordableDesc,

            decentWork:
              result.result.decentWork === undefined
                ? false
                : result.result.decentWork === 1
                  ? true
                  : false,
            decentWorkDesc:
              result.result.decentWorkDesc === undefined
                ? null
                : result.result.decentWorkDesc == null
                  ? null
                  : result.result.decentWorkDesc,

            industryInnovation:
              result.result.industryInnovation === undefined
                ? false
                : result.result.industryInnovation === 1
                  ? true
                  : false,
            industryInnovationDesc:
              result.result.industryInnovationDesc === undefined
                ? null
                : result.result.industryInnovationDesc == null
                  ? null
                  : result.result.industryInnovationDesc,

            reducedInqualites:
              result.result.reducedInqualites === undefined
                ? false
                : result.result.reducedInqualites === 1
                  ? true
                  : false,
            reducedInqualitesDesc:
              result.result.reducedInqualitesDesc === undefined
                ? null
                : result.result.reducedInqualitesDesc == null
                  ? null
                  : result.result.reducedInqualitesDesc,

            sustainableCities:
              result.result.sustainableCities === undefined
                ? false
                : result.result.sustainableCities === 1
                  ? true
                  : false,
            sustainableCitiesDesc:
              result.result.sustainableCitiesDesc === undefined
                ? null
                : result.result.sustainableCitiesDesc == null
                  ? null
                  : result.result.sustainableCitiesDesc,

            responsibleConsumption:
              result.result.responsibleConsumption === undefined
                ? false
                : result.result.responsibleConsumption === 1
                  ? true
                  : false,
            responsibleConsumptionDesc:
              result.result.responsibleConsumptionDesc === undefined
                ? null
                : result.result.responsibleConsumptionDesc == null
                  ? null
                  : result.result.responsibleConsumptionDesc,

            climateAction:
              result.result.climateAction === undefined
                ? false
                : result.result.climateAction === 1
                  ? true
                  : false,
            climateActionDesc:
              result.result.climateActionDesc === undefined
                ? null
                : result.result.climateActionDesc == null
                  ? null
                  : result.result.climateActionDesc,

            lifeBelowWater:
              result.result.lifeBelowWater === undefined
                ? false
                : result.result.lifeBelowWater === 1
                  ? true
                  : false,
            lifeBelowWaterDesc:
              result.result.lifeBelowWaterDesc === undefined
                ? null
                : result.result.lifeBelowWaterDesc == null
                  ? null
                  : result.result.lifeBelowWaterDesc,

            lifeOnLand:
              result.result.lifeOnLand === undefined
                ? false
                : result.result.lifeOnLand === 1
                  ? true
                  : false,
            lifeOnLandDesc:
              result.result.lifeOnLandDesc === undefined
                ? null
                : result.result.lifeOnLandDesc == null
                  ? null
                  : result.result.lifeOnLandDesc,

            peace:
              result.result.peace === undefined
                ? false
                : result.result.peace === 1
                  ? true
                  : false,
            peaceDesc:
              result.result.peaceDesc === undefined
                ? null
                : result.result.peaceDesc == null
                  ? null
                  : result.result.peaceDesc,

            partnership:
              result.result.partnership === undefined
                ? false
                : result.result.partnership === 1
                  ? true
                  : false,
            partnershipDesc:
              result.result.partnershipDesc === undefined
                ? null
                : result.result.partnershipDesc == null
                  ? null
                  : result.result.partnershipDesc,
          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error,
          });
        }
      );
  }

  handleDescChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  handleTogleChange(e) {
    const { name, checked } = e.target;
    this.setState({ [name]: checked });
  }

  async handleSave() {

    const { uuid } = this.props.match.params;
    
    let payload = {
      uuid: uuid,
      noPoverty: this.state.noPoverty,
      noPovertyDesc: this.state.noPoverty ? this.state.noPovertyDesc : null,
      zeroHunger: this.state.zeroHunger,
      zeroHungerDesc: this.state.zeroHunger ? this.state.zeroHungerDesc : null,
      goodHealth: this.state.goodHealth,
      goodHealthDesc: this.state.goodHealth ? this.state.goodHealthDesc : null,
      qualityEducation: this.state.qualityEducation,
      qualityEducationDesc: this.state.qualityEducation ? this.state.qualityEducationDesc : null,
      genderEquality: this.state.genderEquality,
      genderEqualityDesc: this.state.genderEquality ? this.state.genderEqualityDesc : null,
      cleanWater: this.state.cleanWater,
      cleanWaterDesc: this.state.cleanWater ? this.state.cleanWaterDesc : null,
      affordable: this.state.affordable,
      affordableDesc: this.state.affordable ? this.state.affordableDesc : null,
      decentWork: this.state.decentWork,
      decentWorkDesc: this.state.decentWork ? this.state.decentWorkDesc : null,
      industryInnovation: this.state.industryInnovation,
      industryInnovationDesc: this.state.industryInnovation ? this.state.industryInnovationDesc : null,
      reducedInqualites: this.state.reducedInqualites,
      reducedInqualitesDesc: this.state.reducedInqualites ? this.state.reducedInqualitesDesc : null,
      sustainableCities: this.state.sustainableCities,
      sustainableCitiesDesc: this.state.sustainableCities ? this.state.sustainableCitiesDesc : null,
      responsibleConsumption: this.state.responsibleConsumption,
      responsibleConsumptionDesc: this.state.responsibleConsumption ? this.state.responsibleConsumptionDesc : null,
      climateAction: this.state.climateAction,
      climateActionDesc: this.state.climateAction ? this.state.climateActionDesc : null,
      lifeBelowWater: this.state.lifeBelowWater,
      lifeBelowWaterDesc: this.state.lifeBelowWater ? this.state.lifeBelowWaterDesc : null,
      lifeOnLand: this.state.lifeOnLand,
      lifeOnLandDesc: this.state.lifeOnLand ? this.state.lifeOnLandDesc : null,
      peace: this.state.peace,
      peaceDesc: this.state.peace ? this.state.peaceDesc : null,
      partnership: this.state.partnership,
      partnershipDesc: this.state.partnership ? this.state.partnershipDesc : null,
    }

    
    try {
      const headers = {
        Authorization: `Bearer ${currentUser.data.token}`,
        Accept: "application/json",
      };
      await axios.put(`${config.ADMIN_API_URL}sdg/updateSustainableDevelopement`, payload, { headers: headers });
      sweetAlert('success', "Updated Successfully");
    } catch (error) {
      sweetAlert('error', "Oops some error occured");
      // show error message
      console.log(error.message)
    }

    
  }
  render() {
    const { report } = this.state;
    return (
      <div>
        <AdminSidebar dataFromParent={this.props.location.pathname} />
        <AdminHeader />
        <div className="main_wrapper">
          <div className="inner_wraapper">
            <div className="container-fluid">
              <section className="d_text">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="requirem">
                        <div className="text_Parts">
                          <h6 className="back_Introdu">Introduction</h6>
                          <div className="text_interlinked">
                            <p className="interlinked">
                              The <strong>Sustainable Development Goals</strong>{" "}
                              (SDGs) are a collection of 17 interlinked global
                              goals designed to be a "blueprint to achieve a
                              better and more sustainable future for all”. The
                              SDGs were set up in 2015 by the United Nations
                              General Assembly and are intended to be achieved
                              by the year 2030. They are included in a UN
                              Resolution called the
                              <strong> 2030 Agenda.</strong>
                            </p>
                            <p className="towards my-3">
                              Not all of the Goals require a response - Only the
                              ones which you feel your business can have a
                              positive impact towards…
                            </p>
                          </div>
                          <div className="addres">
                            <p className="addres_p">
                              <span className="finan">
                                <i className="fas fa-quote-left"></i>
                              </span>
                              As CEOs, we want to create long term value to
                              shareholders by delivering solid returns for
                              shareholders AND by operating a sustainable
                              business model that addresses the long term goals
                              of (the) society, as provided for in the SDG
                              roadmap. At the same time, data on responsible
                              business and sustainability is proliferating,
                              enabling companies to better understand their
                              impact and implement responsible strategies. What
                              we seek is a general framework for companies to
                              demonstrate their long term sustainability; a
                              framework that integrates financial metrics along
                              with relevant non financial criteria such as ESG
                              considerations, gender equality, compensation
                              practices, supply chain management, and other
                              activities..
                              <span className="with_icon">
                                <i className="fas fa-quote-right"></i>
                              </span>
                            </p>
                            <p className="Brian">
                              <strong>
                                - Brian Moynihan, Chairman and CEO Bank of
                                America
                              </strong>
                            </p>
                          </div>
                          <div class="variants">
                            <div class="file file--upload">
                              <label for="input-file">
                                <i class="fa fa-upload" aria-hidden="true"></i>
                                Upload Report
                              </label>
                              <input
                                // style={{ visibility: "hidden" }}
                                type="file"
                                id="input-file"
                                accept=".pdf, .doc, .docx, .xlsx, .pptx, .ppt, .ott, .odp"
                                name="governancePolicy"
                                onChange={this.onFileChange}
                              />
                            </div>
                            {report[0]?.file !== undefined && (
                              <a
                                className="outputFile"
                                target="_blank"
                                href={BackendBaseUrl + report[0]?.file ?? ""}
                              >
                                Preview
                              </a>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="requirem mt-3">
                        <div className="home_help">
                          <div className="of_America">
                            <div className="row">
                              <div className="col-xl-4 col-md-5 col-12">
                                <div className="chairamw">
                                  <div className="image_icon">
                                    <p className="demons">
                                      <FontAwesomeIcon
                                        className="far"
                                        icon={faLightbulb}
                                      />
                                    </p>
                                    <p className="demons_te">
                                      Sustainable Development Goal
                                    </p>
                                  </div>
                                  <div className="image_text">
                                    <p className="Developme">
                                      Sustainable Development Goal
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="col-xl-4 col-md-2 col-12">
                                <div className="impa">
                                  <p className="can_bus">
                                    Can your business make a positive impact?
                                  </p>
                                </div>
                              </div>
                              <div className="col-xl-4 col-md-5 col-12">
                                <div className="chairam">
                                  <p className="omne impa">Response</p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="of_Americat">
                            <div className="row">
                              <div className="col-xxl-4 col-lg-8 col-md-5 col-12 margin_sustainable_10">
                                <div className="chairamw">
                                  <div className="image_sdg">
                                    <p className="demons">
                                      {/* <FontAwesomeIcon
                                        className="far"
                                        icon={faChild}
                                      /> */}
                                      <Image
                                        className="img-fluid"
                                        src={Sustainable1}
                                      />
                                    </p>
                                    {/* <p className="demons_te">No Poverty</p> */}
                                  </div>
                                  <div className="image_text">
                                    <h5 className="Pove_rty">No Poverty</h5>
                                    <p className="Developme">
                                      Access to basic human needs of health,
                                      education, sanitation{" "}
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="col-xxl-4 col-lg-2 col-md-2 col-12">
                                <div className="impa">
                                  <label className="switch">
                                    <input
                                      type="checkbox"
                                      name="noPoverty"
                                      onChange={(e) => this.handleTogleChange(e)}
                                      checked={this.state.noPoverty}
                                    />
                                    <span className="slider round"></span>
                                  </label>
                                </div>
                              </div>
                              <div className="col-xxl-4 col-lg-12 col-md-5 col-12">
                                <div className="chairam">
                                  <div className="omne">
                                    <div className="input-group">
                                      <textarea
                                        className="form-control nopel"
                                        rows="6"
                                        onChange={(e) => this.handleDescChange(e)}
                                        disabled={!this.state.noPoverty}
                                        value={this.state.noPovertyDesc}
                                        name="noPovertyDesc"
                                      ></textarea>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <hr className="human" />
                          </div>
                          <div className="of_Americat">
                            <div className="row">
                              <div className="col-xxl-4 col-lg-8 col-md-5 col-12 margin_sustainable_10">
                                <div className="chairamw">
                                  <div className="image_sdg">
                                    <p className="demons">
                                      {/* <FontAwesomeIcon
                                        className="far"
                                        icon={faCoffee}
                                      /> */}
                                      <Image
                                        className="img-fluid"
                                        src={Sustainable2}
                                      />
                                    </p>
                                    {/* <p className="demons_te">Zero Hunger</p> */}
                                  </div>
                                  <div className="image_text">
                                    <h5 className="Pove_rty">Zero Hunger</h5>
                                    <p className="Developme">
                                      Providing food and humanitarian relief,
                                      establishing sustainable food production
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="col-xxl-4 col-lg-2 col-md-2 col-12">
                                <div className="impa">
                                  <label className="switch">
                                    <input
                                      type="checkbox"
                                      name="zeroHunger"
                                      onChange={(e) => this.handleTogleChange(e)}
                                      checked={this.state.zeroHunger}
                                    />
                                    <span className="slider round"></span>
                                  </label>
                                </div>
                              </div>
                              <div className="col-xxl-4 col-lg-12 col-md-5 col-12">
                                <div className="chairam">
                                  <div className="omne">
                                    <div className="input-group">
                                      <textarea
                                        className="form-control nopel"
                                        rows="6"
                                        onChange={(e) => this.handleDescChange(e)}
                                        disabled={!this.state.zeroHunger}
                                        value={this.state.zeroHungerDesc}
                                        name="zeroHungerDesc"
                                      ></textarea>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <hr className="human" />
                          </div>
                          <div className="of_Americat">
                            <div className="row">
                              <div className="col-xxl-4 col-lg-8 col-md-5 col-12 margin_sustainable_10">
                                <div className="chairamw">
                                  <div className="image_sdg">
                                    <p className="demons">
                                      {/* <FontAwesomeIcon
                                        className="far"
                                        icon={faChartBar}
                                      /> */}
                                      <Image
                                        className="img-fluid"
                                        src={Sustainable3}
                                      />
                                    </p>
                                    {/* <p className="demons_te">
                                      Good Health and Well-being
                                    </p> */}
                                  </div>
                                  <div className="image_text">
                                    <h5 className="Pove_rty">
                                      Good Health and Well-being
                                    </h5>
                                    <p className="Developme">
                                      Better, more accessible health systems to
                                      increase life-expectancy
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="col-xxl-4 col-lg-2 col-md-2 col-12">
                                <div className="impa">
                                  <label className="switch">
                                    <input
                                      type="checkbox"
                                      name="goodHealth"
                                      onChange={(e) => this.handleTogleChange(e)}
                                      checked={this.state.goodHealth}
                                    />
                                    <span className="slider round"></span>
                                  </label>
                                </div>
                              </div>
                              <div className="col-xxl-4 col-lg-12 col-md-5 col-12">
                                <div className="chairam">
                                  <div className="omne">
                                    <div className="input-group">
                                      <textarea
                                        className="form-control nopel"
                                        rows="6"
                                        onChange={(e) => this.handleDescChange(e)}
                                        disabled={!this.state.goodHealth}
                                        value={this.state.goodHealthDesc}
                                        name="goodHealthDesc"

                                      ></textarea>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <hr className="human" />
                          </div>
                          <div className="of_Americat">
                            <div className="row">
                              <div className="col-xxl-4 col-lg-8 col-md-5 col-12 margin_sustainable_10">
                                <div className="chairamw">
                                  <div className="image_sdg">
                                    <p className="demons">
                                      {/* <FontAwesomeIcon
                                        className="far"
                                        icon={faBookOpen}
                                      /> */}
                                      <Image
                                        className="img-fluid"
                                        src={Sustainable4}
                                      />
                                    </p>
                                    {/* <p className="demons_te">
                                      Quality Education
                                    </p> */}
                                  </div>
                                  <div className="image_text">
                                    <h5 className="Pove_rty">
                                      Quality Education
                                    </h5>
                                    <p className="Developme">
                                      Inclusive education to enable upward{" "}
                                      social mobility and end poverty
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="col-xxl-4 col-lg-2 col-md-2 col-12">
                                <div className="impa">
                                  <label className="switch">
                                    <input
                                      type="checkbox"
                                      name="qualityEducation"
                                      onChange={(e) => this.handleTogleChange(e)}
                                      checked={this.state.qualityEducation}
                                    />
                                    <span className="slider round"></span>
                                  </label>
                                </div>
                              </div>
                              <div className="col-xxl-4 col-lg-12 col-md-5 col-12">
                                <div className="chairam">
                                  <div className="omne">
                                    <div className="input-group">
                                      <textarea
                                        className="form-control nopel"
                                        rows="6"
                                        onChange={(e) => this.handleDescChange(e)}
                                        disabled={!this.state.qualityEducation}
                                        value={this.state.qualityEducationDesc}
                                        name="qualityEducationDesc"
                                      ></textarea>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <hr className="human" />
                          </div>
                          <div className="of_Americat">
                            <div className="row">
                              <div className="col-xxl-4 col-lg-8 col-md-5 col-12 margin_sustainable_10">
                                <div className="chairamw">
                                  <div className="image_sdg">
                                    <p className="demons">
                                      {/* <FontAwesomeIcon
                                        className="far"
                                        icon={faAccessibleIcon}
                                      /> */}
                                      <Image
                                        className="img-fluid"
                                        src={Sustainable5}
                                      />
                                    </p>
                                    {/* <p className="demons_te">Gender Equality</p> */}
                                  </div>
                                  <div className="image_text">
                                    <h5 className="Pove_rty">
                                      Gender Equality
                                    </h5>
                                    <p className="Developme">
                                      Education regardless of gender,
                                      advancement of equality laws, fairer
                                      representation of women
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="col-xxl-4 col-lg-2 col-md-2 col-12">
                                <div className="impa">
                                  <label className="switch">
                                    <input
                                      type="checkbox"
                                      name="genderEquality"
                                      onChange={(e) => this.handleTogleChange(e)}
                                      checked={this.state.genderEquality}
                                    />
                                    <span className="slider round"></span>
                                  </label>
                                </div>
                              </div>
                              <div className="col-xxl-4 col-lg-12 col-md-5 col-12">
                                <div className="chairam">
                                  <div className="omne">
                                    <div className="input-group">
                                      <textarea
                                        className="form-control nopel"
                                        rows="6"
                                        onChange={(e) => this.handleDescChange(e)}
                                        disabled={!this.state.genderEquality}
                                        value={this.state.genderEqualityDesc}
                                        name="genderEqualityDesc"
                                      ></textarea>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <hr className="human" />
                          </div>
                          <div className="of_Americat">
                            <div className="row">
                              <div className="col-xxl-4 col-lg-8 col-md-5 col-12 margin_sustainable_10">
                                <div className="chairamw">
                                  <div className="image_sdg">
                                    <p className="demons">
                                      {/* <FontAwesomeIcon
                                        className="far"
                                        icon={faTint}
                                      /> */}
                                      <Image
                                        className="img-fluid"
                                        src={Sustainable6}
                                      />
                                    </p>
                                    {/* <p className="demons_te">
                                      Clean Water and Sanitation
                                    </p> */}
                                  </div>
                                  <div className="image_text">
                                    <h5 className="Pove_rty">
                                      Clean Water and Sanitation
                                    </h5>
                                    <p className="Developme">
                                      Improving access for billions of people
                                      who lack these basic facilities
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="col-xxl-4 col-lg-2 col-md-2 col-12">
                                <div className="impa">
                                  <label className="switch">
                                    <input
                                      type="checkbox"
                                      name="cleanWater"
                                      onChange={(e) => this.handleTogleChange(e)}
                                      checked={this.state.cleanWater}
                                    />
                                    <span className="slider round"></span>
                                  </label>
                                </div>
                              </div>
                              <div className="col-xxl-4 col-lg-12 col-md-5 col-12">
                                <div className="chairam">
                                  <div className="omne">
                                    <div className="input-group">
                                      <textarea
                                        className="form-control nopel"
                                        rows="6"
                                        onChange={(e) => this.handleDescChange(e)}
                                        disabled={!this.state.cleanWater}
                                        value={this.state.cleanWaterDesc}
                                        name="cleanWaterDesc"
                                      ></textarea>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <hr className="human" />
                          </div>
                          <div className="of_Americat">
                            <div className="row">
                              <div className="col-xxl-4 col-lg-8 col-md-5 col-12 margin_sustainable_10">
                                <div className="chairamw">
                                  <div className="image_sdg">
                                    <p className="demons">
                                      {/* <FontAwesomeIcon
                                        className="far"
                                        icon={faSyncAlt}
                                      /> */}
                                      <Image
                                        className="img-fluid"
                                        src={Sustainable7}
                                      />
                                    </p>
                                    {/* <p className="demons_te">
                                      Affordable and Clean Energy
                                    </p> */}
                                  </div>
                                  <div className="image_text">
                                    <h5 className="Pove_rty">
                                      Affordable and Clean Energy
                                    </h5>
                                    <p className="Developme">
                                      Access to renewable, safe and widely
                                      available energy sources for all
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="col-xxl-4 col-lg-2 col-md-2 col-12">
                                <div className="impa">
                                  <label className="switch">
                                    <input
                                      type="checkbox"
                                      name="affordable"
                                      onChange={(e) => this.handleTogleChange(e)}
                                      checked={this.state.affordable}
                                    />
                                    <span className="slider round"></span>
                                  </label>
                                </div>
                              </div>
                              <div className="col-xxl-4 col-lg-12 col-md-5 col-12">
                                <div className="chairam">
                                  <div className="omne">
                                    <div className="input-group">
                                      <textarea
                                        className="form-control nopel"
                                        rows="6"
                                        onChange={(e) => this.handleDescChange(e)}
                                        disabled={!this.state.affordable}
                                        value={this.state.affordableDesc}
                                        name="affordableDesc"
                                      ></textarea>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <hr className="human" />
                          </div>
                          <div className="of_Americat">
                            <div className="row">
                              <div className="col-xxl-4 col-lg-8 col-md-5 col-12 margin_sustainable_10">
                                <div className="chairamw">
                                  <div className="image_sdg">
                                    <p className="demons">
                                      {/* <FontAwesomeIcon
                                        className="far"
                                        icon={faChartLine}
                                      /> */}
                                      <Image
                                        className="img-fluid"
                                        src={Sustainable8}
                                      />
                                    </p>
                                    {/* <p className="demons_te">
                                      Decent Work and Economic Growth
                                    </p> */}
                                  </div>
                                  <div className="image_text">
                                    <h5 className="Pove_rty">
                                      Decent Work and Economic Growth
                                    </h5>
                                    <p className="Developme">
                                      Creating jobs for all to improve living{" "}
                                      standards, providing sustainable economic
                                      growth
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="col-xxl-4 col-lg-2 col-md-2 col-12">
                                <div className="impa">
                                  <label className="switch">
                                    <input
                                      type="checkbox"
                                      name="decentWork"
                                      onChange={(e) => this.handleTogleChange(e)}
                                      checked={this.state.decentWork}
                                    />
                                    <span className="slider round"></span>
                                  </label>
                                </div>
                              </div>
                              <div className="col-xxl-4 col-lg-12 col-md-5 col-12">
                                <div className="chairam">
                                  <div className="omne">
                                    <div className="input-group">
                                      <textarea
                                        className="form-control nopel"
                                        rows="6"
                                        onChange={(e) => this.handleDescChange(e)}
                                        disabled={!this.state.decentWork}
                                        value={this.state.decentWorkDesc}
                                        name="decentWorkDesc"
                                      ></textarea>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <hr className="human" />
                          </div>
                          <div className="of_Americat">
                            <div className="row">
                              <div className="col-xxl-4 col-lg-8 col-md-5 col-12 margin_sustainable_10">
                                <div className="chairamw">
                                  <div className="image_sdg">
                                    <p className="demons">
                                      {/* <FontAwesomeIcon
                                        className="far"
                                        icon={faBox}
                                      /> */}
                                      <Image
                                        className="img-fluid"
                                        src={Sustainable9}
                                      />
                                    </p>
                                    {/* <p className="demons_te">
                                      9. Reduced Inequalities
                                    </p> */}
                                  </div>
                                  <div className="image_text">
                                    <h5 className="Pove_rty">
                                      Industry, Innovation and Infrastructure
                                    </h5>
                                    <p className="Developme">
                                      Reducing income and other inequalities,
                                      within and between countries
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="col-xxl-4 col-lg-2 col-md-2 col-12">
                                <div className="impa">
                                  <label className="switch">
                                    <input
                                      type="checkbox"
                                      name="industryInnovation"
                                      onChange={(e) => this.handleTogleChange(e)}
                                      checked={this.state.industryInnovation}
                                    />
                                    <span className="slider round"></span>
                                  </label>
                                </div>
                              </div>
                              <div className="col-xxl-4 col-lg-12 col-md-5 col-12">
                                <div className="chairam">
                                  <div className="omne">
                                    <div className="input-group">
                                      <textarea
                                        className="form-control nopel"
                                        rows="6"
                                        onChange={(e) => this.handleDescChange(e)}
                                        disabled={!this.state.industryInnovation}
                                        value={this.state.industryInnovationDesc}
                                        name="industryInnovationDesc"
                                      ></textarea>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <hr className="human" />
                          </div>
                          <div className="of_Americat">
                            <div className="row">
                              <div className="col-xxl-4 col-lg-8 col-md-5 col-12 margin_sustainable_10">
                                <div className="chairamw">
                                  <div className="image_sdg">
                                    <p className="demons">
                                      {/* <FontAwesomeIcon
                                        className="far"
                                        icon={faDropbox}
                                      /> */}
                                      <Image
                                        className="img-fluid"
                                        src={Sustainable10}
                                      />
                                    </p>
                                    {/* <p className="demons_te">
                                      10. Reduced Inequalities
                                    </p> */}
                                  </div>
                                  <div className="image_text">
                                    <h5 className="Pove_rty">
                                      Reduced Inequalities
                                    </h5>
                                    <p className="Developme">
                                      Reducing income and other inequalities,
                                      within and between countries
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="col-xxl-4 col-lg-2 col-md-2 col-12">
                                <div className="impa">
                                  <label className="switch">
                                    <input
                                      type="checkbox"
                                      name="reducedInqualites"
                                      onChange={(e) => this.handleTogleChange(e)}
                                      checked={this.state.reducedInqualites}
                                    />
                                    <span className="slider round"></span>
                                  </label>
                                </div>
                              </div>
                              <div className="col-xxl-4 col-lg-12 col-md-5 col-12">
                                <div className="chairam">
                                  <div className="omne">
                                    <div className="input-group">
                                      <textarea
                                        className="form-control nopel"
                                        rows="6"
                                        onChange={(e) => this.handleDescChange(e)}
                                        disabled={!this.state.reducedInqualites}
                                        value={this.state.reducedInqualitesDesc}
                                        name="reducedInqualitesDesc"
                                      ></textarea>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <hr className="human" />
                          </div>
                          <div className="of_Americat">
                            <div className="row">
                              <div className="col-xxl-4 col-lg-8 col-md-5 col-12 margin_sustainable_10">
                                <div className="chairamw">
                                  <div className="image_sdg">
                                    <p className="demons">
                                      {/* <FontAwesomeIcon
                                        className="far"
                                        icon={faCity}
                                      /> */}
                                      <Image
                                        className="img-fluid"
                                        src={Sustainable11}
                                      />
                                    </p>
                                    {/* <p className="demons_te">
                                      Sustainable Cities and Communities
                                    </p> */}
                                  </div>
                                  <div className="image_text">
                                    <h5 className="Pove_rty">
                                      Sustainable Cities and Communities
                                    </h5>
                                    <p className="Developme">
                                      Making cities safe, inclusive, resilient
                                      and sustainable
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="col-xxl-4 col-lg-2 col-md-2 col-12">
                                <div className="impa">
                                  <label className="switch">
                                    <input
                                      type="checkbox"
                                      name="sustainableCities"
                                      onChange={(e) => this.handleTogleChange(e)}
                                      checked={this.state.sustainableCities}
                                    />
                                    <span className="slider round"></span>
                                  </label>
                                </div>
                              </div>
                              <div className="col-xxl-4 col-lg-12 col-md-5 col-12">
                                <div className="chairam">
                                  <div className="omne">
                                    <div className="input-group">
                                      <textarea
                                        className="form-control nopel"
                                        rows="6"
                                        onChange={(e) => this.handleDescChange(e)}
                                        disabled={!this.state.sustainableCities}
                                        value={this.state.sustainableCitiesDesc}
                                        name="sustainableCitiesDesc"
                                      ></textarea>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <hr className="human" />
                          </div>
                          <div className="of_Americat">
                            <div className="row">
                              <div className="col-xxl-4 col-lg-8 col-md-5 col-12 margin_sustainable_10">
                                <div className="chairamw">
                                  <div className="image_sdg">
                                    <p className="demons">
                                      {/* <FontAwesomeIcon
                                        className="far"
                                        icon={faHistory}
                                      /> */}
                                      <Image
                                        className="img-fluid"
                                        src={Sustainable12}
                                      />
                                    </p>
                                    {/* <p className="demons_te">
                                      Responsible Consumption and Production
                                    </p> */}
                                  </div>
                                  <div className="image_text">
                                    <h5 className="Pove_rty">
                                      Responsible Consumption Production
                                    </h5>
                                    <p className="Developme">
                                      Reversing current consumption trends and
                                      promoting a more sustainable future
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="col-xxl-4 col-lg-2 col-md-2 col-12">
                                <div className="impa">
                                  <label className="switch">
                                    <input
                                      type="checkbox"
                                      name="responsibleConsumption"
                                      onChange={(e) => this.handleTogleChange(e)}
                                      checked={
                                        this.state.responsibleConsumption
                                      }
                                    />
                                    <span className="slider round"></span>
                                  </label>
                                </div>
                              </div>
                              <div className="col-xxl-4 col-lg-12 col-md-5 col-12">
                                <div className="chairam">
                                  <div className="omne">
                                    <div className="input-group">
                                      <textarea
                                        className="form-control nopel"
                                        rows="6"
                                        onChange={(e) => this.handleDescChange(e)}
                                        disabled={!this.state.responsibleConsumption}
                                        value={this.state.responsibleConsumptionDesc}
                                        name="responsibleConsumptionDesc"
                                      ></textarea>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <hr className="human" />
                          </div>
                          <div className="of_Americat">
                            <div className="row">
                              <div className="col-xxl-4 col-lg-8 col-md-5 col-12 margin_sustainable_10">
                                <div className="chairamw">
                                  <div className="image_sdg">
                                    <p className="demons">
                                      {/* <FontAwesomeIcon
                                        className="far"
                                        icon={faGlobe}
                                      /> */}
                                      <Image
                                        className="img-fluid"
                                        src={Sustainable13}
                                      />
                                    </p>
                                    <p className="demons_te">Climate Action</p>
                                  </div>
                                  <div className="image_text">
                                    <h5 className="Pove_rty">Climate Action</h5>
                                    <p className="Developme">
                                      Regulating and reducing emissions and{" "}
                                      promoting renewable energy{" "}
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="col-xxl-4 col-lg-2 col-md-2 col-12">
                                <div className="impa">
                                  <label className="switch">
                                    <input
                                      type="checkbox"
                                      name="climateAction"
                                      onChange={(e) => this.handleTogleChange(e)}
                                      checked={this.state.climateAction}
                                    />
                                    <span className="slider round"></span>
                                  </label>
                                </div>
                              </div>
                              <div className="col-xxl-4 col-lg-12 col-md-5 col-12">
                                <div className="chairam">
                                  <div className="omne">
                                    <div className="input-group">
                                      <textarea
                                        className="form-control nopel"
                                        rows="6"
                                        onChange={(e) => this.handleDescChange(e)}
                                        disabled={!this.state.climateAction}
                                        value={this.state.climateActionDesc}
                                        name="climateActionDesc"
                                      ></textarea>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <hr className="human" />
                          </div>
                          <div className="of_Americat">
                            <div className="row">
                              <div className="col-xxl-4 col-lg-8 col-md-5 col-12 margin_sustainable_10">
                                <div className="chairamw">
                                  <div className="image_sdg">
                                    <p className="demons">
                                      {/* <FontAwesomeIcon
                                        className="far"
                                        icon={faFish}
                                      /> */}
                                      <Image
                                        className="img-fluid"
                                        src={Sustainable14}
                                      />
                                    </p>
                                    {/* <p className="demons_te">
                                      Life Below Water
                                    </p> */}
                                  </div>
                                  <div className="image_text">
                                    <h5 className="Pove_rty">
                                      Life Below Water
                                    </h5>
                                    <p className="Developme">
                                      Conservation, promoting marine diversity
                                      and regulating fishing practices
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="col-xxl-4 col-lg-2 col-md-2 col-12">
                                <div className="impa">
                                  <label className="switch">
                                    <input
                                      type="checkbox"
                                      name="lifeBelowWater"
                                      onChange={(e) => this.handleTogleChange(e)}
                                      checked={this.state.lifeBelowWater}
                                    />
                                    <span className="slider round"></span>
                                  </label>
                                </div>
                              </div>
                              <div className="col-xxl-4 col-lg-12 col-md-5 col-12">
                                <div className="chairam">
                                  <div className="omne">
                                    <div className="input-group">
                                      <textarea
                                        className="form-control nopel"
                                        rows="6"
                                        onChange={(e) => this.handleDescChange(e)}
                                        disabled={!this.state.lifeBelowWater}
                                        value={this.state.lifeBelowWaterDesc}
                                        name="lifeBelowWaterDesc"
                                      ></textarea>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <hr className="human" />
                          </div>
                          <div className="of_Americat">
                            <div className="row">
                              <div className="col-xxl-4 col-lg-8 col-md-5 col-12 margin_sustainable_10">
                                <div className="chairamw">
                                  <div className="image_sdg">
                                    <p className="demons">
                                      {/* <FontAwesomeIcon
                                        className="far"
                                        icon={faTree}
                                      /> */}
                                      <Image
                                        className="img-fluid"
                                        src={Sustainable15}
                                      />
                                    </p>
                                    {/* <p className="demons_te">Life on Land</p> */}
                                  </div>
                                  <div className="image_text">
                                    <h5 className="Pove_rty">Life on Land</h5>
                                    <p className="Developme">
                                      Reversing man-made deforestation and
                                      desertification to sustain all life on
                                      earth{" "}
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="col-xxl-4 col-lg-2 col-md-2 col-12">
                                <div className="impa">
                                  <label className="switch">
                                    <input
                                      type="checkbox"
                                      name="lifeOnLand"
                                      onChange={(e) => this.handleTogleChange(e)}
                                      checked={this.state.lifeOnLand}
                                    />
                                    <span className="slider round"></span>
                                  </label>
                                </div>
                              </div>
                              <div className="col-xxl-4 col-lg-12 col-md-5 col-12">
                                <div className="chairam">
                                  <div className="omne">
                                    <div className="input-group">
                                      <textarea
                                        className="form-control nopel"
                                        rows="6"
                                        onChange={(e) => this.handleDescChange(e)}
                                        disabled={!this.state.lifeOnLand}
                                        value={this.state.lifeOnLandDesc}
                                        name="lifeOnLandDesc"
                                      ></textarea>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <hr className="human" />
                          </div>
                          <div className="of_Americat">
                            <div className="row">
                              <div className="col-xxl-4 col-lg-8 col-md-5 col-12 margin_sustainable_10">
                                <div className="chairamw">
                                  <div className="image_sdg">
                                    <p className="demons">
                                      {/* <FontAwesomeIcon
                                        className="far"
                                        icon={faWheelchair}
                                      /> */}
                                      <Image
                                        className="img-fluid"
                                        src={Sustainable16}
                                      />
                                    </p>
                                    {/* <p className="demons_te">
                                      Peace, Justice and Strong Institutions
                                    </p> */}
                                  </div>
                                  <div className="image_text">
                                    <h5 className="Pove_rty">
                                      Peace, Justice and Strong Institutions
                                    </h5>
                                    <p className="Developme">
                                      Inclusive societies, strong institutions
                                      and equal access to justice
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="col-xxl-4 col-lg-2 col-md-2 col-12">
                                <div className="impa">
                                  <label className="switch">
                                    <input
                                      type="checkbox"
                                      name="peace"
                                      onChange={(e) => this.handleTogleChange(e)}
                                      checked={this.state.peace}
                                    />
                                    <span className="slider round"></span>
                                  </label>
                                </div>
                              </div>
                              <div className="col-xxl-4 col-lg-12 col-md-5 col-12">
                                <div className="chairam">
                                  <div className="omne">
                                    <div className="input-group">
                                      <textarea
                                        className="form-control nopel"
                                        rows="6"
                                        onChange={(e) => this.handleDescChange(e)}
                                        disabled={!this.state.peace}
                                        value={this.state.peaceDesc}
                                        name="peaceDesc"
                                      ></textarea>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <hr className="human" />
                          </div>
                          <div className="of_Americat">
                            <div className="row">
                              <div className="col-xxl-4 col-lg-8 col-md-5 col-12 margin_sustainable_10">
                                <div className="chairamw">
                                  <div className="image_sdg">
                                    <p className="demons">
                                      {/* <FontAwesomeIcon
                                        className="far"
                                        icon={faBullseye}
                                      /> */}
                                      <Image
                                        className="img-fluid"
                                        src={Sustainable17}
                                      />
                                    </p>
                                    {/* <p className="demons_te">
                                      Partnerships for the Goals
                                    </p> */}
                                  </div>
                                  <div className="image_text">
                                    <h5 className="Pove_rty">
                                      Partnerships for the Goals
                                    </h5>
                                    <p className="Developme">
                                      Revitalize strong global partnerships for{" "}
                                      sustainable development{" "}
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="col-xxl-4 col-lg-2 col-md-2 col-12">
                                <div className="impa">
                                  <label className="switch">
                                    <input
                                      type="checkbox"
                                      name="partnership"
                                      onChange={(e) => this.handleTogleChange(e)}
                                      checked={this.state.partnership}
                                    />
                                    <span className="slider round"></span>
                                  </label>
                                </div>
                              </div>
                              <div className="col-xxl-4 col-lg-12 col-md-5 col-12">
                                <div className="chairam">
                                  <div className="omne">
                                    <div className="input-group">
                                      <textarea
                                        className="form-control nopel"
                                        rows="6"
                                        onChange={(e) => this.handleDescChange(e)}
                                        disabled={!this.state.partnership}
                                        value={this.state.partnershipDesc}
                                        name="partnershipDesc"
                                      ></textarea>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                          </div>
                        </div>
                        <div className="carbonSaveCont">
                          <button
                            id="savbtnMain2"
                            className="link_bal_next page_width white "
                            variant="none"
                            onClick={() => this.handleSave()}
                          >
                            Save
                          </button>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
