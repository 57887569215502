/* eslint-disable react/jsx-pascal-case */
import React, { Component } from "react";
import "../Company Admin/Setting/setting.css";
import Table from "react-bootstrap/Table";
import { Pagination, Icon } from "semantic-ui-react";
import AdminSidebar from "../sidebar/admin_sidebar";
import AdminHeader from "../header/admin_header";
import Smartup_Tabbing from "./smartup_tabbing";

export default class SmartUPMembership extends Component {
  render() {
    return (
        <div>
        <AdminSidebar dataFromParent={this.props.location.pathname} />
        <AdminHeader />
        <div className="main_wrapper">
          <div className="inner_wraapper">
            <div className="container-fluid">
              <section className="d_text">
                <div className="container-fluid">
                  <div className="row">
                  <Smartup_Tabbing />
                    <div className="col-sm-12">
                      <div className="col-sm-12">
                        <div className="color_div_on framwork_2">
                          <div className="business_detail">
                            <div className="">
                            <hr className="line mt-5"></hr>
                              <div className="saved_cards">
                                <div className="business_detail">
                                  <div className="heading">
                                    <div className="heading_wth_text">
                                      <div className="d-flex">
                                        <span className="global_link mx-3">
                                          <button className="link_bal_next page_width white">
                                            <i className="fas fa-download white" />
                                          </button>
                                        </span>
                                      </div>
                                    </div>

                                    <div className="form-group has-search one">
                                      <span className="fa fa-search form-control-feedback"></span>
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Search..."
                                        name="search"
                                        onChange={(e) =>
                                          this.applyGlobalSearch(e)
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="table_f">
                                  <Table striped bordered hover size="sm">
                                    <thead>
                                      <tr className="heading_color">
                                        <th style={{ width: "10%" }}>ID</th>
                                        <th>COMPANY NAME</th>
                                        <th>MODULE </th>
                                        <th>DATE / TIME</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td>1</td>
                                        <td>Reliabilt</td>
                                        <td>ESG Reporting</td>
                                        <td>28/03/2022 :: 09:30AM</td>
                                      </tr>
                                      <tr>
                                        <td>2</td>
                                        <td>Electrolux</td>
                                        <td>Sector Question</td>
                                        <td>28/03/2022 :: 09:30AM</td>
                                      </tr>
                                      <tr>
                                        <td>3</td>
                                        <td>Black &amp; Decker</td>
                                        <td>Governance</td>
                                        <td>28/03/2022 :: 09:30AM</td>
                                      </tr>
                                      <tr>
                                        <td>4</td>
                                        <td>Rigid</td>
                                        <td>Suppliers</td>
                                        <td>28/03/2022 :: 09:30AM</td>
                                      </tr>
                                      <tr>
                                        <td>5</td>
                                        <td>Task Force</td>
                                        <td>Supplier Management</td>
                                        <td>28/03/2022 :: 09:30AM</td>
                                      </tr>
                                      <tr>
                                        <td>6</td>
                                        <td>Maytag</td>
                                        <td>SDG's</td>
                                        <td>28/03/2022 :: 09:30AM</td>
                                      </tr>
                                      <tr>
                                        <td>7</td>
                                        <td>Bosch</td>
                                        <td>Carbon Footprint</td>
                                        <td>28/03/2022 :: 09:30AM</td>
                                      </tr>
                                      <tr>
                                        <td>8</td>
                                        <td>Blue Hawk</td>
                                        <td>Board Skills</td>
                                        <td>28/03/2022 :: 09:30AM</td>
                                      </tr>
                                      <tr>
                                        <td>9</td>
                                        <td>Electrolux</td>
                                        <td>ESG Products</td>
                                        <td>28/03/2022 :: 09:30AM</td>
                                      </tr>
                                      <tr>
                                        <td>10</td>
                                        <td>Hotpoint</td>
                                        <td>Suppliers</td>
                                        <td>28/03/2022 :: 09:30AM</td>
                                      </tr>
                                    </tbody>
                                  </Table>
                                  <Pagination
                                    className="mx-auto pagination"
                                    defaultActivePage={1}
                                    onPageChange={this.pageChange}
                                    ellipsisItem={{
                                      content: (
                                        <Icon name="ellipsis horizontal" />
                                      ),
                                      icon: true,
                                    }}
                                    firstItem={{
                                      content: (
                                        <Icon name="angle double left" />
                                      ),
                                      icon: true,
                                    }}
                                    lastItem={{
                                      content: (
                                        <Icon name="angle double right" />
                                      ),
                                      icon: true,
                                    }}
                                    prevItem={{
                                      content: <Icon name="angle left" />,
                                      icon: true,
                                    }}
                                    nextItem={{
                                      content: <Icon name="angle right" />,
                                      icon: true,
                                    }}
                                    totalPages={Math.ceil(
                                      this.state.totalData / this.state.limit
                                    )}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
