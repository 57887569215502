import React, { Component } from "react";
import AdminSidebar from "../../sidebar/admin_sidebar";
import AdminHeader from "../../header/admin_header";
import ListComponent from "../../Company Sub Admin/Pages/management/list";
import "../../Company Sub Admin/Pages/management/management.css";
import config from "../../../config/config.json";
import { authenticationService } from "../../../_services/authentication";
import axios from "axios";
import { sweetAlert } from '../../../utils/UniversalFunction';
import swal from "sweetalert";
import './temp.css';
import { Button, Modal } from "semantic-ui-react";

const ProfilePics = "https://res.cloudinary.com/dmklsntsw/image/upload/v1658480882/dummyPic.75a04487_fqfqey.png";
const currentUser = authenticationService.currentUserValue;

export default class managementDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: true,
      items: [],
      open: false,
      boardSkillQuestionsSubject: [],
      boardSkillQuestionsRelevant: [],
      firstName: "",
      lastName: "",
      gender: "",
      biography: "",
      uploadImage: null,
      setMessage: "",
      title: "",
      expertiseOrskillValue: "",
      selectedSubject: [],
      selectedRelevance: [],
      id: null,
      imageAction: false

    };
  }

  async serverReq() {
    let urlArr = window.location.pathname.split("/");
    const finalUUId = urlArr[urlArr.length - 3];

    const headers = {
      Authorization: `Bearer ${currentUser.data.token}`,
      Accept: "application/json",
    };

    fetch(
      config.ADMIN_API_URL + `company/${finalUUId}/modules/getManagementMembers`,
      {
        headers,
      }
    )
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            items: result.result,
          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error,
          });
        }
      );
  }
  componentDidMount() {
    this.serverReq();
    this.fetchQues();
  }

  async fetchQues() {
    const { uuid } = this.props.match.params;
    try {
      const headers = {
        Authorization: `Bearer ${currentUser.data.token}`,
        Accept: "application/json",
      };
      const reponse = await axios.get(`${config.ADMIN_API_URL}board/getBoardSkillsQues?uuid=${uuid}&type=management`, { headers: headers });
      this.setState({
        boardSkillQuestionsSubject: reponse.data.data.subject,
        boardSkillQuestionsRelevant: reponse.data.data.relevant
      });
    } catch (error) {
      console.log(error.message)
    }
  }
  handleModalOpen(item) {
    // set values
    this.setState({ 
      open: true,
      uploadImage: item.uploadImage,
      id: item.id,
      firstName: item.firstName,
      lastName: item.lastName,
      gender: item.gender,
      biography: item.biography,
      selectedSubject: item.subject.split(','),
      selectedRelevance: item.relevantSkills.split(','),
     })
  }

  handleCloseOpen() {
    this.setState({ open: true, imageAction: false })
  }

  handleGenderChange = (event) => {
    const gender = event.target.value;
    this.setState({ gender: gender, checkGender: true });
  };

  handleChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  }

  onFileChange = (event) => {
    this.setState({ uploadImage: event.target.files[0], editableImage: event.target.files[0], imageAction: true });
    let input = event.target;
    let reader = new FileReader();
    reader.onload = function () {
      let dataURL = reader.result;
      let output = document.getElementById("output");
      output.src = dataURL;
    };
    reader.readAsDataURL(input.files[0]);
  };

  setOpen2(data, boardInformation) {
    this.setState({
      open2: data,
      setId: boardInformation.id,
      firstName: boardInformation.firstName,
      lastName: boardInformation.lastName,
      gender: boardInformation.gender,
      biography: boardInformation.biography,
      selectedUser: boardInformation.subject.split(","),
      editableImage: boardInformation.uploadImage ? config.BASE_URL + boardInformation.uploadImage : null,
      actualImage: boardInformation.uploadImage,
      selectedUser2: boardInformation.relevantSkills.split(","),
    });
  }

  async createBoardSkills() {

    const { uuid } = this.props.match.params;
    const { title, expertiseOrskillValue } = this.state;
    if (title && expertiseOrskillValue) {
      let obj = {};
      obj.description = expertiseOrskillValue;
      obj.title = title;
      obj.uuid = uuid;
      obj.type = "management";

      try {
        const headers = {
          Authorization: `Bearer ${currentUser.data.token}`,
          Accept: "application/json",
        };
        axios.post(`${config.ADMIN_API_URL}board/createBoardSkillsQues`, obj, { headers: headers }).then((res) => {
          this.setState({
            title: "",
            expertiseOrskillValue: ""
          })
          this.fetchQues();
          swal({
            icon: 'success',
            title: "Created Successfully",
            timer: 1000
          })
        }).catch((error) => {
          swal({
            icon: 'error',
            title: error.response.data.message,
            timer: 1000
          })
        });

      } catch (error) {
        // console.log(error.message)
      }
      // console.log("test", reponse);

      // if (checkStatusCode) {
      //   this.serverRequest2();
      //   this.setState({
      //     title: "",
      //     expertiseOrskillValue: "",
      //   });
      // }
    }
  }

  async deleteBoard(id, type) {
    const { uuid } = this.props.match.params;

    console.log("ddd", id, type);

    let payload = {
      uuid: uuid,
      type: "management",
      id: id
    }

    try {
      const headers = {
        Authorization: `Bearer ${currentUser.data.token}`,
        Accept: "application/json",
      };

      await axios.post(`${config.ADMIN_API_URL}board/deleteBoardSkillsQues`, payload, { headers: headers });
      this.fetchQues();
    } catch (error) {
      // console.log(error.message)
    }


    // }).then((result) => {
    //   if (result.isConfirmed) {
    //     axios
    //       .post(
    //         config.API_URL + "removeBoard",
    //         {
    //           id: id,
    //         },
    //         {
    //           headers,
    //         }
    //       )
    //       .then(async (response) => {
    //         sweetAlert("success", response.data.message);
    //         await this.serverRequest()
    //         // const pushToRoute = "/board_skills_detail";
    //         // setTimeout(() => {
    //         //   window.location.href = baseURL + pushToRoute;
    //         // }, 1000);
    //       })
    //       .catch(function (response) {
    //         sweetAlert("error", response.data.message);
    //       });
    //   } else if (result.isDenied) {
    //     sweetAlert("info", "User Safe");
    //   }
    // });
  };

  async handleCheckBoxChange(e) {

    const { checked, name, id } = e.target;
    let temp = [...this.state[name]];
    if (checked) {
      temp.push(id);
      this.setState({ [name]: temp })
    } else {
      let index = temp.indexOf(id);
      temp.splice(index, 1);
      this.setState({ [name]: temp })
    }

  }

  async hitSave(e) {
    const { uuid } = this.props.match.params;

    e.preventDefault();

    const formData = new FormData();

    const subjectText = this.state.boardSkillQuestionsSubject.filter(entity => this.state.selectedSubject.includes(`${entity.id}`)).map(entity => entity.description).join(",");
    const relevenceText = this.state.boardSkillQuestionsRelevant.filter(entity => this.state.selectedRelevance.includes(`${entity.id}`)).map(entity => entity.description).join(",");
    

      if(this.state.uploadImage && this.state.imageAction) {
        formData.append( "uploadImage", this.state.uploadImage, this.state.uploadImage.name );
      }
      formData.append("imageAction", this.state.imageAction)      
      formData.append("uuid", uuid);
      formData.append("type", "board");
      formData.append("firstName", this.state.firstName);
      formData.append("lastName", this.state.lastName);
      formData.append("gender", this.state.gender);
      formData.append("biography", this.state.biography);
      formData.append("subject", this.state.selectedSubject.join(","));
      formData.append("relevantSkills", this.state.selectedRelevance.join(","));
      formData.append("subjectText", subjectText);
      formData.append("relevenceText", relevenceText);
      
    try {
      const headers = {
        Authorization: `Bearer ${currentUser.data.token}`,
        Accept: "application/json",
      };

      await axios.put(`${config.ADMIN_API_URL}board/updateBoardSkills`, formData, { headers: headers });
      sweetAlert("success", "Updated Successfully");

      // clode modal and clear vals
      this.setState({
        open: false,
        firstName: null,
        imageAction: false,
        lastName: null,
        gender: null,
        biography: null,
        uploadImage: null,
        subject: [],
        relevantSkills: []
      });

      // make server request
      this.serverReq();
    } catch (error) {
      console.log(error.message)
    }
  }
  
  handleModalClose() {
    this.setState({ open: false })
  }

  async getDetails() {

  }

  render() {
    const { boardSkillQuestionsSubject, boardSkillQuestionsRelevant, firstName, lastName, gender } = this.state;

    return (
      <div>
        <AdminSidebar dataFromParent={this.props.location.pathname} />
        <AdminHeader />
        <div className="main_wrapper">
          <div className="inner_wraapper">
            <div className="container-fluid">
              <section className="d_text">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="governance">
                        <div className="text_Parts">
                          <div className="back_doll">
                            <div className="Member_chain">
                              <div className="back_mel">
                                <h4 className="critical_h">Members </h4>
                              </div>
                            </div>
                            <div className="new_bel">
                              {this.state.items.map((item3, key3) => (
                                <div key={key3} className="velle">
                                  <div className="text_tnp board_space">
                                    <div className="text_image">
                                      <img
                                        src={
                                          item3.uploadImage === null ? ProfilePics
                                            : config.BASE_URL +
                                            item3.uploadImage
                                        }
                                        alt=""
                                      />
                                    </div>
                                    <div className="helop_tex helop_text2 editContBoard">
                                      <div>
                                        <div className="d-flex justify-content-between">
                                          <h4 className="Willi">{item3.firstName + " " + item3.lastName}</h4>
                                        </div>

                                        <div className="hoel_text my-4">
                                          <h4 className="matterexperties">
                                            Gender:
                                          </h4>
                                          <div className="helop">
                                            {item3.gender}
                                          </div>
                                        </div>

                                        <p className="graphic_pri">
                                          {item3.biography}
                                        </p>

                                        <div className="t_matter">
                                          <div className="hoel_text">
                                            <h4 className="matterexperties">
                                              Subject matter expertise:
                                            </h4>
                                            <div className="helop">
                                              <ul className="grapic">
                                                <ListComponent
                                                  items={item3.subjectInText.split(
                                                    ","
                                                  )}
                                                />
                                              </ul>
                                            </div>
                                          </div>
                                        </div>
                                       
                                        {/* <!--  --> */}
                                        <div className="a_maj">
                                          <div className="hoel_text">
                                            <div className="d-flex justify-content-between">
                                              <h4 className="matterexperties">
                                                Relevant Skills:
                                              </h4>
                                            </div>
                                            <div className="helop">
                                              <ul className="grapic">
                                                <ListComponent
                                                  items={item3.relevantSkillsInText.split(
                                                    ","
                                                  )}
                                                />
                                              </ul>
                                            </div>

                                          </div>
                                        </div>
                                      </div>

                                    </div>
                                    <Button
                                        className="edit mb-3 edt_btn min_wid"
                                        variant="primary"
                                        onClick={() => this.handleModalOpen(item3)}
                                      >
                                        <i className="fa fa-edit" />
                                        Edit
                                      </Button>
                                  </div>
                                  <div className="placerop">
                                    <div className="text_image_me"></div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <Modal
                onClose={() => this.handleModalClose()}
                // onOpen={() => this.setOpen(true)}
                open={this.state.open}
                className="modal_box modal_cont"
              >
                <div className="row">
                  <div className="col-md-4"></div>
                  <div className="col-md-4">
                    <div className="modal-heading heading_add">
                      <h4>Update Member</h4>
                    </div>
                  </div>
                  <div className="col-md-4 float-right">
                    <button
                      // color="black"
                      className="btn btn-danger button-red cls_btn"
                    onClick={() => this.handleModalClose()}
                    >
                      Close
                    </button>
                  </div>
                </div>

                <Modal.Content>
                  <Modal.Description className="modalMainContent">
                    <form name="form" onSubmit={this.handleSubmit}>
                      <div className="modal-body vekp">
                        <div className="row">
                          <div className="col-md-8">
                            <div className="mdoel_glop">
                              <div className="form-group convell memberName">
                                <div className="inputHalfWidth mb-3">
                                  <label
                                    className="name_help"
                                    htmlFor="exampleFormControlInput1"
                                  >
                                    First Name
                                  </label>
                                  <input
                                    type="text"
                                    name="firstName"
                                    className="form-control mellp"
                                    // className={`form-control mellp ${isInvalid ? "is-invalid" : ""
                                    //   }`}
                                    id="exampleFormControlInput1"
                                    placeholder="Enter First Name"
                                    onChange={(e) => this.handleChange(e)}
                                    value={firstName}
                                    required
                                  />
                                </div>
                                <div className="inputHalfWidth">
                                  <label
                                    className="name_help"
                                    htmlFor="exampleFormControlInput1"
                                  >
                                    Last Name
                                  </label>
                                  <input
                                    type="text"
                                    name="lastName"
                                    className="form-control mellp"
                                    // className={`form-control mellp ${isInvalid ? "is-invalid" : ""
                                    //   }`}
                                    id="exampleFormControlInput1"
                                    placeholder="Enter Last Name"
                                    onChange={(e) => this.handleChange(e)}
                                    value={lastName}
                                    required
                                  />
                                </div>
                              </div>
                              {/* ................ */}
                              <div className="mb-4">
                                <label className="mb-4 fw-bold">Gender</label>
                                <div className="d-flex" style={{ gap: "20px" }}>
                                  <div className="d-flex">
                                    <label className="female">
                                      <input
                                        type="radio"
                                        value="male"
                                        style={{ marginRight: "10px" }}
                                        checked={gender === "male" ? true : false}
                                        onChange={
                                          this.handleGenderChange
                                        }
                                        ref={this.inputRef}
                                      />
                                      Male
                                    </label>
                                  </div>
                                  <div className="d-flex">
                                    <label className="female">
                                      <input
                                        type="radio"
                                        value="female"
                                        style={{ marginRight: "10px" }}
                                        checked={gender === "female" ? true : false}
                                        onChange={
                                          this.handleGenderChange
                                        }
                                      />
                                      Female
                                    </label>
                                  </div>
                                  <div className="d-flex">
                                    <label className="female">
                                      <input
                                        type="radio"
                                        value="non-binary"
                                        style={{ marginRight: "10px" }}
                                        checked={gender === "non-binary" ? true : false}
                                        onChange={
                                          this.handleGenderChange
                                        }
                                      />
                                      Non-Binary
                                    </label>
                                  </div>
                                </div>
                                <div>
                                  {/* {!checkGender && submitted && (
                                              <div className="help-block">
                                                Gender is required field
                                              </div>
                                            )} */}
                                  {/* <div></div> */}
                                  {/* <label> */}
                                  {/* <input
                                                  type="radio"
                                                  value="other"
                                                  checked={gender === "other"}
                                                  onChange={
                                                    this.handleGenderChange
                                                  }
                                                />
                                                Other
                                              </label> */}
                                </div>
                              </div>

                              {/* ............... */}

                              <div className="form-group convel">
                                <label
                                  className="name_help"
                                  htmlFor="exampleFormControlInput1"
                                >
                                  Brief Biography
                                </label>
                                <textarea
                                  className="form-control text_np"
                                  name="biography"
                                  id="exampleFormControlTextarea1"
                                  placeholder="Write Biography"
                                  rows="3"
                                  onChange={(e) => this.handleChange(e)}
                                  value={this.state.biography}
                                  required
                                ></textarea>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="uploer_fline">
                              <div className="imageCenter">
                                {/*eslint-disable-next-line */}
                                <img
                                  className="user-image mt-2"
                                  id="output"
                                  // alt="profilePic"
                                  src={ this.state.uploadImage === null ? ProfilePics
                                    : config.BASE_URL +
                                    this.state.uploadImage} 
                                />
                              </div>
                              <div className="fline_img upload-button2">
                                {/*eslint-disable-next-line */}
                                <img
                                  className="user-image mt-2"
                                  id="output"
                                  // alt="profilePic"
                                />
                                <input
                                  type="file"
                                  name="uploadImage"
                                  accept=".jpg, .png, .jpeg"
                                  className="form-control uploadbtn"
                                  onChange={this.onFileChange}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="rolop">
                          <div className="row">
                            <div className="col-md-12">
                              <div className="metter_text">
                                <h4 className="metter_one">
                                  Subject matter expertise
                                </h4>
                              </div>
                              <div className="row">
                                {boardSkillQuestionsSubject.map(
                                  (item, key) => (
                                    <div
                                      key={key}
                                      className="col-md-6"
                                    >
                                      <div className="form-check form-check-inline clobal_checkup">
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          name="selectedSubject"
                                          id={item.id}
                                          onChange={(e) => this.handleCheckBoxChange(e)}
                                          // eslint-disable-next-line
                                          checked={this.state.selectedSubject?.some((relevants) => relevants == item.id)}
                                        />
                                        <label
                                          className="form-check-label label_onekl"
                                          htmlFor="inlineCheckbox114"
                                        >
                                          {item.description}
                                          {item.user_id ===
                                            null ? (
                                            ""
                                          ) : (
                                            <span className="trash_icon" onClick={(e) => { this.deleteBoard(item.id, "board") }}>
                                              <i className="fas fa-trash fa-2xs"></i>
                                            </span>
                                          )}
                                        </label>
                                      </div>
                                    </div>
                                  )
                                )}
                              </div>
                            </div>
                            {/* {!isValidCheck1 && submitted && (
                                          <div className="help-block">
                                            At Least One Checkbox is Required
                                          </div>
                                        )} */}
                            <div className="qop">
                              <div className="veant">
                                <h4 className="vante">
                                  Relevant Skills
                                </h4>
                              </div>

                              <div className="row">
                                {boardSkillQuestionsRelevant.map(
                                  (item2, key2) => (
                                    <div
                                      key={key2}
                                      className="col-md-6"
                                    >
                                      <div className="form-check form-check-inline clobal_checkup">
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          name="selectedRelevance"
                                          id={item2.id}
                                          onChange={(e) => this.handleCheckBoxChange(e)}
                                          // eslint-disable-next-line
                                          checked={this.state.selectedRelevance?.some((relevants) => relevants == item2.id)}
                                        // id="inlineCheckbox1140"
                                        />
                                        <label
                                          className="form-check-label label_onekl"
                                          htmlFor="inlineCheckbox114"
                                        >
                                          {item2.description}
                                          {item2.user_id ===
                                            null ? (
                                            ""
                                          ) : (
                                            <span className="trash_icon" onClick={(e) => { this.deleteBoard(item2.id, "board") }}>
                                              <i className="fas fa-trash fa-2xs"></i>
                                            </span>
                                          )}
                                        </label>
                                      </div>
                                    </div>
                                  )
                                )}
                              </div>
                            </div>
                            {/* {!isValidCheck2 && submitted && (
                                          <div className="help-block">
                                            At Least One Checkbox is Required
                                          </div>
                                        )} */}
                            <div
                              className="row"
                              style={{ padding: "0 10px 21px" }}
                            >
                              <div className="col-md-4">
                                <select
                                  name="title"
                                  value={this.state.title}
                                  onChange={(e) =>
                                    this.handleChange(e)
                                  }
                                  className="form-control"
                                >
                                  <option
                                    className="selectOptions"
                                    value={""}
                                  >
                                    Select option
                                  </option>
                                  <option value={"Subject"}>
                                    Subject matter expertise
                                  </option>
                                  <option value={"Relevant"}>
                                    Relevant Skills
                                  </option>
                                </select>
                              </div>
                              <div className="col-md-4">
                                <input
                                  type="text"
                                  onChange={(e) =>
                                    this.handleChange(e)
                                  }
                                  name="expertiseOrskillValue"
                                  value={
                                    this.state.expertiseOrskillValue
                                  }
                                  className="form-control skillsInp"
                                  placeholder="Enter your expertise or skills"
                                />
                              </div>
                              <div className="col-md-4 board_skill">
                                <div
                                  className="link_bal_next skill-save-btn saveSkills"
                                  onClick={() =>
                                    this.createBoardSkills()
                                  }
                                  value={"Save"}
                                >
                                  Save
                                </div>
                                <div className="button-edit btn_fix_flex">

                                  {/* <>
                                          <Button
                                            className="edit mb-3 edt_btn min_wid"
                                            variant="none"
                                            // onClick={() =>
                                            //   // this.setOpen2(true, item3)
                                            // }
                                          >
                                            <i className="fa fa-edit" />
                                            Edit
                                          </Button>
                                          <Button
                                            className="negative ui button min_wid pad_null_fix"
                                            variant="none"
                                            // data-id={item3.id}
                                            onClick={(e) => this.deleteBoard(e)}
                                          >
                                            <i className="fa fa-trash mar_righ_fix" />
                                            Delete
                                          </Button>
                                        </> */}
                                  {/* )} */}
                                </div>
                                {/* <input
                                              className="btn btn-success page-wid"
                                              onClick={(e) =>
                                                this.createBoardSkills(e)
                                              }
                                              value={"Save"}
                                            /> */}
                              </div>
                            </div>
                            <div className="cenlr board_skill_2 saveContMain">
                            <Button
                              className="edit mb-3 edt_btn min_wid"
                              variant="primary"
                              onClick={(e) => this.hitSave(e)}
                            >
                              Update
                            </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </Modal.Description>
                </Modal.Content>
              </Modal>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
