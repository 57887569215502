import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import head_Logo from "../../img/logo.png";
import foot_Logo from "../../img/logol.png";
import "./common.css";
import "./sidebar.css";

export default class AdminSidebar extends Component {
  render() {
    const company = this.props?.dataFromParent?.split("/");
    return (
      <div>
        <div className="d-flex" id="wrapper">
          {/* <!-- Sidebar--> */}
          <div className="border-end bg-white" id="sidebar-wrapper">
            <div className="sidebar-heading border-bottom color_xl">
              <div className="logo_text">
              <NavLink to="/admin"><img src={head_Logo} alt="" /></NavLink>
              </div>
            </div>
            <div className="list-group list-group-flush">
              <div className="route-dom">
                <ul className="home_icon_img">
                  <li>
                    <NavLink
                      to="/admin"
                      className={
                        company[1] === "admin"
                          ? "active list-group-item list-group-item-action list-group-item-light p-3 nop"
                          : "list-group-item list-group-item-action list-group-item-light p-3 nop"
                      }
                    >
                      <i className="fal fa-th-large"></i>
                      <span className="home_boom">Dashboard</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      activeClassName="active"
                      to="/companies"
                      className={
                        company[2] === "CompaniesTabbingPage"
                          ? "active list-group-item list-group-item-action list-group-item-light p-3 nop"
                          : company[2] === "ModuleDetail"
                          ? "active list-group-item list-group-item-action list-group-item-light p-3 nop"
                          : company[2] === "sectorQuestionPage"
                          ? "active list-group-item list-group-item-action list-group-item-light p-3 nop"
                          : company[2] === "governance"
                          ? "active list-group-item list-group-item-action list-group-item-light p-3 nop"
                          : company[2] === "supplierPage"
                          ? "active list-group-item list-group-item-action list-group-item-light p-3 nop"
                          : company[2] === "supplierManagementDetail"
                          ? "active list-group-item list-group-item-action list-group-item-light p-3 nop"
                          : company[2] === "sustainablePage"
                          ? "active list-group-item list-group-item-action list-group-item-light p-3 nop"
                          : company[2] === "carbonFootprintDetail"
                          ? "active list-group-item list-group-item-action list-group-item-light p-3 nop"
                          : company[2] === "boardSkillPage"
                          ? "active list-group-item list-group-item-action list-group-item-light p-3 nop"
                          : company[2] === "EsgProduct"
                          ? "active list-group-item list-group-item-action list-group-item-light p-3 nop"
                          : company[2] === "GovernanceFast"
                          ? "active list-group-item list-group-item-action list-group-item-light p-3 nop"
                          : company[2] === "AdminInvoice"
                          ? "active list-group-item list-group-item-action list-group-item-light p-3 nop"
                          : "list-group-item list-group-item-action list-group-item-light p-3 nop"
                      }
                    >
                      <i className="fal fa-file-chart-line"></i>
                      <span className="home_boom">Companies</span>
                    </NavLink>
                  </li>
                  
                  {/* <li>
                    <NavLink
                      activeClassName="active"
                      to="/Zoho-Form"
                      className="list-group-item list-group-item-action list-group-item-light p-3 nop"
                    >
                      <i className="fal fa-file-chart-line"></i>
                      <span className="home_boom">ZOHO FORM</span>
                    </NavLink>
                  </li> */}
                  <li>
                    <NavLink
                      activeClassName="active"
                      to="/sub-users"
                      a="true"
                      className={
                        company[2] === "UserDetail"
                          ? "active list-group-item list-group-item-action list-group-item-light p-3 nop"
                          : company[2] === "StatusDetail"
                          ? "active list-group-item list-group-item-action list-group-item-light p-3 nop"
                          : "list-group-item list-group-item-action list-group-item-light p-3 nop"
                      }
                    >
                      <i className="far fa-building"></i>
                      <span className="home_boom">ADD Sub users</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      activeClassName="active"
                      to="/revenue"
                      className="list-group-item list-group-item-action list-group-item-light p-3 nop"
                    >
                      <i className="far fa-user-cog"></i>
                      <span className="home_boom">Revenue</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      activeClassName="active"
                      to="/partners_request"
                      className="list-group-item list-group-item-action list-group-item-light p-3 nop"
                    >
                      <i className="far fa-user-cog"></i>
                      <span className="home_boom">Partners Request</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      activeClassName="active"
                      to="/activities"
                      className="list-group-item list-group-item-action list-group-item-light p-3 nop"
                    >
                      <i className="far fa-chart-line"></i>
                      <span className="home_boom">Activities</span>
                    </NavLink>
                  </li>
                  
                  <li>
                    <NavLink
                      activeClassName="active"
                      to="/global_controls"
                      className={
                        company[1] === "controls"
                          ? "list-group-item list-group-item-action list-group-item-light p-3 nop active"
                          : company[1] === "industry_categories"
                          ? "list-group-item list-group-item-action list-group-item-light p-3 nop active"
                          : company[1] === "add_industry_type"
                          ? "list-group-item list-group-item-action list-group-item-light p-3 nop active"
                          : company[1] === "add_industry"
                          ? "list-group-item list-group-item-action list-group-item-light p-3 nop active"
                          : company[1] === "industry"
                          ? "list-group-item list-group-item-action list-group-item-light p-3 nop active"
                          : company[1] === "questions_industry_wise"
                          ? "list-group-item list-group-item-action list-group-item-light p-3 nop active"
                          : company[1] === "add_sector_questions"
                          ? "list-group-item list-group-item-action list-group-item-light p-3 nop active"
                          : company[1] === "sector_questions"
                          ? "list-group-item list-group-item-action list-group-item-light p-3 nop active"
                          : company[1] === "innovative_programmes"
                          ? "list-group-item list-group-item-action list-group-item-light p-3 nop active"
                          : company[1] === "add_innovative_programmes"
                          ? "list-group-item list-group-item-action list-group-item-light p-3 nop active"
                          : company[1] === "program"
                          ? "list-group-item list-group-item-action list-group-item-light p-3 nop active"
                          : company[1] === "introduction_videos"
                          ? "list-group-item list-group-item-action list-group-item-light p-3 nop active"
                          : company[1] === "video"
                          ? "list-group-item list-group-item-action list-group-item-light p-3 nop active"
                          : company[1] === "add_introduction_videos"
                          ? "list-group-item list-group-item-action list-group-item-light p-3 nop active"
                          : company[1] === "policies"
                          ? "list-group-item list-group-item-action list-group-item-light p-3 nop active"
                          : company[1] === "add_new_policy"
                          ? "list-group-item list-group-item-action list-group-item-light p-3 nop active"
                          : company[1] === "policy"
                          ? "list-group-item list-group-item-action list-group-item-light p-3 nop active"
                          : company[1] === "governance_policies"
                          ? "list-group-item list-group-item-action list-group-item-light p-3 nop active"
                          : company[1] === "cyber_&_technology_policies"
                          ? "list-group-item list-group-item-action list-group-item-light p-3 nop active"
                          : company[1] === "social_policies"
                          ? "list-group-item list-group-item-action list-group-item-light p-3 nop active"
                          : company[1] === "helth_&_sefty_policies"
                          ? "list-group-item list-group-item-action list-group-item-light p-3 nop active"
                          : company[1] === "environmental_policies"
                          ? "list-group-item list-group-item-action list-group-item-light p-3 nop active"
                          : company[1] === "other_policies"
                          ? "list-group-item list-group-item-action list-group-item-light p-3 nop active"
                          : company[1] === "reportings"
                          ? "list-group-item list-group-item-action list-group-item-light p-3 nop active"
                          : company[1] === "report"
                          ? "list-group-item list-group-item-action list-group-item-light p-3 nop active" 
                          :"list-group-item list-group-item-action list-group-item-light p-3 nop"
                      }
                    >
                      <i className="far fa-cog"></i>
                      <span className="home_boom">Global Controls</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/profile"
                      className={
                        company[1] === "billing"
                          ? "active list-group-item list-group-item-action list-group-item-light p-3 nop"
                          : company[1] === "2fa"
                          ? "active list-group-item list-group-item-action list-group-item-light p-3 nop"
                          : "list-group-item list-group-item-action list-group-item-light p-3 nop"
                      }
                    >
                      <i className="fal fa-file-chart-line"></i>
                      <span className="home_boom">Settings</span>
                    </NavLink>
                  </li>
                </ul>
              </div>
            </div>
            <div className="slill_bord">
              <img src={foot_Logo} alt="" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}