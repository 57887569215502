/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { Component } from "react";
import "../../Company Admin/Setting/setting.css";
import config from "../../../config/config.json";
import {sweetAlert} from '../../../utils/UniversalFunction'
import { authenticationService } from "../../../_services/authentication";
import AdminSidebar from "../../sidebar/admin_sidebar";
import AdminHeader from "../../header/admin_header";
import CompaniesTabbing from "../companies/companies_tabbing";
import axios from "axios";
const currentUser = authenticationService.currentUserValue;
const ProfilePics = "https://res.cloudinary.com/dmklsntsw/image/upload/v1658480882/dummyPic.75a04487_fqfqey.png"
export default class CompaniesDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      details: {},
      error: null,
      isLoaded: false,
      firstName: "",
      lastName: "",
      email: "",
      businessNumber: "",
      sectorofInterests: "",
      userCategory: "",
      register_company_name: "",
      main_country_of_operations: "",
      company_industry: "",
      password:"",
      logo: "",
      position: "",
      no_of_users: "",
      id: "",
      uuid:"",
      isChecked:false,
      type: "password"
    };
    this.serverRequest = this.serverRequest.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit2 = this.handleSubmit2.bind(this);
    this.showHide = this.showHide.bind(this);

  }

  showHide(e) {
    e.preventDefault();
    e.stopPropagation();
    this.setState({
      type: this.state.type === "password" ? "input" : "password",
    });
  }
  handleChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  }

  onFileChange = (event) => {
    let id = event.target.getAttribute("data-id");
    const formData = new FormData();

    // Update the formData object
    formData.append(
      "uploadImage",
      event.target.files[0],
      event.target.files[0].name
    );
    formData.append("id", id);
    const headers = {
      Authorization: `Bearer ${currentUser.data.token}`,
      Accept: "application/json",
    };
    axios
      .post(config.API_URL + "uploadProfilePictureWIthId", formData, {
        headers,
      })
      .then((response) => {
        sweetAlert('success',response.data.message);	
        this.setState({ logo: response.data.data });
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch(function (response) {
        sweetAlert('error',response.data.message);
      });
  };

  updateUserStatus = (event) => {
    let uuid = this.state.uuid;
    let isChecked = event.target.checked;
    const headers = {
      Authorization: `Bearer ${currentUser.data.token}`,
      Accept: "application/json",
    };
    let isActiveVal;
    if (isChecked === true) {
      isActiveVal = 1;
    } else {
      isActiveVal = 0;
    }
    axios.post(`${config.ADMIN_API_URL}companies/${uuid}/details`,
        {
          status: isActiveVal,
        },
        {
          headers,
        }
      )
      .then((response) => {
        sweetAlert('success',response.data.message);	
        this.setState({ isChecked: isActiveVal === 1 ? true : false });
      })
      .catch(function (response) {
        sweetAlert('error',response.data.message);
      });
  };

  handleSubmit2(event) {
    event.preventDefault();
    this.setState({ submitted: true });
    const {
      firstName,
      lastName,
      businessNumber,
      userCategory,
      register_company_name,
      main_country_of_operations,
      company_industry,
      position,
      password,
      no_of_users,
      id,
      email,
    } = this.state;
    const headers = {
      Authorization: `Bearer ${currentUser.data.token}`,
      Accept: "application/json",
    };
    axios
      .post(
        config.ADMIN_API_URL + "company/" + id,
        {
          register_company_name: register_company_name,
          main_country_of_operations: main_country_of_operations,
          business_number: businessNumber,
          company_industry: company_industry,
          user_category: userCategory,
          first_name: firstName,
          last_name: lastName,
          position: position,
          no_of_users: no_of_users,
          password: password,
          email: email,
        },
        { headers }
      )
      .then((response) => {
        sweetAlert('success',response.data.message);	
      })
      .catch(function (error) {
        if (error.response) {
          sweetAlert('error',error.response.data.message);
        }
      });
  }

  serverRequest(uuidd) {
    let uuid = uuidd;
    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${currentUser.data.token}`,
        "Content-Type": "application/json",
      },
    };
    let parms = `company/${uuid}`;
    fetch(config.ADMIN_API_URL + parms, requestOptions)
      .then((res) => res.json())
      .then(
        (data) => {
          
          this.setState({
            details: data.data[0],
            register_company_name: data.data[0]?.register_company_name,
            main_country_of_operations:
              data.data[0]?.main_country_of_operations,
            businessNumber: data.data[0]?.businessNumber,
            company_industry: data.data[0]?.company_industry,
            userCategory: data.data[0]?.userCategory,
            firstName: data.data[0]?.firstName,
            lastName: data.data[0]?.lastName,
            position: data.data[0]?.position,
            isChecked:(data.data[0]?.isActive === 1) ? true : false, 
            email: data.data[0]?.email,
            no_of_users: data.data[0]?.no_of_users,
            id: data.data[0]?.id,
            logo:
              data.data[0]?.logo === null
                ? ProfilePics
                : config.BASE_URL + data.data[0]?.logo,
          });
          
        },
        (error) => {}
      );
  }
  componentDidMount() {
    let urlArr = window.location.pathname.split("/");
    this.serverRequest(urlArr[urlArr.length - 2]);
    this.setState({
      uuid:urlArr[urlArr.length - 2]
    })
  }

  render() {
    const {
      register_company_name,
      main_country_of_operations,
      businessNumber,
      company_industry,
      userCategory,
      firstName,
      lastName,
      position,
      no_of_users,
      email,
      logo,
      id,
      type
    } = this.state;
    return (
      <div>
        <AdminSidebar dataFromParent={this.props.location.pathname} />
        <AdminHeader />
        <div className="main_wrapper">
          <div className="inner_wraapper">
            <div className="container-fluid">
              <section className="d_text">
                <div className="container-fluid">
                  <div className="row">
                    <CompaniesTabbing />
                    <div className="col-sm-12">
                      <div className="SVG Stepper">
                        <div className="stepperr_design">
                          <div className="color_div_step my-5">
                            <div className="include">
                              <section className="forms">
                                <div className="row">
                                  <div className="col-md-8 col-xs-12">
                                    <form onSubmit={this.handleSubmit2}>
                                      <div className="business_detail">
                                        <div className="main_one">
                                          <div className="main_two">
                                            <h4>Business Details</h4>
                                          </div>
                                          <div className="main_business_details">
                                          
                                          {/* <i className="fas fa-trash black delete_icon" /> */}
                                          <label className="switch">
                                            <input type="checkbox"  onChange={(e) =>
                                              this.updateUserStatus(e)
                                            } checked={this.state.isChecked}/>
                                            
                                            <span className="slider round"></span>
                                          </label>
                                          </div>
                                        </div>
                                        <hr className="line"></hr>
                                        <div className="form-group pb-3">
                                          <label htmlFor="exampleInputEmail1">
                                            Registered Company Name
                                          </label>
                                          <input
                                            type="text"
                                            value={register_company_name}
                                            name="register_company_name"
                                            onChange={this.handleChange}
                                            className="form-control"
                                            id="exampleInputEmail1"
                                            aria-describedby="emailHelp"
                                            placeholder="Johnson &amp; Johnson"
                                          />
                                        </div>
                                        <div className="form-group pb-3">
                                          <label htmlFor="exampleInputPassword1">
                                            Main Country Of Operations
                                          </label>
                                          <input
                                            type="text"
                                            className="form-control"
                                            value={main_country_of_operations}
                                            name="main_country_of_operations"
                                            onChange={this.handleChange}
                                            id="exampleInputPassword1"
                                            placeholder="USA"
                                          />
                                        </div>
                                        <div className="row">
                                      <div className="col-lg-6 col-xs-6">
                                        <div className="form-group pb-3">
                                        <label htmlFor="exampleInputPassword1">
                                            Business Number
                                          </label>
                                          <input
                                            type="tel"
                                            className="form-control"
                                            id="exampleInputPassword1"
                                            placeholder="businessNumber"
                                            maxLength={9}
                                            name="businessNumber"
                                            onChange={this.handleChange}
                                            value={businessNumber}
                                          />
                                        </div>
                                      </div>
                                      <div className="col-lg-6 col-xs-6">
                                        <div className="form-group pb-3">
                                          <label htmlFor="exampleInputPassword1">
                                            No. of Users
                                          </label>
                                          <input
                                            type="text"
                                            className="form-control disableddd"
                                            id="exampleInputPassword1"
                                            placeholder="Number of Users"
                                            name="no_of_users"
                                            disabled
                                            onChange={this.handleChange}
                                            value={no_of_users}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                        <div className="row">
                                          <div className="col-xxl-6 col-lg-12 col-md-12 col-12">
                                            <div className="form-group pb-3">
                                              <label htmlFor="exampleInputPassword1">
                                                Company Industry
                                              </label>
                                              <input
                                                type="text"
                                                value={company_industry}
                                                name="company_industry"
                                                onChange={this.handleChange}
                                                className="form-control disableddd"
                                                disabled
                                                id="exampleInputPassword1"
                                                placeholder="Business"
                                              />
                                            </div>
                                          </div>
                                          <div className="col-xxl-6 col-lg-12 col-md-12 col-12">
                                            <div className="form-group pb-3">
                                              <label htmlFor="exampleInputPassword1">
                                                User Category
                                              </label>
                                              <input
                                                type="text"
                                                value={userCategory}
                                                name="userCategory"
                                                onChange={this.handleChange}
                                                className="form-control disableddd"
                                                disabled
                                                id="exampleInputPassword1"
                                                placeholder="Partner"
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="business_detail mt-3">
                                        <div className="heading">
                                          <h4>Admin Details</h4>
                                        </div>
                                        <hr className="line"></hr>
                                        <div className="row">
                                          <div className="col-xxl-6 col-lg-12 col-md-12 col-12">
                                            <div className="form-group pb-3">
                                              <label htmlFor="exampleInputPassword1">
                                                First Name
                                              </label>
                                              <input
                                                type="text"
                                                value={firstName}
                                                name="firstName"
                                                onChange={this.handleChange}
                                                className="form-control"
                                                id="exampleInputPassword1"
                                                placeholder="John"
                                              />
                                            </div>
                                          </div>
                                          <div className="col-xxl-6 col-lg-12 col-md-12 col-12">
                                            <div className="form-group pb-3">
                                              <label htmlFor="exampleInputPassword1">
                                                Last Name
                                              </label>
                                              <input
                                                type="text"
                                                value={lastName}
                                                onChange={this.handleChange}
                                                name="lastName"
                                                className="form-control"
                                                id="exampleInputPassword1"
                                                placeholder="Cooper"
                                              />
                                            </div>
                                          </div>
                                        </div>
                                        <div className="form-group pb-3">
                                          <label htmlFor="exampleInputEmail1">
                                            Title or Position
                                          </label>
                                          <input
                                            type="text"
                                            className="form-control disableddd"
                                            value={position}
                                            name="position"
                                            disabled
                                            onChange={this.handleChange}
                                            id="exampleInputEmail1"
                                            aria-describedby="emailHelp"
                                            placeholder="CEO"
                                          />
                                        </div>
                                        <div className="form-group pb-3">
                                          <label htmlFor="exampleInputPassword1">
                                            Corporate Email
                                          </label>
                                          <input
                                            type="email"
                                            className="form-control disableddd"
                                            id="exampleInputPassword1"
                                            placeholder="name"
                                            disabled
                                            name="email"
                                            onChange={this.handleChange}
                                            value={email}
                                          />
                                        </div>
                                        <div className="form_sign password-eye">
                                          <div className="img-eye">
                                            <span onClick={this.showHide}>
                                              {this.state.type === "input" ? (
                                                <i className="fas fa-eye-slash"></i>
                                              ) : (
                                                <i className="fas fa-eye"></i>
                                              )}
                                            </span>
                                          </div>
                                          <div >
                                            <label
                                              className="st_name"
                                              htmlFor="name"
                                            >
                                              Password
                                            </label>
                                            <input
                                              className="form-control name_nf"
                                              type={type}
                                              name="password"
                                              id="exampleInputPassword1"
                                              placeholder="Enter your password"
                                              onChange={this.handleChange}
                                            />
                                          </div>
                                        </div>

                                        
                                      </div>
                                      <div className="view_bottoma d-flex justify-content-start" style={{ marginTop: '20px' }}>
                                        <button
                                          type="submit"
                                          className="left"
                                        >
                                          Submit
                                        </button>
                                      </div>
                                    </form>
                                  </div>
                                  <div className="col-lg-4 col-xs-12">
                                    <div className="upload_image">
                                      <img
                                        className="file-upload-image"
                                        src={logo}
                                        alt="your image"
                                      />
                                      <input
                                        type="file"
                                        accept='.jpg, .png, .jpeg'
                                        name="uploadImage"
                                        data-id={id}
                                        onChange={this.onFileChange}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </section>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
