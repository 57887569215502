/* eslint-disable react/jsx-no-target-blank */
import React, { Component } from "react";
import axios from "axios";
import "react-datepicker/dist/react-datepicker.css";
import AdminSidebar from "../../sidebar/admin_sidebar";
import AdminHeader from "../../header/admin_header";
import config from "../../../config/config.json";
import { authenticationService } from "../../../_services/authentication";
import { sweetAlert } from "../../../utils/UniversalFunction";
import "./companies.css";
import { Accordion, Spinner } from "react-bootstrap";
import classes from "./carbon.module.css";
import moment from "moment";

const BackendBaseUrl = config.BASE_URL;
const currentUser = authenticationService.currentUserValue;
export default class carbonFootprintDetail extends Component {
  constructor(props) {
    super(props);

    let today = new Date(),
      date = today.getFullYear();

    this.state = {
      error: null,
      isLoaded: false,
      items: [],
      fullYear: date,
      scopeFirst: {},
      scopeSecond: {},
      scopeThird: {},
      report: [],
      edit: false,
      scope1: [],
      scope2: [],
      scope3: [],
      totals: {
        scope1: {
          total_cy: 0,
          total_kgco2e: 0,
          total_tco2e: 0,
          total_emission: 0,
        },
        scope2: {
          total_cy: 0,
          total_kgco2e: 0,
          total_tco2e: 0,
          total_emission: 0,
        },
        scope3: {
          total_cy: 0,
          total_kgco2e: 0,
          total_tco2e: 0,
          total_emission: 0,
        },
      },
      saveLoading: false,
    };
    this.onFileChange = this.onFileChange.bind(this);
    this.getUploadedReportData = this.getUploadedReportData.bind(this);
  }

  onFileChange = (event) => {
    let urlArr = window.location.pathname.split("/");
    const finalUUId = urlArr[urlArr.length - 3];

    const formData = new FormData();
    // Update the formData object
    formData.append(
      "uploadImage",
      event.target.files[0],
      event.target.files[0].name
    );
    formData.append("section_name", "carbon_footprint");
    formData.append("sub_section", "carbon_footprint");
    const headers = {
      Authorization: `Bearer ${currentUser.data.token}`,
      Accept: "application/json",
    };
    axios
      .post(
        `${config.ADMIN_API_URL}company/${finalUUId}/sector-question/upload/report`,
        formData,
        {
          headers,
        }
      )
      .then((response) => {
        sweetAlert("success", response.data.message);
      })
      .catch(function (response) {
        sweetAlert("error", response.data.message);
      });
  };

  getUploadedReportData = () => {
    let urlArr = window.location.pathname.split("/");
    const finalUUId = urlArr[urlArr.length - 3];
    const headers = {
      Authorization: `Bearer ${currentUser.data.token}`,
      Accept: "application/json",
    };
    fetch(
      `${config.ADMIN_API_URL}company/${finalUUId}/sector-question/download/report/carbon_footprint/carbon_footprint`,
      {
        headers,
      }
    )
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            report: result.data,
          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error,
          });
        }
      );
    // company/:uuid/sector-question/download/report
  };

  componentDidMount() {
    this.getUploadedReportData();
    this.getScopesSummary();

    // let urlArr = window.location.pathname.split("/");
    // const finalUUId = urlArr[urlArr.length - 3];
    // const headers = {
    //   Authorization: `Bearer ${currentUser.data.token}`,
    //   Accept: "application/json",
    // };

    // fetch(config.ADMIN_API_URL + `company/${finalUUId}/modules/carbonAnswer`, {
    //   headers,
    // })
    //   .then((res) => res.json())
    //   .then(
    //     (result) => {
    //       this.setState({
    //         isLoaded: true,
    //         scopeFirst: result.scopeFirst[0],
    //         scopeSecond: result.scopeSecond[0],
    //         scopeThird: result.scopeThird[0],
    //       });
    //     },
    //     (error) => {
    //       this.setState({
    //         isLoaded: true,
    //         error,
    //       });
    //     }
    //   );
  }

  handleEditClick() {
    this.setState({ edit: !this.state.edit });
  }

  // handleScopeChange(e) {
  //   const { id: key, name: stateName, value } = e.target;
  //   let temp = Object.assign(this.state[stateName]);
  //   temp[key] = value;
  //   this.setState({ [stateName]: temp });
  // }

  // async handleSaveScopeData(type) {
  //   const { uuid } = this.props.match.params;
  //   let payload = {
  //     uuid: uuid,
  //     type: type,
  //     ...this.state[type],
  //   };

  //   try {
  //     const headers = {
  //       Authorization: `Bearer ${currentUser.data.token}`,
  //       Accept: "application/json",
  //     };
  //     await axios.put(
  //       `${config.ADMIN_API_URL}carbon/updateScopeData`,
  //       payload,
  //       { headers: headers }
  //     );
  //     sweetAlert("success", "Updated Successfully");
  //   } catch (error) {
  //     sweetAlert("error", "Oops some error occured");
  //     // show error message
  //     console.log(error.message);
  //   }

  //   console.log("payload", payload);
  // }

  async getScopesSummary() {
    try {
      let urlArr = window.location.pathname.split("/");
      const finalUUId = urlArr[urlArr.length - 3];

      const headers = {
        Authorization: `Bearer ${currentUser.data.token}`,
        Accept: "application/json",
      };

      const apiResponse = await axios.get(
        `${config.ADMIN_API_URL}carbon/getScopeSummary/${finalUUId}`,
        {
          headers: headers,
        }
      );

      this.setState({
        scope1: apiResponse.data.data.scope1,
        scope2: apiResponse.data.data.scope2,
        scope3: apiResponse.data.data.scope3,
        totals: apiResponse.data.totals,
      });
    } catch (err) {
      sweetAlert(
        "error",
        err.response.data.message || "Oops some error occured!"
      );
    }
  }

  handleEmissionChange(e, parentIndex, childIndex, type) {
    const { name, value } = e.target;
    let temp = [...this.state[type]];
    temp[parentIndex][childIndex][name] = value;
   
    temp[parentIndex][childIndex].emission_kgco2e = parseFloat(temp[parentIndex][childIndex].activity_cy) * parseFloat(temp[parentIndex][childIndex].emission_factor);
    temp[parentIndex][childIndex].emission_tco2e = parseFloat(temp[parentIndex][childIndex].emission_kgco2e) / 1000
   
   
    this.setState({ [type]: temp });

    this.calculateTotalEmission(temp, type)
  }


  calculateTotalEmission (dataset, type) {

    let totalSumScope = 0;
    dataset.forEach((innerArray) => {
      innerArray.forEach((element) => {
        totalSumScope += isNaN(element.emission_kgco2e)
          ? 0
          : element.emission_kgco2e;
      });
    });


    let totalSum = 0;
    if(type === 'scope1') {
      totalSum += this.state.totals.scope2.total_kgco2e + this.state.totals.scope3.total_kgco2e + totalSumScope;
    }else if(type === 'scope2') {
      totalSum += this.state.totals.scope1.total_kgco2e + this.state.totals.scope3.total_kgco2e + totalSumScope;
    }else{
      totalSum += this.state.totals.scope1.total_kgco2e + this.state.totals.scope2.total_kgco2e + totalSumScope;
    }

    console.log("totalSum", totalSum);
    const temp = dataset.map((child) => {
      const childArray = child.map((item) => {
        return {
          ...item,
          total_emission: (item.emission_kgco2e / totalSum) * 100,
        };
      });
      return childArray;
    });

    this.setState({ [type]: temp });
    // setEntities(temp);
  };

  async handleEmissionSave() {
    this.setState({ edit: true, saveLoading: true });
    const s1_flattened = this.state.scope1.reduce(
      (acc, curr) => acc.concat(curr),
      []
    );
    const s2_flattened = this.state.scope2.reduce(
      (acc, curr) => acc.concat(curr),
      []
    );
    const s3_flattened = this.state.scope3.reduce(
      (acc, curr) => acc.concat(curr),
      []
    );

    const combinedPayload = [...s1_flattened, ...s2_flattened, ...s3_flattened];

    const formattedPayload = combinedPayload.map((item) => {
      return {
        config_id: item.id,
        activity_cy: item?.activity_cy ? item?.activity_cy : "0",
        emission_factor: item?.emission_factor ? item?.emission_factor : "0",
        emission_kgco2e: item?.emission_kgco2e ? item?.emission_kgco2e : "0",
        emission_tco2e: item?.emission_tco2e ? item?.emission_tco2e : "0",
        total_emission: item?.total_emission ? item?.total_emission : "0",
      };
    });

    // hit api request
    try {
      let urlArr = window.location.pathname.split("/");
      const finalUUId = urlArr[urlArr.length - 3];

      const headers = {
        Authorization: `Bearer ${currentUser.data.token}`,
        Accept: "application/json",
      };

      await axios.put(
        `${config.ADMIN_API_URL}carbon/saveScopeSummary/${finalUUId}`,
        formattedPayload,
        {
          headers: headers,
        }
      );

      this.setState({
        saveLoading: false,
        edit: false,
      });
      sweetAlert("success", "Saved Successfully!");
      this.getScopesSummary();
    } catch (err) {
      sweetAlert(
        "error",
        err.response.data.message || "Oops some error occured!"
      );
    }
  }

  render() {
    const { report, edit, scope1, scope2, scope3, totals } = this.state;
    return (
      <div>
        <AdminSidebar dataFromParent={this.props.location.pathname} />
        <AdminHeader />
        <div className="main_wrapper">
          <div className="inner_wraapper">
            <div className="container-fluid">
              <section className="d_text">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="color_div_on framwork_2">
                        <div className="text_Parts">
                          <div className="text_Description">
                            <div className="d-flex justify-content-between mb-3">
                              <h5 className="motor">Introduction</h5>
                            </div>
                            <p className="specialize">
                              A carbon footprint is an estimate of the total
                              output of greenhouse gas (GHG) emissions caused by
                              an organisation, event, product or person.
                            </p>
                            <p>
                              Calculating your carbon footprint helps set a
                              baseline. Following this up with annual
                              measurements provides you with a consistent and
                              accurate picture across your business.
                              Understanding your carbon footprint will help you
                              understand the activities that result in carbon
                              emissions and then take action to reduce them. As
                              well as helping the environment, managing your
                              carbon emissions can help you save money, cut
                              reputational risk and create new business
                              opportunities.
                            </p>
                          </div>
                          <div className="variants">
                            <div className="file file--upload">
                              <label htmlFor="input-file">
                                <i
                                  className="fa fa-upload"
                                  aria-hidden="true"
                                ></i>
                                Upload Report
                              </label>
                              <input
                                // style={{ visibility: "hidden" }}
                                type="file"
                                id="input-file"
                                accept=".pdf, .doc, .docx, .xlsx, .pptx, .ppt, .ott, .odp"
                                name="governancePolicy"
                                onChange={this.onFileChange}
                              />
                            </div>
                            {report[0]?.file !== undefined && (
                              <a
                                className="outputFile"
                                target="_blank"
                                href={BackendBaseUrl + report[0]?.file ?? ""}
                              >
                                Preview
                              </a>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-sm-12">
                      <div className="color_div_on framwork_2 mt-3">
                        <div className="text_Parts">
                          <div className="col-md-12">
                            <div className="Capital_op carbonEditContainer">
                              <h4 className="E_Emis">Summary</h4>
                              <div className="actions_carbon_container">
                                {edit && (
                                  <button
                                    id="savbtnMain2"
                                    className="link_bal_next white "
                                    variant="none"
                                    onClick={() => this.handleEditClick()}
                                  >
                                    Cancel
                                  </button>
                                )}

                                {edit ? (
                                  <button
                                    id="savbtnMain2"
                                    className="page_save loaderBtnMain"
                                    variant="none"
                                    onClick={() =>
                                      !this.state.saveLoading
                                        ? this.handleEmissionSave()
                                        : null
                                    }
                                  >
                                    Save&nbsp;{" "}
                                    {this.state.saveLoading ? (
                                      <Spinner />
                                    ) : (
                                      <i className="fa fa-save"></i>
                                    )}
                                  </button>
                                ) : (
                                  <button
                                    id="savbtnMain2"
                                    className="page_save loaderBtnMain"
                                    variant="none"
                                    onClick={() => this.handleEditClick()}
                                  >
                                    Edit&nbsp;<i className="fas fa-pen"></i>
                                  </button>
                                )}
                              </div>
                            </div>

                            <Accordion
                              defaultActiveKey={["0", "1", "2"]}
                              alwaysOpen
                            >
                              <Accordion.Item
                                eventKey="0"
                                className={classes.accordianItem}
                              >
                                <Accordion.Header className={classes.accHeader}>
                                  Scope 1 Emissions
                                </Accordion.Header>
                                <Accordion.Body
                                  className={classes.accordionSpc}
                                >
                                  <table className={classes.scopeTable}>
                                    <thead>
                                      <tr>
                                        <th
                                          className={classes.tableHeadCell}
                                          style={{ width: "10%" }}
                                        >
                                          Group
                                        </th>
                                        <th
                                          className={classes.tableHeadCell}
                                          style={{ width: "13%" }}
                                        >
                                          Emissions Group
                                        </th>
                                        <th
                                          className={classes.tableHeadCell}
                                          style={{ width: "20%" }}
                                        >
                                          Emission Source
                                        </th>
                                        <th
                                          className={classes.tableHeadCell}
                                          style={{ width: "8%" }}
                                        >
                                          Unit
                                        </th>
                                        <th
                                          className={classes.tableHeadCell}
                                          style={{ width: "10%" }}
                                        >
                                          Activity data CY {moment().year()}
                                        </th>
                                        <th
                                          className={classes.tableHeadCell}
                                          style={{ width: "10%" }}
                                        >
                                          Emission factor
                                        </th>
                                        <th
                                          className={classes.tableHeadCell}
                                          style={{ width: "10%" }}
                                        >
                                          {moment().year()} Emissions (kg CO2e)
                                        </th>
                                        <th
                                          className={classes.tableHeadCell}
                                          style={{ width: "10%" }}
                                        >
                                          {moment().year()} Emissions (tCO2e)
                                        </th>
                                        <th
                                          className={classes.tableHeadCell}
                                          style={{ width: "10%" }}
                                        >
                                          {moment().year()} Emissions % of total
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td colSpan="12" className="hr_spacing">
                                          {" "}
                                          <hr
                                            className={classes.tableHr}
                                          />{" "}
                                        </td>
                                      </tr>
                                      {scope1.map((child, parentIndex) =>
                                        child.map((item, index) => (
                                          <tr key={index}>
                                            <td
                                              className={classes.tableBodyCell}
                                            >
                                              {" "}
                                              {index === 0 ? item?.group : null}
                                            </td>
                                            <td
                                              className={classes.tableBodyCell}
                                            >
                                              {item?.emission_group}
                                            </td>
                                            <td
                                              className={classes.tableBodyCell}
                                            >
                                              {item?.source}
                                            </td>
                                            <td
                                              className={classes.tableBodyCell}
                                            >
                                              {item?.unit}
                                            </td>
                                            <td
                                              className={classes.tableBodyCell}
                                            >
                                              {edit ? (
                                                <input
                                                  className="form-control"
                                                  type="number"
                                                  name="activity_cy"
                                                  value={item?.activity_cy}
                                                  onChange={(e) =>
                                                    this.handleEmissionChange(
                                                      e,
                                                      parentIndex,
                                                      index,
                                                      "scope1"
                                                    )
                                                  }
                                                />
                                              ) : (
                                                item?.activity_cy
                                              )}
                                            </td>
                                            <td
                                              className={classes.tableBodyCell}
                                            >
                                              {edit ? (
                                                <input
                                                  className="form-control"
                                                  type="number"
                                                  name="emission_factor"
                                                  value={item?.emission_factor.toFixed(2)}
                                                  onChange={(e) =>
                                                    this.handleEmissionChange(
                                                      e,
                                                      parentIndex,
                                                      index,
                                                      "scope1"
                                                    )
                                                  }
                                                />
                                              ) : (
                                                item?.emission_factor.toFixed(2)
                                              )}
                                            </td>
                                            <td
                                              className={classes.tableBodyCell}
                                            >
                                              {item?.emission_kgco2e
                                                ? item?.emission_kgco2e.toFixed(
                                                    2
                                                  )
                                                : 0.0}
                                            </td>
                                            <td
                                              className={classes.tableBodyCell}
                                            >
                                              {item?.emission_tco2e
                                                ? item?.emission_tco2e.toFixed(
                                                    2
                                                  )
                                                : 0.0}
                                            </td>
                                            <td
                                              className={classes.tableBodyCell}
                                            >
                                              {item?.total_emission
                                                ? item?.total_emission.toFixed(
                                                    2
                                                  )
                                                : 0.0}
                                            </td>
                                          </tr>
                                        ))
                                      )}

                                      <tr>
                                        <td colSpan="12" className="hr_spacing">
                                          <hr className={classes.tableHr} />{" "}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className={classes.tableBodyCell}>
                                          <strong>Scope 1 Total</strong>
                                        </td>
                                        <td
                                          className={classes.tableBodyCell}
                                        ></td>
                                        <td
                                          className={classes.tableBodyCell}
                                        ></td>
                                        <td
                                          className={classes.tableBodyCell}
                                        ></td>
                                        <td className={classes.tableBodyCell}>
                                          <strong>
                                            {totals.scope1.total_cy.toFixed(2)}
                                          </strong>
                                        </td>
                                        <td
                                          className={classes.tableBodyCell}
                                        ></td>
                                        <td className={classes.tableBodyCell}>
                                          <strong>
                                            {totals.scope1.total_kgco2e.toFixed(2)}kgCO2e
                                          </strong>
                                        </td>
                                        <td className={classes.tableBodyCell}>
                                          <strong>
                                            {totals.scope1.total_tco2e.toFixed(2)}tCO2e
                                          </strong>
                                        </td>
                                        <td className={classes.tableBodyCell}>
                                          <strong>
                                            {totals.scope1.total_emission.toFixed(2)}%
                                          </strong>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </Accordion.Body>
                              </Accordion.Item>
                              <Accordion.Item
                                eventKey="1"
                                className={classes.accordianItem}
                              >
                                <Accordion.Header className={classes.accHeader}>
                                  Scope 2 Emissions
                                </Accordion.Header>
                                <Accordion.Body
                                  className={classes.accordionSpc}
                                >
                                  <table className={classes.scopeTable}>
                                    <thead>
                                      <tr>
                                        <th
                                          className={classes.tableHeadCell}
                                          style={{ width: "10%" }}
                                        >
                                          Group
                                        </th>
                                        <th
                                          className={classes.tableHeadCell}
                                          style={{ width: "13%" }}
                                        >
                                          Emissions Group
                                        </th>
                                        <th
                                          className={classes.tableHeadCell}
                                          style={{ width: "20%" }}
                                        >
                                          Emission Source
                                        </th>
                                        <th
                                          className={classes.tableHeadCell}
                                          style={{ width: "8%" }}
                                        >
                                          Unit
                                        </th>
                                        <th
                                          className={classes.tableHeadCell}
                                          style={{ width: "10%" }}
                                        >
                                          Activity data CY {moment().year()}
                                        </th>
                                        <th
                                          className={classes.tableHeadCell}
                                          style={{ width: "10%" }}
                                        >
                                          Emission factor
                                        </th>
                                        <th
                                          className={classes.tableHeadCell}
                                          style={{ width: "10%" }}
                                        >
                                          {moment().year()} Emissions (kg CO2e)
                                        </th>
                                        <th
                                          className={classes.tableHeadCell}
                                          style={{ width: "10%" }}
                                        >
                                          {moment().year()} Emissions (tCO2e)
                                        </th>
                                        <th
                                          className={classes.tableHeadCell}
                                          style={{ width: "10%" }}
                                        >
                                          {moment().year()} Emissions % of total
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td colSpan="12" className="hr_spacing">
                                          {" "}
                                          <hr
                                            className={classes.tableHr}
                                          />{" "}
                                        </td>
                                      </tr>
                                      {scope2.map((child, parentIndex) =>
                                        child.map((item, index) => (
                                          <tr key={index}>
                                            <td
                                              className={classes.tableBodyCell}
                                            >
                                              {" "}
                                              {index === 0 ? item?.group : null}
                                            </td>
                                            <td
                                              className={classes.tableBodyCell}
                                            >
                                              {item?.emission_group}
                                            </td>
                                            <td
                                              className={classes.tableBodyCell}
                                            >
                                              {item?.source}
                                            </td>
                                            <td
                                              className={classes.tableBodyCell}
                                            >
                                              {item?.unit}
                                            </td>
                                            <td
                                              className={classes.tableBodyCell}
                                            >
                                              {edit ? (
                                                <input
                                                  className="form-control"
                                                  type="number"
                                                  name="activity_cy"
                                                  value={item?.activity_cy}
                                                  onChange={(e) =>
                                                    this.handleEmissionChange(
                                                      e,
                                                      parentIndex,
                                                      index,
                                                      "scope2"
                                                    )
                                                  }
                                                />
                                              ) : (
                                                item?.activity_cy
                                              )}
                                            </td>
                                            <td
                                              className={classes.tableBodyCell}
                                            >
                                              {edit ? (
                                                <input
                                                  className="form-control"
                                                  type="number"
                                                  name="emission_factor"
                                                  value={item?.emission_factor.toFixed(2)}
                                                  onChange={(e) =>
                                                    this.handleEmissionChange(
                                                      e,
                                                      parentIndex,
                                                      index,
                                                      "scope2"
                                                    )
                                                  }
                                                />
                                              ) : (
                                                item?.emission_factor.toFixed(2)
                                              )}
                                            </td>
                                            <td
                                              className={classes.tableBodyCell}
                                            >
                                              {item?.emission_kgco2e
                                                ? item?.emission_kgco2e.toFixed(
                                                    2
                                                  )
                                                : 0.0}
                                            </td>
                                            <td
                                              className={classes.tableBodyCell}
                                            >
                                              {item?.emission_tco2e
                                                ? item?.emission_tco2e.toFixed(
                                                    2
                                                  )
                                                : 0.0}
                                            </td>
                                            <td
                                              className={classes.tableBodyCell}
                                            >
                                              {item?.total_emission
                                                ? item?.total_emission.toFixed(
                                                    2
                                                  )
                                                : 0.0}
                                            </td>
                                          </tr>
                                        ))
                                      )}

                                      <tr>
                                        <td colSpan="12" className="hr_spacing">
                                          <hr className={classes.tableHr} />{" "}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className={classes.tableBodyCell}>
                                          <strong>Scope 2 Total</strong>
                                        </td>
                                        <td
                                          className={classes.tableBodyCell}
                                        ></td>
                                        <td
                                          className={classes.tableBodyCell}
                                        ></td>
                                        <td
                                          className={classes.tableBodyCell}
                                        ></td>
                                        <td className={classes.tableBodyCell}>
                                          <strong>
                                            {totals.scope2.total_cy.toFixed(2)}
                                          </strong>
                                        </td>
                                        <td
                                          className={classes.tableBodyCell}
                                        ></td>
                                        <td className={classes.tableBodyCell}>
                                          <strong>
                                            {totals.scope2.total_kgco2e.toFixed(2)}kgCO2e
                                          </strong>
                                        </td>
                                        <td className={classes.tableBodyCell}>
                                          <strong>
                                            {totals.scope2.total_tco2e.toFixed(2)}tCO2e
                                          </strong>
                                        </td>
                                        <td className={classes.tableBodyCell}>
                                          <strong>
                                            {totals.scope2.total_emission.toFixed(2)}%
                                          </strong>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </Accordion.Body>
                              </Accordion.Item>
                              <Accordion.Item
                                eventKey="2"
                                className={classes.accordianItem}
                              >
                                <Accordion.Header className={classes.accHeader}>
                                  Scope 3 Emissions
                                </Accordion.Header>
                                <Accordion.Body
                                  className={classes.accordionSpc}
                                >
                                  <table className={classes.scopeTable}>
                                    <thead>
                                      <tr>
                                        <th
                                          className={classes.tableHeadCell}
                                          style={{ width: "10%" }}
                                        >
                                          Group
                                        </th>
                                        <th
                                          className={classes.tableHeadCell}
                                          style={{ width: "13%" }}
                                        >
                                          Emissions Group
                                        </th>
                                        <th
                                          className={classes.tableHeadCell}
                                          style={{ width: "20%" }}
                                        >
                                          Emission Source
                                        </th>
                                        <th
                                          className={classes.tableHeadCell}
                                          style={{ width: "8%" }}
                                        >
                                          Unit
                                        </th>
                                        <th
                                          className={classes.tableHeadCell}
                                          style={{ width: "10%" }}
                                        >
                                          Activity data CY {moment().year()}
                                        </th>
                                        <th
                                          className={classes.tableHeadCell}
                                          style={{ width: "10%" }}
                                        >
                                          Emission factor
                                        </th>
                                        <th
                                          className={classes.tableHeadCell}
                                          style={{ width: "10%" }}
                                        >
                                          {moment().year()} Emissions (kg CO2e)
                                        </th>
                                        <th
                                          className={classes.tableHeadCell}
                                          style={{ width: "10%" }}
                                        >
                                          {moment().year()} Emissions (tCO2e)
                                        </th>
                                        <th
                                          className={classes.tableHeadCell}
                                          style={{ width: "10%" }}
                                        >
                                          {moment().year()} Emissions % of total
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td colSpan="12" className="hr_spacing">
                                          {" "}
                                          <hr
                                            className={classes.tableHr}
                                          />{" "}
                                        </td>
                                      </tr>
                                      {scope3.map((child, parentIndex) =>
                                        child.map((item, index) => (
                                          <tr key={index}>
                                            <td
                                              className={classes.tableBodyCell}
                                            >
                                              {" "}
                                              {index === 0 ? item?.group : null}
                                            </td>
                                            <td
                                              className={classes.tableBodyCell}
                                            >
                                              {item?.emission_group}
                                            </td>
                                            <td
                                              className={classes.tableBodyCell}
                                            >
                                              {item?.source}
                                            </td>
                                            <td
                                              className={classes.tableBodyCell}
                                            >
                                              {item?.unit}
                                            </td>
                                            <td
                                              className={classes.tableBodyCell}
                                            >
                                              {edit ? (
                                                <input
                                                  className="form-control"
                                                  type="number"
                                                  name="activity_cy"
                                                  value={item?.activity_cy}
                                                  onChange={(e) =>
                                                    this.handleEmissionChange(
                                                      e,
                                                      parentIndex,
                                                      index,
                                                      "scope3"
                                                    )
                                                  }
                                                />
                                              ) : (
                                                item?.activity_cy
                                              )}
                                            </td>
                                            <td
                                              className={classes.tableBodyCell}
                                            >
                                              {edit ? (
                                                <input
                                                  className="form-control"
                                                  type="number"
                                                  name="emission_factor"
                                                  value={item?.emission_factor.toFixed(2)}
                                                  onChange={(e) =>
                                                    this.handleEmissionChange(
                                                      e,
                                                      parentIndex,
                                                      index,
                                                      "scope3"
                                                    )
                                                  }
                                                />
                                              ) : (
                                                item?.emission_factor.toFixed(2)
                                              )}
                                            </td>
                                            <td
                                              className={classes.tableBodyCell}
                                            >
                                              {item?.emission_kgco2e
                                                ? item?.emission_kgco2e.toFixed(
                                                    2
                                                  )
                                                : 0.0}
                                            </td>
                                            <td
                                              className={classes.tableBodyCell}
                                            >
                                              {item?.emission_tco2e
                                                ? item?.emission_tco2e.toFixed(
                                                    2
                                                  )
                                                : 0.0}
                                            </td>
                                            <td
                                              className={classes.tableBodyCell}
                                            >
                                              {item?.total_emission
                                                ? item?.total_emission.toFixed(
                                                    2
                                                  )
                                                : 0.0}
                                            </td>
                                          </tr>
                                        ))
                                      )}

                                      <tr>
                                        <td colSpan="12" className="hr_spacing">
                                          <hr className={classes.tableHr} />{" "}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className={classes.tableBodyCell}>
                                          <strong>Scope 3 Total</strong>
                                        </td>
                                        <td
                                          className={classes.tableBodyCell}
                                        ></td>
                                        <td
                                          className={classes.tableBodyCell}
                                        ></td>
                                        <td
                                          className={classes.tableBodyCell}
                                        ></td>
                                        <td className={classes.tableBodyCell}>
                                          <strong>
                                            {totals.scope3.total_cy.toFixed(2)}
                                          </strong>
                                        </td>
                                        <td
                                          className={classes.tableBodyCell}
                                        ></td>
                                        <td className={classes.tableBodyCell}>
                                          <strong>
                                            {totals.scope3.total_kgco2e.toFixed(2)}kgCO2e
                                          </strong>
                                        </td>
                                        <td className={classes.tableBodyCell}>
                                          <strong>
                                            {totals.scope3.total_tco2e.toFixed(2)}tCO2e
                                          </strong>
                                        </td>
                                        <td className={classes.tableBodyCell}>
                                          <strong>
                                            {totals.scope3.total_emission.toFixed(2)}%
                                          </strong>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </Accordion.Body>
                              </Accordion.Item>
                            </Accordion>

                            {/* <div className="stent">
                              <div className="stent_one">
                                <div className="stent_table">
                                  <h4 className="scope">Scope</h4>
                                </div>
                                <div className="stent_table_one">
                                  <h4 className="scope">Activity Type</h4>
                                </div>
                                <div className="stent_table_two">
                                  <h4 className="scope">
                                    Year: {this.state.fullYear}
                                  </h4>
                                </div>
                              </div>
                              <hr className="scope_gelop" />
                              <div className="stent_one">
                                <div className="stent_table">
                                  <h4 className="scope">Scope 1</h4>
                                </div>
                                <div className="stent_table_one">
                                  <p className="ghg">Building Consumption</p>
                                </div>
                                <div className="stent_table_two">
                                  {edit ? (
                                    <input
                                      type="number"
                                      className="form-control scopeEditInp"
                                      placeholder="Enter scope value"
                                      value={scopeFirst?.buildingConsumption}
                                      name="scopeFirst"
                                      id="buildingConsumption"
                                      onChange={(e) => this.handleScopeChange(e)}
                                  />
                                  ) : (
                                    <p className="ghg">
                                    {scopeFirst?.buildingConsumption  ? scopeFirst?.buildingConsumption : 0}
                                  </p>
                                  )}

                                </div>
                              </div>
                              <div className="stent_one">
                                <div className="stent_table"></div>
                                <div className="stent_table_one">
                                  <p className="ghg">Transport Consumption</p>
                                </div>
                                <div className="stent_table_two">
                                {edit ? (
                                    <input
                                      type="number"
                                      className="form-control scopeEditInp"
                                      placeholder="Enter scope value"
                                      value={scopeFirst?.transportConsumption}
                                      name="scopeFirst"
                                      id="transportConsumption"
                                      onChange={(e) => this.handleScopeChange(e)}
                                  />
                                  ) : (
                                    <p className="ghg">
                                    {scopeFirst?.transportConsumption ? scopeFirst?.transportConsumption : 0}
                                  </p>
                                  )}
                                </div>
                              </div>
                              <div className="stent_one">
                                <div className="stent_table"></div>
                                <div className="stent_table_one">
                                  <p className="ghg">Refrigerants</p>
                                </div>
                                <div className="stent_table_two">
                                {edit ? (
                                    <input
                                      type="number"
                                      className="form-control scopeEditInp"
                                      placeholder="Enter scope value"
                                      value={scopeFirst?.refrigerants}
                                      name="scopeFirst"
                                      id="refrigerants"
                                      onChange={(e) => this.handleScopeChange(e)}
                                  />
                                  ) : (
                                    <p className="ghg">
                                    {scopeFirst?.refrigerants ? scopeFirst?.refrigerants : 0}
                                  </p>
                                  )}
                                </div>
                              </div>
                              <div className="stent_one">
                                <div className="stent_table"></div>
                                <div className="stent_table_one">
                                  <p className="ghg">Additional Information</p>
                                </div>
                                <div className="stent_table_two">
                                  {edit ? (
                                    <input
                                      type="number"
                                      className="form-control scopeEditInp"
                                      placeholder="Enter scope value"
                                      value={scopeFirst?.additionalInformation}
                                      name="scopeFirst"
                                      id="additionalInformation"
                                      onChange={(e) => this.handleScopeChange(e)}
                                  />
                                  ) : (
                                    <p className="ghg">
                                    {scopeFirst?.additionalInformation ? scopeFirst?.additionalInformation : 0}
                                  </p>
                                  )}
                                </div>
                              </div>
                              <hr className="scope_gelop" />
                              <div className="stent_one">
                                <div className="stent_table">
                                  <h4 className="scope">Scope 1 Total</h4>
                                </div>
                                <div className="stent_table_one">
                                  <p className="ghg"></p>
                                </div>
                                <div className="stent_table_two carbonSaveCont">
                                  <p className="ghg">
                                    <strong>
                                      {isNaN(scopeFirst?.buildingConsumption) ||
                                        isNaN(scopeFirst?.transportConsumption) ||
                                        isNaN(scopeFirst?.refrigerants) ||
                                        isNaN(scopeFirst?.additionalInformation)
                                        ? 0
                                        : Number(scopeFirst?.buildingConsumption) +
                                        Number(scopeFirst?.transportConsumption) +
                                        Number(scopeFirst?.refrigerants) +
                                        Number(scopeFirst?.additionalInformation)
                                      }
                                      &nbsp;CO2-e
                                    </strong>
                                  </p>
                                  {edit && (
                                    <button
                                      id="savbtnMain2"
                                      className="link_bal_next page_width white "
                                      variant="none"
                                      onClick={() => this.handleSaveScopeData("scopeFirst")}
                                    >
                                      Save
                                    </button>
                                  )}
                                  
                                </div>
                              </div>
                            </div>
                            <div className="stent">
                              <div className="stent_one">
                                <div className="stent_table">
                                  <h4 className="scope">Scope 2</h4>
                                </div>
                                <div className="stent_table_one">
                                  <p className="ghg">
                                    Electricity Consumption Type
                                  </p>
                                </div>
                                <div className="stent_table_two">
                                {edit ? (
                                    <input
                                      type="number"
                                      className="form-control scopeEditInp"
                                      placeholder="Enter scope value"
                                      value={scopeSecond?.electricityConsumption}
                                      name="scopeSecond"
                                      id="electricityConsumption"
                                      onChange={(e) => this.handleScopeChange(e)}
                                  />
                                  ) : (
                                    <p className="ghg">
                                    {scopeSecond?.electricityConsumption ? scopeSecond?.electricityConsumption : 0}
                                  </p>
                                  )}
                                </div>
                              </div>
                              <div className="stent_one">
                                <div className="stent_table"></div>
                                <div className="stent_table_one">
                                  <p className="ghg">Green Power 100%</p>
                                </div>
                                <hr className="scope_gelop" />
                                <div className="stent_table_two">
                                {edit ? (
                                    <input
                                      type="number"
                                      className="form-control scopeEditInp"
                                      placeholder="Enter scope value"
                                      value={scopeSecond?.greenPower}
                                      name="scopeSecond"
                                      id="greenPower"
                                      onChange={(e) => this.handleScopeChange(e)}
                                  />
                                  ) : (
                                    <p className="ghg">
                                    {scopeSecond?.greenPower ? scopeSecond?.greenPower : 0}
                                  </p>
                                  )}
                                </div>
                              </div>
                              <div className="stent_one">
                                <div className="stent_table"></div>
                                <hr className="scope_gelop" />
                                <div className="stent_table_one">
                                  <p className="ghg">Some Green Power</p>
                                </div>
                                <div className="stent_table_two">
                                {edit ? (
                                    <input
                                      type="number"
                                      className="form-control scopeEditInp"
                                      placeholder="Enter scope value"
                                      value={scopeSecond?.someGreenPower}
                                      name="scopeSecond"
                                      id="someGreenPower"
                                      onChange={(e) => this.handleScopeChange(e)}
                                  />
                                  ) : (
                                    <p className="ghg">
                                    {scopeSecond?.someGreenPower ? scopeSecond?.someGreenPower : 0}
                                  </p>
                                  )}
                                </div>
                              </div>
                              <div className="stent_one">
                                <div className="stent_table"></div>
                                <hr className="scope_gelop" />
                                <div className="stent_table_one">
                                  <p className="ghg">Market</p>
                                </div>
                                <div className="stent_table_two">
                                {edit ? (
                                    <input
                                      type="number"
                                      className="form-control scopeEditInp"
                                      placeholder="Enter scope value"
                                      value={scopeSecond?.market}
                                      name="scopeSecond"
                                      id="market"
                                      onChange={(e) => this.handleScopeChange(e)}
                                  />
                                  ) : (
                                    <p className="ghg">
                                    {scopeSecond?.market ? scopeSecond?.market : 0}
                                  </p>
                                  )}
                                </div>
                              </div>
                              <hr className="scope_gelop" />
                              <div className="stent_one">
                                <div className="stent_table">
                                  <h4 className="scope">Scope 2 Total</h4>
                                </div>
                                <div className="stent_table_one">
                                  <p className="ghg"></p>
                                </div>
                                <div className="stent_table_two carbonSaveCont">
                                  <p className="ghg">
                                    <strong>
                                      {isNaN(
                                        scopeSecond?.electricityConsumption
                                      ) ||
                                        isNaN(scopeSecond?.greenPower) ||
                                        isNaN(scopeSecond?.someGreenPower) ||
                                        isNaN(scopeSecond?.market)
                                        ? 0
                                        : Number(
                                          scopeSecond.electricityConsumption
                                        ) +
                                        Number(scopeSecond.greenPower) +
                                        Number(scopeSecond.someGreenPower) +
                                        Number(scopeSecond.market)}
                                      &nbsp;CO2-e
                                    </strong>
                                  </p>
                                  {edit && (
                                    <button
                                      id="savbtnMain2"
                                      className="link_bal_next page_width white "
                                      variant="none"
                                      onClick={() => this.handleSaveScopeData("scopeSecond")}
                                    >
                                      Save
                                    </button>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="stent">
                              <div className="stent_one">
                                <div className="stent_table">
                                  <h4 className="scope">Scope 3</h4>
                                </div>
                                <div className="stent_table_one">
                                  <p className="ghg">Travel</p>
                                </div>
                                <div className="stent_table_two">
                                {edit ? (
                                    <input
                                      type="number"
                                      className="form-control scopeEditInp"
                                      placeholder="Enter scope value"
                                      value={scopeThird?.travel}
                                      name="scopeThird"
                                      id="travel"
                                      onChange={(e) => this.handleScopeChange(e)}
                                  />
                                  ) : (
                                    <p className="ghg">
                                    {scopeThird?.travel ? scopeThird?.travel : 0}
                                  </p>
                                  )}
                                </div>
                              </div>
                              <div className="stent_one">
                                <div className="stent_table"></div>
                                <div className="stent_table_one">
                                  <p className="ghg">Employee commuting</p>
                                </div>
                                <hr className="scope_gelop" />
                                <div className="stent_table_two">
                                  <p className="ghg">
                                    
                                  {edit ? (
                                    <input
                                      type="number"
                                      className="form-control scopeEditInp"
                                      placeholder="Enter scope value"
                                      value={scopeThird?.employeeCommuting}
                                      name="scopeThird"
                                      id="employeeCommuting"
                                      onChange={(e) => this.handleScopeChange(e)}
                                  />
                                  ) : (
                                    <p className="ghg">
                                    {scopeThird?.employeeCommuting ? scopeThird?.employeeCommuting : 0}
                                  </p>
                                  )}
                                  </p>
                                </div>
                              </div>
                              <hr className="scope_gelop" />
                              <div className="stent_one">
                                <div className="stent_table"></div>
                                <hr className="scope_gelop" />
                                <div className="stent_table_one">
                                  <p className="ghg">Waste</p>
                                </div>
                                <div className="stent_table_two">
                                {edit ? (
                                    <input
                                      type="number"
                                      className="form-control scopeEditInp"
                                      placeholder="Enter scope value"
                                      value={scopeThird?.waste}
                                      name="scopeThird"
                                      id="waste"
                                      onChange={(e) => this.handleScopeChange(e)}
                                  />
                                  ) : (
                                    <p className="ghg">
                                    {scopeThird?.waste ? scopeThird?.waste : 0}
                                  </p>
                                  )}
                                </div>
                              </div>
                              <hr className="scope_gelop" />
                              <div className="stent_one">
                                <div className="stent_table"></div>
                                <hr className="scope_gelop" />
                                <div className="stent_table_one">
                                  <p className="ghg">
                                    Purchased goods & services
                                  </p>
                                </div>
                                <div className="stent_table_two">
                                  <p className="ghg">
                                  {edit ? (
                                    <input
                                      type="number"
                                      className="form-control scopeEditInp"
                                      placeholder="Enter scope value"
                                      value={scopeThird?.purchasedGoods}
                                      name="scopeThird"
                                      id="purchasedGoods"
                                      onChange={(e) => this.handleScopeChange(e)}
                                  />
                                  ) : (
                                    <p className="ghg">
                                    {scopeThird?.purchasedGoods ? scopeThird?.purchasedGoods : 0}
                                  </p>
                                  )}
                                  </p>
                                </div>
                              </div>
                              <hr className="scope_gelop" />
                              <div className="stent_one">
                                <div className="stent_table"></div>
                                <hr className="scope_gelop" />
                                <div className="stent_table_one">
                                  <p className="ghg">Capital goods</p>
                                </div>
                                <div className="stent_table_two">
                                  <p className="ghg">
                                  {edit ? (
                                    <input
                                      type="number"
                                      className="form-control scopeEditInp"
                                      placeholder="Enter scope value"
                                      value={scopeThird?.travel}
                                      name="scopeThird"
                                      id="capitalGoods"
                                      onChange={(e) => this.handleScopeChange(e)}
                                  />
                                  ) : (
                                    <p className="ghg">
                                    {scopeThird?.capitalGoods ? scopeThird?.capitalGoods : 0}
                                  </p>
                                  )}
                                  </p>
                                </div>
                              </div>
                              <hr className="scope_gelop" />
                              <div className="stent_one">
                                <div className="stent_table"></div>
                                <div className="stent_table_one">
                                  <p className="ghg">
                                    Upstream and downstream <br />
                                    Transportation & Distribution
                                  </p>
                                </div>
                                <div className="stent_table_two">
                                  <p className="ghg">
                                  {edit ? (
                                    <input
                                      type="number"
                                      className="form-control scopeEditInp"
                                      placeholder="Enter scope value"
                                      value={scopeThird?.upstreamAndDownstream}
                                      name="scopeThird"
                                      id="upstreamAndDownstream"
                                      onChange={(e) => this.handleScopeChange(e)}
                                  />
                                  ) : (
                                    <p className="ghg">
                                    {scopeThird?.upstreamAndDownstream ? scopeThird?.upstreamAndDownstream : 0}
                                  </p>
                                  )}
                                  </p>
                                </div>
                              </div>
                              <div className="stent_one">
                                <div className="stent_table"></div>
                                <hr className="scope_gelop" />
                                <div className="stent_table_one">
                                  <p className="ghg">Investments</p>
                                </div>
                                <div className="stent_table_two">
                                {edit ? (
                                    <input
                                      type="number"
                                      className="form-control scopeEditInp"
                                      placeholder="Enter scope value"
                                      value={scopeThird?.Investments}
                                      name="scopeThird"
                                      id="Investments"
                                      onChange={(e) => this.handleScopeChange(e)}
                                  />
                                  ) : (
                                    <p className="ghg">
                                    {scopeThird?.Investments ? scopeThird?.Investments : 0}
                                  </p>
                                  )}
                                </div>
                              </div>
                              <hr className="scope_gelop" />
                              <div className="stent_one">
                                <div className="stent_table">
                                  <h4 className="scope">Scope 3 Total</h4>
                                </div>
                                <div className="stent_table_one">
                                  <p className="ghg"></p>
                                </div>
                                <div className="stent_table_two carbonSaveCont">
                                  <p className="ghg">
                                    <strong>
                                      {isNaN(scopeThird?.travel) ||
                                        isNaN(scopeThird?.employeeCommuting) ||
                                        isNaN(scopeThird?.waste) ||
                                        isNaN(scopeThird?.purchasedGoods) ||
                                        isNaN(scopeThird?.capitalGoods) ||
                                        isNaN(
                                          scopeThird?.upstreamAndDownstream
                                        ) ||
                                        isNaN(scopeThird?.Investments)
                                        ? 0
                                        : Number(scopeThird.travel) +
                                        Number(scopeThird.employeeCommuting) +
                                        Number(scopeThird.waste) +
                                        Number(scopeThird.purchasedGoods) +
                                        Number(scopeThird.capitalGoods) +
                                        Number(
                                          scopeThird.upstreamAndDownstream
                                        ) +
                                        Number(scopeThird.Investments)}
                                      &nbsp;CO2-e
                                    </strong>
                                  </p>
                                  {edit && (
                                    <button
                                      id="savbtnMain2"
                                      className="link_bal_next page_width white "
                                      variant="none"
                                      onClick={() => this.handleSaveScopeData("scopeThird")}
                                    >
                                      Save
                                    </button>
                                  )}
                                </div>
                              </div>
                            </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
