/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import React, { Component } from "react";
import { authenticationService } from "../../_services/authentication";
import { history } from "../../_helpers/history";
import { Link } from "react-router-dom";
import config from "../../config/config.json";
import "./header.css";
const currentUser = authenticationService.currentUserValue;

const ProfilePics = "https://res.cloudinary.com/dmklsntsw/image/upload/v1658480882/dummyPic.75a04487_fqfqey.png"
export default class header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      logo: "",
    };
    this.goToPreviousPath = this.goToPreviousPath.bind(this);
    this.serverRequest = this.serverRequest.bind(this);
    this.redirection = this.redirection.bind(this);
  }
  goToPreviousPath() {
    window.history.back();
  }
  logout() {
    authenticationService.logout();
    history.push("/");
  }

  serverRequest() {
    const headers = {
      Authorization: `Bearer ${currentUser.data.token}`,
      Accept: "application/json",
    };

    fetch(config.ADMIN_API_URL + "settings", { headers })
      .then((res) => res.json())
      .then(
        (data) => {
          // console.log("Hello", data.result[0]?.logo)
          console.log("data");
          this.setState({
            isLoaded: true,
            firstName: data.result[0]?.firstname,
            lastName: data.result[0]?.lastname,
            email: data.result[0]?.email,
            logo:
              data.result1[0]?.logo === null
                ? ProfilePics
                : config.BASE_URL + data.result1[0]?.logo,
          });
        },
        (error) => {
          localStorage.clear();
          window.location.href = "/";
          this.setState({
            isLoaded: true,
            error,
          });
        }
      );
  }
  componentDidMount() {
    this.serverRequest();
  }

  redirection(){
    window.location.href = "/";
  }
  render() {
    const { logo } = this.state;
    const currentUser = JSON.parse(localStorage.getItem("currentUser"));
    if(currentUser === null){
       this.redirection();
    }
    let fullUrl = window.location.href.split("/");
    let urr = "";
    if(fullUrl[3] === 'sub-users'){
      urr = fullUrl[3]
    }else{
      if (fullUrl[3] !== "user_detail") {
        urr = fullUrl.pop().toUpperCase();
      } else {
        urr = fullUrl[3];
      }
    }
    let finalResult="";
    if(fullUrl[3] === 'sub-users'){
      finalResult = urr.replaceAll("-", " ");
    }else{
      finalResult = urr.replaceAll("_", " ");
    }
   if(fullUrl[4] === 'invoice'){
    finalResult = "Invoice"
   }

    if (finalResult === "SUPPLIERS FAST") {
      finalResult = "SUPPLIERS";
    }

    if(fullUrl[3] === "report") {
      finalResult = "REPORT DETAIL";
    }
    
    let uri = window.location.pathname.split("/");
    let path = uri[4];
  
    if (path === "governance-policy") {
      finalResult = "Governance Policy";
    }

    if (path === "social-policy") {
      finalResult = "Social Policy";
    }

    if (path === "cyber-and-policy") {
      finalResult = "Cyber & Policy";
    }

    if (path === "health-and-safety-policy") {
      finalResult = "Health & Safety Policy";
    }

    if (path === "environmental-policy") {
      finalResult = "Environmental Policy";
    }

    
    if (finalResult === "SUSTAINABLE") {
      finalResult = "SUSTAINABLE DEVELOPMENT GOALS";
    }

    if(fullUrl.pop() === 'details'){
      finalResult = "details";
    }
   
    return (
      <div>
        <div className="d-flex" id="wrapper">
          {/* <!-- Page content wrapper--> */}
          <div id="page-content-wrapper">
            {/* <!-- Top navigation--> */}
            <nav className="navbar navbar-expand-lg navclassName background">
              <div className="container-fluid">
                {/* <!--  <button className="desh" id="sidebarToggle">Deshboard</button> --> */}
                <div className="deahbord" id="sidebarToggle">
                <h4 className="back_quninti back_quninti_2">
                    <a className="back_text">
                      <span className="step_icon">
                        <i
                          onClick={this.goToPreviousPath}
                          className="far fa-long-arrow-left"
                        ></i>
                      </span>
                      {finalResult}
                    </a>
                  </h4>
                </div>
                <button
                  className="navbar-toggler"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span className="navbar-toggler-icon"></span>
                </button>
                <div
                  className="collapse navbar-collapse"
                  id="navbarSupportedContent"
                >
                  <ul className="navbar-nav ms-auto mt-2 mt-lg-0">
                    {/* <li className="nav-item activities-b">
                      <div className="activation-notification">
                          <NavLink to="">
                            <i className="fas fa-bell"></i>
                          </NavLink>  
                          <span className="badge">3</span>
                      </div>

                      
                    </li> */}
                    <li className="nav-item dropdown text_down">
                      <div className="image_round">
                        <img src={logo} className="image--coverq" />
                      </div>
                      <a
                        className="nav-link home_drop"
                        id="navbarDropdown"
                        href="#"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        {currentUser.data.name}
                        <i
                          className="fa fa-caret-down elly"
                          aria-hidden="true"
                        ></i>
                        <p className="text_p">Super Account</p>
                      </a>

                      <div
                        className="dropdown-menu dropdown-menu-end dropdown_menu"
                        aria-labelledby="navbarDropdown"
                      >
                        <Link to="/profile" className="dropdown-item">
                          <i className="fa fa-user"></i>
                          <span>My Profile</span>
                        </Link>
                        <Link to="/2fa" className="dropdown-item">
                          <i className="fa fa-lock"></i>
                          <span>Two Factor Auth</span>
                        </Link>
                        <Link to="/billing" className="dropdown-item">
                          <i className="fa fa-dollar-sign"></i>
                          <span>Subscription</span>
                        </Link>
                        <Link
                          onClick={this.logout}
                          to="/"
                          className="dropdown-item"
                        >
                          <i className="fa fa-sign-out pull-right"></i>
                          <span>Logout</span>
                        </Link>
                        
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </nav>
            {/* <!-- Page content--> */}
          </div>
          {/* <!-- main --> */}
        </div>
      </div>
    );
  }
}
