/* eslint-disable jsx-a11y/role-supports-aria-props */
import React, { Component } from "react";
import AdminSidebar from "../../sidebar/admin_sidebar";
import AdminHeader from "../../header/admin_header";
import { NavLink } from "react-router-dom";
import "./companies.css";
export default class Congratulations extends Component {
  constructor(props) {
    super(props);
    this.state = {
     
    };
    
  }

  componentDidMount() {
    
  }

  render() {
   
    return (
      <div>
        <AdminSidebar dataFromParent={this.props.location.pathname} />
        <AdminHeader />
        <div className="main_wrapper">
          <div className="inner_wraapper">
            <div className="container-fluid">
              <section className="d_text">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-sm-3"></div>
                    <div className="col-sm-6">
                      <div className="col-sm-12">
                        <div className="color_div_on framwork_2">
                          <div className="business_detail">
                            <div className="">
                              <div className="saved_cards">
                                <div className="business_detail">
                                  <div className="heading">
                                    <div className="heading_wth_text">
                                      <div className="d-flex">
                                        
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <h4 className="Account mb-5">Congratulations</h4>
                                <p className="faster_oval">Company Account has been created.</p>
                                <NavLink  to="/companies" className="page_width page_save big-button">
                                  View Companies
                                </NavLink>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-3"></div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    );
  }
}