/* eslint-disable react/jsx-no-target-blank */
import React, { Component } from "react";
import AdminSidebar from "../../sidebar/admin_sidebar";
import AdminHeader from "../../header/admin_header";
import axios from "axios";
import { sweetAlert } from "../../../utils/UniversalFunction";
import config from "../../../config/config.json";
import { authenticationService } from "../../../_services/authentication";
import { TextArea } from 'semantic-ui-react'
import './common.css';
const BackendBaseUrl = config.BASE_URL;
const currentUser = authenticationService.currentUserValue;

export default class sectorQuestionPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      uuid: "",
      error: null,
      isLoaded: false,
      items: [],
      business_model: [],
      cyber_digital: [],
      environmental_capital: [],
      human_capital: [],
      leadership_goverance: [],
      social_capital: [],
      report: [],
      industryID: [],
      description: "",
    };
    this.getUploadedReportData = this.getUploadedReportData.bind(this);
    this.serverRequest = this.serverRequest.bind(this);
  }

  onFileChange = (event) => {
    let urlArr = window.location.pathname.split("/");
    const finalUUId = urlArr[urlArr.length - 2];

    const formData = new FormData();
    // Update the formData object
    formData.append(
      "uploadImage",
      event.target.files[0],
      event.target.files[0].name
    );
    formData.append("section_name", "sector_question");
    formData.append("sub_section", "sector_question");
    const headers = {
      Authorization: `Bearer ${currentUser.data.token}`,
      Accept: "application/json",
    };
    axios
      .post(
        `${config.ADMIN_API_URL}company/${finalUUId}/sector-question/upload/report`,
        formData,
        {
          headers,
        }
      )
      .then((response) => {
        sweetAlert("success", response.data.message);
        this.serverRequest();
      })
      .catch(function (response) {
        sweetAlert("error", response.data.message);
      });
  };

  getUploadedReportData = () => {
    let urlArr = window.location.pathname.split("/");
    const finalUUId = urlArr[urlArr.length - 2];
    const headers = {
      Authorization: `Bearer ${currentUser.data.token}`,
      Accept: "application/json",
    };
    fetch(
      `${config.ADMIN_API_URL}company/${finalUUId}/sector-question/download/report/sector_question/sector_question`,
      {
        headers,
      }
    )
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            report: result.data,
          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error,
          });
        }
      );
    // company/:uuid/sector-question/download/report
  };
  serverRequest = () => {
    this.getUploadedReportData();
    let urlArr = window.location.pathname.split("/");
    const finalUUId = urlArr[urlArr.length - 2];
    this.setState({
      uuid: urlArr[urlArr.length - 2],
    });

    const headers = {
      Authorization: `Bearer ${currentUser.data.token}`,
      Accept: "application/json",
    };
    this.setState({
      isLoaded: true,
    });
    fetch(
      config.ADMIN_API_URL + `company/${finalUUId}/modules/sector-question`,
      {
        headers,
      }
    )
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            business_model: result?.business_model,
            cyber_digital: result.cyber_digital,
            environmental_capital: result?.environmental_capital,
            human_capital: result?.human_capital,
            leadership_goverance: result?.leadership_goverance,
            social_capital: result?.social_capital,
            isLoaded: false,
          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error,
          });
        }
      );
  };

  getindustryDescription(industryID) {
    const currentUser = JSON.parse(localStorage.getItem("currentUser"));
    const headers = {
      Authorization: `Bearer ${currentUser.data?.token}`,
      Accept: "application/json",
    };
    fetch(config.API_URL + `getIndustryDescriptionForCompanySuperAdmin/${industryID}`, { headers })
      .then((res) => res.json())
      .then(
        (result) => {
          console.log();
          this.setState({
            isLoaded: true,
            description:
              result?.description === null
                ? "Description not found"
                : result?.description,
          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error,
          });
        }
      );
  }
  componentDidMount() {
    this.serverRequest();
    this.getindustryDescription();

    let uri = window.location.pathname.split("/");
    let uuid = uri[2];
    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${currentUser.data.token}`,
        "Content-Type": "application/json",
      },
    };
    let parms = `company/${uuid}`;
    fetch(config.ADMIN_API_URL + parms, requestOptions)
      .then((res) => res.json())
      .then(
        (data) => {

          this.setState({
            industryID: data.data[0]?.company_industry_id,
          });
          this.getindustryDescription(this.state.industryID);

        },
        (error) => { }
      );
  }

  async handleSave(type) {

    const { uuid } = this.props.match.params;

      let answers = this.state[type].map((item) => {
        console.log("item", item);
        return {
          id: item.id,
          answer: item.answer
        }
      });

      let payload = {
        uuid: uuid,
        type: type,
        answers: answers
      }

    try {
      const headers = {
        Authorization: `Bearer ${currentUser.data.token}`,
        Accept: "application/json",
      };
      const res = await axios.put(`${config.ADMIN_API_URL}sector/updateSectorQuestion`, payload, { headers: headers });
      console.log("res", res);
      sweetAlert('success', "Updated Successfully");
    } catch (error) {
      sweetAlert('error', "Oops some error occured");
      // show error message
      console.log(error.message)
    }



    // send payload to API
    console.log("payload", payload);

  }

  handleAnswerChange(e) {
    e.preventDefault();
    const { name, value, id: index } = e.target;
    let temp = [...this.state[name]];
    temp[index].answer = value;
    this.setState({[name]: temp})

  }
  render() {
    const {
      business_model,
      cyber_digital,
      environmental_capital,
      human_capital,
      leadership_goverance,
      social_capital,
      report,
    } = this.state;

    return (
      <div>
        <AdminSidebar dataFromParent={this.props.location.pathname} />
        <AdminHeader />
        <div className="main_wrapper">
          <div className="inner_wraapper">
            <div className="container-fluid">
              <section className="d_text">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="Introduction framwork_2">
                        <div className="text_Parts">
                          <div className="text_ntroion">
                            <h5 className="Intro">Introduction</h5>
                            <p className="critical">
                              {this.state.description}
                            </p>
                            <div className="variants">
                              <div className='file file--upload'>
                                <label htmlFor='input-file'>
                                  <i className="fa fa-upload" aria-hidden="true"></i>Upload Report
                                </label>
                                <input
                                  // style={{ visibility: "hidden" }}
                                  type="file"
                                  accept='.pdf, .doc, .docx, .xlsx, .pptx, .ppt, .ott, .odp'
                                  id='input-file'
                                  name="governancePolicy"
                                  onChange={this.onFileChange}
                                />
                              </div>
                              {report[0]?.file !== undefined && (
                                <a
                                  className="outputFile"
                                  target="_blank"
                                  href={BackendBaseUrl + report[0]?.file ?? ""}
                                >
                                  Preview
                                </a>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="frameworks framwork_2">
                        <div className="text_Parts">
                          <div className="col-md-12">
                            <div className="Capital_op">
                              <h4 className="E_capital">
                                Environmental Capital
                              </h4>

                              {environmental_capital.map((item, key) => (
                                <>
                                  <div className="manag">
                                    <div className="heading_h3 mb-3 mt-3">
                                      {item.heading && (
                                        <span className="gement">
                                          {item.heading}
                                          
                                        </span>
                                        
                                      )}
                                    </div>
                                    <h3 className="energy">{item.title}</h3>
                                    <TextArea 
                                      placeholder='Answer' 
                                      className="ansField" 
                                      value={item.answer}
                                      name="environmental_capital"
                                      id={key}
                                      onChange={(e) => this.handleAnswerChange(e)}
                                    />
                                  </div>
                                </>
                              ))}
                              {environmental_capital.length === 0 && (
                                <h6>No Questions here</h6>
                              )}

                            </div>

                          </div>
                        </div>
                        {environmental_capital.length > 0 && (
                          <div className="savbtnCont">
                            <button
                              id="savbtnMain"
                              className="link_bal_next page_width white "
                              variant="none"
                              onClick={() => this.handleSave("environmental_capital")}
                            >
                              Save
                            </button>
                          </div>
                        )}
                      </div>
                      <div className="frameworks framwork_2">
                        <div className="text_Parts">
                          <div className="col-md-12">
                            <div className="Capital_op">
                              <h4 className="E_capital">
                                Business Model & Innovation
                              </h4>

                              {business_model.map((item, key) => (
                                <>
                                  <div className="manag">
                                    <div className="heading_h3 mb-3 mt-3">
                                      {item.heading && (
                                        <span className="gement">
                                          {item.heading}
                                        </span>
                                      )}
                                    </div>
                                    <h3 className="energy">{item.title}</h3>
                                    <TextArea                                      
                                      placeholder='Answer' 
                                      className="ansField" 
                                      value={item.answer}
                                      name="business_model"
                                      id={key}
                                      onChange={(e) => this.handleAnswerChange(e)}
                                    />
                                  </div>
                                </>
                              ))}
                              {business_model.length === 0 && (
                                <h6>No Questions here</h6>
                              )}
                            </div>
                          </div>
                        </div>
                        {business_model.length > 0 && (
                          <div className="savbtnCont">
                            <button
                              id="savbtnMain"
                              className="link_bal_next page_width white "
                              variant="none"
                              onClick={() => this.handleSave("business_model")}
                            >
                              Save
                            </button>
                          </div>
                        )}
                      </div>
                      <div className="frameworks framwork_2">
                        <div className="text_Parts">
                          <div className="col-md-12">
                            <div className="Capital_op">
                              <h4 className="E_capital">Cyber & Digial</h4>

                              {cyber_digital.map((item, key) => (
                                <>
                                  <div className="manag">
                                    <div className="heading_h3 mb-3 mt-3">
                                      {item.heading && (
                                        <span className="gement">
                                          {item.heading}
                                        </span>
                                      )}
                                    </div>
                                    <h3 className="energy">{item.title}</h3>
                                    <TextArea
                                      placeholder='Answer' 
                                      className="ansField" 
                                      value={item.answer}
                                      name="cyber_digital"
                                      id={key}
                                      onChange={(e) => this.handleAnswerChange(e)}
                                    />
                                    {/* <p className="sumption">{item.answer}</p>
                                    <hr className="related" /> */}
                                  </div>
                                </>
                              ))}
                              {cyber_digital.length === 0 && (
                                <h6>No Questions here</h6>
                              )}
                            </div>
                          </div>
                        </div>
                        {cyber_digital.length > 0 && (
                          <div className="savbtnCont">
                            <button
                              id="savbtnMain"
                              className="link_bal_next page_width white "
                              variant="none"
                              onClick={() => this.handleSave("cyber_digital")}
                            >
                              Save
                            </button>
                          </div>
                        )}
                      </div>
                      <div className="frameworks framwork_2">
                        <div className="text_Parts">
                          <div className="col-md-12">
                            <div className="Capital_op">
                              <h4 className="E_capital">Human Capital</h4>

                              {human_capital.map((item, key) => (
                                <>
                                  <div className="manag">
                                    <div className="heading_h3 mb-3 mt-3">
                                      {item.heading && (
                                        <span className="gement">
                                          {item.heading}
                                        </span>
                                      )}
                                    </div>
                                    <h3 className="energy">{item.title}</h3>
                                    <TextArea
                                      placeholder='Answer' 
                                      className="ansField" 
                                      value={item.answer}
                                      name="human_capital"
                                      id={key}
                                      onChange={(e) => this.handleAnswerChange(e)} 
                                    />
                                  </div>
                                </>
                              ))}
                              {human_capital.length === 0 && (
                                <h6>No Questions here</h6>
                              )}
                            </div>
                          </div>
                        </div>
                        {human_capital.length > 0 && (
                          <div className="savbtnCont">
                            <button
                              id="savbtnMain"
                              className="link_bal_next page_width white "
                              variant="none"
                              onClick={() => this.handleSave("human_capital")}
                            >
                              Save
                            </button>
                          </div>
                        )}

                      </div>
                      <div className="frameworks framwork_2">
                        <div className="text_Parts">
                          <div className="col-md-12">
                            <div className="Capital_op">
                              <h4 className="E_capital">
                                Leadership Governance
                              </h4>

                              {leadership_goverance.map((item, key) => (
                                <>
                                  <div className="manag">
                                    <div className="heading_h3 mb-3 mt-3">
                                      {item.heading && (
                                        <span className="gement">
                                          {item.heading}
                                        </span>
                                      )}
                                    </div>
                                    <h3 className="energy">{item.title}</h3>
                                    <TextArea
                                      placeholder='Answer' 
                                      className="ansField" 
                                      value={item.answer}
                                      name="leadership_goverance"
                                      id={key}
                                      onChange={(e) => this.handleAnswerChange(e)} 
                                    />
                                  </div>
                                </>
                              ))}
                              {leadership_goverance.length === 0 && (
                                <h6>No Questions here</h6>
                              )}
                            </div>
                          </div>
                        </div>
                        {leadership_goverance.length > 0 && (
                          <div className="savbtnCont">
                            <button
                              id="savbtnMain"
                              className="link_bal_next page_width white "
                              variant="none"
                              onClick={() => this.handleSave("leadership_goverance")}
                            >
                              Save
                            </button>
                          </div>
                        )}
                      </div>
                      <div className="frameworks framwork_2">
                        <div className="text_Parts">
                          <div className="col-md-12">
                            <div className="Capital_op">
                              <h4 className="E_capital">Social Capital</h4>

                              {social_capital.map((item, key) => (
                                <>
                                  <div className="manag">
                                    <div className="heading_h3 mb-3 mt-3">
                                      {item.heading && (
                                        <span className="gement">
                                          {item.heading}
                                        </span>
                                      )}
                                    </div>
                                    <h3 className="energy">{item.title}</h3>
                                    <TextArea
                                      placeholder='Answer' 
                                      className="ansField" 
                                      value={item.answer}
                                      name="social_capital"
                                      id={key}
                                      onChange={(e) => this.handleAnswerChange(e)} 
                                    />
                                  </div>
                                </>
                              ))}
                              {social_capital.length === 0 && (
                                <h6>No Questions here</h6>
                              )}
                            </div>
                          </div>
                        </div>
                        {social_capital.length > 0 && (
                          <div className="savbtnCont">
                            <button
                              id="savbtnMain"
                              className="link_bal_next page_width white "
                              variant="none"
                              onClick={() => this.handleSave("social_capital")}
                            >
                              Save
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
