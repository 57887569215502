import React, { Component } from "react";
import axios from "axios";
import {sweetAlert} from '../../utils/UniversalFunction'
// import "./common.css";
import logo from "../../img/logosine.png";
import "./signup.css";
import config from "../../config/config.json";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const baseURL = config.baseURL;

export default class Invite extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: null,
      isLoaded: false,
      error2: null,
      isLoaded2: false,
      error3: null,
      isLoaded3: false,
      companyIndustoryItems: [],
      titleOrPositionsItems: [],
      uuid: null,
      user: {
        firstName: "",
        lastName: "",
        email: "",
        position: "",
        password: "",
      },
      submitted: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    let url = window.location.pathname.split("/");
    const { name, value } = event.target;
    const { user } = this.state;
    this.setState({
      user: {
        ...user,
        [name]: value,
      },
    });
    this.setState({ uuid: url.at(-1) });
  }

  handleSubmit(event) {
    event.preventDefault();
    this.setState({ submitted: true });
    const { user } = this.state;
    const uuid = this.state.uuid;

      if (
        user.firstName &&
        user.lastName &&
        user.email &&
        user.position &&
        user.password
      ) {
        axios
          .post(config.OLD_API_URL + "signupSubAdmin", {
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email,
            position: user.position,
            password: user.password,
            uuid: uuid,
          })
          .then((response) => {
            sweetAlert('success',response.data.message);	
            let setResponse = {};
            setResponse.data = response.data;
            setResponse.data.role = response.data.role;
            localStorage.setItem("currentUser", JSON.stringify(setResponse));
            const pushToRoute = "/";
            setTimeout(() => {
              window.location.href = baseURL + pushToRoute;
            }, 1000);
          })
          .catch(function (error) {
            if (error.response) {
              sweetAlert('error',error.response.data.message);
            }
          });
      } else {
        sweetAlert('error',"Please fill all input");
      }
    
  }

  componentDidMount() {

    fetch(config.API_URL + "getTitleOrPositions")
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded3: true,
            titleOrPositionsItems: result.titleOrPositions,
          });
        },
        (error3) => {
          this.setState({
            isLoaded3: true,
            error3,
          });
        }
      );
  }

  render() {
    const { user, submitted } = this.state;
    const { titleOrPositionsItems } = this.state;
    return (
      <div>
        <section className="login">
          <div className="login_part">
            <div className="sing_log">
              <div className="sing_one">
                <img src={logo} alt="logo" />
              </div>
              <form name="form" onSubmit={this.handleSubmit}>
                <div className="step-forms step-forms-active">
                  <div className="text_sing">
                    <h4 className="Account">Set Up Your User Profile Below</h4>
                    <p className="faster_oval mb-5 mt-2">
                      Please make sure you fill in all onboarding information
                      for quick account approval. Contact us if you have any
                      questions or need any help.
                    </p>
                  </div>
                  <div className="ster_form">
                    <div className="make_form">
                      <div className="row">
                        <div className="col-md-6">
                          <div
                            className={
                              "form-group fg" +
                              (submitted && !user.firstName ? " has-error" : "")
                            }
                          >
                            <label className="st_name" htmlFor="name">
                              First Name
                            </label>
                            <input
                              className="form-control name_nf"
                              id="firstName"
                              type="text"
                              name="firstName"
                              value={user.firstName}
                              onChange={this.handleChange}
                            />
                            {submitted && !user.firstName && (
                              <div className="help-block">
                                First Name is required
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div
                            className={
                              "form-group fg" +
                              (submitted && !user.lastName ? " has-error" : "")
                            }
                          >
                            <label className="st_name" htmlFor="name">
                              Last Name
                            </label>
                            <input
                              className="form-control name_nf"
                              id="lastName"
                              type="text"
                              name="lastName"
                              value={user.lastName}
                              onChange={this.handleChange}
                            />
                            {submitted && !user.lastName && (
                              <div className="help-block">
                                Last Name is required
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="make_form">
                      <div
                        className={
                          "form-group fg" +
                          (submitted && !user.position ? " has-error" : "")
                        }
                      >
                        <label className="st_name" htmlFor="name">
                          Title or Position
                        </label>
                        <select
                          id="position"
                          name="position"
                          className="form-control select_map"
                          value={user.position}
                          onChange={this.handleChange}
                        >
                          <option></option>
                          {titleOrPositionsItems.map((titleOrPositionsItem) => (
                            <option key={titleOrPositionsItem.id}>
                              {titleOrPositionsItem.title}
                            </option>
                          ))}
                        </select>
                        {submitted && !user.position && (
                          <div className="help-block">
                            Title or Position is required
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="make_form">
                      <div
                        className={
                          "form-group fg" +
                          (submitted && !user.email ? " has-error" : "")
                        }
                      >
                        <label className="st_name" htmlFor="email">
                          Corporate Email
                        </label>
                        <input
                          className="form-control name_nf"
                          id="email"
                          type="email"
                          name="email"
                          value={user.email}
                          onChange={this.handleChange}
                        />
                        {submitted && !user.email && (
                          <div className="help-block">Email is required</div>
                        )}
                      </div>
                    </div>
                    <div className="make_form">
                      <div
                        className={
                          "form-group fg" +
                          (submitted && !user.password ? " has-error" : "")
                        }
                      >
                        <label className="st_name" htmlFor="name">
                          Password
                        </label>
                        <input
                          className="form-control name_nf"
                          id="password"
                          type="password"
                          name="password"
                          value={user.password}
                          onChange={this.handleChange}
                        />
                        {submitted && !user.password && (
                          <div className="help-block">Password is required</div>
                        )}
                      </div>
                    </div>
                    <div className="plform">
                      <div className="form-check hompop">
                        <input
                          className="form-check-input deckle"
                          type="checkbox"
                          value=""
                          id="checkbox"
                        />
                        <label
                          className="form-check-label date_yup"
                          htmlFor="flexCheckDefault"
                        >
                           I agree to the platform <Link to="/privacy_policy" target="_blank"> data privacy</Link> consent, <Link to="/terms_and_conditions" target="_blank"> terms & conditions</Link>.
                        </label>
                      </div>
                    </div>

                    <div className="make_form">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group fg">
                            <label className="st_name" htmlFor="name">
                              Type the text
                            </label>
                            <input
                              className="form-control name_nf"
                              id="user_captcha_input"
                              type="text"
                              name="captcha"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="glee">
                            <div className="bacei">
                              {/* <LoadCanvasTemplate reloadText="<img src='https://img.icons8.com/external-becris-lineal-becris/32/000000/external-refresh-mintab-for-ios-becris-lineal-becris.png'/>" /> */}
                            </div>
                          </div>
                        </div>
                        <div className="btns-group view_bottoma mt-3">
                          <button
                            type="submit"
                            value="Submit"
                            id="submit-form"
                            className="btn"
                            onClick={this.doSubmit}
                          >
                            Register
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
