import React, { Component } from "react";
import AdminSidebar from "../sidebar/admin_sidebar";
import AdminHeader from "../header/admin_header";
import Table from "react-bootstrap/Table";
import { NavLink } from "react-router-dom";
import "./common.css";
import config from "../../config/config.json";
import { authenticationService } from "../../_services/authentication";
import axios from "axios";
import { Modal, Button } from 'react-bootstrap';
import { Dropdown } from "semantic-ui-react";
import './common.css'

const currentUser = authenticationService.currentUserValue;


export default class SectorReports extends Component {
  constructor(props) {
    super(props);
    this.state = {
      login: false,
      loading: false,
      items: [],
      industries: [],
      show: false,
      selectedIndustry: null,
      targetId: null
    };
  }

  async componentDidMount() {
      await this.serverRequest();
  }

  serverRequest() {
    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${currentUser.data.token}`,
        "Content-Type": "application/json",
      },
    };
    fetch(config.API_URL + "repotings/fetchReportingList?type=sector", requestOptions)
      .then((res) => res.json())
      .then((result) => {

          this.setState({
            items: result.data,
          });
          const industries = result.data.map((report) => parseInt(report.industry))
          // fetch industries
          this.fetchIndustries(industries);
        },
      ).catch((err)=> {
        console.log("ERR", err)
      });
  }

  async fetchIndustries(industries) {
        try {
          const headers = {
            Authorization: `Bearer ${currentUser.data.token}`,
            Accept: "application/json",
          };
          const response = await axios.get(`${config.API_URL}getCompanyIndustoryName`, { headers: headers });
            
          const dataSet = response.data.companyIndustory.filter((item) => !industries.includes(item.id)).map((record) => {
            return {
                key: record.title,
                value: record.title,
                text: record.title
            }
          });

          // map through every industry and select only name
          this.setState({industries: dataSet});
    
        } catch (error) {
          // show error message
          console.log(error.message)
        }
  }

      
//   const handleClose = () => setShow(false);
//   const handleShow = () => setShow(true);

    handleClose() {
        this.setState({
            show: false,
            targetId: null,
            selectedIndustry: null
        });
    }

    handleShow(target){
        this.setState({
            show: true,
            targetId: target
        });
    }

    handleIndustryChange(value) {
        this.setState({
            selectedIndustry: value
        });
    }

    async handleDuplicateReport(){
        
        
        const requestOptions = {
            method: "GET",
            headers: {
              Authorization: `Bearer ${currentUser.data.token}`,
              "Content-Type": "application/json",
            },
          };
          fetch(config.API_URL + `repotings/duplicateSectorReport/${this.state.selectedIndustry}/${this.state.targetId}`, requestOptions)
            .then((res) => res.json())
            .then((result) => {
                this.handleClose();
                this.serverRequest();
              },
            ).catch((err)=> {
              console.log("ERR", err)
            });
    }

  render() {

    const { items, show } = this.state
    return (
      <div>
        <AdminSidebar dataFromParent={this.props.location.pathname} />
        <AdminHeader />
        <div className="main_wrapper">
          <div className="inner_wraapper">
            <div className="container-fluid">
              <section className="d_text">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="col-sm-12">
                        <div className="color_div_on framwork_2">
                          <div className="business_detail">
                            <div className="">
                              <div className="heading">
                                <h4>Sector Reportings</h4>
                              </div>
                              <hr className="line"></hr>
                              <div className="saved_cards">
                              <div className="business_detail">
                                </div>
                                <div className="table_f">
                                  <Table striped bordered hover size="sm">
                                    <thead>
                                      <tr className="heading_color">
                                        <th style={{ width: "5%" }}>Sr.</th>
                                        <th>Reports  Lists</th>
                                        <th style={{ width: "10%" }}>Actions</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                        {items.map((item, index) => {
                                            return (
                                            <tr key={index}>
                                              <td>{index + 1}.</td>
                                              <td>{item?.name}</td>
                                              <td>
                                              <div className="actions_cont"> 
                                                <i className="fa fa-clone actions_ico" onClick={() => this.handleShow(item?.id)}></i>

                                                  <NavLink
                                                  className="non_underline_link bold actions_ico"
                                                  to={`/report/${item?.uuid}`}
                                                >
                                                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M1.181 12C2.121 6.88 6.608 3 12 3c5.392 0 9.878 3.88 10.819 9-.94 5.12-5.427 9-10.819 9-5.392 0-9.878-3.88-10.819-9zM12 17a5 5 0 1 0 0-10 5 5 0 0 0 0 10zm0-2a3 3 0 1 1 0-6 3 3 0 0 1 0 6z"/></svg>
                                                </NavLink>
                                              </div>

                                              </td>
                                            </tr>
                                            )
                                          })
                                        }
                                    </tbody>
                                  </Table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <Modal show={show} onHide={() => this.handleClose()}>
                    <Modal.Header closeButton>
                    <Modal.Title>Duplicate Report</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        
                    <div className="supplierInpCont">
                          <label  className="form-check-label supplierFormLable" htmlFor="flexCheckDefault">
                          Industry*
                          </label>
                          <Dropdown 
                            name="industry"
                            placeholder='Select Industries' 
                            search 
                            options={this.state.industries} 
                            value={this.state.selectedIndustry}
                            fluid
                            onChange={(e, {value}) => this.handleIndustryChange(value)}
                          />
                        </div>    
                    </Modal.Body>
                    <Modal.Footer>
                    <Button variant="secondary" onClick={() => this.handleClose()}>
                        Close
                    </Button>
                    <button className="page_save duplicateBtn" type="button" onClick={() => this.handleDuplicateReport()}>Duplicate</button>

                    {/* <Button variant="primary" onClick={() => this.handleClose()}>
                        Duplicate
                    </Button> */}
                    </Modal.Footer>
                </Modal>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
