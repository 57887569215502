import React, { useEffect, useState } from 'react'

const BillingFeatures = (props) => {
   const [items,setItems] = useState([]);
   useEffect(() => {
       let array = JSON.parse(props.data) 
       setItems(array)
  }, [props]);

  return (
    <>
     {items.length > 0 && (items?.map((item,key) => (
        <li key={key}><b>{key+1}.</b> {item}</li>
     )))}
    </>
  )
}

export default BillingFeatures