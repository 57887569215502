import React, { Component } from "react";
import AdminSidebar from "../sidebar/admin_sidebar";
import AdminHeader from "../header/admin_header";
import Table from "react-bootstrap/Table";
import { NavLink } from "react-router-dom";
import "./control.css";

export default class CompanyPolicies extends Component {
  constructor(props) {
    super(props);
    this.state = {
      login: false,
      loading: false,
      uuid:""
    };
  }

  componentDidMount() {
    let uri = window.location.pathname.split("/");
    this.setState({
      uuid : uri[2]
    });
  }

  render() {
    const { uuid } = this.state;
    return (
      <div>
        <AdminSidebar dataFromParent={this.props.location.pathname} />
        <AdminHeader />
        <div className="main_wrapper">
          <div className="inner_wraapper">
            <div className="container-fluid">
              <section className="d_text">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="col-sm-12">
                        <div className="color_div_on framwork_2">
                          <div className="business_detail">
                            <div className="">
                              <div className="heading">
                                <h4>Policies</h4>
                              </div>
                              <hr className="line"></hr>
                              <div className="saved_cards">
                              <div className="business_detail">
                                  <div className="heading">
                                    <div className="heading_wth_text">
                                      <div className="d-flex">
                                        <span className="global_link mx-0">
                                          <NavLink
                                            className="non_underline_link bold"
                                            to={"/companies/" + uuid + "/add_new_company_policy"}
                                          >
                                            <button
                                              className="link_bal_next page_width white"
                                              variant="none"
                                              to="/add_new_policy"
                                            >
                                              ADD NEW POLICIES
                                            </button>
                                          </NavLink>
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="table_f">
                                  <Table striped bordered hover size="sm">
                                    <thead>
                                      <tr className="heading_color">
                                        <th style={{ width: "5%" }}>Sr.</th>
                                        <th>Policy Lists</th>
                                        <th style={{ width: "5%" }}>View </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td>1.</td>
                                        <td>Governance Policies</td>
                                        <td>
                                          <NavLink
                                            className="non_underline_link bold view_c"
                                            to={"/companies/" + uuid + "/governance_company_policies"}
                                          >
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M1.181 12C2.121 6.88 6.608 3 12 3c5.392 0 9.878 3.88 10.819 9-.94 5.12-5.427 9-10.819 9-5.392 0-9.878-3.88-10.819-9zM12 17a5 5 0 1 0 0-10 5 5 0 0 0 0 10zm0-2a3 3 0 1 1 0-6 3 3 0 0 1 0 6z"/></svg>
                                          </NavLink>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>2.</td>
                                        <td>Social Policies</td>
                                        <td>
                                          <NavLink
                                            className="non_underline_link bold view_c"
                                            to={"/companies/" + uuid + "/social_company_policies"}
                                          >
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M1.181 12C2.121 6.88 6.608 3 12 3c5.392 0 9.878 3.88 10.819 9-.94 5.12-5.427 9-10.819 9-5.392 0-9.878-3.88-10.819-9zM12 17a5 5 0 1 0 0-10 5 5 0 0 0 0 10zm0-2a3 3 0 1 1 0-6 3 3 0 0 1 0 6z"/></svg>
                                          </NavLink>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>3.</td>
                                        <td>Cyber & Technology Policies</td>
                                        <td>
                                          <NavLink
                                            className="non_underline_link bold view_c"
                                            to={"/companies/" + uuid + "/cyber_&_technology_company_policies"}
                                          >
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M1.181 12C2.121 6.88 6.608 3 12 3c5.392 0 9.878 3.88 10.819 9-.94 5.12-5.427 9-10.819 9-5.392 0-9.878-3.88-10.819-9zM12 17a5 5 0 1 0 0-10 5 5 0 0 0 0 10zm0-2a3 3 0 1 1 0-6 3 3 0 0 1 0 6z"/></svg>
                                          </NavLink>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>4.</td>
                                        <td>Health & Safety Policies</td>
                                        <td>
                                          <NavLink
                                            className="non_underline_link bold view_c"
                                            to={"/companies/" + uuid + "/health_&_sefty_company_policies"}
                                          >
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M1.181 12C2.121 6.88 6.608 3 12 3c5.392 0 9.878 3.88 10.819 9-.94 5.12-5.427 9-10.819 9-5.392 0-9.878-3.88-10.819-9zM12 17a5 5 0 1 0 0-10 5 5 0 0 0 0 10zm0-2a3 3 0 1 1 0-6 3 3 0 0 1 0 6z"/></svg>
                                          </NavLink>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>5.</td>
                                        <td>Environmental Policies</td>
                                        <td>
                                          <NavLink
                                            className="non_underline_link bold view_c"
                                            to={"/companies/" + uuid + "/environmental_company_policies"}
                                          >
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M1.181 12C2.121 6.88 6.608 3 12 3c5.392 0 9.878 3.88 10.819 9-.94 5.12-5.427 9-10.819 9-5.392 0-9.878-3.88-10.819-9zM12 17a5 5 0 1 0 0-10 5 5 0 0 0 0 10zm0-2a3 3 0 1 1 0-6 3 3 0 0 1 0 6z"/></svg>
                                          </NavLink>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>6.</td>
                                        <td>Other Policies</td>
                                        <td>
                                          <NavLink
                                            className="non_underline_link bold view_c"
                                            to={"/companies/" + uuid + "/other_company_policies"}
                                          >
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M1.181 12C2.121 6.88 6.608 3 12 3c5.392 0 9.878 3.88 10.819 9-.94 5.12-5.427 9-10.819 9-5.392 0-9.878-3.88-10.819-9zM12 17a5 5 0 1 0 0-10 5 5 0 0 0 0 10zm0-2a3 3 0 1 1 0-6 3 3 0 0 1 0 6z"/></svg>
                                          </NavLink>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </Table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
