import React, { Component } from "react";
import AdminSidebar from "../../sidebar/admin_sidebar";
import AdminHeader from "../../header/admin_header";
import Table from "react-bootstrap/Table";
import config from "../../../config/config.json";
import { Pagination, Icon } from "semantic-ui-react";
import moment from "moment";
import { authenticationService } from "../../../_services/authentication";
import Swal from "sweetalert2";
import { sweetAlert } from "../../../utils/UniversalFunction";
import axios from "axios";

const currentUser = authenticationService.currentUserValue;

export default class Activities extends Component {
  constructor(props) {
    super(props);
    this.state = {
      login: false,
      items: [],
      loading: false,
      totalRows: 0,
      currentPage: 1,
      skip: 0,
      limit: 10,
      orderByName: "id",
      orderByValue: "DESC",
      setStartDate: null,
      setEndDate: null,
      search: "",
      pageCount: 0,
      totalData: 0,
      entities: [],
      ids: [],
    };
    this.pageChange = this.pageChange.bind(this);
    this.serverRequest = this.serverRequest.bind(this);
    this.onDateChange = this.onDateChange.bind(this);
    this.exportCSV = this.exportCSV.bind(this);
  }

  onDateChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
    let setStartDate = null;
    let setEndDate = null;
    if (this.state.setStartDate === null) {
      if (name === "setStartDate") {
        setStartDate = value;
      }
    } else {
      if (value !== this.state.setStartDate && name === "setStartDate") {
        setStartDate = value;
      } else {
        setStartDate = this.state.setStartDate;
      }
    }

    if (this.state.setEndDate === null) {
      if (name === "setEndDate") {
        setEndDate = value;
      }
    } else {
      if (value !== this.state.setEndDate && name === "setEndDate") {
        setEndDate = value;
      } else {
        setEndDate = this.state.setEndDate;
      }
    }

    console.log("First", setStartDate);
    console.log("Second", setEndDate);
    if (setStartDate !== null && setEndDate !== null) {
      setTimeout(() => {
        this.serverRequest();
      }, 200);
    }
  }

  applyGlobalSearch(e) {
    let value = e.target.value;
    let name = e.target.name;
    this.setState({
      [name]: value,
    });
    setTimeout(() => {
      this.serverRequest();
    }, 200);
  }

  serverRequest() {
    const { skip, limit, search } = this.state;

    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${currentUser.data.token}`,
        "Content-Type": "application/json",
      },
    };
    let parms = `acticity?skip=${skip}&limit=${limit}&search=${search}`;
    fetch(config.ADMIN_API_URL + parms, requestOptions)
      .then((res) => res.json())
      .then(
        (data) => {
          let items = data?.data.map((item) => {
            return { ...item, isChecked: false };
          });
          this.setState({
            pageCount: Math.ceil(data?.data?.length / this.state.perPage),
            totalData: data?.count,
            items: items,
          });
        },
        (error) => {}
      );
  }

  pageChange(e, data) {
    console.log("Handle Click", data);
    let page = data.activePage;
    let pageNo = page === 1 ? 0 : (page - 1) * this.state.limit;
    console.log("clicked", pageNo);
    this.setState({
      skip: pageNo,
    });
    this.serverRequest();
  }

  async handleUniversalCheckbox(e) {
    let { items } = this.state;
    const isChecked = e.target.checked;

    let temp = items.map((item) => {
      return { ...item, isChecked: isChecked };
    });
    await this.setState({ items: temp });
    let ids = [];

    temp.map((item) => {
      if (item?.isChecked === true) {
        ids.push(item.id);
      }
      return null;
    });
    await this.setState({ ids: ids });
  }

  async handleSingleCheckbox(e) {
    let { items } = this.state;
    const { id, checked } = e.target;

    let temp = await items.map((item) => {
      // eslint-disable-next-line 
      if (item.id == id) {
        return { ...item, isChecked: checked };
      } else {
        return { ...item };
      }
    });

    await this.setState({ items: temp });
    let ids = [];
    temp.map((item) => {
      if (item?.isChecked === true) {
        ids.push(item.id);
      }
      return null;
    });

    await this.setState({ ids: ids });
  }

  async handleDelete(e) {
    let { ids } = this.state;

    Swal.fire({
      title: "Do you want to delete this activity ?",
      showDenyButton: false,
      showCancelButton: true,
      confirmButtonText: "Delete",
    }).then((result) => {
      if (result.isConfirmed) {
        let headers = {
          Authorization: `Bearer ${currentUser.data.token}`,
          "Content-Type": "application/json",
        };

        axios
          .post(
            config.ADMIN_API_URL + "deleteActivity",
            {
              ids: ids,
            },
            {
              headers,
            }
          )
          .then((response) => {
            sweetAlert("success", response.data.message);

            setTimeout(async () => {
              await this.serverRequest();
            }, 400);
          })
          .catch(function (response) {
            sweetAlert("error", "User already assigned any module");
          });
      } else if (result.isDenied) {
        sweetAlert("info", "User Safe");
      }
    });
  }
  exportCSV(event) {
    event.preventDefault();
    this.setState({ submitted: true });
    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${currentUser.data.token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        startDate: this.state.setStartDate,
        endDate: this.state.setEndDate,
      }),
    };

    fetch(config.ADMIN_API_URL + `exportActivity`, requestOptions)
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            csvLink: result.data,
          });
          let url = config.BASE_URL + result.data;
          window.open(url, "_blank");
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error,
          });
        }
      );
  }

  componentDidMount() {
    this.setState({ items: [] });
    this.serverRequest();
  }

  render() {
    const { items, ids } = this.state;

    return (
      <div>
        <AdminSidebar dataFromParent={this.props.location.pathname} />
        <AdminHeader />
        <div className="main_wrapper">
          <div className="inner_wraapper">
            <div className="container-fluid">
              <section className="d_text">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="col-sm-12">
                        <div className="color_div_on framwork_2">
                          <div className="business_detail">
                            <div className="">
                              <div className="heading">
                                <h4>Activities</h4>
                              </div>
                              <hr className="line"></hr>
                              <div className="saved_cards">
                                <div className="business_detail">
                                  <div className="heading">
                                    <div className="heading_wth_text">
                                      <div className="d-flex">
                                        <span className="global_link m-0">
                                          <button
                                            onClick={this.exportCSV}
                                            className="link_bal_next page_width white"
                                          >
                                            <i className="fas fa-download white" />
                                          </button>
                                        </span>

                                        {ids.length > 0 && (
                                          <span className="global_link mx-3">
                                            <button
                                              onClick={(e) => {
                                                this.handleDelete(e);
                                              }}
                                              className="link_bal_next page_width white"
                                            >
                                              <i className="fas fa-trash white" />
                                            </button>
                                          </span>
                                        )}
                                      </div>
                                    </div>

                                    <div className="form-group has-search one">
                                      <span className="fa fa-search form-control-feedback"></span>
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Search Company Name"
                                        name="search"
                                        onChange={(e) =>
                                          this.applyGlobalSearch(e)
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="table_f table-responsive">
                                  <Table striped bordered hover size="sm">
                                    <thead>
                                      <tr className="heading_color">
                                        {currentUser?.data?.role ===
                                          "super_admin" && (
                                          <th>
                                            <input
                                              type="checkbox"
                                              checked={
                                                items.filter(
                                                  (result) =>
                                                    result?.isChecked !== true
                                                ).length < 1
                                              }
                                              onChange={(e) => {
                                                this.handleUniversalCheckbox(e);
                                              }}
                                            />
                                          </th>
                                        )}
                                        <th style={{ width: "10%" }}>ID</th>
                                        <th>COMPANY NAME</th>
                                        <th>MODULE </th>
                                        <th>DATE / TIME</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {items.map((item, key) => (
                                        <tr key={key}>
                                          {currentUser?.data?.role ===
                                            "super_admin" && (
                                            <td>
                                              <input
                                                id={item?.id}
                                                name={item?.id}
                                                type="checkbox"
                                                checked={
                                                  item?.isChecked
                                                    ? item?.isChecked
                                                    : false
                                                }
                                                onChange={(e) => {
                                                  this.handleSingleCheckbox(e);
                                                }}
                                              />
                                            </td>
                                          )}

                                          <td>{this.state.skip + key + 1}</td>
                                          <td>{item.company_name}</td>
                                          <td>{item.module_name}</td>
                                          <td>
                                            {moment(item.createdAt)
                                              .utc()
                                              .format("DD-MM-YYYY / HH:mm")}
                                          </td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  </Table>
                                  <Pagination
                                    className="mx-auto pagination"
                                    defaultActivePage={1}
                                    onPageChange={this.pageChange}
                                    ellipsisItem={{
                                      content: (
                                        <Icon name="ellipsis horizontal" />
                                      ),
                                      icon: true,
                                    }}
                                    firstItem={{
                                      content: (
                                        <Icon name="angle double left" />
                                      ),
                                      icon: true,
                                    }}
                                    lastItem={{
                                      content: (
                                        <Icon name="angle double right" />
                                      ),
                                      icon: true,
                                    }}
                                    prevItem={{
                                      content: <Icon name="angle left" />,
                                      icon: true,
                                    }}
                                    nextItem={{
                                      content: <Icon name="angle right" />,
                                      icon: true,
                                    }}
                                    totalPages={Math.ceil(
                                      this.state.totalData / this.state.limit
                                    )}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
