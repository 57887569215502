import React, { Component } from "react";
import AdminSidebar from "../sidebar/admin_sidebar";
import AdminHeader from "../header/admin_header";
import Table from "react-bootstrap/Table";
import { NavLink } from "react-router-dom";
import "./common.css";
import config from "../../config/config.json";
import { authenticationService } from "../../_services/authentication";
const currentUser = authenticationService.currentUserValue;


export default class Reports extends Component {
  constructor(props) {
    super(props);
    this.state = {
      login: false,
      loading: false,
      items: []
    };
  }

  async componentDidMount() {
      await this.serverRequest();
  }

  serverRequest() {
    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${currentUser.data.token}`,
        "Content-Type": "application/json",
      },
    };
    fetch(config.API_URL + "repotings/fetchReportingList", requestOptions)
      .then((res) => res.json())
      .then((result) => {

        let obj = {
          id: result.data.length + 1,
          name: "ESG Sector Questions Report",
          type: "sector",
          uuid: null
        }

        let temp = [...result.data, obj];

          this.setState({
            items: temp,
          });
        },
      ).catch((err)=> {
        console.log("ERR", err)
      });
  }
  render() {

    const { items } = this.state
    return (
      <div>
        <AdminSidebar dataFromParent={this.props.location.pathname} />
        <AdminHeader />
        <div className="main_wrapper">
          <div className="inner_wraapper">
            <div className="container-fluid">
              <section className="d_text">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="col-sm-12">
                        <div className="color_div_on framwork_2">
                          <div className="business_detail">
                            <div className="">
                              <div className="heading">
                                <h4>Reportings</h4>
                              </div>
                              <hr className="line"></hr>
                              <div className="saved_cards">
                              <div className="business_detail">
                                </div>
                                <div className="table_f">
                                  <Table striped bordered hover size="sm">
                                    <thead>
                                      <tr className="heading_color">
                                        <th style={{ width: "5%" }}>Sr.</th>
                                        <th>Reports  Lists</th>
                                        <th style={{ width: "5%" }}>View </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      
                                        {items.map((item, index) => {
                                            return (
                                            <tr key={index}>
                                              <td>{index + 1}.</td>
                                              <td>{item?.name}</td>
                                              <td>

                                                {item?.type === "sector" ? (
                                                  <NavLink
                                                  className="non_underline_link bold view_c"
                                                  to={`/reportings/sector`}
                                                >
                                                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M1.181 12C2.121 6.88 6.608 3 12 3c5.392 0 9.878 3.88 10.819 9-.94 5.12-5.427 9-10.819 9-5.392 0-9.878-3.88-10.819-9zM12 17a5 5 0 1 0 0-10 5 5 0 0 0 0 10zm0-2a3 3 0 1 1 0-6 3 3 0 0 1 0 6z"/></svg>
                                                </NavLink>
                                                ): (
                                                  <NavLink
                                                  className="non_underline_link bold view_c"
                                                  to={`/report/${item?.uuid}`}
                                                >
                                                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M1.181 12C2.121 6.88 6.608 3 12 3c5.392 0 9.878 3.88 10.819 9-.94 5.12-5.427 9-10.819 9-5.392 0-9.878-3.88-10.819-9zM12 17a5 5 0 1 0 0-10 5 5 0 0 0 0 10zm0-2a3 3 0 1 1 0-6 3 3 0 0 1 0 6z"/></svg>
                                                </NavLink>
                                                )}

                                              </td>
                                            </tr>
                                            )
                                          })
                                        
                                        }
                                    </tbody>
                                  </Table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
