import React, { Component } from "react";
import { Button, Modal } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import "./billing.css";
import { profileService } from "../../../_services/admin/settingService";
import BillingFeatures from "./BillingFeatures";
import AdminSidebar from "../../sidebar/admin_sidebar";
import AdminHeader from "../../header/admin_header";
import Swal from "sweetalert2";

export default class BillingNew extends Component {
  constructor(props) {
    super(props);
    this.state = {
      login: false,
      activeModal: "",
      items: [],
      no_of_users: "",
      title: "",
      price: "",
      tier: "",
      filterBy: "all",
      values: [{ value: null }],
      selectedPackage: [],
    };
    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.callApi = this.callApi.bind(this);
    this.callApi2 = this.callApi2.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange2 = this.handleChange2.bind(this);
    this.serverRequest = this.serverRequest.bind(this);
  }

  createUI() {
    return this.state.values.map((el, i) => (
      <div key={i}>
        <input
          type="text"
          value={el.value || ""}
          onChange={this.handleChangee.bind(this, i)}
        />
        <input
          type="button"
          value="remove"
          onClick={this.removeClick.bind(this, i)}
        />
      </div>
    ));
  }

  handleChangee(i, event) {
    let values = [...this.state.values];
    values[i].value = event.target.value;
    this.setState({ values });
  }

  addClick() {
    this.setState((prevState) => ({
      values: [...prevState.values, { value: null }],
    }));
  }

  removeClick(i) {
    Swal.fire({
      title: "Do you want to delete this feature?",
      showDenyButton: false,
      showCancelButton: true,
      confirmButtonText: "Delete",
      // denyButtonText: `Don't save`,
    }).then((result) => {
      if (result.isConfirmed) {
        let values = [...this.state.values];
        values.splice(i, 1);
        this.setState({ values });
      } else if (result.isDenied) {
        console.log("Test");
        // sweetAlert('info',"User Safe");
      }
    });
  }

  handleOpenModal(val) {
    this.setState({ activeModal: val });
    this.setState({ showModal: true });
  }

  handleCloseModal() {
    this.setState({ showModal: false });
    this.setState({
      showModal: "",
      title: "",
      no_of_users: "",
      price: "",
      tier: "",
    });
  }

  handleChange2(event) {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  }

  async handleFilterChange(event) {
    let filterBy = event.target.value;
    await this.setState({ filterBy: filterBy });
    await this.serverRequest();
  }

  handleChange(event) {
    const { checked } = event.target;
    let id = event.target.getAttribute("data-id");
    if (checked) {
      this.setState({
        selectedPackage: [...this.state.selectedPackage, id],
      });
    } else {
      let tempuser = this.state.selectedPackage?.filter(
        (item) => Number(item) !== Number(id)
      );
      this.setState({
        selectedPackage: tempuser,
      });
    }
    let obj = {};
    obj._id = id;
    obj.status = checked;
    this.callApi(obj);
    setTimeout(() => {
      this.callApi2();
    }, 500);
  }

  callApi(data) {
    profileService.updateSubscriptionStatus(data);
  }

  async callApi2(filter) {
    return await profileService.getGlobalSubscriptionsPlan(filter);
  }

  async componentDidMount() {
    this.serverRequest();
  }

  async serverRequest() {
    const response = await this.callApi2(this.state.filterBy);
    let arr = [];
    for (let index = 0; index < response?.data.length; index++) {
      const element = response?.data[index].id;
      if (response?.data[index].isActive === 1) {
        arr.push(element);
      }
    }
    this.setState({ items: response?.data, selectedPackage: arr });
  }

  handleSubmit(event) {
    event.preventDefault();
    this.setState({ submitted: true });
    let string = "";
    let ii = 1;
    this.state.values.forEach((element) => {
      if (this.state.values.length === ii) {
        string += element.value;
      } else {
        string += element.value + ",";
      }
      ii++;
    });
    const { title, no_of_users, price, tier } = this.state;
    let obj = {};
    obj.name = title;
    obj.no_of_users = no_of_users;
    obj.amount = price;
    obj.plan = tier;
    obj.metaData = string;
    profileService.createSubscription(obj);
    setTimeout(() => {
      this.handleCloseModal();
      this.serverRequest();
    }, 2000);
  }

  render() {
    const { items, selectedPackage } = this.state;
    return (
      <div>
        <AdminSidebar dataFromParent={this.props.location.pathname} />
        <AdminHeader />
        <div className="main_wrapper">
          <div className="inner_wraapper">
            <div className="container-fluid">
              <section className="d_text">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="strp_progess">
                        <div className="hol_rell">
                          <div className="steps-form">
                            <div className="steps-row setup-panel">
                              <div className="tabs-top setting_admin my-0">
                                <ul>
                                  <li>
                                    <NavLink to="/profile">My Profile</NavLink>
                                  </li>
                                  <li>
                                    <NavLink to="/billing" className="activee">
                                      Billing
                                    </NavLink>
                                  </li>
                                  <li>
                                    <NavLink to="/2fa">Two Factor Auth</NavLink>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="SVG Stepper">
                        <div className="stepperr_design">
                          <div className="color_div_step div-color">
                            <div className="include">
                              <section className="forms">
                                <div className="row">
                                  <div className="col-md-12 col-xs-12">
                                    <div className="business_detail">
                                      <div className="heading pb-5">
                                        <h4>Subscription Plan</h4>
                                        <form>
                                          <div className="form-group">
                                            <select
                                              className="form-control select_subscription"
                                              id="exampleFormControlSelect1"
                                              placeholder="Monthly Plans"
                                              onChange={(e) =>
                                                this.handleFilterChange(e)
                                              }         
                                            >
                                              <option value={"all"}>All</option>
                                              <option value={"year"}>
                                                Yearly
                                              </option>
                                              <option value={"month"}>
                                                Monthly
                                              </option>
                                              <option value={"week"}>
                                                Weekly
                                              </option>
                                              <option value={"day"}>
                                                Daily
                                              </option>
                                            </select>
                                          </div>
                                        </form>
                                      </div>

                                      <div className="cards">
                                        <div className="row justify-content-center">
                                          {items.map((item, key) => (
                                            <div
                                              key={key}
                                              className="col-xl-3 col-md-6 col-xs-12"
                                            >
                                              <div className="card-1">
                                                <span className="tag">
                                                  {item.title}
                                                </span>
                                                <h4>
                                                  $
                                                  {item.price
                                                    .toString()
                                                    .replace(
                                                      /\B(?=(\d{3})+(?!\d))/g,
                                                      ","
                                                    )}
                                                  / <span>{item.tier}</span>
                                                </h4>
                                                <span className="tag my-3">
                                                  No of Users:{" "}
                                                  {item.no_of_users}
                                                </span>
                                                <h6>Features Included:</h6>
                                                <div className="data_card-1">
                                                  <ul>
                                                    <BillingFeatures
                                                      data={item.metaData}
                                                    />
                                                  </ul>
                                                </div>
                                                <div className="custom-switch">
                                                  <label className="switch">
                                                    <input
                                                      type="checkbox"
                                                      checked={selectedPackage?.some(
                                                        (packages) =>
                                                          Number(packages) ===
                                                          item.id
                                                      )}
                                                      data-id={item.id}
                                                      onChange={(e) =>
                                                        this.handleChange(e)
                                                      }
                                                    />
                                                    <span className="slider round"></span>
                                                  </label>
                                                </div>
                                              </div>
                                            </div>
                                          ))}

                                          <div className="global_link w-100 my-5 d-flex justify-content-center">
                                            <button
                                              className="page_width page_save"
                                              variant="none"
                                              onClick={() =>
                                                this.handleOpenModal("login")
                                              }
                                            >
                                              {" "}
                                              ADD NEW SUBSCRIPTION PLAN{" "}
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </section>
                              <Modal
                                animation={true}
                                size="md"
                                className="modal_box"
                                shadow-lg="border"
                                show={
                                  this.state.showModal &&
                                  this.state.activeModal === "login"
                                }
                              >
                                <div className="modal-lg p-4 add_subscription">
                                  <Modal.Header className="pb-0 pb3">
                                    <h4 className="p-0">
                                      Add Subscription Plan
                                    </h4>
                                    <Button
                                      variant="outline-dark"
                                      onClick={this.handleCloseModal}
                                    >
                                      <i className="fa fa-times"></i>
                                    </Button>
                                  </Modal.Header>
                                  <div className="modal-body vekp pt-0">
                                    <div className="row">
                                      <div className="col-md-12">
                                        <div className="pb3">
                                          <div className="forms">
                                            <form onSubmit={this.handleSubmit}>
                                              <div className="form-group">
                                                <label
                                                  htmlFor="exampleFormControlInput1"
                                                  className="form-label"
                                                >
                                                  Subscription Plan Name
                                                </label>
                                                <input
                                                  type="text"
                                                  className="form-control"
                                                  id="exampleInputEmail1"
                                                  aria-describedby="emailHelp"
                                                  placeholder="Subscription Plan Name"
                                                  required
                                                  name="title"
                                                  value={this.state.title}
                                                  onChange={(e) =>
                                                    this.handleChange2(e)
                                                  }
                                                />
                                              </div>
                                              <div className="row my-3">
                                                <div className="col-md-4 col-xs-12">
                                                  <div className="form-group">
                                                    <label
                                                      htmlFor="exampleFormControlInput1"
                                                      className="form-label"
                                                    >
                                                      Price/User
                                                    </label>
                                                    <input
                                                      type="number"
                                                      className="form-control"
                                                      id="exampleInputEmail1"
                                                      aria-describedby="emailHelp"
                                                      name="price"
                                                      placeholder="Enter Amount"
                                                      required
                                                      value={this.state.price}
                                                      onChange={(e) =>
                                                        this.handleChange2(e)
                                                      }
                                                    />
                                                  </div>
                                                </div>
                                                <div className="col-md-4 col-xs-12">
                                                  <div className="form-group mt-2">
                                                    <label htmlFor="exampleFormControlSelect1"></label>
                                                    <select
                                                      className="form-control biling-menu"
                                                      id="exampleFormControlSelect1"
                                                      name="tier"
                                                      required
                                                      onChange={(e) =>
                                                        this.handleChange2(e)
                                                      }
                                                    >
                                                      <option>
                                                        Select Plan Type
                                                      </option>
                                                      <option value={"year"}>
                                                        Yearly
                                                      </option>
                                                      <option value={"month"}>
                                                        Monthly
                                                      </option>
                                                      <option value={"week"}>
                                                        Weekly
                                                      </option>
                                                      <option value={"day"}>
                                                        Daily
                                                      </option>
                                                    </select>
                                                  </div>
                                                </div>
                                                <div className="col-md-4 col-xs-12">
                                                  <div className="form-group mt-2">
                                                    <label htmlFor="exampleFormControlSelect1"></label>
                                                    <select
                                                      className="form-control biling-menu"
                                                      id="exampleFormControlSelect1"
                                                      name="no_of_users"
                                                      required
                                                      onChange={(e) =>
                                                        this.handleChange2(e)
                                                      }
                                                    >
                                                      <option>
                                                        Select No of Users
                                                      </option>
                                                      <option value={"1-5"}>
                                                        1 to 5
                                                      </option>
                                                      <option value={"6-20"}>
                                                        6 to 20
                                                      </option>
                                                      <option value={"21+"}>
                                                        21+
                                                      </option>
                                                    </select>
                                                  </div>
                                                </div>
                                              </div>

                                              <div className="form-group">
                                                <label
                                                  htmlFor="exampleFormControlInput1"
                                                  className="form-label w-100"
                                                >
                                                  Add Services Included
                                                </label>
                                                {this.state.values.map(
                                                  (el, i) => (
                                                    <div
                                                      className="d-flex removeMeta"
                                                      key={i}
                                                    >
                                                      <input
                                                        type="text"
                                                        className="form-control biling-menu"
                                                        placeholder="Write feature"
                                                        // value={el.value || ""}
                                                        onChange={(e) =>
                                                          this.handleChangee(
                                                            i,
                                                            e
                                                          )
                                                        }
                                                        required
                                                      />
                                                      <i
                                                        className="fa fa-trash"
                                                        value="remove"
                                                        onClick={() =>
                                                          this.removeClick(i)
                                                        }
                                                      ></i>
                                                    </div>
                                                  )
                                                )}
                                                <input
                                                  type="button"
                                                  className="btn btn-primary page-wid add_b"
                                                  value="+ Add More"
                                                  onClick={() =>
                                                    this.addClick()
                                                  }
                                                />
                                              </div>

                                              <div className="global_link mt-3 mx-0">
                                                <button
                                                  type="button"
                                                  className="link_bal_next"
                                                  onClick={
                                                    this.handleCloseModal
                                                  }
                                                >
                                                  Cancel
                                                </button>
                                                <button
                                                  type="submit"
                                                  className="page_save page_width mx-3"
                                                >
                                                  Save
                                                </button>
                                              </div>
                                            </form>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Modal>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
