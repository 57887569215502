import React, { Component } from "react";
import AdminSidebar from "../../sidebar/admin_sidebar";
import AdminHeader from "../../header/admin_header";
import config from "../../../config/config.json";
import { authenticationService } from "../../../_services/authentication";
import axios from "axios";
import { sweetAlert } from '../../../utils/UniversalFunction';

const currentUser = authenticationService.currentUserValue;

export default class EsgProducts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isChecked: false,
      error: null,
      isLoaded: false,
      isLoaded2: false,
      isLoaded3: false,
      items: [],
      answersss: [],
      // selectedUser: [],
      questionsFirst: [],
      questionsSecond: [],

      answers: [],
      submitted: false,

      selectedInductry: null,
      selected: [],
      selected2: [],
      selectedUser: [],
      selectedUser2: [],
      isCompanySubAdminSubmit: false,
      isCompanyAdminSubmit: false,
    };
  }

  componentDidMount() {
    let urlArr = window.location.pathname.split("/");
    const finalUUId = urlArr[urlArr.length - 3];

    const headers = {
      Authorization: `Bearer ${currentUser.data.token}`,
      Accept: "application/json",
    };

    //   Get Answers Api
    fetch(
      config.ADMIN_API_URL +
        `company/${finalUUId}/modules/getEsgProductAnswerApi`,
      { headers }
    )
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded3: true,
            selectedInductry:
              result.answers[0]?.selectedInductry === undefined
                ? ""
                : result.answers[0]?.selectedInductry,
            selectedUser:
              result.answers[0]?.targetIndustry.split(",") === undefined
                ? []
                : result.answers[0]?.targetIndustry.split(","),
            selectedUser2:
              result.answers[0]?.financialProducts.split(",") === undefined
                ? []
                : result.answers[0]?.financialProducts.split(","),
          });
          //answersss  answersss[0]?.frameworksUsed.split(",")
        },
        (error) => {
          this.setState({
            isLoaded3: true,
            error,
          });
        }
      );

    fetch(config.API_URL + "getEsgProductQuestions", { headers })
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded2: true,
            questionsFirst: result.targerInductry,
            questionsSecond: result.financialProducts,
          });
        },
        (error) => {
          this.setState({
            isLoaded2: true,
            error,
          });
        }
      );
  }

  async handleChangeVals(e) {
    const {value} = e.target;
    this.setState({selectedInductry: value});
  }

  async handleOptionChange(e) {

    const { checked, name, id } = e.target;
    
    let temp = [...this.state[name]];

    if(checked) {

      // push to array
      temp.push(id);
      this.setState({[name]: temp});
    }else{

      // pop from array
      let index = this.state[name].indexOf(id);
      temp.splice(index, 1);
      this.setState({[name]: temp});
    }

  }
  async handleDataSave() {

    const { uuid } = this.props.match.params;

    let payload = {
      uuid: uuid,
      selectedInductry: this.state.selectedInductry,
      targetIndustry: this.state.selectedUser.join(','),
      financialProducts: this.state.selectedUser2.join(',') 
    }

          
    try {
      const headers = {
        Authorization: `Bearer ${currentUser.data.token}`,
        Accept: "application/json",
      };
      await axios.put(`${config.ADMIN_API_URL}esgProducts/updateEsgProducts`, payload, { headers: headers });
      sweetAlert('success', "Updated Successfully");
    } catch (error) {
      sweetAlert('error', "Oops some error occured");
      // show error message
      console.log(error.message)
    }
    console.log("payload", payload);
  }

  render() {
    return (
      <div>
        <AdminSidebar dataFromParent={this.props.location.pathname} />
        <AdminHeader />
        <div className="main_wrapper">
          <div className="inner_wraapper">
            <div className="container-fluid">
              <section className="d_text">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="Introductionwe">
                        <div className="text_Parts">
                          <div className="mb-3">
                            <div className="d-flex justify-content-between mb-3">
                              <h5 className="governance_head">Introduction</h5>
                              <div className="form_x mb-3"></div>
                            </div>
                            <p className="products_ava">
                              Improved ESG performance can introduce a new range
                              of products available to you and your business.
                              Our goal is to not only help you on your ESG
                              strategy and reporting journey but to realise as
                              much benefit by being a part of our ESG community.{" "}
                            </p>
                          </div>
                          <div className="our_esg">
                            <h4 className="esg_reali">
                              Select any of the below products and we will keep
                              you informed of:
                            </h4>
                            <p className="suppl mb-3">
                              1. If your business has the potential to be a
                              supplier to other organisations on our platform{" "}
                            </p>
                            <p className="suppl">
                              2. Any new products or services that are available
                              to your business due to your ESG commitments
                            </p>
                          </div>
                        </div>
                        <div className="keep_onel mt-5">
                          <div className="report mb-3">
                            <h4 className="frameje mb-5">Your target industry</h4>
                          </div>
                          <div className="fremove">
                            <div className="row">
                              {this.state.questionsFirst.map((item, key) => (
                                <div className="col-md-6" key={key}>
                                  <div className="form-check form-check-inline clobal_check">
                                    <input
                                      className="form-check-input input_one"
                                      name="selectedUser"
                                      id={item.id}
                                      onChange={(e) =>this.handleOptionChange(e)}
                                      checked={this.state.selectedUser?.some(
                                        (frameworkiuse) =>
                                          Number(frameworkiuse) === item.id
                                      )}
                                      type="checkbox"
                                      // id={"frameworks" + (key + 1)}
                                    />
                                    <label
                                      className="form-check-label label_one"
                                      htmlFor="inlineCheckbox1999"
                                    >
                                      {item.description}
                                    </label>
                                  </div>
                                </div>
                              ))}
                            </div>

                            <div className="form_fe">
                              <label className="rellp" htmlFor="email">
                                Please provide a detailed explanation of how you
                                can provide
                                <br />
                                goods and/or services to your selected
                                industries:{" "}
                              </label>
                              <textarea
                                className="form-control bellpo"
                                id="exampleFormControlTextarea1"
                                name="selectedInductry"
                                // disabled
                                onChange={(e) => this.handleChangeVals(e)}
                                value={this.state.selectedInductry}
                                rows="3"
                              ></textarea>
                            </div>
                            <div className="e_inter">
                              <div className="eointer">
                                <h4 className="lease_uop">
                                  Please select if you have interest in
                                  receiving information from ESG and/or Impact
                                  financial product
                                  <br /> providers regarding the following
                                  products:
                                </h4>
                                <div className="selecti">
                                  <div className="row">
                                    {this.state.questionsSecond.map(
                                      (item, key) => (
                                        <div className="col-md-6" key={key}>
                                          <div className="form-check form-check-inline clobal_check">
                                            <input
                                              className="form-check-input input_one"
                                              type="checkbox"
                                              name="selectedUser2"
                                              onChange={(e) =>this.handleOptionChange(e)}
                                              checked={this.state.selectedUser2?.some(
                                                (frameworkiuse) =>
                                                  Number(frameworkiuse) ===
                                                  item.id
                                              )}
                                              id={item.id}
                                              // id={"frameworks" + (key + 1)}
                                            />
                                            <label
                                              className="form-check-label label_one"
                                              htmlFor="inlineCheckbox1999"
                                            >
                                              {item.description}
                                            </label>
                                          </div>
                                        </div>
                                      )
                                    )}
                                  </div>
                                </div>
              
                              </div>
               
                            </div>
                   
                          </div>
                        </div>
                        <div className="esgProdSaveCont">
                          <button
                              id="savbtnMain2"
                              className="link_bal_next page_width white "
                              variant="none"
                              onClick={() => this.handleDataSave()}
                            >
                              Save
                          </button>
                        </div>
                        
                      </div>
                 
                    </div>
                  
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
