import React, { Component } from "react";
import "../Company_Super_Admin/companies/companies.css";
import { NavLink } from "react-router-dom";

export default class Smartup_Tabbing extends Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  }

  render() {
    return (
      <div>
        <div className="tabs-top my-0">
          <ul>
            <li>
              <NavLink className="select"
                to="/admin/SmartUP/Users"
              >
                User Details
              </NavLink>
            </li>
            <li>
              <NavLink className="select"
                to="/admin/SmartUP/Channels"
              >
                Channels Details
              </NavLink>
            </li>
            <li>
              <NavLink className="select"
                to="/admin/SmartUP/LearningRecords"
              >
                Learning Records
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}
