import React, { Component } from "react";
import AdminSidebar from "../sidebar/admin_sidebar";
import AdminHeader from "../header/admin_header";
import config from "../../config/config.json";
import axios from "axios";
import { sweetAlert } from "../../utils/UniversalFunction";
import "./control.css";
import { authenticationService } from "../../_services/authentication";
const currentUser = authenticationService.currentUserValue;

export default class AddIntroductionVideos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      login: false,
      items: [],
      introductionVideos: [],
      loading: false,
      title: "",
      category: "",
      video_link: "",
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  }

  handleSubmit(event) {
    event.preventDefault();
    this.setState({ submitted: true });
    const { title, category, video_link } = this.state;
    const headers = {
      Authorization: `Bearer ${currentUser.data.token}`,
      Accept: "application/json",
    };
    axios
      .post(
        config.API_URL + "createIntroductionVideos",
        {
          title: title,
          category: category,
          video_link: video_link,
        },
        { headers }
      )
      .then((response) => {
        sweetAlert("success", response.data.message);
        setTimeout(() => {
          this.props.history.push(`/introduction_videos`);
        }, 1000);
      })
      .catch(function (error) {
        if (error.response) {
          sweetAlert("error", error.response.data.message);
        }
      });
  }

  componentDidMount() {
    
  }

  render() {
    return (
      <div>
        <AdminSidebar dataFromParent={this.props.location.pathname} />
        <AdminHeader />
        <div className="main_wrapper">
          <div className="inner_wraapper">
            <div className="container-fluid">
              <section className="d_text">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="col-sm-12">
                        <div className="color_div_on framwork_2">
                          <div className="business_detail">
                            <div className="">
                              <div className="saved_cards">
                                <form name="form" onSubmit={this.handleSubmit}>
                                  <div className="business_detail">
                                    <div className="heading">
                                      <h4>Add Introduction Video</h4>
                                    </div>
                                    <hr className="line"></hr>

                                    <div className="row">
                                      <div className="col-lg-6 col-xs-12">
                                        <div className="form-group">
                                          <label
                                            htmlFor="title"
                                            className="mb-2"
                                          >
                                            Introduction Video Title*
                                          </label>
                                          <input
                                            type="text"
                                            className="form-control py-3"
                                            id="title"
                                            placeholder="Enter Introduction Video Title"
                                            name="title"
                                            onChange={this.handleChange}
                                          />
                                        </div>
                                      </div>
                                      <div className="col-lg-6 col-xs-12">
                                        <div className="form-group">
                                          <label
                                            htmlFor="title"
                                            className="mb-2"
                                          >
                                            Category*
                                          </label>
                                          <select
                                            onChange={this.handleChange}
                                            name="category"
                                            className="select_one industrylist"
                                          >
                                          <option>Select Category</option>
                                            <option value="esg_reporting">ESG Reporting</option>
                                            <option value="sector_questions">Sector Questions</option>
                                            <option value="governance">Governance</option>
                                            <option value="suppliers">Suppliers</option>
                                            <option value="supplier_management">Supplier Management</option>
                                            <option value="sustainable">SDGs</option>
                                            <option value="board_skills">Board Skills</option>
                                            <option value="management">Management</option>
                                            <option value="esg_products">ESG Products</option>
                                            <option value="training">Training</option>
                                          </select>
                                        </div>
                                      </div>
                                      <div className="form-group py-3">
                                        <label
                                          htmlFor="question"
                                          className="mb-2"
                                        >
                                          Introduction Video Link*
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control py-3"
                                          id="video_link"
                                          placeholder="Enter Introduction Video Link"
                                          name="video_link"
                                          onChange={this.handleChange}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="global_link mx-0 my-3">
                                    <button
                                      type="submit"
                                      className="page_width page_save"
                                    >
                                      ADD NOW
                                    </button>
                                  </div>
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
