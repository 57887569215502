/* eslint-disable react/jsx-no-target-blank */
import axios from "axios";
import React, { Component } from "react";
import AdminSidebar from "../../sidebar/admin_sidebar";
import AdminHeader from "../../header/admin_header";
import "../../Company Sub Admin/Pages/supplier/supplier_details.css";
import config from "../../../config/config.json";
import {sweetAlert} from '../../../utils/UniversalFunction'
import { authenticationService } from "../../../_services/authentication";
import './companies.css';
import Select from 'react-select';


const currentUser = authenticationService.currentUserValue;
const BackendBaseUrl = config.BASE_URL;

const options = [
  { value: "Water", label: "Water" },
  { value: "Soil", label: "Soil" },
  { value: "Forests", label: "Forests" },
  { value: "Biodiversity", label: "Biodiversity" },
]

export default class supplierPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openModel: false,
      activeModal: "",
      socialPolicy: [],
      uuid: "",
      setOpen: false,
      error: null,
      items: [],
      answers: [],
      supplyChainFirstQuestion : "What is the current nature of the relationship between your company and your suppliers?",
      supplyChainSecondQuestion : "To what extent does your company audit your supply chains?",
      supplyChainThirdQuestion : "Which existing supply chain policies are in place?",
      supplyChainFourthQuestion : "How many tiers are there in your supply chain?",
      supplyChainFifthQuestion : "What is the transparency level of your suppliers?",
      businessModelData:[],
      environmentalTopicData:[],
      environmentalOptionData:[],
      governanceTopicData:[],
      socialTopicData:[],
      submitted: false,
      report: [],
      selectedOption: null,
    };
    this.goToPreviousPath = this.goToPreviousPath.bind(this);
    this.getUploadedReportData = this.getUploadedReportData.bind(this);
  }

  onFileChange = (event) => {
    let urlArr = window.location.pathname.split("/");
    const finalUUId = urlArr[urlArr.length - 3];

    const formData = new FormData();
    // Update the formData object
    formData.append(
      "uploadImage",
      event.target.files[0],
      event.target.files[0].name
    );
    formData.append("section_name", "supplier");
    formData.append("sub_section", "supplier");
    const headers = {
      Authorization: `Bearer ${currentUser.data.token}`,
      Accept: "application/json",
    };
    axios
      .post(
        `${config.ADMIN_API_URL}company/${finalUUId}/module/supplier/upload/report`,
        formData,
        {
          headers,
        }
      )
      .then((response) => {
        sweetAlert('success',response.data.message);	
      })
      .catch(function (response) {
        sweetAlert('error',response.data.message);
      });
  };

  getUploadedReportData = () => {
    let urlArr = window.location.pathname.split("/");
    const finalUUId = urlArr[urlArr.length - 2];
    const headers = {
      Authorization: `Bearer ${currentUser.data.token}`,
      Accept: "application/json",
    };
    fetch(
      `${config.ADMIN_API_URL}company/${finalUUId}/module/supplier/download/report/supplier/supplier`,
      {
        headers,
      }
    )
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            report: result.data,
          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error,
          });
        }
      );
  };

  componentDidMount() {
    this.getUploadedReportData();
    let urlArr = window.location.pathname.split("/");
    const finalUUId = urlArr[urlArr.length - 3];
    this.setState({
      uuid: urlArr[urlArr.length - 3],
    });

    const headers = {
      Authorization: `Bearer ${currentUser.data.token}`,
      Accept: "application/json",
    };

    fetch(config.ADMIN_API_URL + `company/${finalUUId}/modules/supplier`, {
      headers,
    })
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded2: true,
            supplyChainFirstAnswer:
              result.data[0]?.currentNature === undefined
                ? ""
                : result.data[0]?.currentNature,
            supplyChainSecondAnswer:
              result.data[0]?.extent === undefined
                ? ""
                : result.data[0]?.extent,
            supplyChainThirdAnswer:
              result.data[0]?.existingSupply === undefined
                ? ""
                : result.data[0]?.existingSupply,
            supplyChainFourthAnswer:
              result.data[0]?.tiers === undefined ? "" : result.data[0]?.tiers,
            supplyChainFifthAnswer:
              result.data[0]?.transparencyLevel === undefined
                ? ""
                : result.data[0]?.transparencyLevel,
          });
          console.log("resultresultresult", result);
          
        },
        (error) => {
          this.setState({
            isLoaded2: true,
            error,
          });
        }
      );

    fetch(
      config.ADMIN_API_URL + `company/${finalUUId}/modules/supplierTopics`,
      { headers },
      { type: "currentNature" }
    )
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            businessModelData:(result?.businessModel.length > 0) ? JSON.parse(result?.businessModel[0]?.answer) : [],
            environmentalTopicData:(result?.environmentalTopic.length > 0) ? JSON.parse(result?.environmentalTopic[0]?.answer) : [],
            environmentalOptionData:(result?.environmentalTopic.length > 0) ? JSON.parse(result?.environmentalTopic[0]?.answer)[5].answer : [],
            governanceTopicData:(result?.governanceTopic.length > 0) ? JSON.parse(result?.governanceTopic[0]?.answer) : [],
            socialTopicData:(result?.socialTopic.length > 0) ? JSON.parse(result?.socialTopic[0]?.answer) : []
          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error,
          });
        }
      );
;
  }

  goToPreviousPath() {
    this.props.history.goBack();
  }



  async handleSupplierDetailsChange (e) {
    const {name, value, id: index} = e.target;
    let temp = [...this.state[name]];
    temp[index].answer = value;
    this.setState({[name]: temp});
  }

  

  async handleSupplierChange (e) {
    const {name, value } = e.target;
    this.setState({[name]: value});
  }

  async handleSupplierDetailsSelectChange (e) {
    let temp = [...this.state.environmentalTopicData];
    temp[5].answer = e;
    this.setState({environmentalTopicData: temp});
  }
  async handleSaveSupplierMain () {

    const { uuid } = this.props.match.params;

    const payload = {
      currentNature: this.state.supplyChainFirstAnswer,
      uuid: uuid,
      extent: this.state.supplyChainSecondAnswer,
      existingSupply: this.state.supplyChainThirdAnswer,
      tiers: this.state.supplyChainFourthAnswer,
      transparencyLevel: this.state.supplyChainFifthAnswer
    }
    
    try {
      const headers = {
        Authorization: `Bearer ${currentUser.data.token}`,
        Accept: "application/json",
      };
      await axios.put(`${config.ADMIN_API_URL}supplier/updateSupplier`, payload, { headers: headers });
      sweetAlert('success', "Updated Successfully");
    } catch (error) {
      sweetAlert('error', "Oops some error occured");
      // show error message
      console.log(error.message)
    }
  }

  handleChange = (selectedOption) => {
    this.setState({ selectedOption }, () =>
      console.log(`Option selected:`, this.state.selectedOption)
    );
  };

  async handleSupplierDetailsSave (stateName, type) {

    const { uuid } = this.props.match.params;

    let payload = {
      uuid: uuid,
      type: type,
      answer: this.state[stateName]
    }
    
    try {
      const headers = {
        Authorization: `Bearer ${currentUser.data.token}`,
        Accept: "application/json",
      };
      await axios.put(`${config.ADMIN_API_URL}supplier/updateSupplierDetails`, payload, { headers: headers });
      sweetAlert('success', "Updated Successfully");
    } catch (error) {
      sweetAlert('error', "Oops some error occured");
    }

  }



  render() {
    const { report,businessModelData, environmentalTopicData, governanceTopicData,socialTopicData  } = this.state;
    return (
      <div>
        <AdminSidebar dataFromParent={this.props.location.pathname} />
        <AdminHeader />
        <div className="main_wrapper">
          <div className="inner_wraapper">
            <div className="container-fluid">
              <section className="d_text">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="governance pb-5">
                        <div className="text_Parts">
                          <div className="text_governance">
                            <h5 className="governance_head mb-4">Introduction</h5>
                            <p className="suppliers_p">
                              Today, an estimate of 80% of global trade passes
                              through supply chains. Outsourcing production to
                              suppliers in countries with a cost advantage can
                              deliver significant economic benefits, but also
                              introduce a range of reputational and operational
                              risks.
                            </p>
                            <p className="suppliers_t">
                              An appropriate supply chain management system
                              involves measurement, verification and reporting
                              against these risks. Codes of conduct in supplier
                              contracts and incentive structures can improve
                              awareness and eventually performance. Furthermore,
                              collaboration with industry peers, civil society
                              and regulatory authorities is also important to
                              effectively manage existing and emerging ESG
                              supply chain risks.
                            </p>
                            <div className="Identified">
                              <h4 className="Identified_text">
                                Identified ESG risks in supply chains
                              </h4>
                              <p className="chains">
                                Traditional key considerations in supply chains
                                include technical quality, cost effectiveness,
                                speed of delivery and reliability. However,
                                sustainability factors are increasingly gaining
                                importance.
                              </p>
                            </div>
                          </div>
                          <div className="reviewed">
                            <div className="reviewed_ones">
                              <h4 className="include_h">Examples include:</h4>
                              <p className="policy">
                                <span className="policy_icon">
                                  <i className="fal fa-chevron-right"></i>
                                </span>
                                Environmental pollution{" "}
                              </p>
                              <p className="policy">
                                <span className="policy_icon">
                                  <i className="fal fa-chevron-right"></i>
                                </span>
                                Shortages of raw material and natural resources
                              </p>
                              <p className="policy">
                                <span className="policy_icon">
                                  <i className="fal fa-chevron-right"></i>
                                </span>
                                Workforce health and safety incidents{" "}
                              </p>
                              <p className="policy">
                                <span className="policy_icon">
                                  <i className="fal fa-chevron-right"></i>
                                </span>
                                Labour disputes
                              </p>
                              <p className="policy">
                                <span className="policy_icon">
                                  <i className="fal fa-chevron-right"></i>
                                </span>
                                Corruption and bribery
                              </p>
                              <p className="policy">
                                <span className="policy_icon">
                                  <i className="fal fa-chevron-right"></i>
                                </span>
                                Geopolitical considerations
                              </p>
                            </div>
                          </div>
                          <div className="variants">
                            <div className='file file--upload'>
                              <label htmlFor='input-file'>
                                <i className="fa fa-upload" aria-hidden="true"></i>Upload Report
                              </label>
                              <input
                                // style={{ visibility: "hidden" }}
                                type="file"
                                id='input-file'
                                accept='.pdf, .doc, .docx, .xlsx, .pptx, .ppt, .ott, .odp'
                                name="governancePolicy"
                                onChange={this.onFileChange}
                              />
                            </div>
                            {report[0]?.file !== undefined && (
                            <a
                              className="outputFile"
                              target="_blank"
                              href={BackendBaseUrl + report[0]?.file ?? ""}
                            >
                              Preview
                            </a>

                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-sm-12">
                      <div className="governance pb-5">
                        <div className="text_Parts">
                          <div className="back_doll">
                            <div className="supply_chain">
                              <h4 className="critical_h">
                                Good supply chain management involves four
                                critical management components:{" "}
                              </h4>
                            </div>
                            <div className="google_sup">
                              <div className="sup_can">
                                <div className="four_div">
                                  <div className="four_text">
                                    <h4 className="as_usual">
                                      Fit for purpose
                                    </h4>
                                    <p className="operating_m">
                                      Are the actions you're undertaking fit for
                                      purpose, adapted to the recipients and
                                      prioritised to meet our supplier
                                      management strategy?{" "}
                                    </p>
                                  </div>
                                </div>
                                {/* <!-- 2 --> */}
                                <div className="four_div">
                                  <div className="four_text_one">
                                    <h4 className="as_usual">
                                      Business-as-usual{" "}
                                    </h4>
                                    <p className="operating_m">
                                      Can managment of suppliers and supplier
                                      ESG risks be embedded into your operating
                                      model and management controls?{" "}
                                    </p>
                                  </div>
                                </div>
                                {/* <!-- 3 --> */}
                                <div className="four_div">
                                  <div className="four_text_two">
                                    <h4 className="as_usual">
                                      A balanced approach
                                    </h4>
                                    <p className="operating_m">
                                      Do not disincentivise partners or
                                      suppliers to do
                                      <br /> business with our organisation.
                                    </p>
                                  </div>
                                </div>
                                {/* <!-- 4 --> */}
                                <div className="four_div">
                                  <div className="four_text_there">
                                    <h4 className="as_usual">
                                      Risk based due-dilligence
                                    </h4>
                                    <p className="operating_m">
                                      Undertaking activities in countries
                                      considered 'high-risk modern slavery
                                      geographies'
                                    </p>
                                    <p className="high_risk">
                                      <span className="high_icon">
                                        <i className="fal fa-chevron-right"></i>
                                      </span>
                                      Buying high-risk goods & services{" "}
                                    </p>
                                    <p className="high_risk">
                                      <span className="high_icon">
                                        <i className="fal fa-chevron-right"></i>
                                      </span>
                                      Engaging with high-risk sectors{" "}
                                    </p>
                                    <p className="high_risk">
                                      <span className="high_icon">
                                        <i className="fal fa-chevron-right"></i>
                                      </span>
                                      Engaging in vulnerable populations{" "}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="home_risck pb-0">
                            <div className="row">
                              <div className={this.state.supplyChainFirstAnswer || this.state.supplyChainFirstAnswer ? "col-lg-6" : "col-lg-6"}>
                                <div className="form-group bop">
                                  <label className="nature_one">
                                    {this.state.supplyChainFirstQuestion}
                                  </label>
                                  <textarea
                                    // readOnly
                                    className="form-control text_w"
                                    name="supplyChainFirstAnswer"
                                    value={
                                      this.state.supplyChainFirstAnswer
                                    }
                                    rows="3"
                                    onChange={(e) => this.handleSupplierChange(e)}
                                  ></textarea>
                                </div>
                              </div>
                              <div className={this.state.supplyChainSecondAnswer || this.state.supplyChainSecondAnswer ? "col-lg-6" : "col-lg-6"}>
                                <div className="form-group bop">
                                  <label className="nature_one">
                                    {this.state.supplyChainSecondQuestion}
                                  </label>
                                  <textarea
                                    // readOnly
                                    className="form-control text_w"
                                    value={
                                      this.state.supplyChainSecondAnswer
                                    }
                                    name="supplyChainSecondAnswer"
                                    rows="3"
                                    onChange={(e) => this.handleSupplierChange(e)}
                                  ></textarea>
                                </div>
                              </div>
                              <div className={this.state.supplyChainThirdAnswer || this.state.supplyChainThirdAnswer ? "col-lg-6" : "col-lg-6"}>
                                <div className="form-group bop">
                                  <label className="nature_one">
                                    {this.state.supplyChainThirdQuestion}
                                  </label>
                                  <textarea
                                    // readOnly
                                    className="form-control text_w"
                                    value={
                                      this.state.supplyChainThirdAnswer
                                    }
                                    name="supplyChainThirdAnswer"
                                    rows="3"
                                    onChange={(e) => this.handleSupplierChange(e)}
                                  ></textarea>
                                </div>
                              </div>
                              <div className={this.state.supplyChainFourthAnswer || this.state.supplyChainFourthAnswer ? "col-lg-6" : "col-lg-6"}>
                                <div className="form-group bop">
                                  <label className="nature_one">
                                    {this.state.supplyChainFourthQuestion}
                                  </label>
                                  <textarea
                                    // readOnly
                                    className="form-control text_w"
                                    value={
                                      this.state.supplyChainFourthAnswer
                                    }
                                    name="supplyChainFourthAnswer"
                                    rows="3"
                                    onChange={(e) => this.handleSupplierChange(e)}
                                  ></textarea>
                                </div>
                              </div>
                              <div className={this.state.supplyChainFifthAnswer || this.state.supplyChainFifthAnswer ? "col-lg-6" : "col-lg-6"}>
                                <div className="form-group bop">
                                  <label className="nature_one">
                                    {this.state.supplyChainFifthQuestion}
                                  </label>
                                  <br />
                                  <select
                                    // readOnly
                                    className="select_one"
                                    value={this.state.supplyChainFifthAnswer}
                                    name="supplyChainFifthAnswer"
                                    onChange={(e) => this.handleSupplierChange(e)}
                                  >
                                    <option value="low">
                                      {"Low"}
                                    </option>
                                    <option value="medium">
                                      {"Medium"}
                                    </option>
                                    <option value="high">
                                      {"High"}
                                    </option>
                                  </select>
                                </div>
                              </div>

                              <div className="supplierSaveBtn">
                              <button
                                  id="savbtnMain3"
                                  className="link_bal_next page_width white "
                                  variant="none"
                                  onClick={()=> this.handleSaveSupplierMain()}
                                >
                              Save
                            </button>
                            </div>
                          </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-sm-12">
                      <div className="governance1 pb-0">
                        <div className="four_box_text">
                          <div className="Environmental">
                            <h4 className="Environmental_text">
                              Environmental Topics
                            </h4>
                          </div>
                          <div className="home_risckq">
                            <div className="row">
                            <div className={environmentalTopicData[0]?.answer || environmentalTopicData[0]?.answer ? "col-lg-6" : "col-lg-6 hideBox"}>
                                  <div className="form-group bop">
                                    <label className="nature_one">
                                      {environmentalTopicData[0]?.question}
                                    </label>
                                    <textarea
                                      className="form-control text_w"
                                      // readOnly
                                      id={0}
                                      name="environmentalTopicData"
                                      value={
                                        environmentalTopicData[0]?.answer                                                    
                                      }
                                      onChange={(e) => this.handleSupplierDetailsChange(e)}
                                      rows="3"
                                    ></textarea>
                                  </div>
                                </div>
                                <div className={environmentalTopicData[1]?.answer || environmentalTopicData[1]?.answer ? "col-lg-6" : "col-lg-6 hideBox"}>
                                  <div className="form-group bop">
                                    <label className="nature_one">
                                      {environmentalTopicData[1]?.question}
                                    </label>
                                    <textarea
                                      className="form-control text_w"
                                      // readOnly
                                      id={1}
                                      name="environmentalTopicData"
                                      value={
                                        environmentalTopicData[1]?.answer                                                    
                                      }
                                      onChange={(e) => this.handleSupplierDetailsChange(e)}
                                      rows="3"
                                    ></textarea>
                                  </div>
                                </div>
                                <div className={environmentalTopicData[2]?.answer || environmentalTopicData[2]?.answer ? "col-lg-6" : "col-lg-6 hideBox"}>
                                  <div className="form-group bop">
                                    <label className="nature_one">
                                      {environmentalTopicData[2]?.question}
                                    </label>
                                    <textarea
                                      className="form-control text_w"
                                      // readOnly
                                      id={2}
                                      name="environmentalTopicData"
                                      value={
                                        environmentalTopicData[2]?.answer                                                    
                                      }
                                      onChange={(e) => this.handleSupplierDetailsChange(e)}
                                      rows="3"
                                    ></textarea>
                                  </div>
                                </div>
                                <div className={environmentalTopicData[3]?.answer || environmentalTopicData[3]?.answer ? "col-lg-6" : "col-lg-6 hideBox"}>
                                  <div className="form-group bop">
                                    <label className="nature_one">
                                      {environmentalTopicData[3]?.question}
                                    </label>
                                    <textarea
                                      className="form-control text_w"
                                      // readOnly
                                      id={3}
                                      name="environmentalTopicData"
                                      value={
                                        environmentalTopicData[3]?.answer                                                    
                                      }
                                      onChange={(e) => this.handleSupplierDetailsChange(e)}
                                      rows="3"
                                    ></textarea>
                                  </div>
                                </div>
                                <div className={environmentalTopicData[4]?.answer || environmentalTopicData[4]?.answer ? "col-lg-6" : "col-lg-6 hideBox"}>
                                  <div className="form-group bop">
                                    <label className="nature_one">
                                      {environmentalTopicData[4]?.question}
                                    </label>
                                    <textarea
                                      className="form-control text_w"
                                      // readOnly
                                      id={4}
                                      name="environmentalTopicData"
                                      value={
                                        environmentalTopicData[4]?.answer                                                    
                                      }
                                      onChange={(e) => this.handleSupplierDetailsChange(e)}
                                      rows="3"
                                    ></textarea>
                                  </div>
                                </div>

                                {/* Make changes here */}
                                <div className={environmentalTopicData[4]?.answer || environmentalTopicData[4]?.answer ? "col-lg-6" : "col-lg-6 hideBox"}>
                                  <div  className="form-group bop">
                                  <label className="nature_one">
                                      {environmentalTopicData[5]?.question}
                                    </label>
                                    <Select
                                      value={environmentalTopicData[5]?.answer}
                                      id={5}
                                      name="environmentalTopicData"
                                      onChange={(e) => this.handleSupplierDetailsSelectChange(e)}
                                      options={options}
                                      isMulti
                                    />
                                  </div>
                                </div>
                                {/* <div className={environmentalTopicData[5]?.question || environmentalTopicData[5]?.question ? "col-lg-6" : "col-lg-6 hideBox"}>
                                  <div className="form-group bop">
                                    <label className="nature_one">
                                      {environmentalTopicData[5]?.question}
                                    </label>
                                   <div className="dataValue">
                                    {environmentalOptionData || environmentalOptionData ?
                                     <p>
                                       {environmentalOptionData[0]?.value}<br />
                                       {environmentalOptionData[1]?.value}<br />
                                       {environmentalOptionData[2]?.value}<br /> 
                                       {environmentalOptionData[3]?.value}<br /> 
                                       {environmentalOptionData[4]?.value}
                                     </p>
                                     : "None"
                                    }
                          
                                    </div>

                                  </div>

                                </div> */}
                                <div className="supplierSaveBtn2">
                                  <button
                                    id="savbtnMain2"
                                    className="link_bal_next page_width white "
                                    variant="none"
                                    onClick={()=> this.handleSupplierDetailsSave("environmentalTopicData", "environmentalTopic")}
                                  >
                                    Save
                                  </button>
                                </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-sm-12">
                      <div className="governance1 pb-0">
                        <div className="four_box_text">
                          <div className="Environmental">
                            <h4 className="Environmental_text">
                              Social Topics
                            </h4>
                          </div>
                          <div className="home_risckq">
                            <div className="row">
                            {socialTopicData.map((socialItems, key) => (
                                <div key={key} className="col-lg-6">
                                  <div className="form-group bop">
                                    <label className="nature_one">
                                      {socialItems.question}
                                    </label>
                                    <textarea
                                      className="form-control text_w"
                                      // readOnly
                                      id={key}
                                      name="socialTopicData"
                                      value={
                                        socialItems.answer
                                      }
                                      onChange={(e) => this.handleSupplierDetailsChange(e)}
                                      rows="3"
                                    ></textarea>
                                  </div>
                                </div>
                              ))}
                                <div className="supplierSaveBtn">
                                  <button
                                    id="savbtnMain3"
                                    className="link_bal_next page_width white "
                                    variant="none"
                                    onClick={()=> this.handleSupplierDetailsSave("socialTopicData", "socialTopic")}
                                  >
                                    Save
                                  </button>
                                </div>
                            </div>

                          </div>
         
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-sm-12">
                      <div className="governance pb-0 pt-2">
                        <div className="four_box_text">
                          <div className="Environmental">
                            <h4 className="Environmental_text">
                              Governance Topics
                            </h4>
                          </div>
                          <div className="home_risckq">
                            <div className="row">
                            {governanceTopicData.map((governanceItems, key) => (
                                <div key={key} className="col-lg-6">
                                  <div className="form-group bop">
                                    <label className="nature_one">
                                      {governanceItems.question}
                                    </label>
                                    <textarea
                                      className="form-control text_w"
                                      // readOnly
                                      id={key}
                                      name="governanceTopicData"
                                      value={
                                        governanceItems.answer
                                      }
                                      onChange={(e) => this.handleSupplierDetailsChange(e)}
                                      rows="3"
                                    ></textarea>
                                  </div>
                                </div>
                              ))}
                                <div className="supplierSaveBtn2">
                                  <button
                                    id="savbtnMain2"
                                    className="link_bal_next page_width white "
                                    variant="none"
                                    onClick={()=> this.handleSupplierDetailsSave("governanceTopicData", "governanceTopic")}
                                  >
                                    Save
                                  </button>
                                </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-sm-12">
                      <div className="governance pb-0 pt-2">
                        <div className="four_box_text">
                          <div className="Environmental">
                            <h4 className="Environmental_text">
                              Business Model
                            </h4>
                          </div>
                          <div className="home_risckq">
                            <div className="row">
                              {businessModelData.map((businessItems, key) => (
                                <div key={key} className="col-lg-6">
                                  <div className="form-group bop">
                                    <label className="nature_one">
                                      {businessItems.question}
                                    </label>
                                    <textarea
                                      className="form-control text_w"
                                      // readOnly
                                      id={key}
                                      name="businessModelData"
                                      value={
                                        businessItems.answer
                                      }
                                      onChange={(e) => this.handleSupplierDetailsChange(e)}
                                      rows="3"
                                    ></textarea>
                                  </div>
                                </div>
                              ))}
                                <div className="supplierSaveBtn">
                                  <button
                                    id="savbtnMain3"
                                    className="link_bal_next page_width white "
                                    variant="none"
                                    onClick={()=> this.handleSupplierDetailsSave("businessModelData", "businessModel")}
                                  >
                                    Save
                                  </button>
                                </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
