import React, { Component } from "react";

export default class social_consideration extends Component {
  render() {
    return (
      <div>
        {/* <!-- end panel 1 --> */}
        <div id="panel2" className="panel">
          <div className="table_well">
            <p className="actual">
              <strong>Human capital management:</strong> A sector's capacity to
              develop a long-lasting productive workforce while reducing
              potential operational disruptions from workforce mismanagement;
              diversity and inclusion attributes; exposure to strikes and the
              sector's general exposure to dealing with emerging skills scarcity
              or surplus labor.
            </p>
            <p className="actual">
              <strong>Changing consumer or user preferences:</strong> We
              recognize that changes in consumer behavior are often the result
              of complex dynamics, such as changes in technology or fashion or
              other disruptive business trends. Therefore, we treat a change in
              consumer preferences as a social factor related to sustainability,
              health, safety, the environment, privacy, financial mis-selling,
              or community and human rights, particularly when an entity has
              triggered the change.
            </p>
            <p className="actual">
              <strong>Demographic changes:</strong> Potential costs or
              opportunities related to population growth and composition, such
              as an aging population, urbanization, changing living standards,
              or a growing middle class.
            </p>
          </div>
        </div>
      </div>
    );
  }
}
