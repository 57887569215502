import React, { Component } from "react";
import AdminSidebar from "../../sidebar/admin_sidebar";
import AdminHeader from "../../header/admin_header";
import config from "../../../config/config.json";
import "../../Company Sub Admin/Pages/esg_reporting/esg_reporting.css";
import axios from "axios";
import { sweetAlert } from "../../../utils/UniversalFunction";

import { authenticationService } from "../../../_services/authentication";

const currentUser = authenticationService.currentUserValue;

export default class home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      uuid: "",
      isChecked: false,
      error: null,
      isLoaded: false,
      isLoaded2: false,
      isLoaded3: false,
      answersss: [],
      // selectedUser: [],
      questions: [],
      esgReportingQuestionsEnvironment: [],
      esgReportingQuestionsBusiness_model_innovation: [],
      esgReportingQuestionsCyber_digital: [],
      esgReportingQuestionsHuman_capital: [],
      esgReportingQuestionsLeadership_governance: [],
      esgReportingQuestionsSocial_capital: [],
      answers: [],
      selected: [],
      selected2: [],
      selected3: [],
      selected4: [],
      selected5: [],
      selected6: [],
      selected7: [],
      selectedUser: [],
      selectedUser2: [],
      selectedUser3: [],
      selectedUser4: [],
      selectedUser5: [],
      selectedUser6: [],
      selectedUser7: [],
    };
  }
  componentDidMount() {
    let urlArr = window.location.pathname.split("/");
    const finalUUId = urlArr[urlArr.length - 2];
    this.setState({
      uuid: urlArr[urlArr.length - 2],
    });

    const headers = {
      Authorization: `Bearer ${currentUser.data.token}`,
      Accept: "application/json",
    };
    //   Get Answers Api
    fetch(config.ADMIN_API_URL + `company/${finalUUId}/modules/esg-reporting`, {
      headers,
    })
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded3: true,
            answersss: result.esgReportingAnswers,
            selectedUser:
              result.esgReportingAnswers[0]?.frameworksUsed.split(",") ===
                undefined
                ? []
                : result.esgReportingAnswers[0]?.frameworksUsed.split(","),
            selectedUser2:
              result.esgReportingAnswers[0]?.businessModelAndInnovation.split(
                ","
              ) === undefined
                ? []
                : result.esgReportingAnswers[0]?.businessModelAndInnovation.split(
                  ","
                ),
            selectedUser3:
              result.esgReportingAnswers[0]?.cyberAndDigital.split(",") ===
                undefined
                ? []
                : result.esgReportingAnswers[0]?.cyberAndDigital.split(","),
            selectedUser4:
              result.esgReportingAnswers[0]?.environment.split(",") ===
                undefined
                ? []
                : result.esgReportingAnswers[0]?.environment.split(","),
            selectedUser5:
              result.esgReportingAnswers[0]?.humanCapital.split(",") ===
                undefined
                ? []
                : result.esgReportingAnswers[0]?.humanCapital.split(","),
            selectedUser6:
              result.esgReportingAnswers[0]?.leadershipAndGovernance.split(
                ","
              ) === undefined
                ? []
                : result.esgReportingAnswers[0]?.leadershipAndGovernance.split(
                  ","
                ),
            selectedUser7:
              result.esgReportingAnswers[0]?.socialCapital.split(",") ===
                undefined
                ? []
                : result.esgReportingAnswers[0]?.socialCapital.split(","),
          });
          //answersss  answersss[0]?.frameworksUsed.split(",")
        },
        (error) => {
          this.setState({
            isLoaded3: true,
            error,
          });
        }
      );

    fetch(config.API_URL + "getEsgReportingQuestions", { headers })
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded2: true,
            questions: result.esgReportingQuestionsFrameWorkUsed,
            esgReportingQuestionsEnvironment:
              result.esgReportingQuestionsEnvironment,
            esgReportingQuestionsBusiness_model_innovation:
              result.esgReportingQuestionsBusiness_model_innovation,
            esgReportingQuestionsCyber_digital:
              result.esgReportingQuestionsCyber_digital,
            esgReportingQuestionsHuman_capital:
              result.esgReportingQuestionsHuman_capital,
            esgReportingQuestionsLeadership_governance:
              result.esgReportingQuestionsLeadership_governance,
            esgReportingQuestionsSocial_capital:
              result.esgReportingQuestionsSocial_capital,
          });
        },
        (error) => {
          this.setState({
            isLoaded2: true,
            error,
          });
        }
      );
  }

  handleMultiSelect = (e, data) => {

    console.log("HEllo")
    // debugger
    const { checked } = e.target;
    if (checked) {
      this.setState({
        selectedUser: [...this.state.selectedUser, data],
      });
    } else {
      let tempuser = this.state.selectedUser?.filter(
        (item) => Number(item) !== Number(data)
      );
      this.setState({
        selectedUser: tempuser,
      });
    }
  };
  handleMultiSelect2 = (e, data) => {
    // debugger
    const { checked } = e.target;
    if (checked) {
      this.setState({
        selectedUser2: [...this.state.selectedUser2, data],
      });
    } else {
      let tempuser2 = this.state.selectedUser2?.filter(
        (item2) => Number(item2) !== Number(data)
      );
      this.setState({
        selectedUser2: tempuser2,
      });
    }
  };
  handleMultiSelect3 = (e, data) => {
    // debugger
    const { checked } = e.target;
    if (checked) {
      this.setState({
        selectedUser3: [...this.state.selectedUser3, data],
      });
    } else {
      let tempuser3 = this.state.selectedUser3?.filter(
        (item3) => Number(item3) !== Number(data)
      );
      this.setState({
        selectedUser3: tempuser3,
      });
    }
  };
  handleMultiSelect4 = (e, data) => {
    // debugger
    const { checked } = e.target;
    if (checked) {
      this.setState({
        selectedUser4: [...this.state.selectedUser4, data],
      });
    } else {
      let tempuser4 = this.state.selectedUser4?.filter(
        (item4) => Number(item4) !== Number(data)
      );
      this.setState({
        selectedUser4: tempuser4,
      });
    }
  };
  handleMultiSelect5 = (e, data) => {
    // debugger
    const { checked } = e.target;
    if (checked) {
      this.setState({
        selectedUser5: [...this.state.selectedUser5, data],
      });
    } else {
      let tempuser5 = this.state.selectedUser5?.filter(
        (item5) => Number(item5) !== Number(data)
      );
      this.setState({
        selectedUser5: tempuser5,
      });
    }
  };
  handleMultiSelect6 = (e, data) => {
    // debugger
    const { checked } = e.target;
    if (checked) {
      this.setState({
        selectedUser6: [...this.state.selectedUser6, data],
      });
    } else {
      let tempuser6 = this.state.selectedUser6?.filter(
        (item6) => Number(item6) !== Number(data)
      );
      this.setState({
        selectedUser6: tempuser6,
      });
    }
  };
  handleMultiSelect7 = (e, data) => {
    // debugger
    const { checked } = e.target;
    if (checked) {
      this.setState({
        selectedUser7: [...this.state.selectedUser7, data],
      });
    } else {
      let tempuser7 = this.state.selectedUser7?.filter(
        (item7) => Number(item7) !== Number(data)
      );
      this.setState({
        selectedUser7: tempuser7,
      });
    }
  };

  handleSaveOptions = async (e) => {
    e.preventDefault();
    const { uuid } = this.props.match.params;

    const payload = {
      uuid: uuid,
      frameworksUsed: this.state.selectedUser.filter(item => item !== '').join(","),
      environment: this.state.selectedUser4.filter(item => item !== '').join(","),
      socialCapital: this.state.selectedUser7.filter(item => item !== '').join(","),
      leadershipAndGovernance: this.state.selectedUser6.filter(item => item !== '').join(","),
      humanCapital: this.state.selectedUser5.filter(item => item !== '').join(","),
      cyberAndDigital: this.state.selectedUser3.filter(item => item !== '').join(","),
      businessModelAndInnovation: this.state.selectedUser2.filter(item => item !== '').join(","),
    }

    try {
      const headers = {
        Authorization: `Bearer ${currentUser.data.token}`,
        Accept: "application/json",
      };
      await axios.put(`${config.ADMIN_API_URL}report/updateReporting`, payload, { headers: headers });
      sweetAlert('success', "Updated Successfully");
    } catch (error) {
      sweetAlert('error', "Oops some error occured");
      // show error message
      console.log(error.message)
    }

  }

  render() {
    const {
      questions,
      esgReportingQuestionsEnvironment,
      esgReportingQuestionsBusiness_model_innovation,
      esgReportingQuestionsCyber_digital,
      esgReportingQuestionsHuman_capital,
      esgReportingQuestionsLeadership_governance,
      esgReportingQuestionsSocial_capital,
      selectedUser,
      selectedUser2,
      selectedUser3,
      selectedUser4,
      selectedUser5,
      selectedUser6,
      selectedUser7,
    } = this.state;
    return (
      <>
        <AdminSidebar dataFromParent={this.props.location.pathname} />
        <AdminHeader />
        <div className="main_wrapper">
          <div className="inner_wraapper">
            <div className="container-fluid">
              <section className="d_text">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-sm-12">
                      <form name="form" onSubmit={this.handleSubmit}>
                        <div className="Introduction">
                          <div className="text_Parts">
                            <div className="d-flex justify-content-between mb-3">
                              <h5 className="motor">Introduction</h5>
                            </div>
                            <p className="specialize">
                              Environmental, Social and Governance (ESG) is a
                              both a framework and a methodology to improve
                              financial and non-financial performance. This
                              criteria captures and critiques all aspects of a
                              business beyond business plans and financial
                              reporting, aimed at improving financial
                              performance, while reducing operating risks. ESG
                              is becoming a critical business management tool
                              impacting debt funding rates, credit ratings and
                              fund manager interest from the equity investment
                              market, with better ESG strategy directly
                              correlated to improved share price performance.{" "}
                            </p>
                          </div>
                        </div>
                        <div className="frameworks framwork_2">
                          <div className="text_Parts">
                            <div className="text_ntroion">
                              <h5 className="frame">
                                Please select which ESG reporting frameworks you
                                have used in the past:
                              </h5>
                              <div className="Global">
                                <div className="row">
                                  <div className="col-sm-12 col-xs-12">
                                    <div className="wel_fel">
                                      {questions.map((item, key) => (
                                        <div className="Global_text">
                                          <div className="form-check form-check-inline clobal_check">
                                            <input
                                              className="form-check-input input_one "
                                              name="frameworksUsed[]"
                                              // disabled
                                              onChange={(e) =>
                                                this.handleMultiSelect(
                                                  e,
                                                  item.id
                                                )
                                              }
                                              checked={selectedUser?.some(
                                                (frameworkiuse) =>
                                                  Number(frameworkiuse) ===
                                                  item.id
                                              )}
                                              type="checkbox"
                                              id={"frameworks" + (key + 1)}
                                            />
                                            <label
                                              className="form-check-label label_one"
                                              htmlFor="inlineCheckbox1"
                                            >
                                              {item.description}
                                            </label>
                                          </div>
                                        </div>

                                      ))}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="mt-4">
                          <div className="col-sm-12">
                            <div className="Below_demo">
                              <div className="text_Parts">
                                <div className="text_Below_one">
                                  <p className="Below">
                                    Below we have listed a range of potential
                                    risks that may impact your business. Select
                                    which are appropriate to you so we can
                                    better understand how to improve your
                                    performance.{" "}
                                  </p>
                                  <div className="Below_text">
                                    <div className="row">
                                      <div className="col-xxl-4 col-lg-12 col-md-12 col-12">
                                        <div className="better_dic">
                                          <div className="golp"></div>
                                          <div className="Emissions mb-4">
                                            <h4 className="ronment">
                                              Environment
                                            </h4>
                                          </div>
                                          {esgReportingQuestionsEnvironment.map(
                                            (item2, key2) => (
                                              <div className="Global_text">
                                                <div className="form-check form-check-inline clobal_check">
                                                  <input
                                                    className="form-check-input input_one"
                                                    type="checkbox"
                                                    // disabled
                                                    name="environment[]"
                                                    onChange={(e) =>
                                                      this.handleMultiSelect4(
                                                        e,
                                                        item2.id
                                                      )
                                                    }
                                                    checked={selectedUser4?.some(
                                                      (eenvironment) =>
                                                        Number(eenvironment) ===
                                                        item2.id
                                                    )}
                                                    id="inlineCheckbox1"
                                                  />
                                                  <label
                                                    className="form-check-label label_one"
                                                    htmlFor="inlineCheckbox1"
                                                  >
                                                    {item2.description}
                                                  </label>
                                                </div>
                                              </div>
                                            )
                                          )}
                                        </div>
                                      </div>
                                      <div className="col-xxl-4 col-lg-12 col-md-12 col-12">
                                        <div className="better_dic">
                                          <div className="golp"></div>
                                          <div className="Emissions mb-4">
                                            <h4 className="ronment">
                                              Social Capital
                                            </h4>
                                          </div>

                                          {esgReportingQuestionsSocial_capital.map(
                                            (item3, key3) => (
                                              <div className="Global_text">
                                                <div className="form-check form-check-inline clobal_check">
                                                  <input
                                                    className="form-check-input input_one"
                                                    name="social_capital[]"
                                                    // disabled
                                                    onChange={(e) =>
                                                      this.handleMultiSelect7(
                                                        e,
                                                        item3.id
                                                      )
                                                    }
                                                    checked={selectedUser7?.some(
                                                      (eenvironment) =>
                                                        Number(eenvironment) ===
                                                        item3.id
                                                    )}
                                                    type="checkbox"
                                                    id="inlineCheckbox8"
                                                  />
                                                  <label
                                                    className="form-check-label label_one"
                                                    htmlFor="inlineCheckbox8"
                                                  >
                                                    {item3.description}
                                                  </label>
                                                </div>
                                              </div>
                                            )
                                          )}
                                        </div>
                                      </div>
                                      <div className="col-xxl-4 col-lg-12 col-md-12 col-12">
                                        <div className="Emissions mb-4">
                                          <h4 className="ronment">
                                            Leadership & Governance
                                          </h4>
                                        </div>

                                        {esgReportingQuestionsLeadership_governance.map(
                                          (item4, key4) => (
                                            <div className="Global_text">
                                              <div className="form-check form-check-inline clobal_check">
                                                <input
                                                  className="form-check-input input_one"
                                                  type="checkbox"
                                                  // disabled
                                                  name="leadership_governance[]"
                                                  onChange={(e) =>
                                                    this.handleMultiSelect6(
                                                      e,
                                                      item4.id
                                                    )
                                                  }
                                                  checked={selectedUser6?.some(
                                                    (eenvironment) =>
                                                      Number(eenvironment) ===
                                                      item4.id
                                                  )}
                                                  id="flexCheckChecked14"
                                                />
                                                <label
                                                  className="form-check-label label_one"
                                                  htmlFor="inlineCheckbox14"
                                                >
                                                  {item4.description}
                                                </label>
                                              </div>
                                            </div>
                                          )
                                        )}
                                      </div>
                                    </div>
                                    <hr className="Ethics" />
                                    {/* <!-- Human Capital --> */}
                                    <div className="row">
                                      <div className="col-xxl-4 col-lg-4 col-md-4 col-4">
                                        <div className="Emissions mb-4">
                                          <h4 className="ronment">
                                            Human Capital
                                          </h4>
                                        </div>

                                        {esgReportingQuestionsHuman_capital.map(
                                          (item5, key5) => (
                                            <div className="Global_text">
                                              <div className="form-check form-check-inline clobal_check">
                                                <input
                                                  className="form-check-input input_one"
                                                  name="human_capital[]"
                                                  // disabled
                                                  onChange={(e) =>
                                                    this.handleMultiSelect5(
                                                      e,
                                                      item5.id
                                                    )
                                                  }
                                                  checked={selectedUser5?.some(
                                                    (eenvironment) =>
                                                      Number(eenvironment) ===
                                                      item5.id
                                                  )}
                                                  type="checkbox"
                                                  id="inlineCheckbox19"
                                                />
                                                <label
                                                  className="form-check-label label_one"
                                                  htmlFor="inlineCheckbox19"
                                                >
                                                  {item5.description}
                                                </label>
                                              </div>
                                            </div>
                                          )
                                        )}
                                      </div>
                                      <div className="ccol-xxl-4 col-lg-4 col-md-4 col-4">
                                        <div className="Emissions mb-4">
                                          <h4 className="ronment">
                                            Business Model & Innovation
                                          </h4>
                                        </div>

                                        {esgReportingQuestionsBusiness_model_innovation.map(
                                          (item6, key6) => (
                                            <div className="Global_text">
                                              <div className="form-check form-check-inline clobal_check">
                                                <input
                                                  className="form-check-input input_one"
                                                  name="business_model_innovation[]"
                                                  // disabled
                                                  onChange={(e) =>
                                                    this.handleMultiSelect2(
                                                      e,
                                                      item6.id
                                                    )
                                                  }
                                                  checked={selectedUser2?.some(
                                                    (eenvironment) =>
                                                      Number(eenvironment) ===
                                                      item6.id
                                                  )}
                                                  type="checkbox"
                                                  id="inlineCheckbox8"
                                                />{" "}
                                                <label
                                                  className="form-check-label label_one"
                                                  htmlFor="inlineCheckbox23"
                                                >
                                                  {item6.description}
                                                </label>
                                              </div>
                                            </div>
                                          )
                                        )}
                                      </div>
                                      <div className="col-xxl-4 col-lg-4 col-md-4 col-4">
                                        <div className="Emissions mb-4">
                                          <h4 className="ronment">
                                            Cyber & Digital
                                          </h4>
                                        </div>
                                        {esgReportingQuestionsCyber_digital.map(
                                          (item7, key7) => (
                                            <div className="Global_text">
                                              <div className="form-check form-check-inline clobal_check">
                                                <input
                                                  className="form-check-input input_one"
                                                  name="cyber_digital[]"
                                                  // disabled
                                                  onChange={(e) =>
                                                    this.handleMultiSelect3(
                                                      e,
                                                      item7.id
                                                    )
                                                  }
                                                  checked={selectedUser3?.some(
                                                    (eenvironment) =>
                                                      Number(eenvironment) ===
                                                      item7.id
                                                  )}
                                                  type="checkbox"
                                                  id="inlineCheckbox30"
                                                />
                                                <label
                                                  className="form-check-label label_one"
                                                  htmlFor="inlineCheckbox30"
                                                >
                                                  {item7.description}
                                                </label>
                                              </div>
                                            </div>
                                          )
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="savbtnCont">                                                    
                                <button
                                  id="savbtnMain"
                                  className="link_bal_next page_width white " 
                                  variant="none"
                                  onClick={this.handleSaveOptions}
                                >
                                  Save
                                </button>
                            </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </>
    );
  }
}
