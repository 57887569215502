import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import config from "../../../config/config.json";
import axios from "axios";
import {
  sweetAlert,
  checkPasswordValidation,
} from "../../../utils/UniversalFunction";
import AdminSidebar from "../../sidebar/admin_sidebar";
import AdminHeader from "../../header/admin_header";
import { authenticationService } from "../../../_services/authentication";

import "../../Company Admin/Setting/setting.css";
const ProfilePics =
  "https://res.cloudinary.com/dmklsntsw/image/upload/v1658480882/dummyPic.75a04487_fqfqey.png";
// const baseURL = config.baseURL;
const currentUser = authenticationService.currentUserValue;
export default class SettingDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      login: false,
      isLoaded: false,
      firstName: "",
      lastName: "",
      email: "",
      logo: "",
      id: "",
      password: "",
      type: "password",
      passwordValidation: false,
      passwordValidationMessage: "",
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.serverRequest = this.serverRequest.bind(this);
    this.showHide = this.showHide.bind(this);
  }
  showHide(e) {
    e.preventDefault();
    e.stopPropagation();
    this.setState({
      type: this.state.type === "password" ? "input" : "password",
    });
  }

  handleChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    if (name === "password") {
      let condition = checkPasswordValidation(value);
      if (condition === true) {
        this.setState({
          passwordValidation: true,
          passwordValidationMessage: "",
        });
      } else {
        this.setState({
          passwordValidation: false,
          passwordValidationMessage: condition,
        });
      }
    }
    this.setState({
      [name]: value,
    });
  }

  handleSubmit(event) {
    event.preventDefault();
    this.setState({ submitted: true });
    const {
      firstName,
      lastName,
      email,
      password,
      passwordValidation,
      passwordValidationMessage,
    } = this.state;
    const headers = {
      Authorization: `Bearer ${currentUser.data.token}`,
      Accept: "application/json",
    };

    if (passwordValidation) {
      let userEntity = currentUser;
      let updateName = `${firstName} ${lastName}`;
      userEntity.data.name = updateName;
      localStorage.setItem("currentUser", JSON.stringify(userEntity));

      axios
        .post(
          config.API_URL + "updateProfile",
          {
            first_name: firstName,
            last_name: lastName,
            email: email,
            password: password,
          },
          { headers }
        )
        .then((response) => {
          sweetAlert("success", response.data.message);

          setTimeout(() => {
            const newLocal = "/profile";
            this.props.history.push(newLocal);
          }, 1000);
        })
        .catch(function (error) {
          if (error.response) {
            sweetAlert("error", error.response.data.message);
          }
        });
    } else {
      sweetAlert("error", passwordValidationMessage);
    }
  }

  onFileChange = (event) => {
    // let id = event.target.getAttribute("data-id");
    const formData = new FormData();
    // Update the formData object
    formData.append(
      "uploadImage",
      event.target.files[0],
      event.target.files[0].name
    );

    const headers = {
      Authorization: `Bearer ${currentUser.data.token}`,
      Accept: "application/json",
    };
    axios
      .post(config.API_URL + "uploadProfilePicture", formData, { headers })
      .then((response) => {
        sweetAlert("success", response.data.message);
        this.setState({ logo: response.data.data });
        this.serverRequest();
      })
      .catch(function (response) {
        sweetAlert("error", response.data.message);
      });
  };

  serverRequest() {
    const headers = {
      Authorization: `Bearer ${currentUser.data.token}`,
      Accept: "application/json",
    };

    fetch(config.ADMIN_API_URL + "settings", { headers })
      .then((res) => res.json())
      .then(
        (data) => {
          // console.log("Hello", data.result[0]?.logo)
          // console.log("data");
          this.setState({
            isLoaded: true,
            firstName: data.result[0]?.firstname,
            lastName: data.result[0]?.lastname,
            email: data.result[0]?.email,
            logo:
              data.result1[0]?.logo === null
                ? ProfilePics
                : config.BASE_URL + data.result1[0]?.logo,
          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error,
          });
        }
      );
  }
  componentDidMount() {
    this.serverRequest();
  }
  render() {
    const { firstName, lastName, email, logo, id, type } = this.state;

    return (
      <div>
        <AdminSidebar dataFromParent={this.props.location.pathname} />
        <AdminHeader />
        <div className="main_wrapper">
          <div className="inner_wraapper">
            <div className="container-fluid">
              <section className="d_text">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="strp_progess">
                        <div className="hol_rell">
                          <div className="steps-form">
                            <div className="steps-row setup-panel">
                              <div className="tabs-top setting_admin setting_tab_d my-0">
                                <ul>
                                  <li>
                                    <NavLink to="/profile" className="activee">
                                      My Profile
                                    </NavLink>
                                  </li>
                                  <li>
                                    <NavLink to="/billing">Billing</NavLink>
                                  </li>
                                  <li>
                                    <NavLink to="/2fa">Two Factor Auth</NavLink>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="SVG Stepper">
                        <div className="stepperr_design">
                          <div className="color_div_step div-color">
                            <div className="include">
                              <section className="forms">
                                <div className="row">
                                  <div className="col-md-8 col-xs-12">
                                    <form
                                      name="form"
                                      onSubmit={this.handleSubmit}
                                    >
                                      <div className="business_detail">
                                        <div className="heading">
                                          <h4>Admin Details</h4>
                                        </div>
                                        <hr className="line"></hr>

                                        <div className="row">
                                          <div className="col-lg-6 col-xs-6">
                                            <div className="form-group pb-3">
                                              <label htmlFor="exampleInputPassword1">
                                                First Name
                                              </label>
                                              <input
                                                type="text"
                                                className="form-control"
                                                id="exampleInputPassword1"
                                                placeholder="First Name"
                                                name="firstName"
                                                onChange={this.handleChange}
                                                value={firstName}
                                              />
                                            </div>
                                          </div>
                                          <div className="col-lg-6 col-xs-6">
                                            <div className="form-group pb-3">
                                              <label htmlFor="exampleInputPassword1">
                                                Last Name
                                              </label>
                                              <input
                                                type="text"
                                                className="form-control"
                                                id="exampleInputPassword1"
                                                placeholder="Last name"
                                                name="lastName"
                                                onChange={this.handleChange}
                                                value={lastName}
                                              />
                                            </div>
                                          </div>
                                        </div>
                                        <div className="form-group pb-3">
                                          <label htmlFor="exampleInputEmail1">
                                            Corporate Email
                                          </label>
                                          <input
                                            type="email"
                                            className="form-control disableddd"
                                            id="exampleInputPassword1"
                                            placeholder="Email Address"
                                            disabled
                                            name="email"
                                            onChange={this.handleChange}
                                            value={email}
                                          />
                                        </div>
                                        <div className="form_sign password-eye">
                                          <div className="img-eye">
                                            <span onClick={this.showHide}>
                                              {this.state.type === "input" ? (
                                                <i className="fas fa-eye-slash"></i>
                                              ) : (
                                                <i className="fas fa-eye"></i>
                                              )}
                                            </span>
                                          </div>
                                          <div>
                                            <label
                                              className="st_name"
                                              htmlFor="name"
                                            >
                                              Password
                                            </label>
                                            <input
                                              className="form-control name_nf"
                                              type={type}
                                              name="password"
                                              id="exampleInputPassword1"
                                              placeholder="Enter your password"
                                              onChange={this.handleChange}
                                            />
                                            {this.state.passwordValidation ===
                                              false && (
                                              <div className="help-block">
                                                {
                                                  this.state
                                                    .passwordValidationMessage
                                                }
                                              </div>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                      <div className="global_link mx-0 my-3">
                                        <button
                                          type="submit"
                                          className="page_width page_save"
                                        >
                                          SAVE
                                        </button>
                                      </div>
                                    </form>
                                  </div>
                                  <div className="col-lg-4 col-xs-12">
                                    <div className="upload_image">
                                      <img
                                        className="file-upload-image"
                                        src={logo}
                                        alt=""
                                      />
                                      <input
                                        type="file"
                                        accept=".jpg, .png, .jpeg"
                                        name="uploadImage"
                                        data-id={id}
                                        onChange={this.onFileChange}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </section>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
