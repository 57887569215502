import React, { Component } from "react";
import { Button, Modal } from "react-bootstrap";
import "../Setting/billing.css";
import {sweetAlert} from '../../../utils/UniversalFunction'
import axios from "axios";
import config from "../../../config/config.json";
import { profileService } from "../../../_services/admin/settingService";
import CompaniesTabbing from "../companies/companies_tabbing";
import BillingFeatures from "../Setting/BillingFeatures";
import AdminSidebar from "../../sidebar/admin_sidebar";
import AdminHeader from "../../header/admin_header";
import { authenticationService } from "../../../_services/authentication";
const currentUser = authenticationService.currentUserValue;

export default class Plans extends Component {
  constructor(props) {
    super(props);
    this.state = {
      login: false,
      activeModal: "",
      isLoaded: false,
      items: [],
      users:[],
      id:[],
      title: "",
      no_of_users: "",
      price: "",
      tier: "",
      uuid: "",
      user_id:"",
      values: [{ value: null }],
      isChecked:true,
    };
    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.callApi = this.callApi.bind(this);
    this.callApi2 = this.callApi2.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange2 = this.handleChange2.bind(this);
    this.serverRequest = this.serverRequest.bind(this);
    this.getcompanyuserdetails = this.getcompanyuserdetails.bind(this);
  }

  getcompanyuserdetails(){
    let uri = window.location.pathname.split("/");
    let uuid = uri[2];
    const headers = {
      Authorization: `Bearer ${currentUser.data.token}`,
      Accept: "application/json",
    };
    let parms = `company/${uuid}`;
    fetch(config.ADMIN_API_URL + parms, {headers})
      .then((res) => res.json())
      .then(
        (data) => {
          
          this.setState({
            id: data.data[0]?.id,
            users: data.data[0]?.no_of_users,
          });
        },
        (error) => {}
      );
  }

  subscriptionPlanStatus = (event) => {
    let isChecked = event.target.checked;
    const headers = {
      Authorization: `Bearer ${currentUser.data.token}`,
      Accept: "application/json",
    };
    let isActiveVal;
    if (isChecked === true) {
      isActiveVal = 1;
    } else {
      isActiveVal = 0;
    }
    axios.post(`${config.ADMIN_API_URL}billing/subscriptionPlanStatus/update`,
        {
          isActive: isActiveVal,
          user_id: this.state.id,
        },
        {
          headers,
        }
      )
      .then((response) => {
        sweetAlert('success',response.data.message);	
        this.setState({ isChecked: isActiveVal === 1 ? true : false });
        console.log("response", response);
      })
      
      .catch(function (response) {
        sweetAlert('error',response.data.message);
      });
  };

  createUI() {
    return this.state.values.map((el, i) => (
      <div key={i}>
        <input
          type="text"
          value={el.value || ""}
          onChange={this.handleChangee.bind(this, i)}
        />
        <input
          type="button"
          value="remove"
          onClick={this.removeClick.bind(this, i)}
        />
      </div>
    ));
  }

  handleChangee(i, event) {
    let values = [...this.state.values];
    values[i].value = event.target.value;
    this.setState({ values });
  }

  addClick() {
    this.setState((prevState) => ({
      values: [...prevState.values, { value: null }],
    }));
  }

  removeClick(i) {
    let values = [...this.state.values];
    values.splice(i, 1);
    this.setState({ values });
  }

  handleOpenModal(val) {
    this.setState({ activeModal: val });
    this.setState({ showModal: true });
  }

  handleCloseModal() {
    this.setState({ showModal: false });
    this.setState({
      showModal: "",
      title: "",
      price: "",
      tier: "",
    });
  }

  handleChange2(event) {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  }

  handleChange(event) {
    const { checked } = event.target;
    let id = event.target.getAttribute("data-id");
    let obj = {};
    obj._id = id;
    obj.status = checked;
    this.callApi(obj);
    setTimeout(() => {
      this.callApi2();
    }, 1000);
  }

  callApi(data) {
    profileService.updateSubscriptionStatus(data);
  }

  async callApi2() {
    return await profileService.getSpecificSubscriptionsPlans();
  }

  // async componentDidMount() {
  //   const response = await this.callApi2();
  //   this.setState({ items: response?.data });
  // }

  async serverRequest() {
    const response = await this.callApi2();
    this.setState({ items: response?.data });
  }

  
  handleSubmit(event) {
    event.preventDefault();
    this.setState({ submitted: true });
    let uri = window.location.pathname.split("/");
    let uuid = uri[2];
    let string = "";
    let ii = 1;
    this.state.values.forEach((element) => {
      if (this.state.values.length === ii) {
        string += element.value;
      } else {
        string += element.value + ",";
      }
      ii++;
    });
    const { title, price, tier, no_of_users, currency } = this.state;
    let obj = {};
    obj.name = title;
    obj.no_of_users = no_of_users;
    obj.amount = price;
    obj.plan = tier;
    obj.metaData = string;
    obj.uuid = uuid;
    obj.currency = currency;
    profileService.createSpecificPlan(obj);
    setTimeout(() => {
      window.location.reload();
    }, 2000);
  }

  componentDidMount() {
    this.getcompanyuserdetails();
    let uri = window.location.pathname.split("/");
    let uuid = uri[2];
    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${currentUser.data.token}`,
        "Content-Type": "application/json",
      },
    };
    fetch(config.ADMIN_API_URL + `billing/SpecificSubscriptions/${uuid}`, requestOptions)
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded2: true,
            items: result.data,
          });
          console.log("result", result);
        },

        (error2) => {
          this.setState({
            isLoaded2: true,
            error2,
          });
        }
      );
  }

  render() {
    const { items } = this.state;
    return (
      <div>
        <AdminSidebar dataFromParent={this.props.location.pathname} />
        <AdminHeader />
        <div className="main_wrapper">
          <div className="inner_wraapper">
            <div className="container-fluid">
              <section className="d_text">
                <div className="container-fluid">
                  <div className="row">
                  <CompaniesTabbing />
                    <div className="col-sm-12">
                      <div className="strp_progess">
                        <div className="hol_rell">
                          <div className="steps-form">
                            <div className="steps-row setup-panel">
                              
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="SVG Stepper">
                        <div className="stepperr_design">
                          <div className="color_div_step div-color">
                            <div className="include">
                              <section className="forms">
                                <div className="row">
                                  <div className="col-md-12 col-xs-12">
                                    <div className="business_detail">
                                      <div className="heading pb-5">
                                        <h4>Subscription Plan</h4>
                                        <form>
                                          <div className="form-group">
                                            <select
                                              className="form-control select_subscription"
                                              id="exampleFormControlSelect1"
                                              placeholder="Monthly Plans"
                                            >
                                              <option>Monthly Plans</option>
                                              <option>$185/ Month</option>
                                              <option>$150/ Month</option>
                                              <option>POA</option>
                                            </select>
                                          </div>
                                        </form>
                                      </div>
                                      <div className="subscription_plan mb-5">
                                      <h5>Global Subscription Plan Status</h5>
                                      <label className="switch">
                                        <input type="checkbox"  onChange={(e) =>
                                          this.subscriptionPlanStatus(e)
                                        } checked={this.state.isChecked}/>
                                        
                                        <span className="slider round"></span>
                                      </label>
                                      </div>
                                      <div className="cards">
                                        <div className="row justify-content-center">
                                          {items.map((item, key) => (
                                            <div
                                              key={key}
                                              className="col-xl-3 col-md-6 col-xs-12"
                                            >
                                              <div className="card-1">
                                                <span className="tag">
                                                  {item.title}
                                                </span>
                                                <h4>
                                                  {item?.currency === "dkk"? "kr" : "$"}
                                                  {item.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}/{" "}
                                                  <span>{item.tier}</span>
                                                </h4>
                                                <span className="tag my-3">No of Users: {item.no_of_users}</span>
                                                <h6>Features Included:</h6>
                                                <div className="data_card-1">
                                                  <ul>
                                                    <BillingFeatures
                                                      data={item.metaData}
                                                    />
                                                  </ul>
                                                </div>
                                                <div className="custom-switch">
                                                  <label className="switch">
                                                    <input
                                                      type="checkbox"
                                                      {...(item.isActive
                                                        ? { checked: true }
                                                        : {})}
                                                      data-id={item.id}
                                                      onChange={(e) =>
                                                        this.handleChange(e)
                                                      }
                                                    />
                                                    <span className="slider round"></span>
                                                  </label>
                                                </div>
                                              </div>
                                            </div>
                                          ))}

                                          <div className="global_link w-100 my-5 d-flex justify-content-center">
                                            <button
                                              className="page_width page_save"
                                              variant="none"
                                              onClick={() =>
                                                this.handleOpenModal("login")
                                              }
                                            >
                                              {" "}
                                              ADD NEW SUBSCRIPTION PLAN{" "}
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </section>
                              <Modal
                                animation={true}
                                size="md"
                                className="modal_box"
                                shadow-lg="border"
                                show={
                                  this.state.showModal &&
                                  this.state.activeModal === "login"
                                }
                              >
                                <div className="modal-lg p-4 header add_subscription">
                                  <Modal.Header className="pb-0 pb3">
                                  <h4 className="p-0">
                                            Add Subscription Plan
                                          </h4>
                                    <Button
                                      variant="outline-dark"
                                      onClick={this.handleCloseModal}
                                    >
                                      <i className="fa fa-times"></i>
                                    </Button>

                                  </Modal.Header>
                                  <div className="modal-body vekp pt-0">
                                    <div className="row">
                                      <div className="col-md-12">
                                        <div className="pb3">
                                          <div className="forms">
                                            <form onSubmit={this.handleSubmit}>
                                              <div className="form-group">
                                                <label
                                                  htmlFor="exampleFormControlInput1"
                                                  className="form-label"
                                                >
                                                  Subscription Plan Name
                                                </label>
                                                <input
                                                  type="text"
                                                  className="form-control"
                                                  id="exampleInputEmail1"
                                                  aria-describedby="emailHelp"
                                                  placeholder="Subscription Plan Name"
                                                  required
                                                  name="title"
                                                  value={this.state.title}
                                                  onChange={(e) =>
                                                    this.handleChange2(e)
                                                  }
                                                />
                                              </div>
                                              <div className="row my-3">
                                                <div className="col-md-6 col-xs-12">
                                                  <div className="form-group">
                                                    <label
                                                      htmlFor="exampleFormControlInput1"
                                                      className="form-label"
                                                    >
                                                      Price/User
                                                    </label>
                                                    <input
                                                      type="number"
                                                      className="form-control"
                                                      id="exampleInputEmail1"
                                                      aria-describedby="emailHelp"
                                                      placeholder="Enter Amount"
                                                      name="price"
                                                      required
                                                      value={this.state.price}
                                                      onChange={(e) =>
                                                        this.handleChange2(e)
                                                      }
                                                    />
                                                  </div>
                                                </div>
                                                <div className="col-md-6 col-xs-12">
                                                  <div className="form-group mt-2">
                                                    <label htmlFor="exampleFormControlSelect1"></label>
                                                    <select
                                                      className="form-control biling-menu"
                                                      id="exampleFormControlSelect1"
                                                      name="currency"
                                                      required
                                                      onChange={(e) =>
                                                        this.handleChange2(e)
                                                      }
                                                    >
                                                      <option>Select Currency</option>
                                                      <option value={"uds"}>
                                                        USD
                                                      </option>
                                                      <option value={"dkk"}>
                                                        DKK
                                                      </option>
                                                    </select>
                                                  </div>
                                                </div>

                                                <div className="col-md-6 col-xs-12">
                                                  <div className="form-group mt-2">
                                                    <label htmlFor="exampleFormControlSelect1"></label>
                                                    <select
                                                      className="form-control biling-menu"
                                                      id="exampleFormControlSelect1"
                                                      name="tier"
                                                      required
                                                      onChange={(e) =>
                                                        this.handleChange2(e)
                                                      }
                                                    >
                                                      <option>Select Plan Type</option>
                                                      <option value={"year"}>
                                                        Yearly
                                                      </option>
                                                      <option value={"month"}>
                                                        Monthly
                                                      </option>
                                                      <option value={"week"}>
                                                        Weekly
                                                      </option>
                                                      <option value={"day"}>
                                                        Daily
                                                      </option>
                                                    </select>
                                                  </div>
                                                </div>
                                                <div className="col-md-6 col-xs-12">
                                                  <div className="form-group mt-2">
                                                    <label htmlFor="exampleFormControlSelect1"></label>
                                                    <select
                                                      className="form-control biling-menu"
                                                      id="exampleFormControlSelect1"
                                                      name="no_of_users"
                                                      required
                                                      onChange={(e) =>
                                                        this.handleChange2(e)
                                                      }
                                                    >
                                                      <option>Select No of Users</option>
                                                      <option value={this.state.users}>
                                                      {this.state.users}
                                                      </option>
                                                    </select>
                                                  </div>
                                                </div>
                                              </div>

                                              <div className="form-group">
                                                <label
                                                  htmlFor="exampleFormControlInput1"
                                                  className="form-label w-100"
                                                >
                                                  Add Services Included
                                                </label>
                                                {this.state.values.map(
                                                  (el, i) => (
                                                    <div
                                                      className="d-flex removeMeta"
                                                      key={i}
                                                    >
                                                      <input
                                                        type="text"
                                                        className="form-control biling-menu"
                                                        placeholder="Write Feature"
                                                        // value={el.value || ""}
                                                        onChange={(e) =>
                                                          this.handleChangee(
                                                            i,
                                                            e
                                                          )
                                                        }
                                                        required
                                                      />
                                                      <i
                                                        className="fa fa-trash"
                                                        value="remove"
                                                        onClick={() =>
                                                          this.removeClick(i)
                                                        }
                                                      ></i>
                                                    </div>
                                                  )
                                                )}
                                                <input
                                                  type="button"
                                                  className="btn btn-primary page-wid add_b"
                                                  value="+ Add More"
                                                  onClick={() =>
                                                    this.addClick()
                                                  }
                                                />
                                              </div>

                                              <div className="global_link mt-3 mx-0">
                                                <button
                                                  type="button"
                                                  className="link_bal_next"
                                                  onClick={
                                                    this.handleCloseModal
                                                  }
                                                >
                                                  Cancel
                                                </button>
                                                <button
                                                  type="submit"
                                                  className="page_save page_width mx-3"
                                                >
                                                  Save
                                                </button>
                                              </div>
                                            </form>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Modal>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
