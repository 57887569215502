/* eslint-disable react/jsx-no-target-blank */
import React, { Component } from "react";
import AdminSidebar from "../../sidebar/admin_sidebar";
import AdminHeader from "../../header/admin_header";
import axios from "axios";
import { sweetAlert } from "../../../utils/UniversalFunction";
import config from "../../../config/config.json";
import { authenticationService } from "../../../_services/authentication";
import { Spinner } from 'react-bootstrap';

const BackendBaseUrl = config.BASE_URL;
const currentUser = authenticationService.currentUserValue;

export default class annualReportPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            uuid: "",
            error: null,
            isLoaded: false,
            report: [],
            industryID: [],
            description: "",
            loading: false,
            file: null,
            uploadLoad: false
        };
        this.getUploadedReportData = this.getUploadedReportData.bind(this);
        this.serverRequest = this.serverRequest.bind(this);
    }


    uploadReport = async (event) => {

        const { uuid } = this.props.match.params;
        this.setState({uploadLoad: true});

        const formData = new FormData();
        formData.append(
            "report",
            event.target.files[0],
            event.target.files[0].name
        );

        formData.append("section_name", "anual_report");
        formData.append("sub_section", "anual_report");


        const headers = {
            Authorization: `Bearer ${currentUser.data.token}`,
            Accept: "application/json",
        };

        axios.post(`${config.ADMIN_API_URL}company/${uuid}/annual_report`, formData, { headers })
            .then((response) => {
                sweetAlert("success", "Report uploaded successfully!");
                this.serverRequest();
                this.setState({uploadLoad: false});
            })
            .catch(function (response) {
                sweetAlert("error", response.data.message);
                this.setState({uploadLoad: false});
            });
    }

    getUploadedReportData = () => {

        const { uuid } = this.props.match.params;

        const headers = {
            Authorization: `Bearer ${currentUser.data.token}`,
            Accept: "application/json",
        };

        fetch(
            `${config.ADMIN_API_URL}company/downloadAnnualReport/${uuid}`,
            {
                headers,
            }
        )
            .then((res) => res.json())
            .then(
                (result) => {
                    if (result?.data?.file) {
                        this.setState({
                            file: result.data.file,
                        });
                    }
                },
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error,
                    });
                }
            );
        // company/:uuid/sector-question/download/report
    };

    serverRequest = () => {
        this.getUploadedReportData();
    };

    componentDidMount() {
        this.serverRequest();
    }

    render() {
        const { file } = this.state;

        return (
            <div>
                <AdminSidebar dataFromParent={this.props.location.pathname} />
                <AdminHeader />
                <div className="main_wrapper">
                    <div className="inner_wraapper">
                        <div className="container-fluid">
                            <section className="d_text">
                                <div className="container-fluid">
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="Introduction framwork_2">
                                                <div className="text_Parts">
                                                    <div className="text_ntroion">
                                                        <h5 className="Intro">ESG Annual Report</h5>
                                                        <p className="critical">
                                                        This ESG Report outlines a range of identified ESG risks for our organisation. We are committed 
                                                        to not only identifying, but continually developing strategies to mitigate our ESG risks. This 
                                                        report discloses a snapshot of material ESG risks which have identified internally and presented 
                                                        to us as part of our work with ESG Reporting Intelligence. 
                                                        </p>
                                                        <div className="variants">
                                                            <div className='file file--upload'>
                                                                    {this.state.uploadLoad ? (
                                                                        <label htmlFor='input-file'>
                                                                            <Spinner
                                                                                as="span"
                                                                                animation="border"
                                                                                size="sm"
                                                                                role="status"
                                                                                aria-hidden="true"
                                                                            />
                                                                            Uploading...
                                                                        </label>
                                                                    ) : (
                                                                        <label htmlFor='input-file'>
                                                                            <i className="fa fa-upload" aria-hidden="true"></i>
                                                                            Upload Report
                                                                        </label>
                                                                    )}

                                                                    
                                                               
                                                                <input
                                                                    // style={{ visibility: "hidden" }}
                                                                    type="file"
                                                                    accept='.pdf, .doc, .docx, .xlsx, .pptx, .ppt, .ott, .odp'
                                                                    id='input-file'
                                                                    name="governancePolicy"
                                                                    onChange={this.uploadReport}
                                                                    disabled={this.state.uploadLoad}
                                                                />
                                                                {/* <button
                                                                className="outputFile"
                                                                >
                                                                
                                                                </button> */}
                                                            </div>
                                                            {file && (
                                                                <a
                                                                    className="outputFile"
                                                                    target="_blank"
                                                                    href={BackendBaseUrl + "reports/" + file ?? ""}
                                                                >
                                                                    Preview
                                                                </a>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
