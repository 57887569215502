/* eslint-disable jsx-a11y/role-supports-aria-props */
import React, { Component } from "react";
import axios from "axios";
import AdminSidebar from "../../sidebar/admin_sidebar";
import AdminHeader from "../../header/admin_header";
import { NavLink } from "react-router-dom";
import { Button, Modal } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import { sweetAlert, isValidEmail } from "../../../utils/UniversalFunction";
import { authenticationService } from "../../../_services/authentication";
import "./companies.css";
import { Pagination, Icon } from "semantic-ui-react";

import config from "../../../config/config.json";
const currentUser = authenticationService.currentUserValue;
export default class companies extends Component {
  constructor(props) {
    super(props);
    this.state = {
      login: false,
      country: false,
      industry: false,
      category: false,
      activeModal: "",
      showModal: false,
      email: "",
      submitted: false,
      items: [],
      loading: false,
      totalRows: 0,
      currentPage: 1,
      skip: 0,
      startDate: "",
      endDate: "",
      limit: 10,
      countryFilter: "",
      industryFilter: "",
      categoryFilter: "",
      orderByName: "id",
      orderByValue: "DESC",
      search: "",
      pageCount: 0,
      totalData: 0,
      countriesList: [],
      industryList: [],
      searchKey: "",
      emailvalidation: false,
      emailvalidationMessage: "",
    };
    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.pageChange = this.pageChange.bind(this);
    this.serverRequest = this.serverRequest.bind(this);
    this.applyFilter = this.applyFilter.bind(this);
    this.applySorting = this.applySorting.bind(this);
    this.clearFilter = this.clearFilter.bind(this);
    this.exportCSV = this.exportCSV.bind(this);
  }

  handleOpenModal(val) {
    this.setState({ activeModal: val });
    this.setState({ showModal: true });
  }

  handleCloseModal() {
    this.setState({ showModal: false });
    this.setState({ showModal: "" });
  }

  handleChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    console.log(target);

    if (name === "email") {
      let condition = isValidEmail(value);
      if (condition === true) {
        this.setState({
          emailValidation: true,
          emailValidationMessage: "",
        });
      } else {
        this.setState({
          emailValidation: false,
          emailValidationMessage: "Please check email format",
        });
      }
    }
    this.setState({
      [name]: value,
    });
  }

  handleSubmit(event) {
    event.preventDefault();
    this.setState({ submitted: true });

    const { email, emailValidation } = this.state;
    const headers = {
      Authorization: `Bearer ${currentUser.data.token}`,
      Accept: "application/json",
    };
    if (email && emailValidation) {
      axios
        .post(
          config.API_URL + "sendInviteForCompany",
          {
            email: email,
          },
          { headers }
        )
        .then((response) => {
          sweetAlert("success", response.data.message);
          setTimeout(() => {
            this.handleCloseModal();
            this.setState({ email: "" });
            this.setState({ submitted: false });
          }, 1000);
        })
        .catch(function (error) {
          if (error.response) {
            sweetAlert("error", error.response.data.message);
          }
        });
    }
  }

  applyFilter(e) {
    let value = e.target.value;
    let name = e.target.name;
    this.setState({
      [name]: value,
    });
  }

  applyGlobalSearch(e) {
    let value = e.target.value;
    this.setState({
      searchKey: value,
    });
    setTimeout(() => {
      this.serverRequest();
    }, 200);
  }

  applySorting(e) {
    let value = e.target.value;
    let name = e.target.name;
    this.setState({
      orderByName: name,
      orderByValue: value,
    });
  }

  clearFilter(e) {
    let name = e.target.name;
    this.setState({
      [name]: "",
      orderByName: "id",
      orderByValue: "DESC",
    });
    setTimeout(() => {
      this.serverRequest();
    }, 500);
  }

  serverRequest() {
    const {
      skip,
      limit,
      countryFilter,
      industryFilter,
      categoryFilter,
      orderByName,
      orderByValue,
      searchKey,
      showModal,
    } = this.state;

    if (showModal === true) {
      this.setState({
        showModal: false,
      });
    }
    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${currentUser.data.token}`,
        "Content-Type": "application/json",
      },
    };
    let parms = `company?offset=${skip}&limit=${limit}&country=${countryFilter}&industry=${industryFilter}&category=${categoryFilter}&orderByName=${orderByName}&orderByValue=${orderByValue}&search=${searchKey}`;
    fetch(config.ADMIN_API_URL + parms, requestOptions)
      .then((res) => res.json())
      .then(
        (data) => {
          this.setState({
            pageCount: Math.ceil(data.length / this.state.perPage),
            totalData: data.data.count,
            items: data.data.rows,
          });
        },
        (error) => {}
      );
  }

  async pageChange(e, data) {
    console.log("Handle Click", data);
    let page = data.activePage;
    let pageNo = page === 1 ? 0 : (page - 1) * this.state.limit;
    console.log("clicked", pageNo);
    await this.setState({
      skip: pageNo,
    });
    await this.serverRequest();
    // console.log("order", orders);
  }

  exportCSV(event) {
    event.preventDefault();
    this.setState({ submitted: true });
    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${currentUser.data.token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        startDate: this.state.setStartDate,
        endDate: this.state.setEndDate,
      }),
    };

    fetch(config.ADMIN_API_URL + `company/export`, requestOptions)
      .then((res) => res.json())
      .then(
        (result) => {
          let url = config.BASE_URL + result.data;
          window.open(url, "_blank");
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error,
          });
        }
      );
  }

  componentDidMount() {
    this.setState({ items: [] });
    this.serverRequest();
    fetch(config.API_URL + "getAllCountries")
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            countriesList: result.countries,
          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error,
          });
        }
      );
    fetch(config.API_URL + "getCompanyIndustoryName")
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            industryList: result.companyIndustory,
          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error,
          });
        }
      );
  }

  render() {
    const { items, countriesList, industryList, skip } = this.state;
    return (
      <div>
        <AdminSidebar dataFromParent={this.props.location.pathname} />
        <AdminHeader />
        <div className="main_wrapper">
          <div className="inner_wraapper">
            <div className="container-fluid">
              <section className="d_text">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="col-sm-12">
                        <div className="color_div_on framwork_2">
                          <div className="business_detail">
                            <div className="">
                              <div className="heading">
                                <h4>Companies</h4>
                              </div>
                              <hr className="line"></hr>
                              <div className="saved_cards">
                                <div className="business_detail">
                                  <div className="heading">
                                    <div className="heading_wth_text">
                                      <div className="button_companies">
                                        <span className="global_link mx-0">
                                          <NavLink
                                            className="non_underline_link bold"
                                            to="/add_new_company"
                                          >
                                            <button
                                              className="link_bal_next page_width white"
                                              variant="none"
                                              to="/add_new_company"
                                            >
                                              ADD NEW COMPANY
                                            </button>
                                          </NavLink>
                                        </span>
                                        <span className="global_link mx-3">
                                          <button
                                            className="link_bal_next page_width white"
                                            variant="none"
                                            onClick={() =>
                                              this.handleOpenModal("login")
                                            }
                                          >
                                            {" "}
                                            INVITE NEW COMPANY{" "}
                                          </button>
                                        </span>
                                        <span className="global_link mx-3">
                                          <button
                                            onClick={this.exportCSV}
                                            className="link_bal_next page_width white"
                                          >
                                            <i className="fas fa-download white" />
                                          </button>
                                        </span>
                                      </div>
                                    </div>

                                    <div className="form-group has-search one">
                                      <span className="fa fa-search form-control-feedback"></span>
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Search Company, Category, Industry &amp; Business Number"
                                        name="search"
                                        onChange={(e) =>
                                          this.applyGlobalSearch(e)
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="table_f table-responsive">
                                  <Table striped bordered hover size="sm">
                                    <thead>
                                      <tr className="heading_color">
                                        <th>ID</th>
                                        <th>COMPANY NAME</th>
                                        <th>
                                          COUNTRY
                                          <span>
                                            <i
                                              className="fad fa-sort-amount-up mx-3"
                                              variant="none"
                                              onClick={() =>
                                                this.handleOpenModal("country")
                                              }
                                            ></i>
                                          </span>
                                        </th>
                                        <th>BUSINESS NUMBER</th>
                                        <th>
                                          INDUSTRY
                                          <span>
                                            <i
                                              className="fad fa-sort-amount-up mx-3"
                                              variant="none"
                                              onClick={() =>
                                                this.handleOpenModal("industry")
                                              }
                                            ></i>
                                          </span>
                                        </th>
                                        <th>
                                          CATEGORY
                                          <span>
                                            <i
                                              className="fad fa-sort-amount-up mx-3"
                                              variant="none"
                                              onClick={() =>
                                                this.handleOpenModal("category")
                                              }
                                            ></i>
                                          </span>
                                        </th>
                                        <th>STATUS</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {items.map((item, key) => (
                                        <tr key={key}>
                                          <td>{(key + 1) + (skip)}</td>
                                          <td>
                                            <NavLink
                                              className="non_underline_link"
                                              to={
                                                "/companies/" +
                                                item.uuid +
                                                "/details"
                                              }
                                            >
                                              <span className="black bold">
                                                {item.register_company_name}
                                              </span>
                                            </NavLink>
                                          </td>
                                          <td>{item.country}</td>
                                          <td>{item.businessNumber}</td>
                                          <td>{item.company_industry}</td>
                                          <td>{item.userCategory}</td>
                                          <td>
                                            <NavLink
                                              className="non_underline_link"
                                              to={
                                                "/companies/" +
                                                item.uuid +
                                                "/details"
                                              }
                                            >
                                              <span className="red bold">
                                                {item.isActive === 0 &&
                                                  "Inactive"}
                                              </span>
                                              <span className="green bold">
                                                {item.isActive === 1 &&
                                                  "Active"}
                                              </span>
                                            </NavLink>
                                          </td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  </Table>

                                  <Pagination
                                    className="mx-auto pagination"
                                    defaultActivePage={1}
                                    onPageChange={this.pageChange}
                                    ellipsisItem={{
                                      content: (
                                        <Icon name="ellipsis horizontal" />
                                      ),
                                      icon: true,
                                    }}
                                    firstItem={{
                                      content: (
                                        <Icon name="angle double left" />
                                      ),
                                      icon: true,
                                    }}
                                    lastItem={{
                                      content: (
                                        <Icon name="angle double right" />
                                      ),
                                      icon: true,
                                    }}
                                    prevItem={{
                                      content: <Icon name="angle left" />,
                                      icon: true,
                                    }}
                                    nextItem={{
                                      content: <Icon name="angle right" />,
                                      icon: true,
                                    }}
                                    totalPages={Math.ceil(
                                      this.state.totalData / this.state.limit
                                    )}
                                  />

                                  <Modal
                                    animation={true}
                                    size="md"
                                    className="modal_box"
                                    shadow-lg="border"
                                    show={
                                      this.state.showModal &&
                                      this.state.activeModal === "login"
                                    }
                                  >
                                    <div className="modal-lg">
                                      <Modal.Header className="pb-0">
                                        <Button
                                          variant="outline-dark"
                                          onClick={this.handleCloseModal}
                                        >
                                          <i className="fa fa-times"></i>
                                        </Button>
                                      </Modal.Header>
                                      <div className="modal-body vekp pt-0">
                                        <div className="row">
                                          <div className="col-md-12">
                                            <div className="pb3">
                                              <h4 className="mb-3 p-0">
                                                Invite New Company
                                              </h4>
                                              <form
                                                name="form"
                                                onSubmit={this.handleSubmit}
                                              >
                                                <div className="forms">
                                                  <div className="form-group">
                                                    <label
                                                      htmlFor="exampleFormControlInput1 mt-3"
                                                      className="form-label"
                                                    >
                                                      Enter user email address
                                                      below to send sign up
                                                      invite
                                                    </label>
                                                    <input
                                                      className="form-control my-2 mb-3"
                                                      type="email"
                                                      aria-expanded="false"
                                                      placeholder="Email"
                                                      value={this.state.email}
                                                      name="email"
                                                      onChange={
                                                        this.handleChange
                                                      }
                                                    />
                                                    {this.state
                                                      .emailValidation ===
                                                      false && (
                                                      <div className="help-block">
                                                        {
                                                          this.state
                                                            .emailValidationMessage
                                                        }
                                                      </div>
                                                    )}
                                                    {this.state.submitted &&
                                                      !this.state.email && (
                                                        <div className="help-block">
                                                          Email is required
                                                        </div>
                                                      )}
                                                  </div>
                                                  <div className="cenlr">
                                                    <button
                                                      className="page_save page_width"
                                                      to="#"
                                                      disabled={false}
                                                    >
                                                      SEND
                                                    </button>
                                                  </div>
                                                </div>
                                              </form>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </Modal>

                                  <Modal
                                    animation={true}
                                    size="md"
                                    className="modal_box"
                                    shadow-lg="border"
                                    show={
                                      this.state.showModal &&
                                      this.state.activeModal === "industry"
                                    }
                                  >
                                    <div className="modal-lg">
                                      <Modal.Header className="pb-0">
                                        <Button
                                          variant="outline-dark"
                                          onClick={this.handleCloseModal}
                                        >
                                          <i className="fa fa-times"></i>
                                        </Button>
                                      </Modal.Header>
                                      <div className="modal-body vekp pt-0">
                                        <div className="row">
                                          <div className="col-md-12">
                                            <div className="pb4">
                                              <div className="py-3">
                                                <div className="form-check-inline">
                                                  <label
                                                    className="form-check-label"
                                                    htmlFor="flexCheckChecked"
                                                  >
                                                    {" "}
                                                    Sort: A - Z{" "}
                                                  </label>
                                                  <input
                                                    className="form-check-input mx-3"
                                                    type="radio"
                                                    name="inlineRadioOptions"
                                                    id="inlineRadio1"
                                                    value="option1"
                                                  />
                                                </div>
                                                <div className="form-check-inline">
                                                  <label
                                                    className="form-check-label"
                                                    htmlFor="flexCheckChecked"
                                                  >
                                                    {" "}
                                                    Sort: Z - A{" "}
                                                  </label>
                                                  <input
                                                    className="form-check-input mx-3"
                                                    type="radio"
                                                    name="inlineRadioOptions"
                                                    id="inlineRadio1"
                                                    value="option1"
                                                  />
                                                </div>
                                              </div>

                                              <div className="input-group mb-3">
                                                <select
                                                  className="form-control dropdown-toggle"
                                                  name="industryFilter"
                                                  onChange={(e) =>
                                                    this.applyFilter(e)
                                                  }
                                                >
                                                  <option value="">
                                                    Select Industry
                                                  </option>

                                                  {industryList.map((item2) => (
                                                    <option
                                                      key={item2.id}
                                                      value={item2.title}
                                                    >
                                                      {item2.title}
                                                    </option>
                                                  ))}
                                                </select>
                                              </div>
                                              <div className="cenlr">
                                                <button
                                                  className="page_save page_width"
                                                  name="industryFilter"
                                                  onClick={this.clearFilter}
                                                >
                                                  CLEAR
                                                </button>
                                                <button
                                                  className="page_save page_width"
                                                  onClick={this.serverRequest}
                                                >
                                                  APPLY
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </Modal>

                                  <Modal
                                    animation={true}
                                    size="md"
                                    className="modal_box"
                                    shadow-lg="border"
                                    show={
                                      this.state.showModal &&
                                      this.state.activeModal === "category"
                                    }
                                  >
                                    <div className="modal-lg">
                                      <Modal.Header className="pb-0">
                                        <Button
                                          variant="outline-dark"
                                          onClick={this.handleCloseModal}
                                        >
                                          <i className="fa fa-times"></i>
                                        </Button>
                                      </Modal.Header>
                                      <div className="modal-body vekp pt-0">
                                        <div className="row">
                                          <div className="col-md-12">
                                            <div className="pb4">
                                              <div className="py-3">
                                                <div className="form-check-inline">
                                                  <label
                                                    className="form-check-label"
                                                    htmlFor="flexCheckChecked"
                                                  >
                                                    {" "}
                                                    Sort: A - Z{" "}
                                                  </label>
                                                  <input
                                                    className="form-check-input mx-3"
                                                    type="radio"
                                                    name="inlineRadioOptions"
                                                    id="inlineRadio1"
                                                    value="option1"
                                                  />
                                                </div>
                                                <div className="form-check-inline">
                                                  <label
                                                    className="form-check-label"
                                                    htmlFor="flexCheckChecked"
                                                  >
                                                    {" "}
                                                    Sort: Z - A{" "}
                                                  </label>
                                                  <input
                                                    className="form-check-input mx-3"
                                                    type="radio"
                                                    name="inlineRadioOptions"
                                                    id="inlineRadio1"
                                                    value="option1"
                                                  />
                                                </div>
                                              </div>

                                              <div className="input-group mb-3">
                                                <select
                                                  type="text"
                                                  className="form-control dropdown-toggle"
                                                  placeholder="Search"
                                                  name="categoryFilter"
                                                  onChange={(e) =>
                                                    this.applyFilter(e)
                                                  }
                                                >
                                                  <option value="">
                                                    Select Category
                                                  </option>
                                                  <option value="Partner">
                                                    Partner
                                                  </option>
                                                  <option value="Business Account">
                                                    Business Account
                                                  </option>
                                                </select>
                                              </div>
                                              <div className="cenlr">
                                                <button
                                                  className="page_save page_width"
                                                  name="categoryFilter"
                                                  onClick={this.clearFilter}
                                                >
                                                  CLEAR
                                                </button>
                                                <button
                                                  className="page_save page_width"
                                                  onClick={this.serverRequest}
                                                >
                                                  APPLY
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </Modal>

                                  <Modal
                                    animation={true}
                                    size="md"
                                    className="modal_box"
                                    shadow-lg="border"
                                    show={
                                      this.state.showModal &&
                                      this.state.activeModal === "country"
                                    }
                                  >
                                    <div className="modal-lg">
                                      <Modal.Header className="pb-0">
                                        <Button
                                          variant="outline-dark"
                                          onClick={this.handleCloseModal}
                                        >
                                          <i className="fa fa-times"></i>
                                        </Button>
                                      </Modal.Header>
                                      <div className="modal-body vekp pt-0">
                                        <div className="row">
                                          <div className="col-md-12">
                                            <div className="pb4">
                                              <div className="py-3">
                                                <div className="form-check-inline">
                                                  <label
                                                    className="form-check-label"
                                                    htmlFor="flexCheckChecked"
                                                  >
                                                    {" "}
                                                    Sort: A - Z{" "}
                                                  </label>
                                                  <input
                                                    className="form-check-input mx-3"
                                                    type="radio"
                                                    name="country"
                                                    value="ASC"
                                                    onChange={(e) =>
                                                      this.applySorting(e)
                                                    }
                                                  />
                                                </div>
                                                <div className="form-check-inline">
                                                  <label
                                                    className="form-check-label"
                                                    htmlFor="flexCheckChecked"
                                                  >
                                                    {" "}
                                                    Sort: Z - A{" "}
                                                  </label>
                                                  <input
                                                    className="form-check-input mx-3"
                                                    type="radio"
                                                    name="country"
                                                    value="DESC"
                                                    onChange={(e) =>
                                                      this.applySorting(e)
                                                    }
                                                  />
                                                </div>
                                              </div>

                                              <div className="input-group mb-3">
                                                <select
                                                  className="form-control dropdown-toggle"
                                                  placeholder="Search"
                                                  name="countryFilter"
                                                  onChange={(e) =>
                                                    this.applyFilter(e)
                                                  }
                                                >
                                                  <option value="">
                                                    Select Country
                                                  </option>

                                                  {countriesList.map((item) => (
                                                    <option
                                                      key={item.id}
                                                      value={item.nicename}
                                                    >
                                                      {item.name}
                                                    </option>
                                                  ))}
                                                </select>
                                              </div>
                                              <div className="cenlr">
                                                <button
                                                  className="page_save page_width"
                                                  name="countryFilter"
                                                  onClick={this.clearFilter}
                                                >
                                                  CLEAR
                                                </button>
                                                <button
                                                  className="page_save page_width"
                                                  onClick={this.serverRequest}
                                                >
                                                  APPLY
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </Modal>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
