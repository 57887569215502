const editorConfig  = {
    toolbar: {
        items: [
            'undo', 'redo',
		
				'heading',
				'|',
				'fontSize',
				'fontFamily',
				'|',
				'fontColor',
				'fontBackgroundColor',
				'|',
				'bold',
				'italic',
				'underline',
				'strikethrough',
				'|',
				'alignment',
				'|',
				'numberedList',
				'bulletedList',
				'|',
				'outdent',
				'indent',
				'|',
				'todoList',
				'link',
				'blockQuote',
				'imageUpload',
				'insertTable',
				'mediaEmbed',
				'|',
				'undo',
				'redo',
				'superscript',
				'restrictedEditingException',
				'findAndReplace',
				'highlight',
				'horizontalLine',
				'imageInsert',
				'pageBreak',
				'removeFormat',
				'specialCharacters',
                    // 'style',
                    // 'subscript'
        ],
        
        shouldNotGroupWhenFull: true
    },
    fontFamily: {
        options: [
          "default",
          "Ubuntu, Arial, sans-serif",
          "Ubuntu Mono, Courier New, Courier, monospace",
          "Calibri",
          "Calibri Light",
        ],
      },
      heading: {
        options: [
          {
            model: "paragraph",
            title: "Paragraph 1",
            class: "ck-heading_paragraph1",
          },
          {
            model: "paragraphT",
            view: { name: "p", classes: "report_p1" },
            title: "Paragraph 2",
            class: "ck-heading_paragraph2",
          },
          {
            model: "heading1",
            view: { name: "h1", classes: "report_h1" },
            title: "Heading 1",
            class: "ck-heading_heading1",
          },
          {
            model: "heading2",
            view: { name: "p", classes: "report_h2" },
            title: "Heading 2",
            class: "ck-heading_heading2",
          },
          {
            model: "heading3",
            view: { name: "p", classes: "report_h3" },
            title: "Heading 3",
            class: "ck-heading_heading3",
          },
        ],
      },
      fontSize: {
        options: [
          4, 6, 8, 10, 12, 13, 14, 16, 18, 20, 22, 24, 26, 28, 30, 32, 34, 36,
          38, 40, 42, 44, 46, 48, 50,
        ],
      },
      htmlSupport: {
        allow: [
          {
            name: /^(div|p|ul|li|ol|a|figure|button|p|h[1-6]|table|th|tr)$/,
            classes: true,
            styles: true
          }
        ]
      }
}

export default editorConfig;
