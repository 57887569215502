import React, { Component } from "react";
import MyProfilePage from "./my_profile";
import BillingPage from "./billing";
import GoogleTwoFA from "./twoFA";
import { Tabs, Tab } from "react-bootstrap";

export default class SettingDetailPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tab: "first",
    };
  }
  componentDidMount() {
    let queries = window.location.href.split("=").pop();
    console.log("URI", queries);
    this.setState({
      tab:
        queries === "auth"
          ? "third"
          : queries === "profile"
          ? "first"
          : "second",
    });
  }

  render() {
    console.log("My Tab",this.state.tab);
    return (
      <div>
        <div className="steps-step ste-steps">
        {this.state.tab && (
          <Tabs
            defaultActiveKey={this.state.tab}
            id="step-9"
            className="mb-3 parent-enviornment2 d-flex justify-content-center setting-tb"
          >
            <Tab eventKey="first" title="MY PROFILE" className="enviornment">
              <MyProfilePage />
            </Tab>
            <Tab eventKey="second" title="BILLING">
              <BillingPage />
            </Tab>
            <Tab eventKey="third" title="TWO FACTOR AUTH">
              <GoogleTwoFA />
            </Tab>
          </Tabs>
        )}
        </div>
      </div>
    );
  }
}
