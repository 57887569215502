import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import head_Logo from "../../img/logo.png";
import foot_Logo from "../../img/logol.png";
import "./common.css";
import "./sidebar.css";

export default class sidebar extends Component {
  render() {
    const company = this.props?.dataFromParent?.split("/");
    console.log("Active class", company[1]);
    const sustainableTarget = {
      pathname: "/sustainable",
      key: Date.now(), // we could use Math.random, but that's not guaranteed unique.
      state: {
        applied: true,
      },
    };

    const homeTarget = {
      pathname: "/home",
      key: Date.now(), // we could use Math.random, but that's not guaranteed unique.
      state: {
        applied: true,
      },
    };

    return (
      <div>
        <div className="d-flex" id="wrapper">
          {/* <!-- Sidebar--> */}
          <div className="border-end bg-white" id="sidebar-wrapper">
            <div className="sidebar-heading border-bottom color_xl">
              <div className="logo_text">
              <NavLink to="/home"><img src={head_Logo} alt="" /></NavLink>
              </div>
            </div>
            <div className="list-group list-group-flush">
              <div className="route-dom">
                <ul className="home_icon_img">
                  <li>
                    <NavLink
                      activeClassName="active"
                      to={homeTarget}
                      className="list-group-item list-group-item-action list-group-item-light p-3 nop"
                    >
                      <i className="fal fa-th-large"></i>
                      <span className="home_boom">Dashboard</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      activeClassName="active"
                      to="/esg_reporting"
                      className={
                        company[1] === "esg_reporting"
                          ? "list-group-item list-group-item-action list-group-item-light p-3 nop active"
                          : company[1] === "esg_reporting_pie"
                          ? "list-group-item list-group-item-action list-group-item-light p-3 nop active"
                          : "list-group-item list-group-item-action list-group-item-light p-3 nop"
                      }
                    >
                      <i className="fal fa-file-chart-line"></i>
                      <span className="home_boom">ESG Reporting</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      activeClassName="active"
                      to="/sector_question"
                      className={
                        company[1] === "sector_question"
                          ? "list-group-item list-group-item-action list-group-item-light p-3 nop active"
                          : company[1] === "Environment_Capital"
                          ? "list-group-item list-group-item-action list-group-item-light p-3 nop active"
                          : company[1] === "Social_Capital"
                          ? "list-group-item list-group-item-action list-group-item-light p-3 nop active"
                          : company[1] === "Leadership_Governance"
                          ? "list-group-item list-group-item-action list-group-item-light p-3 nop active"
                          : company[1] === "Business_Model"
                          ? "list-group-item list-group-item-action list-group-item-light p-3 nop active"
                          : company[1] === "sector_question_detail"
                          ? "list-group-item list-group-item-action list-group-item-light p-3 nop active"
                          : company[1] === "Cyber_Digial"
                          ? "list-group-item list-group-item-action list-group-item-light p-3 nop active"
                          : company[1] === "Human_Capital"
                          ? "list-group-item list-group-item-action list-group-item-light p-3 nop active"
                          : "list-group-item list-group-item-action list-group-item-light p-3 nop"
                      }
                    >
                      <i className="far fa-clipboard"></i>
                      <span className="home_boom">Sector Question</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      activeClassName="active"
                      to="/governance"
                      a="true"
                      className={
                        company[1] === "governance"
                          ? "list-group-item list-group-item-action list-group-item-light p-3 nop active"
                          : company[1] === "Governance_Policies"
                          ? "list-group-item list-group-item-action list-group-item-light p-3 nop active"
                          : company[1] === "Social_Policies"
                          ? "list-group-item list-group-item-action list-group-item-light p-3 nop active"
                          : company[1] === "Cyber_Technology"
                          ? "list-group-item list-group-item-action list-group-item-light p-3 nop active"
                          : company[1] === "Health_Safety"
                          ? "list-group-item list-group-item-action list-group-item-light p-3 nop active"
                          : company[1] === "Environmental_Policy"
                          ? "list-group-item list-group-item-action list-group-item-light p-3 nop active"
                          : "list-group-item list-group-item-action list-group-item-light p-3 nop"
                      }
                    >
                      <i className="far fa-building"></i>
                      <span className="home_boom">Governance</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      activeClassName="active"
                      to="/supplier"
                      className={
                        company[1] === "supplier"
                          ? "list-group-item list-group-item-action list-group-item-light p-3 nop active"
                          : company[1] === "supplier_fast"
                          ? "list-group-item list-group-item-action list-group-item-light p-3 nop active"
                          : company[1] === "Environmental_Topics"
                          ? "list-group-item list-group-item-action list-group-item-light p-3 nop active"
                          : company[1] === "Social_Topics"
                          ? "list-group-item list-group-item-action list-group-item-light p-3 nop active"
                          : company[1] === "Governance_Topics"
                          ? "list-group-item list-group-item-action list-group-item-light p-3 nop active"
                          : company[1] === "Business_Models"
                          ? "list-group-item list-group-item-action list-group-item-light p-3 nop active"
                          : company[1] === "supplier_details"
                          ? "list-group-item list-group-item-action list-group-item-light p-3 nop active"
                          : "list-group-item list-group-item-action list-group-item-light p-3 nop"
                      }
                    >
                      <i className="far fa-user-cog"></i>
                      <span className="home_boom">Suppliers</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      activeClassName="active"
                      to="/supplier_management"
                      className={
                        company[1] === "supplier_management"
                          ? "list-group-item list-group-item-action list-group-item-light p-3 nop active"
                          : company[1] === "supplier_management_option"
                          ? "list-group-item list-group-item-action list-group-item-light p-3 nop active"
                          : company[1] === "supplier_management_form"
                          ? "list-group-item list-group-item-action list-group-item-light p-3 nop active"
                          : company[1] === "supplier_management_detail"
                          ? "list-group-item list-group-item-action list-group-item-light p-3 nop active"
                          : "list-group-item list-group-item-action list-group-item-light p-3 nop"
                      }
                    >
                      <i className="fal fa-file-chart-line"></i>
                      <span className="home_boom">Supplier Management</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      activeClassName="active"
                      to={sustainableTarget}
                      className="list-group-item list-group-item-action list-group-item-light p-3 nop"
                    >
                      <span className="leaf-fer">
                        <i className="fal fa-leaf"></i>
                        <span className="home_boom">SDG's</span>
                      </span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      activeClassName="active"
                      to="/carbon_footprint"
                      className={
                        company[1] === "carbon_footprint"
                          ? "list-group-item list-group-item-action list-group-item-light p-3 nop active"
                          : company[1] === "Scope_Emission_1"
                          ? "list-group-item list-group-item-action list-group-item-light p-3 nop active"
                          : company[1] === "Scope_Emission_2"
                          ? "list-group-item list-group-item-action list-group-item-light p-3 nop active"
                          : company[1] === "Scope_Emission_3"
                          ? "list-group-item list-group-item-action list-group-item-light p-3 nop active"
                          : company[1] === "result"
                          ? "list-group-item list-group-item-action list-group-item-light p-3 nop active"
                          : company[1] === "carbon_footprint_detail"
                          ? "list-group-item list-group-item-action list-group-item-light p-3 nop active"
                          : "list-group-item list-group-item-action list-group-item-light p-3 nop"
                      }
                    >
                      <i className="fal fa-shoe-prints"></i>
                      <span className="home_boom">Carbon Footprint</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      activeClassName="active"
                      to="/board_skills"
                      className={
                        company[1] === "board_skills"
                          ? "list-group-item list-group-item-action list-group-item-light p-3 nop active"
                          : company[1] === "board_skills_detail"
                          ? "list-group-item list-group-item-action list-group-item-light p-3 nop active"
                          : "list-group-item list-group-item-action list-group-item-light p-3 nop"
                      }
                    >
                      <i className="fal fa-clipboard"></i>
                      <span className="home_boom">Board Skills</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      activeClassName="active"
                      to="/esg_products"
                      className="list-group-item list-group-item-action list-group-item-light p-3 nop"
                    >
                      <i className="fal fa-archive"></i>
                      <span className="home_boom">ESG Products</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      activeClassName="active"
                      to="/training"
                      className="list-group-item list-group-item-action list-group-item-light p-3 nop"
                    >
                      <i className="fal fa-address-card"></i>
                      <span className="home_boom">Training</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      activeClassName="active"
                      to="/Setting_Profile"
                      className={
                        company[1] === "Setting_Profile"
                          ? "list-group-item list-group-item-action list-group-item-light p-3 nop active"
                          : company[1] === "Setting_Billing"
                          ? "list-group-item list-group-item-action list-group-item-light p-3 nop active"
                          : company[1] === "Setting_Sub_Admin"
                          ? "list-group-item list-group-item-action list-group-item-light p-3 nop active"
                          : company[1] === "user_detail"
                          ? "list-group-item list-group-item-action list-group-item-light p-3 nop active"
                          : "list-group-item list-group-item-action list-group-item-light p-3 nop"
                      }
                    >
                      <i className="fal fa-cog"></i>
                      <span className="home_boom">Setting</span>
                    </NavLink>
                  </li>
                </ul>
              </div>
            </div>
            <div className="slill_bord">
              <img src={foot_Logo} alt="" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
