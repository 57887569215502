/* eslint-disable jsx-a11y/role-supports-aria-props */
import React, { Component } from "react";
import AdminSidebar from "../../sidebar/admin_sidebar";
import AdminHeader from "../../header/admin_header";
import axios from "axios";
import { NavLink } from "react-router-dom";
import { Button, Modal } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import { Pagination, Icon } from "semantic-ui-react";
import { authenticationService } from "../../../_services/authentication";
import { sweetAlert, isValidEmail } from "../../../utils/UniversalFunction";
import "../companies/companies.css";
import config from "../../../config/config.json";
const currentUser = authenticationService.currentUserValue;
const BackendBaseUrl = config.BASE_URL;

export default class EsgSubAdmin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      login: false,
      country: false,
      industry: false,
      category: false,
      activeModal: "",
      showModal: false,
      email: "",
      submitted: false,
      search: "",
      pageCount: 0,
      totalData: 0,
      items: [],
      loading: false,
      startDate: "",
      endDate: "",
      totalRows: 0,
      currentPage: 1,
      skip: 0,
      limit: 10,
      emailvalidation: false,
      emailvalidationMessage: "",
    };
    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.pageChange = this.pageChange.bind(this);
    this.serverRequest = this.serverRequest.bind(this);
    this.exportCSV = this.exportCSV.bind(this);
  }

  applyGlobalSearch(e) {
    let value = e.target.value;
    let name = e.target.name;
    this.setState({
      [name]: value,
    });
    setTimeout(() => {
      this.serverRequest();
    }, 200);
  }

  handleOpenModal(val) {
    this.setState({ activeModal: val });
    this.setState({ showModal: true });
  }

  handleCloseModal() {
    this.setState({ showModal: false });
    this.setState({ showModal: "" });
  }

  handleChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    if (name === "email") {
      let condition = isValidEmail(value);
      if (condition === true) {
        this.setState({
          emailValidation: true,
          emailValidationMessage: "",
        });
      } else {
        this.setState({
          emailValidation: false,
          emailValidationMessage: "Please check email format",
        });
      }
    }
    console.log(target);
    this.setState({
      [name]: value,
    });
  }

  handleSubmit(event) {
    event.preventDefault();
    this.setState({ submitted: true });
    const { email, emailValidation } = this.state;
    const headers = {
      Authorization: `Bearer ${currentUser.data.token}`,
      Accept: "application/json",
    };
    if (email) {
      if (emailValidation) {
      axios
        .post(
          config.ADMIN_API_URL + "sub-admin/sendInviteForSubAdmin",
          {
            email: email,
          },
          { headers }
        )
        .then((response) => {
          sweetAlert("success", response.data.message);
          setTimeout(() => {
            this.handleCloseModal();
            this.setState({ email: "" });
            this.setState({ submitted: false });
          }, 1000);
        })
        .catch(function (error) {
          if (error.response) {
            sweetAlert("error", error.response.data.message);
          }
        });

      }else{
        sweetAlert("error", "Invalid mail format!");
      }
    }
  }

  exportCSV(event) {
    event.preventDefault();
    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${currentUser.data.token}`,
        "Content-Type": "application/json",
      },
    };
    fetch(`${config.ADMIN_API_URL}sub-admins/export`, requestOptions)
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            csvLink: result.data,
          });
          let url = BackendBaseUrl + result.data;
          window.open(url, "_blank");
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error,
          });
        }
      );
  }

  serverRequest() {
    const { skip, limit, search, showModal } = this.state;
    if (showModal === true) {
      this.setState({
        showModal: false,
      });
    }
    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${currentUser.data.token}`,
        "Content-Type": "application/json",
      },
    };
    let parms = `sub-admins?offset=${skip}&limit=${limit}&search=${search}`;
    fetch(config.ADMIN_API_URL + parms, requestOptions)
      .then((res) => res.json())
      .then(
        (data) => {
          this.setState({
            pageCount: Math.ceil(data.length / this.state.perPage),
            totalData: data.data.count,
            items: data.data.rows,
          });
        },
        (error) => {}
      );
  }

  async pageChange(e, data) {
    console.log("Handle Click", data);
    let page = data.activePage;
    let pageNo = page === 1 ? 0 : (page - 1) * this.state.limit;
    console.log("clicked", pageNo);
    await this.setState({
      skip: pageNo,
    });
    await this.serverRequest();
    // console.log("order", orders);
  }

  componentDidMount() {
    this.setState({ items: [] });
    this.serverRequest();
  }

  render() {
    const { items, skip } = this.state;
    return (
      <div>
        <AdminSidebar dataFromParent={this.props.location.pathname} />
        <AdminHeader />
        <div className="main_wrapper">
          <div className="inner_wraapper">
            <div className="container-fluid">
              <section className="d_text">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="col-sm-12">
                        <div className="color_div_on framwork_2">
                          <div className="business_detail">
                            <div className="">
                              <div className="heading">
                                <h4>Sub Users</h4>
                              </div>
                              <hr className="line"></hr>
                              <div className="saved_cards">
                                <div className="business_detail">
                                  <div className="heading">
                                    <div className="heading_wth_text">
                                      <div className="d-flex">
                                        <span className="global_link mx-0">
                                          <button
                                            className="link_bal_next page_width white"
                                            variant="none"
                                            onClick={() =>
                                              this.handleOpenModal("login")
                                            }
                                          >
                                            {" "}
                                            INVITE NEW SUB Users{" "}
                                          </button>
                                        </span>
                                        <span className="global_link mx-3">
                                          <button
                                            onClick={this.exportCSV}
                                            className="link_bal_next page_width white"
                                          >
                                            <i className="fas fa-download white" />
                                          </button>
                                        </span>
                                      </div>
                                    </div>

                                    <div className="form-group has-search one">
                                      <span className="fa fa-search form-control-feedback"></span>
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Search Sub-Admin Name &amp; Email"
                                        name="search"
                                        onChange={(e) =>
                                          this.applyGlobalSearch(e)
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="table_f table-responsive">
                                  <Table striped bordered hover size="sm">
                                    <thead>
                                      <tr className="heading_color">
                                        <th style={{ width: "10%" }}>ID</th>
                                        <th>SUB USERS NAME</th>
                                        <th>EMAIL</th>
                                        <th>COMPANIES ASSIGNED</th>
                                        <th style={{ width: "10%" }}>STATUS</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {items.map((item, key) => (
                                        <tr key={key}>
                                          <td>{(key + 1) + (skip)}</td>
                                          <td>
                                            <NavLink
                                              className="non_underline_link"
                                              to={`/sub-users/${item.uuid}`}
                                            >
                                              <span className="black bold">
                                                {item.firstName +
                                                  " " +
                                                  item.lastName}
                                              </span>
                                            </NavLink>
                                          </td>
                                          <td>{item.email}</td>
                                          <td>{item.assignedCount}</td>
                                          <td>
                                            <NavLink
                                              className="non_underline_link"
                                              to={`/sub-users/${item.uuid}`}
                                            >
                                              <span className="red bold">
                                                {item.isActive === 0 &&
                                                  "Inactive"}
                                              </span>
                                              <span className="green bold">
                                                {item.isActive === 1 &&
                                                  "Active"}
                                              </span>
                                            </NavLink>
                                          </td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  </Table>
                                  <Pagination
                                    className="mx-auto pagination"
                                    defaultActivePage={1}
                                    onPageChange={this.pageChange}
                                    ellipsisItem={{
                                      content: (
                                        <Icon name="ellipsis horizontal" />
                                      ),
                                      icon: true,
                                    }}
                                    firstItem={{
                                      content: (
                                        <Icon name="angle double left" />
                                      ),
                                      icon: true,
                                    }}
                                    lastItem={{
                                      content: (
                                        <Icon name="angle double right" />
                                      ),
                                      icon: true,
                                    }}
                                    prevItem={{
                                      content: <Icon name="angle left" />,
                                      icon: true,
                                    }}
                                    nextItem={{
                                      content: <Icon name="angle right" />,
                                      icon: true,
                                    }}
                                    totalPages={Math.ceil(
                                      this.state.totalData / this.state.limit
                                    )}
                                  />
                                  <Modal
                                    animation={true}
                                    size="md"
                                    className="modal_box"
                                    shadow-lg="border"
                                    show={
                                      this.state.showModal &&
                                      this.state.activeModal === "login"
                                    }
                                  >
                                    <div className="modal-lg">
                                      <Modal.Header className="pb-0">
                                        <Button
                                          variant="outline-dark"
                                          onClick={this.handleCloseModal}
                                        >
                                          <i className="fa fa-times"></i>
                                        </Button>
                                      </Modal.Header>
                                      <div className="modal-body vekp pt-0">
                                        <div className="row">
                                          <div className="col-md-12">
                                            <div className="pb3">
                                              <h4>Add New Sub-Admin</h4>
                                              <form
                                                name="form"
                                                onSubmit={this.handleSubmit}
                                              >
                                                <div className="forms">
                                                  <div className="form-group">
                                                    <label
                                                      htmlFor="exampleFormControlInput1"
                                                      className="form-label"
                                                    >
                                                      Enter user’s email address
                                                      below to send sign up
                                                      invite
                                                    </label>
                                                    <input
                                                      className="form-control my-3"
                                                      aria-expanded="false"
                                                      placeholder="Email"
                                                      value={this.state.email}
                                                      name="email"
                                                      onChange={
                                                        this.handleChange
                                                      }
                                                    />

                                                    {this.state
                                                      .emailValidation ===
                                                      false && (
                                                      <div className="help-block">
                                                        {
                                                          this.state
                                                            .emailValidationMessage
                                                        }
                                                      </div>
                                                    )}

                                                    {this.state.submitted &&
                                                      !this.state.email && (
                                                        <div className="help-block">
                                                          Email is required
                                                        </div>
                                                      )}
                                                  </div>
                                                  <div className="cenlr">
                                                    <button
                                                      className="page_save page_width"
                                                      to="#"
                                                    >
                                                      SEND
                                                    </button>
                                                  </div>
                                                </div>
                                              </form>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </Modal>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
