import React, { Component } from "react";
import AdminSidebar from "../../sidebar/admin_sidebar";
import AdminHeader from "../../header/admin_header";
import { NavLink } from "react-router-dom";
import { Button, Modal } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import { Pagination, Icon } from "semantic-ui-react";
import Moment from "react-moment";
import { saveAs } from "file-saver";
import e1 from "../../../img/e1.png";
import e2 from "../../../img/e2.png";
import e3 from "../../../img/e3.png";
import "../companies/companies.css";
import config from "../../../config/config.json";
import { authenticationService } from "../../../_services/authentication";
const currentUser = authenticationService.currentUserValue;

export default class Revenue extends Component {
  constructor(props) {
    super(props);
    this.state = {
      login: false,
      country: false,
      industry: false,
      category: false,
      activeModal: "",
      totalRows: 0,
      currentPage: 1,
      skip: 0,
      startDate: "",
      endDate: "",
      limit: 10,
      orderByName: "id",
      orderByValue: "DESC",
      search: "",
      pageCount: 0,
      totalData: 0,
      searchKey : ""
    };
    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.applyFilter = this.applyFilter.bind(this);
    this.applyGlobalSearch = this.applyGlobalSearch.bind(this);
    this.applySorting = this.applySorting.bind(this);
    this.clearFilter = this.clearFilter.bind(this);
    this.serverRequest = this.serverRequest.bind(this);
    this.pageChange = this.pageChange.bind(this);
    this.exportCSV = this.exportCSV.bind(this);
  }

  applyFilter(e) {
    let value = e.target.value;
    let name = e.target.name;
    this.setState({
      [name]: value,
    });
  }

  applyGlobalSearch(e) {
    let value = e.target.value;
    this.setState({
      searchKey: value,
    });
    setTimeout(() => {
      this.serverRequest();
    }, 200);
  }

  applySorting(e) {
    let value = e.target.value;
    let name = e.target.name;
    this.setState({
      orderByName: name,
      orderByValue: value,
    });
  }

  clearFilter(e) {
    let name = e.target.name;
    this.setState({
      [name]: "",
      orderByName: "id",
      orderByValue: "DESC",
    });
    setTimeout(() => {
      this.serverRequest();
    }, 500);
  }

  serverRequest() {
    const {
      skip,
      limit,
      searchKey,
      showModal,
    } = this.state;

    if (showModal === true) {
      this.setState({
        showModal: false,
      });
    }
    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${currentUser.data.token}`,
        "Content-Type": "application/json",
      },
    };
    let parms = `revenue?skip=${skip}&limit=${limit}&search=${searchKey}`;
    fetch(config.ADMIN_API_URL + parms, requestOptions)
      .then((res) => res.json())
      .then(
        (data) => {
          console.log("Revenue Data",data.data.items);
          this.setState({
            pageCount: Math.ceil(data.data.items.length / this.state.perPage),
            totalData: data?.data?.totalCount,
            items: data?.data?.items,
          });
        },
        (error) => {}
      );
  }

  async pageChange(e, data) {
    console.log("Handle Click", data);
    let page = data.activePage;
    let pageNo = page === 1 ? 0 : (page - 1) * this.state.limit;
    console.log("clicked", pageNo);
    await this.setState({
      skip: pageNo,
    });
    await this.serverRequest();
    // console.log("order", orders);
  }


  handleOpenModal(val) {
    this.setState({ activeModal: val });
    this.setState({ showModal: true });
  }

  exportCSV(event) {
    event.preventDefault();
    this.setState({ submitted: true });
    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${currentUser.data.token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        startDate: this.state.setStartDate,
        endDate: this.state.setEndDate,
      }),
    };

    fetch(
      config.ADMIN_API_URL + `revenue/export`,
      requestOptions
    )
      .then((res) => res.json())
      .then(
        (result) => {
          console.log();
          this.setState({
            csvLink: result.data,
          });
          let url = config.BASE_URL + result.data;
          saveAs(
            url,
            url 
          );
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error,
          });
        }
      );
  }

  handleCloseModal() {
    this.setState({ showModal: false });
    this.setState({ showModal: "" });
  }
  componentDidMount() {
    this.setState({ items: [] });
    this.serverRequest();
  }
  render() {
    const { items, skip } = this.state;
    return (
      <div>
        <AdminSidebar dataFromParent={this.props.location.pathname} />
        <AdminHeader />
        <div className="main_wrapper">
          <div className="inner_wraapper">
            <div className="container-fluid">
              <section className="d_text">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="col-sm-12">
                        <div className="color_div_on framwork_2">
                          <div className="business_detail">
                            <div className="">
                            <div className="heading">
                                <h4>Revenue</h4>
                              </div>
                              <hr className="line"></hr>
                              <div className="saved_cards">
                                <div className="business_detail">
                                  <div className="heading">
                                    <div className="heading_wth_text">
                                      <div className="d-flex">
                                        <span className="global_link mx-0">
                                          <button onClick={this.exportCSV} className="link_bal_next page_width white">
                                            <i className="fas fa-download white" />
                                          </button>
                                        </span>
                                      </div>
                                    </div>

                                    <div className="form-group has-search one">
                                      <span className="fa fa-search form-control-feedback"></span>
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Search Name, Plan and Amount..."
                                        name="search"
                                        onChange={(e) =>
                                          this.applyGlobalSearch(e)
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="table_f table-responsive">
                                  <Table striped bordered hover size="sm">
                                    <thead>
                                      <tr className="heading_color">
                                        <th style={{ width: "10%" }}>ID</th>
                                        <th>
                                          COMPANY NAME
                                          {/* <span>
                                            <i
                                              className="fad fa-sort-amount-up mx-3"
                                              variant="none"
                                              onClick={() =>
                                                this.handleOpenModal("country")
                                              }
                                            ></i>
                                          </span> */}
                                        </th>
                                        <th>
                                          MONTH
                                          {/* <span>
                                            <i
                                              className="fad fa-sort-amount-up mx-3"
                                              variant="none"
                                              onClick={() =>
                                                this.handleOpenModal("industry")
                                              }
                                            ></i>
                                          </span> */}
                                        </th>
                                        <th>
                                          SUBSCRIPTION PLAN
                                          {/* <span>
                                            <i
                                              className="fad fa-sort-amount-up mx-3"
                                              variant="none"
                                              onClick={() =>
                                                this.handleOpenModal("category")
                                              }
                                            ></i>
                                          </span> */}
                                        </th>
                                        <th>AMOUNT PAID</th>
                                        <th style={{ width: "10%" }}>
                                          INVOICE
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                    {(items|| []).map((item, key) => (
                                      <tr key={key}>
                                        <td>{(key + 1) + (skip)}</td>
                                        <td>{item.register_company_name}</td>
                                        <td><Moment
                                        format="MMM-YYYY"
                                        withTitle
                                      >
                                        {item.created_at}
                                      </Moment></td>
                                        <td>{item.title}</td>
                                        <td>${item.amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                                        <td>
                                          <NavLink
                                            className="table-tag"
                                            to={`/revenue/invoice/${item.uuid}`}
                                          >
                                            <span>
                                              <i className="fa fa-eye"></i>
                                            </span>
                                            View
                                          </NavLink>
                                        </td>
                                      </tr>
                                    ))}
                                     
                                   
                                    </tbody>
                                  </Table>
                                  <div className="pagination_billing justify-content-between">
                                  <Pagination
                                    className="mx-auto pagination"
                                    defaultActivePage={1}
                                    onPageChange={this.pageChange}
                                    ellipsisItem={{
                                      content: (
                                        <Icon name="ellipsis horizontal" />
                                      ),
                                      icon: true,
                                    }}
                                    firstItem={{
                                      content: (
                                        <Icon name="angle double left" />
                                      ),
                                      icon: true,
                                    }}
                                    lastItem={{
                                      content: (
                                        <Icon name="angle double right" />
                                      ),
                                      icon: true,
                                    }}
                                    prevItem={{
                                      content: <Icon name="angle left" />,
                                      icon: true,
                                    }}
                                    nextItem={{
                                      content: <Icon name="angle right" />,
                                      icon: true,
                                    }}
                                    totalPages={Math.ceil(
                                      this.state.totalData / this.state.limit
                                    )}
                                  />
                                  </div>
                                  <Modal
                                    animation={true}
                                    size="md"
                                    className="modal_box"
                                    shadow-lg="border"
                                    show={
                                      this.state.showModal &&
                                      this.state.activeModal === "login"
                                    }
                                  >
                                    <div className="modal-lg">
                                      <Modal.Header className="pb-0">
                                        <Button
                                          variant="outline-dark"
                                          onClick={this.handleCloseModal}
                                        >
                                          <i className="fa fa-times"></i>
                                        </Button>
                                      </Modal.Header>
                                      <div className="modal-body vekp pt-0">
                                        <div className="row">
                                          <div className="col-md-12">
                                            <div className="pb3">
                                              <h4>Add New Company</h4>
                                              <div className="dropdown">
                                                <label
                                                  htmlFor="exampleFormControlInput1"
                                                  className="form-label"
                                                >
                                                  Enter user's email address
                                                  below to send sign up invite
                                                </label>
                                                <input
                                                  className="btn btn-secondary dropdown-toggle my-3"
                                                  href="#"
                                                  role="button"
                                                  id="dropdownMenuLink"
                                                  data-bs-toggle="dropdown"
                                                  aria-expanded="false"
                                                  placeholder="John Cooper"
                                                />
                                                <div
                                                  className="dropdown-menu border-0 shadow"
                                                  aria-labelledby="dropdownMenuLink"
                                                >
                                                  <ul>
                                                    <li>
                                                      <NavLink
                                                        className="dropdown-item py-2 px-0"
                                                        to="/"
                                                      >
                                                        <div className="form-check check-form d-flex">
                                                          <div className="form-d">
                                                            <img
                                                              src={e1}
                                                              alt=""
                                                            />
                                                            <label
                                                              className="form-check-label"
                                                              htmlFor="exampleRadios1"
                                                            >
                                                              {" "}
                                                              John Cooper{" "}
                                                            </label>
                                                          </div>
                                                          <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="exampleRadios"
                                                            id="exampleRadios1"
                                                            value="option1"
                                                          />
                                                        </div>
                                                      </NavLink>
                                                    </li>
                                                    <li>
                                                      <NavLink
                                                        className="dropdown-item py-2 px-0"
                                                        to="/"
                                                      >
                                                        <div className="form-check check-form d-flex">
                                                          <div className="form-d">
                                                            <img
                                                              src={e2}
                                                              alt=""
                                                            />
                                                            <label
                                                              className="form-check-label"
                                                              htmlFor="exampleRadios1"
                                                            >
                                                              {" "}
                                                              John Cooper{" "}
                                                            </label>
                                                          </div>
                                                          <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="exampleRadios"
                                                            id="exampleRadios1"
                                                            value="option1"
                                                          />
                                                        </div>
                                                      </NavLink>
                                                    </li>
                                                    <li>
                                                      <NavLink
                                                        className="dropdown-item py-2 px-0"
                                                        to="/"
                                                      >
                                                        <div className="form-check check-form d-flex">
                                                          <div className="form-d">
                                                            <img
                                                              src={e3}
                                                              alt=""
                                                            />
                                                            <label
                                                              className="form-check-label"
                                                              htmlFor="exampleRadios1"
                                                            >
                                                              {" "}
                                                              John Cooper{" "}
                                                            </label>
                                                          </div>
                                                          <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="exampleRadios"
                                                            id="exampleRadios1"
                                                            value="option1"
                                                          />
                                                        </div>
                                                      </NavLink>
                                                    </li>
                                                    <li>
                                                      <NavLink
                                                        className="dropdown-item py-2 px-0"
                                                        to="/"
                                                      >
                                                        <div className="form-check check-form d-flex">
                                                          <div className="form-d">
                                                            <img
                                                              src={e1}
                                                              alt=""
                                                            />
                                                            <label
                                                              className="form-check-label"
                                                              htmlFor="exampleRadios1"
                                                            >
                                                              {" "}
                                                              John Cooper{" "}
                                                            </label>
                                                          </div>
                                                          <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="exampleRadios"
                                                            id="exampleRadios1"
                                                            value="option1"
                                                          />
                                                        </div>
                                                      </NavLink>
                                                    </li>
                                                    <li>
                                                      <NavLink
                                                        className="dropdown-item py-2 px-0"
                                                        to="/"
                                                      >
                                                        <div className="form-check check-form d-flex">
                                                          <div className="form-d">
                                                            <img
                                                              src={e2}
                                                              alt=""
                                                            />
                                                            <label
                                                              className="form-check-label"
                                                              htmlFor="exampleRadios1"
                                                            >
                                                              {" "}
                                                              John Cooper{" "}
                                                            </label>
                                                          </div>
                                                          <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="exampleRadios"
                                                            id="exampleRadios1"
                                                            value="option1"
                                                          />
                                                        </div>
                                                      </NavLink>
                                                    </li>
                                                    <li>
                                                      <NavLink
                                                        className="dropdown-item py-2 px-0"
                                                        to="/"
                                                      >
                                                        <div className="form-check check-form d-flex">
                                                          <div className="form-d">
                                                            <img
                                                              src={e3}
                                                              alt=""
                                                            />
                                                            <label
                                                              className="form-check-label"
                                                              htmlFor="exampleRadios1"
                                                            >
                                                              {" "}
                                                              John Cooper{" "}
                                                            </label>
                                                          </div>
                                                          <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="exampleRadios"
                                                            id="exampleRadios1"
                                                            value="option1"
                                                          />
                                                        </div>
                                                      </NavLink>
                                                    </li>
                                                    <li>
                                                      <NavLink
                                                        className="dropdown-item py-2 px-0"
                                                        to="/"
                                                      >
                                                        <div className="form-check check-form d-flex">
                                                          <div className="form-d">
                                                            <img
                                                              src={e1}
                                                              alt=""
                                                            />
                                                            <label
                                                              className="form-check-label"
                                                              htmlFor="exampleRadios1"
                                                            >
                                                              {" "}
                                                              John Cooper{" "}
                                                            </label>
                                                          </div>
                                                          <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="exampleRadios"
                                                            id="exampleRadios1"
                                                            value="option1"
                                                          />
                                                        </div>
                                                      </NavLink>
                                                    </li>
                                                  </ul>
                                                </div>
                                              </div>
                                              <div className="cenlr">
                                                <button
                                                  className="page_save page_width"
                                                  to="#"
                                                >
                                                  SEND
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </Modal>

                                  <Modal
                                    animation={true}
                                    size="md"
                                    className="modal_box"
                                    shadow-lg="border"
                                    show={
                                      this.state.showModal &&
                                      this.state.activeModal === "industry"
                                    }
                                  >
                                    <div className="modal-lg">
                                      <Modal.Header className="pb-0">
                                        <Button
                                          variant="outline-dark"
                                          onClick={this.handleCloseModal}
                                        >
                                          <i className="fa fa-times"></i>
                                        </Button>
                                      </Modal.Header>
                                      <div className="modal-body vekp pt-0">
                                        <div className="row">
                                          <div className="col-md-12">
                                            <div className="pb4">
                                              <div className="py-3">
                                                <div className="form-check-inline">
                                                  <label
                                                    className="form-check-label"
                                                    htmlFor="flexCheckChecked"
                                                  >
                                                    {" "}
                                                    Sort: A - Z{" "}
                                                  </label>
                                                  <input
                                                    className="form-check-input mx-3"
                                                    type="radio"
                                                    name="inlineRadioOptions"
                                                    id="inlineRadio1"
                                                    value="option1"
                                                  />
                                                </div>
                                                <div className="form-check-inline">
                                                  <label
                                                    className="form-check-label"
                                                    htmlFor="flexCheckChecked"
                                                  >
                                                    {" "}
                                                    Sort: Z - A{" "}
                                                  </label>
                                                  <input
                                                    className="form-check-input mx-3"
                                                    type="radio"
                                                    name="inlineRadioOptions"
                                                    id="inlineRadio1"
                                                    value="option1"
                                                  />
                                                </div>
                                              </div>

                                              <div className="input-group mb-3">
                                                <span className="fa fa-search form-control-feedback search-icon"></span>
                                                <input
                                                  type="text"
                                                  className="form-control dropdown-toggle"
                                                  data-toggle="dropdown"
                                                  aria-haspopup="true"
                                                  placeholder="Search"
                                                />
                                                <div className="dropdown-menu">
                                                  <div className="dropdown-item form d-flex justify-content-between">
                                                    <span>
                                                      Asset Management & Custody
                                                      Activities
                                                    </span>
                                                    <input
                                                      className="form-check-input"
                                                      type="radio"
                                                      name="radioNoLabel"
                                                      id="radioNoLabel1"
                                                      value=""
                                                      aria-label="..."
                                                    />
                                                  </div>

                                                  <div className="dropdown-item form d-flex justify-content-between">
                                                    <span>
                                                      Commercial Banks
                                                    </span>
                                                    <input
                                                      className="form-check-input"
                                                      type="radio"
                                                      name="radioNoLabel"
                                                      id="radioNoLabel1"
                                                      value=""
                                                      aria-label="..."
                                                    />
                                                  </div>

                                                  <div className="dropdown-item form d-flex justify-content-between">
                                                    <span>
                                                      Consumer Finance
                                                    </span>
                                                    <input
                                                      className="form-check-input"
                                                      type="radio"
                                                      name="radioNoLabel"
                                                      id="radioNoLabel1"
                                                      value=""
                                                      aria-label="..."
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="cenlr">
                                                <button
                                                  className="page_save page_width"
                                                  to="#"
                                                >
                                                  CLEAR
                                                </button>
                                                <button
                                                  className="page_save page_width"
                                                  to="#"
                                                >
                                                  APPLY
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </Modal>

                                  <Modal
                                    animation={true}
                                    size="md"
                                    className="modal_box"
                                    shadow-lg="border"
                                    show={
                                      this.state.showModal &&
                                      this.state.activeModal === "category"
                                    }
                                  >
                                    <div className="modal-lg">
                                      <Modal.Header className="pb-0">
                                        <Button
                                          variant="outline-dark"
                                          onClick={this.handleCloseModal}
                                        >
                                          <i className="fa fa-times"></i>
                                        </Button>
                                      </Modal.Header>
                                      <div className="modal-body vekp pt-0">
                                        <div className="row">
                                          <div className="col-md-12">
                                            <div className="pb4">
                                              <div className="py-3">
                                                <div className="form-check-inline">
                                                  <label
                                                    className="form-check-label"
                                                    htmlFor="flexCheckChecked"
                                                  >
                                                    {" "}
                                                    Sort: A - Z{" "}
                                                  </label>
                                                  <input
                                                    className="form-check-input mx-3"
                                                    type="radio"
                                                    name="inlineRadioOptions"
                                                    id="inlineRadio1"
                                                    value="option1"
                                                  />
                                                </div>
                                                <div className="form-check-inline">
                                                  <label
                                                    className="form-check-label"
                                                    htmlFor="flexCheckChecked"
                                                  >
                                                    {" "}
                                                    Sort: Z - A{" "}
                                                  </label>
                                                  <input
                                                    className="form-check-input mx-3"
                                                    type="radio"
                                                    name="inlineRadioOptions"
                                                    id="inlineRadio1"
                                                    value="option1"
                                                  />
                                                </div>
                                              </div>

                                              <div className="input-group mb-3">
                                                <span className="fa fa-search form-control-feedback search-icon"></span>
                                                <input
                                                  type="text"
                                                  className="form-control dropdown-toggle"
                                                  data-toggle="dropdown"
                                                  aria-haspopup="true"
                                                  placeholder="Search"
                                                />
                                                <div className="dropdown-menu">
                                                  <div className="dropdown-item form d-flex justify-content-between">
                                                    <span>Partner</span>
                                                    <input
                                                      className="form-check-input"
                                                      type="radio"
                                                      name="radioNoLabel"
                                                      id="radioNoLabel1"
                                                      value=""
                                                      aria-label="..."
                                                    />
                                                  </div>

                                                  <div className="dropdown-item form d-flex justify-content-between">
                                                    <span>
                                                      Business Account
                                                    </span>
                                                    <input
                                                      className="form-check-input"
                                                      type="radio"
                                                      name="radioNoLabel"
                                                      id="radioNoLabel1"
                                                      value=""
                                                      aria-label="..."
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="cenlr">
                                                <button
                                                  className="page_save page_width"
                                                  to="#"
                                                >
                                                  CLEAR
                                                </button>
                                                <button
                                                  className="page_save page_width"
                                                  to="#"
                                                >
                                                  APPLY
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </Modal>

                                  <Modal
                                    animation={true}
                                    size="md"
                                    className="modal_box"
                                    shadow-lg="border"
                                    show={
                                      this.state.showModal &&
                                      this.state.activeModal === "country"
                                    }
                                  >
                                    <div className="modal-lg">
                                      <Modal.Header className="pb-0">
                                        <Button
                                          variant="outline-dark"
                                          onClick={this.handleCloseModal}
                                        >
                                          <i className="fa fa-times"></i>
                                        </Button>
                                      </Modal.Header>
                                      <div className="modal-body vekp pt-0">
                                        <div className="row">
                                          <div className="col-md-12">
                                            <div className="pb4">
                                              <div className="py-3">
                                                <div className="form-check-inline">
                                                  <label
                                                    className="form-check-label"
                                                    htmlFor="flexCheckChecked"
                                                  >
                                                    {" "}
                                                    Sort: A - Z{" "}
                                                  </label>
                                                  <input
                                                    className="form-check-input mx-3"
                                                    type="radio"
                                                    name="inlineRadioOptions"
                                                    id="inlineRadio1"
                                                    value="option1"
                                                  />
                                                </div>
                                                <div className="form-check-inline">
                                                  <label
                                                    className="form-check-label"
                                                    htmlFor="flexCheckChecked"
                                                  >
                                                    {" "}
                                                    Sort: Z - A{" "}
                                                  </label>
                                                  <input
                                                    className="form-check-input mx-3"
                                                    type="radio"
                                                    name="inlineRadioOptions"
                                                    id="inlineRadio1"
                                                    value="option1"
                                                  />
                                                </div>
                                              </div>

                                              <div className="input-group mb-3">
                                                <span className="fa fa-search form-control-feedback search-icon"></span>
                                                <input
                                                  type="text"
                                                  className="form-control dropdown-toggle"
                                                  data-toggle="dropdown"
                                                  aria-haspopup="true"
                                                  placeholder="Search"
                                                />
                                                <div className="dropdown-menu">
                                                  <div className="dropdown-item form d-flex justify-content-between">
                                                    <span>Afghanistan</span>
                                                    <input
                                                      className="form-check-input"
                                                      type="radio"
                                                      name="radioNoLabel"
                                                      id="radioNoLabel1"
                                                      value=""
                                                      aria-label="..."
                                                    />
                                                  </div>

                                                  <div className="dropdown-item form d-flex justify-content-between">
                                                    <span>Albania</span>
                                                    <input
                                                      className="form-check-input"
                                                      type="radio"
                                                      name="radioNoLabel"
                                                      id="radioNoLabel1"
                                                      value=""
                                                      aria-label="..."
                                                    />
                                                  </div>

                                                  <div className="dropdown-item form d-flex justify-content-between">
                                                    <span>Algeria</span>
                                                    <input
                                                      className="form-check-input"
                                                      type="radio"
                                                      name="radioNoLabel"
                                                      id="radioNoLabel1"
                                                      value=""
                                                      aria-label="..."
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="cenlr">
                                                <button
                                                  className="page_save page_width"
                                                  to="#"
                                                >
                                                  CLEAR
                                                </button>
                                                <button
                                                  className="page_save page_width"
                                                  to="#"
                                                >
                                                  APPLY
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </Modal>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    );
  }
}