import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import logo from "../../img/logosine.png";
import axios from "axios";
import { sweetAlert } from "../../utils/UniversalFunction";
import "./login.css";
import "../sidebar/common.css";
import config from "../../config/config.json";

export default class NewPassword extends Component {
  constructor(props) {
    super(props);
    // redirect to home if already logged in
    this.state = {
      user: {
        password: "",
        confirmPassword: "",
      },
      type: "password",
      type1: "password",
      submitted: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.showHide = this.showHide.bind(this);
    this.showHide1 = this.showHide1.bind(this);
  }

  handleChange(event) {
    const { name, value } = event.target;
    const { user } = this.state;
    this.setState({
      user: {
        ...user,
        [name]: value,
      },
    });
  }

  showHide(e) {
    e.preventDefault();
    e.stopPropagation();
    this.setState({
      type: this.state.type === "password" ? "input" : "password"
    });
  }

  showHide1(e) {
    e.preventDefault();
    e.stopPropagation();
    this.setState({
      type1: this.state.type1 === "password" ? "input" : "password"
    });
  }


  handleSubmit(event) {
    event.preventDefault();
    this.setState({ submitted: true });
    const { user } = this.state;
    let checkBothPasswordSameOrNot = user.password === user.confirmPassword;
    if (checkBothPasswordSameOrNot) {
      if (user.password) {
        axios
          .post(config.ADMIN_API_URL + "token/verfy", {
            token: window.location.href.split("/").pop(),
            password: user.password,
          })
          .then((response) => {
            sweetAlert("success", response.data.message);
            setTimeout(() => {
              window.location.href = config.baseURL + "/Login";
            }, 1000);
          })
          .catch(function (error) {
            if (error.response) {
              sweetAlert("error", error.response.data.message);
            }
          });
      } else {
        sweetAlert("error", "Please fill Password");
      }
    } else {
      sweetAlert(
        "error",
        "Your password or confirm password not matched please check and try again later..!"
      );
    }
  }

  render() {
    const { user, submitted, type, type1 } = this.state;
    return (
      <div>
        <section className="login">
          <div className="login_part">
            <div className="sing_log">
              <div className="sing_one">
                <img src={logo} alt="logo" />
              </div>
              <div className="text_sing mb-4">
                <h4 className="Account">Reset Password</h4>
                <p className="faster_oval">Enter Your Password</p>
              </div>
              <form name="form" onSubmit={this.handleSubmit}>
                <div className="ster_form">
                  <div className="make_form">
                    <div className="row">
                      <div className="col-lg-12 col-xs-12">
                        <div className="login_bt form_sign">
                          <div className="form-group fg">
                            <label className="st_name" htmlFor="name">
                              Password
                            </label>
                            <input
                              className="form-control name_nf"
                              type={type}
                              name="password"
                              placeholder="Enter New Password"
                              value={user.password}
                              onChange={this.handleChange}
                            />
                            <div className="img-eye eyeIcon">
                            <span class="eyebutton" onClick={this.showHide}>
                              {this.state.type === "input" ? (
                                <i className="fas fa-eye-slash"></i>
                              ) : (
                                <i className="fas fa-eye"></i>
                              )}
                            </span>
                          </div>
                            {submitted && !user.password && (
                              <div className="help-block">
                                New Password is required
                              </div>
                            )}
                          </div>
                          <div className="form-group fg mt-5">
                            <label className="st_name" htmlFor="name">
                              Confirm Password
                            </label>
                            <input
                              className="form-control name_nf"
                              type={type1}
                              name="confirmPassword"
                              placeholder="Enter Confirm Password"
                              value={user.confirmPassword}
                              onChange={this.handleChange}
                            />
                            <div className="img-eye eyeIcon">
                            <span className="eyebutton" onClick={this.showHide1}>
                              {this.state.type1 === "input" ? (
                                <i className="fas fa-eye-slash"></i>
                              ) : (
                                <i className="fas fa-eye"></i>
                              )}
                            </span>
                          </div>
                            {submitted && !user.confirmPassword && (
                              <div className="help-block">
                                Conform Password is required
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="view_bottoma">
                    {user.password && user.confirmPassword ? (
                      <button type="submit" value="Submit" className="btn">
                        Reset
                      </button>
                    ) : (
                      <button
                        type="submit"
                        disabled
                        value="Submit"
                        className="btn button_color"
                      >
                        Reset
                      </button>
                    )}
                  </div>
                  <div className="global d-flex justify-content-center m-3">
                    <NavLink
                      className="page_width page_save button_color"
                      to="/Login"
                    >
                      Back to Login
                    </NavLink>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
