import React from "react";
import { Redirect } from "react-router-dom";
import { getStore } from "./utils/UniversalFunction";

export function OtpVerification(a, b) {
  const auth = getStore("currentUser");
  const statusFor2Fa = getStore("2faStatus");
  if (auth) {
    let status = statusFor2Fa === 0;
    if (status) {
      return <Redirect to={{ pathname: "/OTPVerify" }} />;
    } else {
      return <Redirect to={{ pathname: document.URL.split("/").pop() }} />;
    }
  } else {
    // let urls = ["ResetPass", "privacy_policy","verify"];
    // const result = urls.filter(checkAdult);
    // function checkAdult(uri) {
    //   return uri === document.URL.split("/").pop();
    // }
    // let isMatched = result;
    // if (isMatched.length > 0) {
      return <Redirect to={{ pathname: document.URL.split("/").pop() }} />;
    // } else {
    //   return <Redirect to={{ pathname: "/login" }} />;
    // }
  }
}

export default OtpVerification;
