import React, { Component } from "react";
import axios from "axios";
import { sweetAlert } from "../../../utils/UniversalFunction";
import AdminSidebar from "../../sidebar/admin_sidebar";
import AdminHeader from "../../header/admin_header";
import Table from "react-bootstrap/Table";
import { NavLink } from "react-router-dom";
import { Button, Modal } from "react-bootstrap";
import { authenticationService } from "../../../_services/authentication";
import "../../Company Admin/Setting/setting.css";
import { Pagination, Icon } from "semantic-ui-react";
import config from "../../../config/config.json";
const currentUser = authenticationService.currentUserValue;
const ProfilePics =
  "https://res.cloudinary.com/dmklsntsw/image/upload/v1658480882/dummyPic.75a04487_fqfqey.png";
export default class UserDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      login: false,
      country: false,
      industry: false,
      category: false,
      activeModal: "",
      firstName: "",
      lastName: "",
      position: "",
      email: "",
      id: "",
      password: "",
      uuid: "",
      items: [],
      loading: false,
      totalRows: 0,
      currentPage: 1,
      skip: 0,
      limit: 10,
      countryFilter: "",
      industryFilter: "",
      categoryFilter: "",
      orderByName: "id",
      orderByValue: "DESC",
      search: "",
      pageCount: 0,
      totalData: 0,
      countriesList: [],
      industryList: [],
      isChecked: false,
    };
    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.serverRequest = this.serverRequest.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.applyFilter = this.applyFilter.bind(this);
    this.applyCountry = this.applyCountry.bind(this);
    this.applyIndustry = this.applyIndustry.bind(this);
    this.applyCategory = this.applyCategory.bind(this);
    this.applySorting = this.applySorting.bind(this);
    this.clearFilter = this.clearFilter.bind(this);
    this.companyList = this.companyList.bind(this);
    this.getCountries = this.getCountries.bind(this);
    this.getIndustryName = this.getIndustryName.bind(this);
    this.exportCSV = this.exportCSV.bind(this);
    this.pageChange = this.pageChange.bind(this);
  }

  applyFilter(e) {
    let value = e.target.value;
    let name = e.target.name;
    this.setState({
      [name]: value,
    });
  }

  applyCountry(e) {
    let value = e.target.value;
    let name = e.target.name;
    this.setState({
      [name]: value,
    });
    setTimeout(() => {
      this.companyList(this.state.uuid);
    }, 200);
  }

  applyIndustry(e) {
    let value = e.target.value;
    let name = e.target.name;
    this.setState({
      [name]: value,
    });
    setTimeout(() => {
      this.companyList(this.state.uuid);
    }, 200);
  }

  applyCategory(e) {
    let value = e.target.value;
    let name = e.target.name;
    this.setState({
      [name]: value,
    });
    setTimeout(() => {
      this.companyList(this.state.uuid);
    }, 200);
  }

  applyGlobalSearch(e) {
    let value = e.target.value;
    let name = e.target.name;
    this.setState({
      [name]: value,
    });
    setTimeout(() => {
      this.companyList(this.state.uuid);
    }, 200);
  }

  applySorting(e) {
    let value = e.target.value;
    let name = e.target.name;
    this.setState({
      orderByName: name,
      orderByValue: value,
    });
  }

  clearFilter(e) {
    let name = e.target.name;
    this.setState({
      [name]: "",
      orderByName: "id",
      orderByValue: "DESC",
    });
    setTimeout(() => {
      this.companyList(this.state.uuid);
    }, 500);
  }

  handleChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  }

  companyList(uuid) {
    const {
      skip,
      limit,
      countryFilter,
      industryFilter,
      categoryFilter,
      orderByName,
      orderByValue,
      search,
      showModal,
    } = this.state;
    if (showModal === true) {
      this.setState({
        showModal: false,
      });
    }
    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${currentUser.data.token}`,
        "Content-Type": "application/json",
      },
    };
    let parms = `sub-admins/${uuid}/companies/?offset=${skip}&limit=${limit}&country=${countryFilter}&industry=${industryFilter}&category=${categoryFilter}&orderByName=${orderByName}&orderByValue=${orderByValue}&search=${search}`;
    fetch(config.ADMIN_API_URL + parms, requestOptions)
      .then((res) => res.json())
      .then(
        (data) => {
          console.log("Data is here", data);
          this.setState({
            pageCount: Math.ceil(data.length / this.state.perPage),
            totalData: data.data.count,
            items: data.data.rows,
          });
        },
        (error) => {}
      );
  }

  exportCSV(event) {
    event.preventDefault();
    this.setState({ submitted: true });
    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${currentUser.data.token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        startDate: this.state.setStartDate,
        endDate: this.state.setEndDate,
      }),
    };

    fetch(
      config.ADMIN_API_URL + `sub-admins/${this.state.uuid}/companies/export`,
      requestOptions
    )
      .then((res) => res.json())
      .then(
        (result) => {
          let url = config.BASE_URL + result.data;
          window.open(url, "_blank");
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error,
          });
        }
      );
  }

  pageChange(e, data) {
    console.log("Handle Click", data);
    let page = data.activePage;
    let pageNo = page === 1 ? 0 : (page - 1) * this.state.limit;
    console.log("clicked", pageNo);
    this.setState({
      skip: pageNo,
    });
    setTimeout(() => {
      this.companyList(this.state.uuid);
    }, 200);
  }

  serverRequest(uuidd) {
    let uuid = uuidd;
    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${currentUser.data.token}`,
        "Content-Type": "application/json",
      },
    };
    let parms = `sub-admins/${uuid}`;
    fetch(config.ADMIN_API_URL + parms, requestOptions)
      .then((res) => res.json())
      .then(
        (data) => {
          this.setState({
            firstName: data.data?.firstName,
            lastName: data.data?.lastName,
            position: data.data?.position,
            email: data.data?.email,
            id: data.data?.id,
            isChecked: data.data?.isActive === 1 ? true : false,
            logo:
              data?.logo === "" 
              ? ProfilePics 
              : config.BASE_URL + data?.logo,
          });
          console.log("hello", this.state.logo);
        },
        (error) => {}
      );
  }

  onFileChange = (event) => {
    let uuid = this.state.uuid;
    const formData = new FormData();
    // Update the formData object
    if (event.target.files[0].name !== undefined) {
      formData.append(
        "uploadImage",
        event.target.files[0],
        event.target.files[0].name
      );
      // formData.append("uuid", uuid);
      const headers = {
        Authorization: `Bearer ${currentUser.data.token}`,
        Accept: "application/json",
      };
      axios
        .post(
          config.ADMIN_API_URL + `sub-admins/${uuid}/uploadProfilePicture`,
          formData,
          {
            headers,
          }
        )
        .then((response) => {
          sweetAlert("success", response.data.message);
          this.setState({ logo: response.data.data });
          this.serverRequest(this.state.uuid);
        })
        .catch(function (response) {
          sweetAlert("error", response.data.message);
        });
    }
  };

  updateUserStatus = (event) => {
    let uuid = this.state.uuid;
    let isChecked = event.target.checked;
    const headers = {
      Authorization: `Bearer ${currentUser.data.token}`,
      Accept: "application/json",
    };
    let isActiveVal;
    if (isChecked === true) {
      isActiveVal = 1;
    } else {
      isActiveVal = 0;
    }
    axios
      .post(
        `${config.ADMIN_API_URL}sub-admins/${uuid}/updateStatus`,
        {
          status: isActiveVal,
        },
        {
          headers,
        }
      )
      .then((response) => {
        sweetAlert("success", response.data.message);
        this.setState({ isChecked: isActiveVal === 1 ? true : false });
      })
      .catch(function (response) {
        sweetAlert("error", response.data.message);
      });
  };

  handleSubmit(event) {
    event.preventDefault();
    this.setState({ submitted: true });
    const { firstName, lastName, position, uuid, password } = this.state;
    const headers = {
      Authorization: `Bearer ${currentUser.data.token}`,
      Accept: "application/json",
    };
    axios
      .post(
        `${config.ADMIN_API_URL}sub-admins/${uuid}`,
        {
          first_name: firstName,
          last_name: lastName,
          position: position,
          password: password,
        },
        { headers }
      )
      .then((response) => {
        sweetAlert("success", response.data.message);
        this.serverRequest(this.state.uuid);
      })
      .catch(function (error) {
        if (error.response) {
          sweetAlert("error", error.response.data.message);
        }
      });
  }

  getProfileData() {}

  handleOpenModal(val) {
    this.setState({ activeModal: val });
    this.setState({ showModal: true });
  }

  handleCloseModal() {
    this.setState({ showModal: false });
    this.setState({ showModal: "" });
  }

  getCountries() {
    fetch(config.API_URL + "getAllCountries")
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            countriesList: result.countries,
          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error,
          });
        }
      );
  }

  getIndustryName() {
    fetch(config.API_URL + "getCompanyIndustoryName")
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            industryList: result.companyIndustory,
          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error,
          });
        }
      );
  }

  componentDidMount() {
    let urlArr = window.location.pathname.split("/");
    this.serverRequest(urlArr[urlArr.length - 1]);
    this.companyList(urlArr[urlArr.length - 1]);
    this.getCountries();
    this.getIndustryName();
    this.setState({
      uuid: urlArr[urlArr.length - 1],
    });
    this.setState({ items: [] });
  }

  render() {
    const { items, industryList, countriesList, logo } = this.state;
    return (
      <div>
        <AdminSidebar dataFromParent={this.props.location.pathname} />
        <AdminHeader />
        <div className="main_wrapper">
          <div className="inner_wraapper">
            <div className="container-fluid">
              <section className="d_text">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="SVG Stepper">
                        <div className="stepperr_design">
                          <div className="color_div_step">
                            <div className="include">
                              <div className="row forms">
                                <div className="heading">
                                  <h4>Sub User Details</h4>
                                  <div className="text-right index">
                                    {/* <span>
                                      <i className="fa fa-edit mx-3"></i>
                                    </span>
                                    <span>
                                      <i className="fas fa-trash mx-3"></i>
                                    </span> */}
                                    <div className="impa ml-3">
                                      <label className="switch">
                                        <input
                                          type="checkbox"
                                          onChange={(e) =>
                                            this.updateUserStatus(e)
                                          }
                                          checked={this.state.isChecked}
                                        />
                                        <span className="slider round"></span>
                                      </label>
                                    </div>
                                  </div>
                                </div>
                                <hr className="line mt-3"></hr>
                                <div className="col-md-8 col-xs-12">
                                  <form onSubmit={this.handleSubmit}>
                                    <div className="business_detail">
                                      <div className="row my-3">
                                        <div className="col-lg-6 col-xs-6">
                                          <div className="form-group pb-3">
                                            <label htmlFor="exampleInputPassword1">
                                              First Name
                                            </label>
                                            <input
                                              type="text"
                                              className="form-control"
                                              placeholder="John"
                                              name="firstName"
                                              onChange={this.handleChange}
                                              value={this.state.firstName}
                                            />
                                          </div>
                                        </div>
                                        <div className="col-lg-6 col-xs-6">
                                          <div className="form-group pb-3">
                                            <label htmlFor="exampleInputPassword1">
                                              Last Name
                                            </label>
                                            <input
                                              type="text"
                                              onChange={this.handleChange}
                                              name="lastName"
                                              className="form-control"
                                              placeholder="Doe"
                                              value={this.state.lastName}
                                            />
                                          </div>
                                        </div>
                                        <div className="col-lg-6 col-xs-6">
                                          <div className="form-group pb-3">
                                            <label htmlFor="exampleInputPassword1">
                                              Title or Position
                                            </label>
                                            <input
                                              type="text"
                                              name="position"
                                              onChange={this.handleChange}
                                              className="form-control disableddd"
                                              disabled
                                              placeholder="CRO"
                                              value={this.state.position}
                                            />
                                          </div>
                                        </div>
                                        <div className="col-lg-6 col-xs-6">
                                          <div className="form-group pb-3">
                                            <label htmlFor="exampleInputPassword1">
                                              Corporate Email
                                            </label>
                                            <input
                                              type="text"
                                              readOnly
                                              className="form-control disableddd"
                                              name="email"
                                              onChange={this.handleChange}
                                              placeholder="johndoe@esg.com"
                                              value={this.state.email}
                                            />
                                          </div>
                                        </div>
                                        <div className="col-lg-12 col-xs-12">
                                          <div className="form-group pb-3">
                                            <label htmlFor="exampleInputPassword1">
                                              Reset password
                                            </label>
                                            <input
                                              type="password"
                                              className="form-control"
                                              id="exampleInputPassword1"
                                              placeholder="Enter password for Change or leave this field"
                                              name="password"
                                              onChange={this.handleChange}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <button
                                      type="submit"
                                      className="page_with page_save"
                                    >
                                      Save
                                    </button>
                                  </form>
                                </div>
                                <div className="col-lg-4 col-xs-12">
                                  <div className="upload_image">
                                    <img
                                      className="file-upload-image"
                                      src={logo}
                                      alt=""
                                    />
                                    <input
                                      className=""
                                      accept='.jpg, .png, .jpeg'
                                      type="file"
                                      onChange={this.onFileChange}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="color_div_step mt-3">
                            <div className="include">
                              <div className="row">
                                <div className="saved_cards business_detail">
                                  <div className="heading">
                                    <div className="heading_wth_text">
                                      <div className="d-flex">
                                        <span className="global_link mx-0">
                                          <NavLink
                                            to={`/sub-users/${this.state.uuid}/companies`}
                                            className="link_bal_next page_width white"
                                          >
                                            ASSIGN COMPANY{" "}
                                          </NavLink>
                                        </span>
                                        <span className="global_link mx-3">
                                          <button
                                            onClick={this.exportCSV}
                                            className="link_bal_next page_width white"
                                          >
                                            <i className="fas fa-download white" />
                                          </button>
                                        </span>
                                        {/* <span className="global_link mx-0">
                                          <button className="link_bal_next page_width white">
                                            <i className="fas fa-sort-amount-up-alt white" />
                                          </button>
                                        </span> */}
                                      </div>
                                    </div>

                                    <div className="form-group has-search one">
                                      <span className="fa fa-search form-control-feedback"></span>
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Search Category, Industry &amp; Business Number"
                                        name="search"
                                        onChange={(e) =>
                                          this.applyGlobalSearch(e)
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>

                                <div className="table_f table-responsive">
                                  <Table striped bordered hover size="sm">
                                    <thead>
                                      <tr className="heading_color">
                                        <th>ID</th>
                                        <th>COMPANY NAME</th>
                                        <th>
                                          COUNTRY
                                          <span>
                                            <i
                                              className="fad fa-sort-amount-up mx-3"
                                              variant="none"
                                              onClick={() =>
                                                this.handleOpenModal("country")
                                              }
                                            ></i>
                                          </span>
                                        </th>
                                        <th>BUSINESS NUMBER</th>
                                        <th>
                                          INDUSTRY
                                          <span>
                                            <i
                                              className="fad fa-sort-amount-up mx-3"
                                              variant="none"
                                              onClick={() =>
                                                this.handleOpenModal("industry")
                                              }
                                            ></i>
                                          </span>
                                        </th>
                                        <th>
                                          CATEGORY
                                          <span>
                                            <i
                                              className="fad fa-sort-amount-up mx-3"
                                              variant="none"
                                              onClick={() =>
                                                this.handleOpenModal("category")
                                              }
                                            ></i>
                                          </span>
                                        </th>
                                        <th>STATUS</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {items.map((item, key) => (
                                        <tr key={key}>
                                          <td>{key + 1}</td>
                                          <td>
                                            <NavLink
                                              className="non_underline_link"
                                              to={
                                                "/companies/" +
                                                item.uuid +
                                                "/details"
                                              }
                                            >
                                              <span className="black bold">
                                                {item.register_company_name}
                                              </span>
                                            </NavLink>
                                          </td>
                                          <td>{item.country}</td>
                                          <td>{item.businessNumber}</td>
                                          <td>{item.company_industry}</td>
                                          <td>{item.userCategory}</td>
                                          <td>
                                            <NavLink
                                              className="non_underline_link"
                                              to={
                                                "/companies/" +
                                                item.uuid +
                                                "/details"
                                              }
                                            >
                                              <span className="red bold">
                                                {item.isActive === 0 &&
                                                  "Inactive"}
                                              </span>
                                              <span className="green bold">
                                                {item.isActive === 1 &&
                                                  "Active"}
                                              </span>
                                            </NavLink>
                                          </td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  </Table>
                                  <Pagination
                                    className="mx-auto pagination"
                                    defaultActivePage={1}
                                    onPageChange={this.pageChange}
                                    ellipsisItem={{
                                      content: (
                                        <Icon name="ellipsis horizontal" />
                                      ),
                                      icon: true,
                                    }}
                                    firstItem={{
                                      content: (
                                        <Icon name="angle double left" />
                                      ),
                                      icon: true,
                                    }}
                                    lastItem={{
                                      content: (
                                        <Icon name="angle double right" />
                                      ),
                                      icon: true,
                                    }}
                                    prevItem={{
                                      content: <Icon name="angle left" />,
                                      icon: true,
                                    }}
                                    nextItem={{
                                      content: <Icon name="angle right" />,
                                      icon: true,
                                    }}
                                    totalPages={Math.ceil(
                                      this.state.totalData / this.state.limit
                                    )}
                                  />
                                  {/* <Modal
                                    animation={true}
                                    size="md"
                                    className="modal_box"
                                    shadow-lg="border"
                                    show={
                                      this.state.showModal &&
                                      this.state.activeModal === "login"
                                    }
                                  >
                                    <div className="modal-lg">
                                      <Modal.Header className="pb-0">
                                        <Button
                                          variant="outline-dark"
                                          onClick={this.handleCloseModal}
                                        >
                                          <i className="fa fa-times"></i>
                                        </Button>
                                      </Modal.Header>
                                      <div className="modal-body vekp pt-0">
                                        <div className="row">
                                          <div className="col-md-12">
                                            <div className="pb3">
                                              <h4>Add New Company</h4>
                                              <div className="dropdown">
                                                <label
                                                  htmlFor="exampleFormControlInput1"
                                                  className="form-label"
                                                >
                                                  Enter user's email address
                                                  below to send sign up invite
                                                </label>
                                                <input
                                                  className="btn btn-secondary dropdown-toggle my-3"
                                                  href="#"
                                                  role="button"
                                                  id="dropdownMenuLink"
                                                  data-bs-toggle="dropdown"
                                                  aria-expanded="false"
                                                  placeholder="John Cooper"
                                                />
                                                <div
                                                  className="dropdown-menu border-0 shadow"
                                                  aria-labelledby="dropdownMenuLink"
                                                >
                                                  <ul>
                                                    <li>
                                                      <NavLink
                                                        className="dropdown-item py-2 px-0"
                                                        to="/"
                                                      >
                                                        <div className="form-check check-form d-flex">
                                                          <div className="form-d">
                                                            <img
                                                              src={e1}
                                                              alt=""
                                                            />
                                                            <label
                                                              className="form-check-label"
                                                              htmlFor="exampleRadios1"
                                                            >
                                                              {" "}
                                                              John Cooper{" "}
                                                            </label>
                                                          </div>
                                                          <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="exampleRadios"
                                                            id="exampleRadios1"
                                                            value="option1"
                                                          />
                                                        </div>
                                                      </NavLink>
                                                    </li>
                                                    <li>
                                                      <NavLink
                                                        className="dropdown-item py-2 px-0"
                                                        to="/"
                                                      >
                                                        <div className="form-check check-form d-flex">
                                                          <div className="form-d">
                                                            <img
                                                              src={e2}
                                                              alt=""
                                                            />
                                                            <label
                                                              className="form-check-label"
                                                              htmlFor="exampleRadios1"
                                                            >
                                                              {" "}
                                                              John Cooper{" "}
                                                            </label>
                                                          </div>
                                                          <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="exampleRadios"
                                                            id="exampleRadios1"
                                                            value="option1"
                                                          />
                                                        </div>
                                                      </NavLink>
                                                    </li>
                                                    <li>
                                                      <NavLink
                                                        className="dropdown-item py-2 px-0"
                                                        to="/"
                                                      >
                                                        <div className="form-check check-form d-flex">
                                                          <div className="form-d">
                                                            <img
                                                              src={e3}
                                                              alt=""
                                                            />
                                                            <label
                                                              className="form-check-label"
                                                              htmlFor="exampleRadios1"
                                                            >
                                                              {" "}
                                                              John Cooper{" "}
                                                            </label>
                                                          </div>
                                                          <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="exampleRadios"
                                                            id="exampleRadios1"
                                                            value="option1"
                                                          />
                                                        </div>
                                                      </NavLink>
                                                    </li>
                                                    <li>
                                                      <NavLink
                                                        className="dropdown-item py-2 px-0"
                                                        to="/"
                                                      >
                                                        <div className="form-check check-form d-flex">
                                                          <div className="form-d">
                                                            <img
                                                              src={e1}
                                                              alt=""
                                                            />
                                                            <label
                                                              className="form-check-label"
                                                              htmlFor="exampleRadios1"
                                                            >
                                                              {" "}
                                                              John Cooper{" "}
                                                            </label>
                                                          </div>
                                                          <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="exampleRadios"
                                                            id="exampleRadios1"
                                                            value="option1"
                                                          />
                                                        </div>
                                                      </NavLink>
                                                    </li>
                                                    <li>
                                                      <NavLink
                                                        className="dropdown-item py-2 px-0"
                                                        to="/"
                                                      >
                                                        <div className="form-check check-form d-flex">
                                                          <div className="form-d">
                                                            <img
                                                              src={e2}
                                                              alt=""
                                                            />
                                                            <label
                                                              className="form-check-label"
                                                              htmlFor="exampleRadios1"
                                                            >
                                                              {" "}
                                                              John Cooper{" "}
                                                            </label>
                                                          </div>
                                                          <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="exampleRadios"
                                                            id="exampleRadios1"
                                                            value="option1"
                                                          />
                                                        </div>
                                                      </NavLink>
                                                    </li>
                                                    <li>
                                                      <NavLink
                                                        className="dropdown-item py-2 px-0"
                                                        to="/"
                                                      >
                                                        <div className="form-check check-form d-flex">
                                                          <div className="form-d">
                                                            <img
                                                              src={e3}
                                                              alt=""
                                                            />
                                                            <label
                                                              className="form-check-label"
                                                              htmlFor="exampleRadios1"
                                                            >
                                                              {" "}
                                                              John Cooper{" "}
                                                            </label>
                                                          </div>
                                                          <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="exampleRadios"
                                                            id="exampleRadios1"
                                                            value="option1"
                                                          />
                                                        </div>
                                                      </NavLink>
                                                    </li>
                                                    <li>
                                                      <NavLink
                                                        className="dropdown-item py-2 px-0"
                                                        to="/"
                                                      >
                                                        <div className="form-check check-form d-flex">
                                                          <div className="form-d">
                                                            <img
                                                              src={e1}
                                                              alt=""
                                                            />
                                                            <label
                                                              className="form-check-label"
                                                              htmlFor="exampleRadios1"
                                                            >
                                                              {" "}
                                                              John Cooper{" "}
                                                            </label>
                                                          </div>
                                                          <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="exampleRadios"
                                                            id="exampleRadios1"
                                                            value="option1"
                                                          />
                                                        </div>
                                                      </NavLink>
                                                    </li>
                                                  </ul>
                                                </div>
                                              </div>
                                              <div className="cenlr">
                                                <button
                                                  className="page_save page_width"
                                                  to="#"
                                                >
                                                  SEND
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </Modal> */}

                                  <Modal
                                    animation={true}
                                    size="md"
                                    className="modal_box"
                                    shadow-lg="border"
                                    show={
                                      this.state.showModal &&
                                      this.state.activeModal === "industry"
                                    }
                                  >
                                    <div className="modal-lg">
                                      <Modal.Header className="pb-0">
                                        <Button
                                          variant="outline-dark"
                                          onClick={this.handleCloseModal}
                                        >
                                          <i className="fa fa-times"></i>
                                        </Button>
                                      </Modal.Header>
                                      <div className="modal-body vekp pt-0">
                                        <div className="row">
                                          <div className="col-md-12">
                                            <div className="pb4">
                                              <div className="py-3">
                                                <div className="form-check-inline">
                                                  <label
                                                    className="form-check-label"
                                                    htmlFor="flexCheckChecked"
                                                  >
                                                    {" "}
                                                    Sort: A - Z{" "}
                                                  </label>
                                                  <input
                                                    className="form-check-input mx-3"
                                                    type="radio"
                                                    name="inlineRadioOptions"
                                                    id="inlineRadio1"
                                                    value="option1"
                                                  />
                                                </div>
                                                <div className="form-check-inline">
                                                  <label
                                                    className="form-check-label"
                                                    htmlFor="flexCheckChecked"
                                                  >
                                                    {" "}
                                                    Sort: Z - A{" "}
                                                  </label>
                                                  <input
                                                    className="form-check-input mx-3"
                                                    type="radio"
                                                    name="inlineRadioOptions"
                                                    id="inlineRadio1"
                                                    value="option1"
                                                  />
                                                </div>
                                              </div>

                                              <div className="input-group mb-3">
                                                <select
                                                  className="form-control dropdown-toggle"
                                                  name="industryFilter"
                                                  onChange={(e) =>
                                                    this.applyFilter(e)
                                                  }
                                                >
                                                  <option value="">
                                                    Select Industry
                                                  </option>

                                                  {industryList.map((item2) => (
                                                    <option
                                                      key={item2.id}
                                                      value={item2.title}
                                                    >
                                                      {item2.title}
                                                    </option>
                                                  ))}
                                                </select>
                                              </div>
                                              <div className="cenlr">
                                                <button
                                                  className="page_save page_width"
                                                  name="industryFilter"
                                                  onClick={this.clearFilter}
                                                >
                                                  CLEAR
                                                </button>
                                                <button
                                                  className="page_save page_width"
                                                  onClick={this.applyIndustry}
                                                >
                                                  APPLY
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </Modal>

                                  <Modal
                                    animation={true}
                                    size="md"
                                    className="modal_box"
                                    shadow-lg="border"
                                    show={
                                      this.state.showModal &&
                                      this.state.activeModal === "category"
                                    }
                                  >
                                    <div className="modal-lg">
                                      <Modal.Header className="pb-0">
                                        <Button
                                          variant="outline-dark"
                                          onClick={this.handleCloseModal}
                                        >
                                          <i className="fa fa-times"></i>
                                        </Button>
                                      </Modal.Header>
                                      <div className="modal-body vekp pt-0">
                                        <div className="row">
                                          <div className="col-md-12">
                                            <div className="pb4">
                                              <div className="py-3">
                                                <div className="form-check-inline">
                                                  <label
                                                    className="form-check-label"
                                                    htmlFor="flexCheckChecked"
                                                  >
                                                    {" "}
                                                    Sort: A - Z{" "}
                                                  </label>
                                                  <input
                                                    className="form-check-input mx-3"
                                                    type="radio"
                                                    name="inlineRadioOptions"
                                                    id="inlineRadio1"
                                                    value="option1"
                                                  />
                                                </div>
                                                <div className="form-check-inline">
                                                  <label
                                                    className="form-check-label"
                                                    htmlFor="flexCheckChecked"
                                                  >
                                                    {" "}
                                                    Sort: Z - A{" "}
                                                  </label>
                                                  <input
                                                    className="form-check-input mx-3"
                                                    type="radio"
                                                    name="inlineRadioOptions"
                                                    id="inlineRadio1"
                                                    value="option1"
                                                  />
                                                </div>
                                              </div>

                                              <div className="input-group mb-3">
                                                <select
                                                  type="text"
                                                  className="form-control dropdown-toggle"
                                                  placeholder="Search"
                                                  name="categoryFilter"
                                                  onChange={(e) =>
                                                    this.applyFilter(e)
                                                  }
                                                >
                                                  <option value="">
                                                    Select Category
                                                  </option>
                                                  <option value="Partner">
                                                    Partner
                                                  </option>
                                                  <option value="Business Account">
                                                    Business Account
                                                  </option>
                                                </select>
                                              </div>
                                              <div className="cenlr">
                                                <button
                                                  className="page_save page_width"
                                                  name="categoryFilter"
                                                  onClick={this.clearFilter}
                                                >
                                                  CLEAR
                                                </button>
                                                <button
                                                  className="page_save page_width"
                                                  onClick={this.applyCategory}
                                                >
                                                  APPLY
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </Modal>

                                  <Modal
                                    animation={true}
                                    size="md"
                                    className="modal_box"
                                    shadow-lg="border"
                                    show={
                                      this.state.showModal &&
                                      this.state.activeModal === "country"
                                    }
                                  >
                                    <div className="modal-lg">
                                      <Modal.Header className="pb-0">
                                        <Button
                                          variant="outline-dark"
                                          onClick={this.handleCloseModal}
                                        >
                                          <i className="fa fa-times"></i>
                                        </Button>
                                      </Modal.Header>
                                      <div className="modal-body vekp pt-0">
                                        <div className="row">
                                          <div className="col-md-12">
                                            <div className="pb4">
                                              <div className="py-3">
                                                <div className="form-check-inline">
                                                  <label
                                                    className="form-check-label"
                                                    htmlFor="flexCheckChecked"
                                                  >
                                                    {" "}
                                                    Sort: A - Z{" "}
                                                  </label>
                                                  <input
                                                    className="form-check-input mx-3"
                                                    type="radio"
                                                    name="country"
                                                    value="ASC"
                                                    onChange={(e) =>
                                                      this.applySorting(e)
                                                    }
                                                  />
                                                </div>
                                                <div className="form-check-inline">
                                                  <label
                                                    className="form-check-label"
                                                    htmlFor="flexCheckChecked"
                                                  >
                                                    {" "}
                                                    Sort: Z - A{" "}
                                                  </label>
                                                  <input
                                                    className="form-check-input mx-3"
                                                    type="radio"
                                                    name="country"
                                                    value="DESC"
                                                    onChange={(e) =>
                                                      this.applySorting(e)
                                                    }
                                                  />
                                                </div>
                                              </div>

                                              <div className="input-group mb-3">
                                                <select
                                                  className="form-control dropdown-toggle"
                                                  placeholder="Search"
                                                  name="countryFilter"
                                                  onChange={(e) =>
                                                    this.applyFilter(e)
                                                  }
                                                >
                                                  <option value="">
                                                    Select Country
                                                  </option>

                                                  {countriesList.map((item) => (
                                                    <option
                                                      key={item.id}
                                                      value={item.nicename}
                                                    >
                                                      {item.name}
                                                    </option>
                                                  ))}
                                                </select>
                                              </div>
                                              <div className="cenlr">
                                                <button
                                                  className="page_save page_width"
                                                  name="countryFilter"
                                                  onClick={this.clearFilter}
                                                >
                                                  CLEAR
                                                </button>
                                                <button
                                                  className="page_save page_width"
                                                  onClick={this.applyCountry}
                                                >
                                                  APPLY
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </Modal>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
