/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import Swal from "sweetalert2";
import React, { Component } from "react";
import { Button, Header, Modal, Input } from "semantic-ui-react";
import axios from "axios";
import "./governancePage.css";
import moment from "moment";
import {sweetAlert} from '../../../../utils/UniversalFunction'
import { authenticationService } from "../../../../_services/authentication";
import config from "../../../../config/config.json";
import AdminSidebar from "../../../sidebar/admin_sidebar";
import AdminHeader from "../../../header/admin_header";
import GovernanceTabbing from "./governance_tabbing";

const BASE_URL = config.BASE_URL;
const currentUser = authenticationService.currentUserValue;
export default class social_policies_tabbing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openModel: false,
      activeModal: "",
      activeModal1: "",
      isLoaded: true,
      socialPolicy: [],
      socialSpecificPolicy: [],
      policyComment: [],
      comment:"",
      policyid: [],
      uuid: "",
      setOpen: false,
      setOpen1: false,
      feedback: "",
      status: "",
      idd: "",
      openAddModal: false,
      newPolicy: ""
    };
    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleOpenModal1 = this.handleOpenModal1.bind(this);
    this.handleCloseModal1 = this.handleCloseModal1.bind(this);
    this.goToPreviousPath = this.goToPreviousPath.bind(this);
    this.serverRequest = this.serverRequest.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.serverRequest2 = this.serverRequest2.bind(this);
    this.serverRequest3 = this.serverRequest3.bind(this);
    this.handleSubmit2 = this.handleSubmit2.bind(this);
    this.handleSubmit3 = this.handleSubmit3.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleChange1 = this.handleChange1.bind(this);
    this.policyReevaluated = this.policyReevaluated.bind(this);
    this.socialSpecificPolicy = this.socialSpecificPolicy.bind(this);
  }

  handleChange(event) {
    const { value } = event.target;
    this.setState({
      feedback: value,
    });
  }

  handleChange1(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  }

  goToPreviousPath() {
    this.props.history.goBack();
  }

  handleSubmit = (event) => {
    let getStatus = event.target.getAttribute("data-status");
    let getId = event.target.getAttribute("data-id");
    const headers = {
      Authorization: `Bearer ${currentUser.data.token}`,
      Accept: "application/json",
    };

    Swal.fire({
      title: "Do you want to Approve Policy?",
      showDenyButton: false,
      showCancelButton: true,
      confirmButtonText: "Update",
      // denyButtonText: `Don't save`,
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .post(
            config.ADMIN_API_URL +
              `company/${this.state.uuid}/modules/governance/governance-policy/${getId}`,
            {
              status: getStatus,
            },
            {
              headers,
            }
          )
          .then((response) => {
            this.serverRequest2();
            this.socialSpecificPolicy();
            sweetAlert('success',response.data.message);	
          })
          .catch(function (response) {
            sweetAlert('error',response.data.message);
          });
      } else if (result.isDenied) {
        sweetAlert('info',"User Safe");
      }
    });
  };

  handleSubmit2 = (event) => {
    event.preventDefault();
    const { status, idd, feedback } = this.state;
    let getStatus = status;
    let getId = idd;
    const headers = {
      Authorization: `Bearer ${currentUser.data.token}`,
      Accept: "application/json",
    };
    axios
      .post(
        config.ADMIN_API_URL +
          `company/${this.state.uuid}/modules/governance/governance-policy/${getId}`,
        {
          status: getStatus,
          feedback: feedback,
        },
        {
          headers,
        }
      )
      .then((response) => {
        this.onClose();
        this.serverRequest2();
        this.socialSpecificPolicy();
        sweetAlert('success',response.data.message);	
      })
      .catch(function (response) {
        sweetAlert('error',response.data.message);
      });
  };

  policyReevaluated = (event) => {
    let getStatus = event.target.getAttribute("data-status");
    let getId = event.target.getAttribute("data-id");
    console.log("this is attr", event.target);
    const headers = {
      Authorization: `Bearer ${currentUser.data.token}`,
      Accept: "application/json",
    };
    axios
      .post(
        config.ADMIN_API_URL +
          `company/${this.state.uuid}/modules/updateReevaluatePolicy/${getId}`,
        {
          status: getStatus,
        },
        {
          headers,
        }
      )
      .then((response) => {
        this.onClose();
        this.serverRequest2();
        this.socialSpecificPolicy();
      })
      .catch(function (response) {
        sweetAlert('error',response.data.message);
      });
  };

  handleOpenModal(val) {
    this.setState({ activeModal: val });
    this.setState({ showModal: true });
  }
  handleOpenModal1(val) {
    this.setState({ activeModal1: val });
    this.setState({ showModal: true });
  }

  handleCloseModal1() {
    this.setState({ showModal: false });
    this.setState({ showModal: "" });
  }
  onClose() {
    this.setState({
      setOpen: false,
      setOpen1: false,
    });
  }
  setOpen(event) {
    let getStatus = event.target.getAttribute("data-status");
    let getId = event.target.getAttribute("data-id");
    this.setState({
      setOpen: true,
      status: getStatus,
      idd: getId,
    });
  }

  setOpen1(event) {
    let policyid = event.target.getAttribute("policyid");
    this.setState({
      setOpen1: true,
      policyid: policyid,
    });

    let commentor = this.state.id;

    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${currentUser.data.token}`,
        "Content-Type": "application/json",
      },
    };

    fetch(
      config.API_URL +
        `getGovernanceComments/${policyid}/${commentor}`,
      requestOptions
    )
      .then((res) => res.json())
      .then(
        (data) => {
          this.setState({
            isLoaded: false,
            policyComment: data.data,
          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error,
          });
        }
      );
  }

  handleCloseModal() {
    this.setState({ showModal: false });
    this.setState({ showModal: "" });
  }

  handleSubmit3 = (event) => {
    event.preventDefault();
    const headers = {
      Authorization: `Bearer ${currentUser.data.token}`,
      Accept: "application/json",
    };
    axios
      .post(
        config.API_URL +
          "sendGovernanceComments",
        {
          governanceTopicsID : this.state.policyid,
          commentor : this.state.id,
          role : localStorage.getItem("role"),
          comment : this.state.comment
        },
        {
          headers,
        }
      )
      .then((response) => {
        this.onClose();
        sweetAlert('success',response.data.message);	
      })
      .catch(function (response) {
        sweetAlert('error',response.data.message);
      });
  };

  serverRequest() {
    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${currentUser.data.token}`,
        "Content-Type": "application/json",
      },
    };
    fetch(config.API_URL + "getGovernanceTopics", requestOptions)
      .then((res) => res.json())
      .then(
        (data) => {
          this.setState({
            isLoaded: false,
            socialPolicy: data.socialPolicy,
          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error,
          });
        }
      );
  }

  serverRequest2() {
    let urlArr = window.location.pathname.split("/");
    const finalUUId = urlArr[urlArr.length - 3];
    this.setState({
      uuid: urlArr[urlArr.length - 3],
    });

    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${currentUser.data.token}`,
        "Content-Type": "application/json",
      },
    };

    fetch(
      config.ADMIN_API_URL +
        `company/${finalUUId}/modules/governance/governance-policy`,
      requestOptions
    )
      .then((res) => res.json())
      .then(
        (data) => {
          this.setState({
            isLoaded: false,
            socialPolicy: data.socialPolicy,
          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error,
          });
        }
      );
  }

  serverRequest3(uuidd) {
    let uuid = uuidd;
    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${currentUser.data.token}`,
        "Content-Type": "application/json",
      },
    };
    let parms = `company/${uuid}`;
    fetch(config.ADMIN_API_URL + parms, requestOptions)
      .then((res) => res.json())
      .then(
        (data) => {
          
          this.setState({
            id: data.data[0]?.id,
          });
          
        },
        (error) => {}
      );
  }

  socialSpecificPolicy(){
    let urlArr = window.location.pathname.split("/");
    const finalUUId = urlArr[urlArr.length - 3];
    this.setState({
      uuid: urlArr[urlArr.length - 3],
    });

    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${currentUser.data.token}`,
        "Content-Type": "application/json",
      },
    };

    fetch(
      config.ADMIN_API_URL +
        `company/modules/governance/specific-governance-policy/${finalUUId}`,
      requestOptions
    )
      .then((res) => res.json())
      .then(
        (data) => {
          this.setState({
            isLoaded: false,
            socialSpecificPolicy: data.socialPolicy,
          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error,
          });
        }
      );
  }

  componentDidMount() {
    let urlArr = window.location.pathname.split("/");
    this.serverRequest3(urlArr[urlArr.length - 3]);
    this.setState({
      uuid:urlArr[urlArr.length - 3]
    })
    this.serverRequest2();
    this.socialSpecificPolicy();
  }

  
  async handlePolicyChange(e) {

    const { name, value, id:index } = e.target;

    let temp = [...this.state[name]];
    temp[index].description = value;
    this.setState({[name]: temp});
    
  }


   async handlePolicySave() {

    const { uuid } = this.props.match.params;


    const defaultsPayload = this.state.socialPolicy.map((item) => {
      return {
        id: item.id,
        policy_id: item.governanceTopicsIds,
        description: item.description,
      }
    });
    
    const customsPayload = this.state.socialSpecificPolicy.map((item) => {
      return {
        id: item.id,
        policy_id: item.governanceTopicsIds,
        description: item.description,
      }
    });

    const entities = [...defaultsPayload, ...customsPayload];

    const payload = {
      uuid: uuid,
      entities: entities
    }

    try {
      const headers = {
        Authorization: `Bearer ${currentUser.data.token}`,
        Accept: "application/json",
      };
      await axios.put(`${config.ADMIN_API_URL}governance/updateGovernanceData`, payload, { headers: headers });
      sweetAlert('success', "Updated Successfully");
    } catch (error) {
      sweetAlert('error', "Oops some error occured");
      // show error message
      console.log(error.message)
    }

  }

  
  async handleModalOpen() {
    this.setState({openAddModal: true});
  }

  handleCloseModel() {
    this.setState({openAddModal: false});
  }

  handlenewPolicyUpdate(e) {
    this.setState({newPolicy: e.target.value});
  }

  async handleSaveNewPolicy() {
    const { uuid } = this.props.match.params;
    if(this.state.newPolicy){
      const payload = {
        uuid: uuid,
        policy: this.state.newPolicy,
        type: 'socialPolicy'
      }

      try {
        const headers = {
          Authorization: `Bearer ${currentUser.data.token}`,
          Accept: "application/json",
        };

        await axios.put(`${config.ADMIN_API_URL}governance/addGovernancePolicy`, payload, { headers: headers });
        sweetAlert('success', "Created Successfully");

        // close modal
        this.setState({ 
          openAddModal: false,
          newPolicy: ""
        });

        this.socialSpecificPolicy();

      } catch (error) {
        
        this.setState({ 
          openAddModal: false,
          newPolicy: ""
        });

        sweetAlert('error', "Already exists");
      }
      
    }else{
      sweetAlert("error", "This is required field");
    }
  }

  render() {
    const { policyComment, socialPolicy,socialSpecificPolicy, openAddModal} = this.state;

    return (
      <div>
        <AdminSidebar dataFromParent={this.props.location.pathname} />
        <AdminHeader />
        <div className="main_wrapper">
          <div className="inner_wraapper">
            <div className="container-fluid">
              <section className="d_text">
                <div className="container-fluid">
                  <div className="row">
                    <GovernanceTabbing />
                    <div className="col-sm-12">
                      <div className="strp_progess">
                        <div className="hol_rell">
                          <div className="steps-form">
                            <div className="steps-row setup-panel"></div>
                          </div>
                        </div>
                      </div>
                      <div className="SVG Stepper">
                        <div className="stepperr_design">
                          <div className="color_div_step">
                            <div className="include">
                              <div className="row setup-content" id="step-10">
                                <div className="col-md-12">
                                  {/* <h6 className="back_quninti">
                                    <NavLink className="back_text" to="#">
                                      <span className="step_icon">
                                        <i className="far fa-long-arrow-alt-left"></i>
                                      </span>
                                      Back
                                    </NavLink>
                                  </h6> */}
                                  <div className="">

                                    <div className="headerCont">
                                    <h4 className="E_capital p-0 pb-4">
                                        Social Policies
                                    </h4>
                                    <button
                                        id="savbtnMain2"
                                        className="link_bal_next page_width white "
                                        variant="none"
                                        onClick={()=> this.handleModalOpen()}
                                      >
                                    +Add new Policy
                                  </button>
                                    
                                    </div>
                                    <div className="Statement">
                                      {socialPolicy.map((item, key) => (
                                        <div
                                          key={key}
                                          className="Statement_one"
                                        >
                                          <div className="Statement_2">
                                            <p className="statement_p">
                                              {item.question}
                                            </p>
                                            <textarea
                                                className="form-control box_layout"
                                                name="socialPolicy"
                                                id={key}
                                                value={item.description || item.description ? item.description : ""}
                                                onChange={(e) => this.handlePolicyChange(e)}
                                                placeholder="No Description"
                                            ></textarea>
                                          </div>
                                          <div className="Statement_two">
                                            <div className="statement_icon d-flex align-items-center">
                                              {item.docsFile && (
                                                <>
                                                  <span className="statement_pen">
                                                    {item.status === 1 && (
                                                      <>
                                                        <a
                                                          href="#"
                                                          data-status="0"
                                                          data-id={
                                                            item.savedIdd
                                                          }
                                                          onClick={
                                                            this
                                                              .policyReevaluated
                                                          }
                                                        >
                                                          <i
                                                            data-status="0"
                                                            data-id={
                                                              item.savedIdd
                                                            }
                                                            onClick={
                                                              this
                                                                .policyReevaluated
                                                            }
                                                            className="fas fa-pen"
                                                          >
                                                            <span
                                                              data-status="0"
                                                              data-id={
                                                                item.savedIdd
                                                              }
                                                              onClick={
                                                                this
                                                                  .policyReevaluated
                                                              }
                                                              className="mx-3"
                                                            >
                                                              Re-evaluate
                                                            </span>
                                                          </i>
                                                        </a>
                                                        <i
                                                          style={{
                                                            color: "green",
                                                          }}
                                                          className="fas fa-check-circle"
                                                        ></i>
                                                      </>
                                                    )}
                                                    {item.status === 2 && (
                                                      <>
                                                        <a
                                                          href={"#"}
                                                          data-status="0"
                                                          data-id={
                                                            item.savedIdd
                                                          }
                                                          onClick={
                                                            this
                                                              .policyReevaluated
                                                          }
                                                        >
                                                          <i
                                                            data-status="0"
                                                            data-id={
                                                              item.savedIdd
                                                            }
                                                            onClick={
                                                              this
                                                                .policyReevaluated
                                                            }
                                                            className="fas fa-pen"
                                                          >
                                                            <span
                                                              data-status="0"
                                                              data-id={
                                                                item.savedIdd
                                                              }
                                                              onClick={
                                                                this
                                                                  .policyReevaluated
                                                              }
                                                              className="mx-3"
                                                            >
                                                              Re-evaluate
                                                            </span>
                                                          </i>
                                                        </a>
                                                        <i
                                                          style={{
                                                            color: "red",
                                                          }}
                                                          className="fas fa-check-circle"
                                                        ></i>
                                                      </>
                                                    )}
                                                    {item.status === 0 && (
                                                      <>
                                                        <i
                                                          data-status="1"
                                                          data-id={
                                                            item.savedIdd
                                                          }
                                                          onClick={
                                                            this.handleSubmit
                                                          }
                                                          className="fas fa-check green"
                                                        ></i>
                                                        <i
                                                          data-status="2"
                                                          data-id={
                                                            item.savedIdd
                                                          }
                                                          onClick={(e) =>
                                                            this.setOpen(e)
                                                          }
                                                          className="fas fa-times red"
                                                        ></i>
                                                      </>
                                                    )}
                                                  </span>
                                                  <span className="">
                                                    <button
                                                      className="btn btn-link"
                                                      variant="none"
                                                      onClick={() =>
                                                        this.handleOpenModal(
                                                          "login"
                                                        )
                                                      }
                                                    >
                                                      <a
                                                        href={
                                                          BASE_URL +
                                                          item.docsFile
                                                        }
                                                        target="_blank"
                                                        download=""
                                                      >
                                                      <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M17 1.48619e-06C26.3891 6.65366e-07 34 7.6109 34 17C34 26.3891 26.3891 34 17 34C7.6109 34 -6.65366e-07 26.3891 -1.48619e-06 17C-2.30701e-06 7.6109 7.6109 2.30701e-06 17 1.48619e-06ZM17 3.4C13.3931 3.4 9.93384 4.83285 7.38334 7.38335C4.83285 9.93384 3.4 13.3931 3.4 17C3.4 20.6069 4.83285 24.0662 7.38335 26.6167C9.93384 29.1671 13.3931 30.6 17 30.6C20.6069 30.6 24.0662 29.1671 26.6167 26.6167C29.1671 24.0662 30.6 20.6069 30.6 17C30.6 13.3931 29.1671 9.93384 26.6166 7.38335C24.0662 4.83285 20.6069 3.4 17 3.4Z" fill="#A7ACC8"></path><path d="M11.4 17.25L11.65 17.25L11.65 17L11.65 11.8056L22.35 11.8056L22.35 17L22.35 17.25L22.6 17.25L24 17.25L24.25 17.25L24.25 17L24.25 10.7778C24.25 10.5132 24.1556 10.2548 23.9808 10.0606C23.8052 9.86542 23.561 9.75 23.3 9.75L10.7 9.75C10.439 9.75 10.1948 9.86542 10.0192 10.0606C9.84435 10.2548 9.75 10.5132 9.75 10.7778L9.75 17L9.75 17.25L10 17.25L11.4 17.25ZM21.2 19.0833L17.95 19.0833L17.95 13.8889L17.95 13.6389L17.7 13.6389L16.3 13.6389L16.05 13.6389L16.05 13.8889L16.05 19.0833L12.8 19.0833L12.2387 19.0833L12.6142 19.5006L16.8142 24.1672L17 24.3737L17.1858 24.1672L21.3858 19.5006L21.7613 19.0833L21.2 19.0833Z" fill="#A7ACC8" stroke="#A7ACC8" stroke-width="0.5"></path></svg>

                                                        {/* <i className="fa fa-eye">
                                                          <span className="mx-2">
                                                            View
                                                          </span>
                                                        </i> */}
                                                      </a>
                                                    </button>
                                                  </span>
                                                </>
                                              )}
                                              {/* {item.docsFile === "" && (
                                                <p>N/A</p>
                                              )} */}
                                              <div className="px-3">
                                              <a
                                              policyid= {item.id}
                                              onClick={(e) => this.setOpen1(e)}>
                                              {/* <i policyid= {item.id} className="fa fa-comment"> */}
                                              <svg policyid= {item.id} width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M0.5 17C0.5 7.8873 7.8873 0.5 17 0.5C26.1127 0.5 33.5 7.8873 33.5 17V28.6667L33.5 28.7251C33.5 29.2979 33.5001 29.8239 33.4604 30.262C33.4175 30.7356 33.3188 31.2516 33.0311 31.75C32.7239 32.2821 32.2821 32.7239 31.75 33.0311C31.2516 33.3188 30.7356 33.4175 30.262 33.4604C29.8239 33.5001 29.2979 33.5 28.7251 33.5L28.6667 33.5H17C7.8873 33.5 0.5 26.1127 0.5 17ZM17 3.5C9.54416 3.5 3.5 9.54416 3.5 17C3.5 24.4558 9.54416 30.5 17 30.5H28.6667C29.3174 30.5 29.7051 30.4986 29.9912 30.4726C30.164 30.457 30.236 30.4365 30.2558 30.4296C30.3273 30.387 30.387 30.3273 30.4296 30.2558C30.4365 30.236 30.457 30.164 30.4726 29.9912C30.4986 29.7051 30.5 29.3174 30.5 28.6667V17C30.5 9.54416 24.4558 3.5 17 3.5ZM30.4272 30.2618C30.4272 30.2617 30.4277 30.2602 30.429 30.2576C30.4279 30.2606 30.4273 30.2619 30.4272 30.2618ZM30.2618 30.4272C30.2619 30.4272 30.2606 30.4279 30.2576 30.429C30.2602 30.4277 30.2617 30.4272 30.2618 30.4272ZM9.5 15C9.5 14.1716 10.1716 13.5 11 13.5H23C23.8284 13.5 24.5 14.1716 24.5 15C24.5 15.8284 23.8284 16.5 23 16.5H11C10.1716 16.5 9.5 15.8284 9.5 15ZM17 21.5C16.1716 21.5 15.5 22.1716 15.5 23C15.5 23.8284 16.1716 24.5 17 24.5H23C23.8284 24.5 24.5 23.8284 24.5 23C24.5 22.1716 23.8284 21.5 23 21.5H17Z" fill="url(#paint0_linear_1_206)"></path><defs><linearGradient id="paint0_linear_1_206" x1="11" y1="2.5" x2="36" y2="45.5" gradientUnits="userSpaceOnUse"><stop stop-color="#233076"></stop><stop offset="1" stop-color="#3BABD6"></stop></linearGradient></defs></svg>
                                              {/* </i> */}
                                              </a>
                                            </div>
                                            </div>
                                          </div>
                                        </div>
                                      ))}
                                      {socialSpecificPolicy.map((item, key) => (
                                        <div
                                          key={key}
                                          className="Statement_one"
                                        >
                                          <div className="Statement_2">
                                            <p className="statement_p">
                                              {item.question}
                                            </p>
                                            <textarea
                                                className="form-control box_layout"
                                                name="socialSpecificPolicy"
                                                id={key}
                                                onChange={(e) => this.handlePolicyChange(e)}
                                                value={item.description || item.description ? item.description : ""}
                                                placeholder="No Description"
                                            ></textarea>
                                          </div>
                                          <div className="Statement_two">
                                            <div className="statement_icon d-flex align-items-center">
                                              {item?.docsFile && (
                                                <>
                                                  <span className="statement_pen">
                                                    {item.status === 1 && (
                                                      <>
                                                        <a
                                                          href="#"
                                                          data-status="0"
                                                          data-id={
                                                            item.savedIdd
                                                          }
                                                          onClick={
                                                            this
                                                              .policyReevaluated
                                                          }
                                                        >
                                                          <i
                                                            data-status="0"
                                                            data-id={
                                                              item.savedIdd
                                                            }
                                                            onClick={
                                                              this
                                                                .policyReevaluated
                                                            }
                                                            className="fas fa-pen"
                                                          >
                                                            <span
                                                              data-status="0"
                                                              data-id={
                                                                item.savedIdd
                                                              }
                                                              onClick={
                                                                this
                                                                  .policyReevaluated
                                                              }
                                                              className="mx-3"
                                                            >
                                                              Re-evaluate
                                                            </span>
                                                          </i>
                                                        </a>
                                                        <i
                                                          style={{
                                                            color: "green",
                                                          }}
                                                          className="fas fa-check-circle"
                                                        ></i>
                                                      </>
                                                    )}
                                                    {item.status === 2 && (
                                                      <>
                                                        <a
                                                          href={"#"}
                                                          data-status="0"
                                                          data-id={
                                                            item.savedIdd
                                                          }
                                                          onClick={
                                                            this
                                                              .policyReevaluated
                                                          }
                                                        >
                                                          <i
                                                            data-status="0"
                                                            data-id={
                                                              item.savedIdd
                                                            }
                                                            onClick={
                                                              this
                                                                .policyReevaluated
                                                            }
                                                            className="fas fa-pen"
                                                          >
                                                            <span
                                                              data-status="0"
                                                              data-id={
                                                                item.savedIdd
                                                              }
                                                              onClick={
                                                                this
                                                                  .policyReevaluated
                                                              }
                                                              className="mx-3"
                                                            >
                                                              Re-evaluate
                                                            </span>
                                                          </i>
                                                        </a>
                                                        <i
                                                          style={{
                                                            color: "red",
                                                          }}
                                                          className="fas fa-check-circle"
                                                        ></i>
                                                      </>
                                                    )}
                                                    {item.status === 0 && (
                                                      <>
                                                        <i
                                                          data-status="1"
                                                          data-id={
                                                            item.savedIdd
                                                          }
                                                          onClick={
                                                            this.handleSubmit
                                                          }
                                                          className="fas fa-check green"
                                                        ></i>
                                                        <i
                                                          data-status="2"
                                                          data-id={
                                                            item.savedIdd
                                                          }
                                                          onClick={(e) =>
                                                            this.setOpen(e)
                                                          }
                                                          className="fas fa-times red"
                                                        ></i>
                                                      </>
                                                    )}
                                                  </span>
                                                  <span className="">
                                                    <button
                                                      className="btn btn-link"
                                                      variant="none"
                                                      onClick={() =>
                                                        this.handleOpenModal(
                                                          "login"
                                                        )
                                                      }
                                                    >
                                                      <a
                                                        href={
                                                          BASE_URL +
                                                          item.docsFile
                                                        }
                                                        target="_blank"
                                                        download=""
                                                      >
                                                        <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M17 1.48619e-06C26.3891 6.65366e-07 34 7.6109 34 17C34 26.3891 26.3891 34 17 34C7.6109 34 -6.65366e-07 26.3891 -1.48619e-06 17C-2.30701e-06 7.6109 7.6109 2.30701e-06 17 1.48619e-06ZM17 3.4C13.3931 3.4 9.93384 4.83285 7.38334 7.38335C4.83285 9.93384 3.4 13.3931 3.4 17C3.4 20.6069 4.83285 24.0662 7.38335 26.6167C9.93384 29.1671 13.3931 30.6 17 30.6C20.6069 30.6 24.0662 29.1671 26.6167 26.6167C29.1671 24.0662 30.6 20.6069 30.6 17C30.6 13.3931 29.1671 9.93384 26.6166 7.38335C24.0662 4.83285 20.6069 3.4 17 3.4Z" fill="#A7ACC8"></path><path d="M11.4 17.25L11.65 17.25L11.65 17L11.65 11.8056L22.35 11.8056L22.35 17L22.35 17.25L22.6 17.25L24 17.25L24.25 17.25L24.25 17L24.25 10.7778C24.25 10.5132 24.1556 10.2548 23.9808 10.0606C23.8052 9.86542 23.561 9.75 23.3 9.75L10.7 9.75C10.439 9.75 10.1948 9.86542 10.0192 10.0606C9.84435 10.2548 9.75 10.5132 9.75 10.7778L9.75 17L9.75 17.25L10 17.25L11.4 17.25ZM21.2 19.0833L17.95 19.0833L17.95 13.8889L17.95 13.6389L17.7 13.6389L16.3 13.6389L16.05 13.6389L16.05 13.8889L16.05 19.0833L12.8 19.0833L12.2387 19.0833L12.6142 19.5006L16.8142 24.1672L17 24.3737L17.1858 24.1672L21.3858 19.5006L21.7613 19.0833L21.2 19.0833Z" fill="#A7ACC8" stroke="#A7ACC8" stroke-width="0.5"></path></svg>
                                                        {/* <i className="fa fa-eye">
                                                          <span className="mx-2">
                                                            View
                                                          </span>
                                                        </i> */}
                                                      </a>
                                                    </button>
                                                  </span>
                                                </>
                                              )}
                                              {/* {item.docsFile === "" && (
                                                <p>N/A</p>
                                              )} */}
                                              <div className="px-3">
                                              <a
                                              policyid= {item.id}
                                              onClick={(e) => this.setOpen1(e)}>
                                              <svg policyid= {item.id} width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M0.5 17C0.5 7.8873 7.8873 0.5 17 0.5C26.1127 0.5 33.5 7.8873 33.5 17V28.6667L33.5 28.7251C33.5 29.2979 33.5001 29.8239 33.4604 30.262C33.4175 30.7356 33.3188 31.2516 33.0311 31.75C32.7239 32.2821 32.2821 32.7239 31.75 33.0311C31.2516 33.3188 30.7356 33.4175 30.262 33.4604C29.8239 33.5001 29.2979 33.5 28.7251 33.5L28.6667 33.5H17C7.8873 33.5 0.5 26.1127 0.5 17ZM17 3.5C9.54416 3.5 3.5 9.54416 3.5 17C3.5 24.4558 9.54416 30.5 17 30.5H28.6667C29.3174 30.5 29.7051 30.4986 29.9912 30.4726C30.164 30.457 30.236 30.4365 30.2558 30.4296C30.3273 30.387 30.387 30.3273 30.4296 30.2558C30.4365 30.236 30.457 30.164 30.4726 29.9912C30.4986 29.7051 30.5 29.3174 30.5 28.6667V17C30.5 9.54416 24.4558 3.5 17 3.5ZM30.4272 30.2618C30.4272 30.2617 30.4277 30.2602 30.429 30.2576C30.4279 30.2606 30.4273 30.2619 30.4272 30.2618ZM30.2618 30.4272C30.2619 30.4272 30.2606 30.4279 30.2576 30.429C30.2602 30.4277 30.2617 30.4272 30.2618 30.4272ZM9.5 15C9.5 14.1716 10.1716 13.5 11 13.5H23C23.8284 13.5 24.5 14.1716 24.5 15C24.5 15.8284 23.8284 16.5 23 16.5H11C10.1716 16.5 9.5 15.8284 9.5 15ZM17 21.5C16.1716 21.5 15.5 22.1716 15.5 23C15.5 23.8284 16.1716 24.5 17 24.5H23C23.8284 24.5 24.5 23.8284 24.5 23C24.5 22.1716 23.8284 21.5 23 21.5H17Z" fill="url(#paint0_linear_1_206)"></path><defs><linearGradient id="paint0_linear_1_206" x1="11" y1="2.5" x2="36" y2="45.5" gradientUnits="userSpaceOnUse"><stop stop-color="#233076"></stop><stop offset="1" stop-color="#3BABD6"></stop></linearGradient></defs></svg>
                                              {/* <i policyid= {item.id} className="fa fa-comment">
                                              </i> */}
                                              </a>
                                            </div>
                                            </div>
                                          </div>
                                        </div>
                                      ))}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          <div className="savbtnCont">
                            <button
                              id="savbtnMain"
                              className="link_bal_next page_width white "
                              variant="none"
                              onClick={() => this.handlePolicySave()}
                            >
                              Save
                            </button>
                          </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>

              <Modal open={openAddModal} className="addpolicyModal">
                <Modal.Header>Add New Policy</Modal.Header>
                <form onSubmit={this.handleSubmit2}>
                  <Modal.Content>
                    <Modal.Description>
                      <Header>Enter Policy Name</Header>
                      <div className="form-group">
                        <Input fluid className="mdInpPolicy" placeholder='Enter here ...' value={this.state?.newPolicy || ""} onChange={(e) => this.handlenewPolicyUpdate(e)} />
                      </div>
                    </Modal.Description>
                  </Modal.Content>
                  <Modal.Actions className="policyBtnCont">
                  <Button onClick={() => this.handleCloseModel()}>  
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      onClick={() => this.handleSaveNewPolicy()}
                    > 
                    Save
                    </Button>

                  </Modal.Actions>
                </form>
              </Modal>

              <Modal open={this.state.setOpen} className="feedback2">
                <Modal.Header>Feedback</Modal.Header>
                <form onSubmit={this.handleSubmit2}>
                  <Modal.Content>
                    <Modal.Description>
                      <Header>Please enter your comments below</Header>

                      <div className="form-group">
                        <textarea
                          defaultValue={this.state.feedback}
                          onChange={this.handleChange}
                          className="form-control"
                        ></textarea>
                      </div>
                    </Modal.Description>
                  </Modal.Content>
                  <Modal.Actions>
                    <Button
                      type="submit"
                    > Submit </Button>
                    <Button color="" onClick={() => this.onClose(false)}>
                      Cancel
                    </Button>
                  </Modal.Actions>
                </form>
              </Modal>
              <Modal open={this.state.setOpen1} className="feedback3">
                <Modal.Header>Comments</Modal.Header>
                <form onSubmit={this.handleSubmit3}>
                  <Modal.Content>
                    <Modal.Description>
                      <div className="commentsBox">
                      {policyComment.map((item, key) => (
                      <div 
                      className={
                        item.role === "company_admin"
                          ? "sender"
                          : "receiver"
                      }
                      >
                        <p
                        className={
                          item.role === "company_admin"
                            ? "senderMessage"
                            : "receiverMessage"
                        }
                        >{item.comment}
                        <span className="commentDate">
                        {moment(item.createdAt).utc().format('DD-MM-YYYY')}
                        </span>
                        </p>
                      </div>
                       ))}                      
                      </div>
                      <Header>Please enter your comments below</Header>
                      
                      <div className="form-group">
                        <textarea
                          name="comment"
                          onChange={this.handleChange1}
                          className="form-control"
                        ></textarea>
                      </div>
                    </Modal.Description>
                  </Modal.Content>
                  <Modal.Actions>
                    <Button
                      type="submit"
                    > Send
                    </Button>
                    <Button onClick={() => this.onClose(false)}>
                      Cancel
                    </Button>
                  </Modal.Actions>
                </form>
              </Modal>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
