import React, { Component } from "react";
import Swal from "sweetalert2";
import axios from "axios";
import { sweetAlert } from "../../utils/UniversalFunction";
import AdminSidebar from "../sidebar/admin_sidebar";
import AdminHeader from "../header/admin_header";
import Table from "react-bootstrap/Table";
import config from "../../config/config.json";
// import { Button, Modal } from "react-bootstrap";
import Moment from "react-moment";
import "./control.css";
import { Pagination, Icon } from "semantic-ui-react";
import { authenticationService } from "../../_services/authentication";
const currentUser = authenticationService.currentUserValue;

export default class RequestCoachingDataLists extends Component {
  constructor(props) {
    super(props);
    this.state = {
      login: false,
      loading: false,
      coachingData: [],
      totalRows: 0,
      currentPage: 1,
      skip: 0,
      search: "",
      limit: 10,
      pageCount: 0,
      totalData: 0,
      ids: [],
      showModal: false,
    };
    this.deleteCoachingData = this.deleteCoachingData.bind(this);
  }

  deleteCoachingData(id) {
    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${currentUser.data.token}`,
        "Content-Type": "application/json",
      },
    };
    Swal.fire({
      title: "Do you want to delete this Coachings Data?",
      showDenyButton: false,
      showCancelButton: true,
      confirmButtonText: "Delete",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .get(
            config.OLD_API_URL + `removeCoachingData/${id}`,
            requestOptions,
            {
              id: id,
            }
          )
          .then(() => {
            sweetAlert("success", "Delete successful");
            this.setState({ status: "Delete successful" });
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          })
          .catch(function (response) {
            sweetAlert("error", "Delete Unsuccessful");
          });
      } else if (result.isDenied) {
        sweetAlert("info", "Sector Question Safe");
      }
    });
  }

  async handleDelete(e) {
    let { ids } = this.state;

    Swal.fire({
      title: "Do you want to delete this Coaching Data ?",
      showDenyButton: false,
      showCancelButton: true,
      confirmButtonText: "Delete",
    }).then((result) => {
      if (result.isConfirmed) {
        let headers = {
          Authorization: `Bearer ${currentUser.data.token}`,
          "Content-Type": "application/json",
        };

        axios
          .post(
            config.OLD_API_URL + "removeCoachingData",
            {
              ids: ids,
            },
            {
              headers,
            }
          )
          .then((response) => {
            sweetAlert("success", response.data.message);

            setTimeout(async () => {
              await this.serverRequest();
            }, 400);
          })
          .catch(function (response) {
            sweetAlert("error", "Unable to Delete!");
          });
      } else if (result.isDenied) {
        sweetAlert("info", "User Safe");
      }
    });
  }

  async handleUniversalCheckbox(e) {
    let { coachingData } = this.state;
    const isChecked = e.target.checked;

    let temp = coachingData.map((item) => {
      return { ...item, isChecked: isChecked };
    });
    await this.setState({ coachingData: temp });
    let ids = [];

    temp.map((item) => {
      if (item?.isChecked === true) {
        ids.push(item.id);
      }
      return null;
    });
    await this.setState({ ids: ids });
  }

  async handleSingleCheckbox(e) {
    let { coachingData } = this.state;
    const { id, checked } = e.target;

    let temp = await coachingData.map((item) => {
      // eslint-disable-next-line
      if (item.id == id) {
        return { ...item, isChecked: checked };
      } else {
        return { ...item };
      }
    });

    await this.setState({ coachingData: temp });
    let ids = [];
    temp.map((item) => {
      if (item?.isChecked === true) {
        ids.push(item.id);
      }
      return null;
    });

    await this.setState({ ids: ids });
  }

  componentDidMount() {
    this.serverRequest();
  }

  serverRequest() {
    const { skip, limit, search } = this.state;
    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${currentUser.data.token}`,
        "Content-Type": "application/json",
      },
    };
    let uri = window.location.pathname.split("/");
    this.setState({
      uuid: uri[2],
    });
    let uuid = uri[2];
    fetch(
      config.OLD_API_URL +
        `getCoaching/${uuid}?skip=${skip}&limit=${limit}&search=${search}`,
      requestOptions
    )
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded2: true,
            pageCount: Math.ceil(result.length / this.state.perPage),
            totalData: result.data.count,
            coachingData: result.data.rows,
          });
        },
        (error2) => {
          this.setState({
            isLoaded2: true,
            error2,
          });
        }
      );
  }

  applyGlobalSearch(e) {
    let value = e.target.value;
    let name = e.target.name;
    this.setState({
      [name]: value,
    });
    setTimeout(() => {
      this.serverRequest();
    }, 200);
  }

  async pageChange(e, data) {
    // e.preventDefault();
    let page = data.activePage;
    let pageNo = page === 1 ? 0 : (page - 1) * this.state.limit;
    await this.setState({
      skip: pageNo,
    });

    await this.serverRequest();
  }

  render() {
    const { coachingData, skip, ids } = this.state;

    return (
      <div>
        <AdminSidebar dataFromParent={this.props.location.pathname} />
        <AdminHeader />
        <div className="main_wrapper">
          <div className="inner_wraapper">
            <div className="container-fluid">
              <section className="d_text">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="col-sm-12">
                        <div className="color_div_on framwork_2">
                          <div className="business_detail">
                            <div className="">
                              <div className="heading">
                                <div style={{ display: "flex" }}>
                                  <h4 style={{ margin: "auto" }}>
                                    Request Coaching Data Lists
                                  </h4>
                                  {ids.length > 0 && (
                                    <span className="global_link mx-2">
                                      <button
                                        onClick={(e) => {
                                          this.handleDelete(e);
                                        }}
                                        className="link_bal_next page_width white"
                                      >
                                        <i className="fas fa-trash white" />
                                      </button>
                                    </span>
                                  )}
                                </div>
                                <hr className="line"></hr>
                                <div className="form-group has-search one">
                                  <span className="fa fa-search form-control-feedback"></span>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Search Module and Message"
                                    name="search"
                                    onChange={(e) => this.applyGlobalSearch(e)}
                                  />
                                </div>
                              </div>

                              <div className="saved_cards">
                                <div className="table_f">
                                  <Table striped bordered hover size="sm">
                                    <thead>
                                      <tr className="heading_color">
                                        <th>
                                          <input
                                            type="checkbox"
                                            checked={
                                              coachingData.filter(
                                                (result) =>
                                                  result?.isChecked !== true
                                              ).length < 1
                                            }
                                            onChange={(e) => {
                                              this.handleUniversalCheckbox(e);
                                            }}
                                          />
                                        </th>
                                        <th style={{ width: "5%" }}>Sr.</th>
                                        <th>Modules</th>
                                        <th>Request Message</th>
                                        <th>Date</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {coachingData.map((item, key) => (
                                        <tr key={key}>
                                          <td>
                                            <input
                                              id={item?.id}
                                              name={item?.id}
                                              type="checkbox"
                                              checked={
                                                item?.isChecked
                                                  ? item?.isChecked
                                                  : false
                                              }
                                              onChange={(e) => {
                                                this.handleSingleCheckbox(e);
                                              }}
                                            />
                                          </td>
                                          <td>{key + 1 + skip}</td>
                                          <td>{item.module}</td>
                                          <td>{item.message}</td>
                                          <td>
                                            <Moment
                                              format="DD-MMM-YYYY"
                                              withTitle
                                            >
                                              {item.createdAt}
                                            </Moment>
                                          </td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  </Table>
                                  <Pagination
                                    className="mx-auto pagination"
                                    defaultActivePage={1}
                                    onPageChange={(e, data) => {
                                      this.pageChange(e, data);
                                    }}
                                    ellipsisItem={{
                                      content: (
                                        <Icon name="ellipsis horizontal" />
                                      ),
                                      icon: true,
                                    }}
                                    firstItem={{
                                      content: (
                                        <Icon name="angle double left" />
                                      ),
                                      icon: false,
                                    }}
                                    lastItem={{
                                      content: (
                                        <Icon name="angle double right" />
                                      ),
                                      icon: true,
                                    }}
                                    prevItem={{
                                      content: <Icon name="angle left" />,
                                      icon: true,
                                    }}
                                    nextItem={{
                                      content: <Icon name="angle right" />,
                                      icon: true,
                                    }}
                                    totalPages={Math.ceil(
                                      this.state.totalData / this.state.limit
                                    )}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
