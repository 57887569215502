import React, { Component } from "react";
import AdminSidebar from "../sidebar/admin_sidebar";
import AdminHeader from "../header/admin_header";
import axios from "axios";
import { sweetAlert } from "../../utils/UniversalFunction";
import config from "../../config/config.json";
import "./control.css";
import { authenticationService } from "../../_services/authentication";
const currentUser = authenticationService.currentUserValue;

export default class EditSectorQuestions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      login: false,
      items: [],
      loading: false,
      title: "",
      heading: "",
      tab_name: "",
      industry_id: "",
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  }

  handleSubmit(event) {
    let uri = window.location.pathname.split("/");
    let id = uri[2];
    event.preventDefault();
    this.setState({ submitted: true });
    const { industry_id, tab_name, heading, title } = this.state;
    const headers = {
      Authorization: `Bearer ${currentUser.data.token}`,
      Accept: "application/json",
    };
    axios
      .post(
        config.API_URL + `updateSectorQuestion/${id}`,
        {
          industry_id: industry_id,
          tab_name: tab_name,
          heading: heading,
          title: title,
        },
        { headers }
      )
      .then((response) => {
        sweetAlert("success", response.data.message);
      })
      .catch(function (error) {
        if (error.response) {
          sweetAlert("error", error.response.data.message);
        }
      });
  }

  componentDidMount() {
    let uri = window.location.pathname.split("/");
    let id = uri[2];
    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${currentUser.data.token}`,
        "Content-Type": "application/json",
      },
    };
    fetch(config.API_URL + `getSingleSectorQuestion/${id}`, requestOptions)
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded2: true,
            industry_title: result.headings[0].industry_title,
            tab_name: result.headings[0].tab_name,
            heading: result.headings[0].heading,
            title: result.headings[0].questionTitle,
          });
        },
        (error2) => {
          this.setState({
            isLoaded2: true,
            error2,
          });
        }
      );
  }

  render() {
    const { industry_title, tab_name, heading, title } = this.state;
    return (
      <div>
        <AdminSidebar dataFromParent={this.props.location.pathname} />
        <AdminHeader />
        <div className="main_wrapper">
          <div className="inner_wraapper">
            <div className="container-fluid">
              <section className="d_text">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="col-sm-12">
                        <div className="color_div_on framwork_2">
                          <div className="business_detail">
                            <div className="">
                              <div className="saved_cards">
                                <form name="form" onSubmit={this.handleSubmit}>
                                  <div className="business_detail">
                                    <div className="heading">
                                      <h4>Update Sector Question</h4>
                                    </div>
                                    <hr className="line"></hr>

                                    <div className="row">
                                      <div className="col-lg-6 col-xs-6">
                                        <div className="form-group">
                                          <label
                                            htmlFor="industryName"
                                            className="mb-2"
                                          >
                                            Industry Name*
                                          </label>
                                          <input
                                            type="text"
                                            className="form-control py-3"
                                            id="industryName"
                                            value={industry_title}
                                            disabled
                                            placeholder="Select Industry Name"
                                            name="industry_id"
                                            onChange={this.handleChange}
                                          />
                                        </div>
                                      </div>
                                      <div className="col-lg-6 col-xs-6">
                                        <div className="form-group">
                                          <label
                                            htmlFor="questionTab"
                                            className="mb-2"
                                          >
                                            Question Tabs*
                                          </label>
                                          <input
                                            type="text"
                                            className="form-control py-3"
                                            id="questionTab"
                                            value={tab_name}
                                            disabled
                                            placeholder="Select Question Tabs"
                                            name="tab_name"
                                            onChange={this.handleChange}
                                          />
                                        </div>
                                      </div>

                                      <div className="col-lg-12 col-xs-12 mt-3">
                                        <div className="form-group">
                                          <label
                                            htmlFor="questionHeading"
                                            className="mb-2"
                                          >
                                            Question Heading
                                          </label>
                                          <input
                                            type="text"
                                            className="form-control py-3"
                                            id=" questionHeading"
                                            value={heading}
                                            placeholder="Enter Question Heading or Leave This Options"
                                            name="heading"
                                            onChange={this.handleChange}
                                          />
                                        </div>
                                      </div>
                                      <div className="form-group py-3">
                                        <label
                                          htmlFor="question"
                                          className="mb-2"
                                        >
                                          Sector Question*
                                        </label>
                                        <textarea
                                          type="text"
                                          className="form-control"
                                          id="question"
                                          value={title}
                                          placeholder="Write Sector Question title"
                                          name="title"
                                          onChange={this.handleChange}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="global_link mx-0 my-3">
                                    <button
                                      type="submit"
                                      className="page_width page_save"
                                    >
                                      UPDATE NOW
                                    </button>
                                  </div>
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
